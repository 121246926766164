import { colors } from "./colors";
import { fontWeightConfig } from "../common";

export const fontsConfiguration = {
  pageTitle: {
    size: "18px",
    weight: fontWeightConfig.medium,
    color: colors.textMain,
    lineHeight: "30px",
  },
  planTitle: {
    size: "24px",
    weight: fontWeightConfig.regular,
    color: colors.textMain,
    lineHeight: "40px",
  },
  pageTextImportantRegular: {
    size: "16px",
    weight: fontWeightConfig.regular,
    color: colors.textMain,
    lineHeight: "28px",
  },
  pageTextImportantmedium: {
    size: "16px",
    weight: fontWeightConfig.medium,
    color: colors.textMain,
    lineHeight: "28px",
  },
  pageText: {
    size: "14px",
    weight: fontWeightConfig.regular,
    colorMain: colors.textMain,
    colorSecondary: colors.textSecondary,
    colorLabel: colors.labelText,
    lineHeight: "24px",
  },
  cardTitle: {
    size: "18px",
    weight: fontWeightConfig.medium,
    color: colors.textMain,
    lineHeight: "34px",
  },
  inputLabel: {
    size: "13px",
    weight: fontWeightConfig.medium,
    color: colors.labelText,
  },
  filterLabel: {
    size: "12px",
    weight: fontWeightConfig.medium,
    color: colors.labelText,
  },
  otherLabel: {
    size: "13px",
    weight: fontWeightConfig.medium,
    color: colors.labelText,
  },
  buttonText: {
    size: "14px",
    weight: fontWeightConfig.medium,
  },
  placeholder: {
    size: "13px",
    weight: fontWeightConfig.regular,
    color: colors.textSecondary,
  },
};
