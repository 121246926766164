import {
  SIGN_UP_REQUEST,
  SIGN_UP_REQUEST_SUCCESS,
  SIGN_UP_REQUEST_FAILURE,
  ADD_REGISTRATION,
  ADD_REGISTRATION_SUCCESS,
  ADD_REGISTRATION_FAILURE,
  SIGN_UP_RESET,
} from "../../utils/constants";
import { createNotification } from "../../utils/notificationManager";

// ------------------------------------
// Action handler methods
// ------------------------------------
export const signUpRequestHandler = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const signUpRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      isLoading: false,
      redirect: true,
      getCredential: data.data,
    };
  }
  return {
    ...state,
    isLoading: false,
    getCredential: null,
  };
};

export const signUpRequestFailureHandler = (state, action) => {
  if (action.payload.response === undefined) {
    return {
      ...state,
      isLoading: false,
      redirect: false,
      getCredential: null,
    };
  }
  const {
    payload: {
      response: { data },
    },
  } = action;

  if (data.email !== undefined) {
    createNotification("error", data.email[0], 3000);
  } else if (data.username !== undefined) {
    createNotification("error", data.username[0], 3000);
  }
  return {
    ...state,
    isLoading: false,
    redirect: false,
    getCredential: null,
  };
};

export const signUpResetHandler = (state, action) => {
  return {
    ...state,
    isLoading: false,
    redirect: false,
    getCredential: null,
    isRegisteredLoading: false,
    paddleRegInfo: null,
    registeredInfo: null,
  };
};


export const addRegistrationHandler = (state, action) => {
  return {
    ...state,
    isRegisteredLoading: true,
  };
};

export const addRegistrationSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  console.log(data);
  if (data.status === 200 && data.data === "succeeded") {
    createNotification("success", "Payment successfully completed & Account Created.", 3000);
    return {
      ...state,
      isRegisteredLoading: false,
      registeredInfo: data.data,
    };
  }
  else if(data.status === 200 && data.data === "Added" && state.paddleRegInfo.SubscriptionPlanId == 1){
    createNotification("success", "Free Account Created.", 3000);
    return {
      ...state,
      isRegisteredLoading: false,
      registeredInfo: data.data,
    };
  }
  else if(data.status === 200 && data.data === "Exist"){
    createNotification("error", "Account Exist!!", 3000);
    return {
      ...state,
      isRegisteredLoading: false,
      registeredInfo: data.data,
    };
  }
  return {
    ...state,
    isRegisteredLoading: false,
    registeredInfo: null,
  };
};

export const addRegistrationFailureHandler = (state, action) => {
  createNotification("error", "Error in Registration", 3000);
  if (action.payload.response === undefined) {
    return {
      ...state,
      isRegisteredLoading: false,
      registeredInfo: null,
    };
  }
  return {
    ...state,
    isRegisteredLoading: false,
    registeredInfo: null,
  };
};

// Action Handlers
const ACTION_HANDLERS = {
  [SIGN_UP_REQUEST]: signUpRequestHandler,
  [SIGN_UP_REQUEST_SUCCESS]: signUpRequestSuccessHandler,
  [SIGN_UP_REQUEST_FAILURE]: signUpRequestFailureHandler,
  [ADD_REGISTRATION]: addRegistrationHandler,
  [ADD_REGISTRATION_SUCCESS]: addRegistrationSuccessHandler,
  [ADD_REGISTRATION_FAILURE]: addRegistrationFailureHandler,
  [SIGN_UP_RESET]: signUpResetHandler,
};

// default initial state
const initialState = {
  isLoading: false,
  redirect: false,
  getCredential: null,
  isRegisteredLoading: false,
  paddleRegInfo: null,
  registeredInfo: null,
};

export default function signUpReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
