import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import templates from "../../utils/templates";
import Select from "../../components/ReactSelectDropdown";
import FilterDropDownStyle from "../../components/DropdownStyle/FilterDropDownStyle";
import {
  Container,
  TemplateContainer,
  TemplateCard,
  FilterContainer,
  TemplateImage,
} from "../../styledComponents/dashboard";
import {
  PageTitle,
  CommonText,
  InputWithLabelSection,
  FilterLabel,
  InputField,
} from "../../styledComponents/common";

const Dashboard = (props) => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStatus, setSelectedStatus] = useState({
    value: "",
    label: "All",
  });
  const statusOptions = [
    { value: "", label: "All Receipts" },
    { value: "General Receipts", label: "general receipts" },
    { value: "Gas Petrol Fuel Receipt", label: "Gas Petrol Fuel Receipts" },
    { value: "Grocery Receipts", label: "Grocery Receipts" },
    { value: "Parking Receipts", label: "Parking Receipts" },
    { value: "Pharmacy Receipts", label: "Pharmacy Receipts" },
    { value: "Phone Internet Receipts", label: "Phone Internet Receipts" },
    { value: "Restaurant Receipts", label: "Restaurant Receipts" },
    { value: "Taxi Receipts", label: "Taxi Receipts" },
    { value: "Toll Receipts", label: "Toll Receipts" },
  ];
  const [filteredTemplate, setFilteredTemplate] = useState([]);

  useEffect(() => {
    if (templates && templates.length > 0) {
      let newList = [...templates];
      let filterExist = false;
      if (selectedStatus.value && selectedStatus.value !== "") {
        newList = newList.filter(
          (item) => item.category === selectedStatus.value
        );
        setFilteredTemplate(newList);
        filterExist = true;
      }
      if (searchTerm && searchTerm !== "") {
        newList = newList.filter((item) =>
          item.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredTemplate(newList);
        filterExist = true;
      }
      !filterExist && setFilteredTemplate(newList);
    }
  }, [selectedStatus.value, searchTerm]);

  const onSearchTermChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
  };

  const onStatusSelect = (e) => {
    setSelectedStatus(e);
  };

  const onRouteToGenerate = (templateId) => {
    props.clearGeneratedState();
    navigate(`/user/generate-receipt?id=${templateId}`);
  };

  return (
    <Container>
      <PageTitle>All Receipts</PageTitle>
      <FilterContainer>
        <InputWithLabelSection gap="10px">
          <FilterLabel>Choose Category</FilterLabel>
          <Select
            isSearchable
            value={selectedStatus}
            options={statusOptions}
            onChange={(e) => {
              onStatusSelect(e);
            }}
            styles={FilterDropDownStyle({ height: "39px" })}
          />
        </InputWithLabelSection>

        <InputWithLabelSection gap="10px">
          <FilterLabel>Search</FilterLabel>
          <InputField
            type="text"
            placeholder="Search"
            value={searchTerm}
            width="250px"
            onChange={(e) => onSearchTermChange(e)}
          />
        </InputWithLabelSection>
      </FilterContainer>
      <TemplateContainer>
        {filteredTemplate &&
          filteredTemplate.length > 0 &&
          filteredTemplate.map((template, index) => (
            <TemplateCard
              key={index}
              onClick={() => onRouteToGenerate(template.id)}
            >
              <CommonText name>{template.name}</CommonText>
              <TemplateImage src={template.image} alt={template.name} />
            </TemplateCard>
          ))}
      </TemplateContainer>
    </Container>
  );
};

export default Dashboard;
