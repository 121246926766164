/* eslint-disable no-nested-ternary */
const DropdownStyle = (props) => {
  return {
    container: (styles) => {
      return {
        ...styles,
        width: "100%",
        pointerEvents: "auto",
      };
    },
    control: (styles, state) => {
      return {
        ...styles,
        width: "100%",
        minHeight: "40px",
        border: state.menuIsOpen ? `1px solid #20bead` : `1px solid #e4e7eb`,
        borderRadius: "6px",
        background: "#fff",
        boxShadow: state.menuIsOpen && `0 0 0 4px #eaf9f7`,
        cursor: state.isDisabled ? "not-allowed" : "pointer",
        "&:hover": {
          borderColor: state.menuIsOpen ? `#20bead` : `#635BFF`,
          boxShadow: `0 0 0 4px #eaf9f7`,
        },
      };
    },
    menuList: (styles) => {
      return {
        ...styles,
        borderRadius: "6px",
        "::-webkit-scrollbar": {
          width: "6px",
        },
        "::-webkit-scrollbar-track": {
          background: "#eff1f8",
        },
        "::-webkit-scrollbar-thumb": {
          background: "#d2d9ea",
          borderRadius: "3px",
        },
        "::-webkit-scrollbar-thumb:hover": {
          background: "#b4bdd6",
        },
      };
    },
    menu: (styles) => {
      return {
        ...styles,
        marginTop: "12px",
        border: "1px solid #20bead",
        borderRadius: "6px",
        outline: "none",
        boxShadow: "none",
        zIndex: "5",
      };
    },
    option: (styles, { data, isDisabled, isSelected, isFocused }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#20bead" : "#fff",
        color: isSelected ? "#fff" : "rgba(61, 77, 105, 0.7)",
        cursor: isDisabled ? "not-allowed" : "pointer",
        fontFamily: '"Montserrat", sans-serif',
        fontSize: "13px",
        "&:hover": {
          backgroundColor: !isSelected && "#eaf9f7",
        },
      };
    },
    singleValue: (styles) => ({
      ...styles,
      fontSize: "13px",
      color: "#1f2939",
      fontFamily: '"Montserrat", sans-serif',
    }),
    placeholder: (styles) => ({
      ...styles,
      color: "rgba(61, 77, 105, 0.7)",
      fontSize: "13px",
    }),
    dropdownIndicator: (styles, state) => {
      return {
        ...styles,
        transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
        transition: "transform 0.35s ease",
      };
    },
    input: (styles, state) => ({
      ...styles,
      fontFamily: '"Montserrat", sans-serif',
      fontSize: "13px",
      color: "#1f2939",
    }),
  };
};

export default DropdownStyle;
