import React, { useEffect } from "react";
import myPlanIcon from "../../assets/img/icons/my_plan.svg";
import changePlanIcon from "../../assets/img/icons/change_plan.svg";
import changeCardIcon from "../../assets/img/icons/credit-card-change.svg";
import organizationIcon from "../../assets/img/icons/workspace.svg";
import emailIcon from "../../assets/img/icons/email-icon.svg";
import tickWhite from "../../assets/img/icons/tick_2.svg";

import {
  SettingsGridContainer,
  SettingsCard,
  WeeklyEmailCard,
  CheckboxDiv,
  CheckboxTickIcon,
} from "../../styledComponents/settings";
import { CommonFlex, CommonText } from "../../styledComponents/common";

import FeatureImageWithText from "../../components/FeatureImageWithText/FeatureImageWithText";

import settingsFeatureImg from "../../assets/img/pageImages/settings.png";

const CommonCard = ({ Title, Subtitle, Icon, history, onClickUrl, isLink }) => {
  return (
    <SettingsCard
      onClick={() => {
        if (isLink) {
          window.open(onClickUrl, "_blank");
        } else {
          history.push(onClickUrl);
        }
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <img src={Icon} alt="" style={{ height: "25px", width: "25px" }} />
        <span
          style={{
            fontSize: "14px",
            fontWeight: "600",
            marginLeft: "10px",
          }}
        >
          {Title}
        </span>
      </div>
      <div style={{ marginTop: "8px" }}>
        <span style={{ color: "rgba(61, 77, 105, 0.7)", fontSize: "13px" }}>
          {Subtitle}
        </span>
      </div>
    </SettingsCard>
  );
};

const WeeklyEmailSettingsCard = (props) => {
  const updateEmailSettings = (value) => {
    if (
      props.selectedOrganization &&
      props.selectedOrganization.id &&
      props.selectedOrganization.configuration &&
      props.selectedOrganization.configuration.id
    ) {
      const payload = {
        organization_id: props.selectedOrganization.id,
        configuration_id: props.selectedOrganization.configuration.id,
        receive_weekly_email: value,
      };
      props.updateWeeklyEmailSettings(payload);
    }
  };
  return (
    <WeeklyEmailCard>
      <div style={{ display: "flex", alignItems: "center" }}>
        <img src={emailIcon} alt="" style={{ height: "25px", width: "25px" }} />
        <span
          style={{ fontSize: "14px", fontWeight: "600", marginLeft: "10px" }}
        >
          Email Preferences
        </span>
      </div>
      <CommonFlex margin="12px 0 0 0" gap="10px">
        <CheckboxDiv
          active={props.weeklyEmail}
          onClick={() => updateEmailSettings(!props.weeklyEmail)}
        >
          <CheckboxTickIcon src={tickWhite} alt="" />
        </CheckboxDiv>
        <CommonText label fontWeight="500">
          Send weekly reports in email
        </CommonText>
      </CommonFlex>
    </WeeklyEmailCard>
  );
};

const Settings = ({
  history,
  selectedOrganization,
  settingsIsLoading,
  weeklyEmail,
  getWeeklyEmailSettings,
  updateWeeklyEmailSettings,
}) => {
  // useEffect(() => {
  //   if (
  //     selectedOrganization &&
  //     selectedOrganization.id &&
  //     selectedOrganization.configuration &&
  //     selectedOrganization.configuration.id
  //   ) {
  //     const payload = {
  //       organization_id: selectedOrganization.id,
  //       configuration_id: selectedOrganization.configuration.id,
  //     };

  //     getWeeklyEmailSettings(payload);
  //   }
  // }, [selectedOrganization]);
  return (
    <div className="content">
      <FeatureImageWithText
        image={settingsFeatureImg}
        title="Customize almost everything"
        subtitle="All the settings are available in the main app. Currently it's not available in the demo"
      />
      {/* <Row>
        <Col md={12}>
          <div>
            <p
              style={{
                fontSize: "20px",
                color: "rgba(59, 63, 71, 0.6)",
              }}
            >
              General
            </p>
          </div>
        </Col>
      </Row>
      <SettingsGridContainer>
        <CommonCard
          Title="Organization"
          Subtitle="Create or customize your organizations"
          Icon={organizationIcon}
          history={history}
          onClickUrl="/user/organization"
        />
      </SettingsGridContainer> */}
      {/* <Row>
        <Col md={12}>
          <div>
            <p
              style={{
                fontSize: "15px",
                fontWeight: "600",
                color: "rgba(61,77,105,0.7)",
                margin: "0 0 10px 5px",
              }}
            >
              Billing
            </p>
          </div>
        </Col>
      </Row>
      <SettingsGridContainer>
        <CommonCard
          Title="My Plan"
          Subtitle="View all information"
          Icon={myPlanIcon}
          history={history}
          onClickUrl="/user/current-plan"
        />
        <CommonCard
          Title="Change Subscription Plan"
          Subtitle="Upgrade to your favorite plan"
          Icon={changePlanIcon}
          history={history}
          onClickUrl="/user/subscription"
        />
        {selectedOrganization &&
          selectedOrganization.id &&
          selectedOrganization.subscription &&
          // selectedOrganization.subscription.status === "active" &&
          // selectedOrganization.subscription.paddlesubscription_active &&
          selectedOrganization.subscription.update_url && (
            <CommonCard
              Title="Change Card"
              Subtitle="Change payment method"
              Icon={changeCardIcon}
              isLink
              onClickUrl={selectedOrganization.subscription.update_url}
            />
          )}
      </SettingsGridContainer>
      <Row style={{ marginTop: "15px" }}>
        <Col md={12}>
          <div>
            <p
              style={{
                fontSize: "15px",
                fontWeight: "600",
                color: "rgba(61,77,105,0.7)",
                margin: "0 0 10px 5px",
              }}
            >
              Feature
            </p>
          </div>
        </Col>
      </Row>
      <SettingsGridContainer>
        <WeeklyEmailSettingsCard
          weeklyEmail={weeklyEmail}
          updateWeeklyEmailSettings={updateWeeklyEmailSettings}
          selectedOrganization={selectedOrganization}
        />
      </SettingsGridContainer> */}
    </div>
  );
};

export default Settings;
