import { useTheme } from "styled-components";
import {
  commonContainerStyles,
  commonControlStyles,
  commonOptionStyles,
  commonMenuListStyles,
  commonMenuStyles,
  commonSingleValueStyles,
  commonPlaceholderStyles,
  commonDropdownIndicatorStyles,
  commonInputStyles,
} from "./commonDropdownStyles";

const FilterDropDownStyle = (props) => {
  const theme = useTheme();
  return {
    container: (styles) => {
      return {
        ...commonContainerStyles(styles, theme),
      };
    },
    control: (styles, state) => {
      return {
        ...commonControlStyles(styles, state, theme),
        minHeight: props.height || "38px",
        minWidth: props.width || "230px",
        border: `1px solid ${
          props && props.error
            ? theme.colors.main.error
            : state.menuIsOpen
            ? theme.dropdown.borderColor.focus
            : theme.dropdown.borderColor.default
        }`,
        background:
          props && props.error
            ? `rgba(241, 87, 98, 0.05)`
            : theme.colors.main.white,
      };
    },
    option: (styles, state) => {
      return {
        ...commonOptionStyles(styles, state, theme),
      };
    },
    menuList: (styles) => {
      return {
        ...commonMenuListStyles(styles, theme),
      };
    },
    menu: (styles, state) => {
      return {
        ...commonMenuStyles(styles, state, theme),
        minWidth: props.menuWidth || "230px",
      };
    },
    singleValue: (styles) => ({
      ...commonSingleValueStyles(styles, theme),
    }),
    placeholder: (styles) => ({
      ...commonPlaceholderStyles(styles, theme),
    }),
    dropdownIndicator: (styles, state) => {
      return {
        ...commonDropdownIndicatorStyles(styles, state, theme),
      };
    },
    input: (styles, state) => ({
      ...commonInputStyles(styles, state, theme),
    }),
  };
};

export default FilterDropDownStyle;
