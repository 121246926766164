import {
  REQUEST_ORGANIZATION_LIST,
  SELECT_ORGANIZATION,
  CREATE_ORGANIZATION,
  GET_ORGANIZATION_TYPES,
  GET_ORGANIZATION_PLANS,
  UPDATE_ORGANIZATION,
  GET_ORGANIZATION_MEMBERS,
  DELETE_ORGANIZATION,
  CLEAR_ORG_CREATED,
} from "../../utils/constants";

// ------------------------------------
// Actions
// ------------------------------------
export function deleteOrganization(value) {
  return {
    type: DELETE_ORGANIZATION,
    payload: value,
  };
}

export function getOrganizationList() {
  return {
    type: REQUEST_ORGANIZATION_LIST,
  };
}

export function selectOrganization(id) {
  return {
    type: SELECT_ORGANIZATION,
    payload: id,
  };
}

export function getOrganizationTypes() {
  return {
    type: GET_ORGANIZATION_TYPES,
  };
}

export function getOrganizationPlans() {
  return {
    type: GET_ORGANIZATION_PLANS,
  };
}

export function createOrganization(data) {
  return {
    type: CREATE_ORGANIZATION,
    payload: data,
  };
}

export function updateOrganization(data) {
  return {
    type: UPDATE_ORGANIZATION,
    payload: data,
  };
}

export function getOrganizationMembers(data) {
  return {
    type: GET_ORGANIZATION_MEMBERS,
    payload: data,
  };
}

export function clearOrgCreated() {
  return {
    type: CLEAR_ORG_CREATED,
  };
}

export const actions = {
  getOrganizationList,
  selectOrganization,
  createOrganization,
  updateOrganization,
  getOrganizationTypes,
  getOrganizationMembers,
  deleteOrganization,
  clearOrgCreated,
};
