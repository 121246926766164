import React, { useState, useEffect } from "react";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import MyReceiptTable from "./MyReceiptsTable";
import DeletePopup from "../../components/Popup/DeletePopup";
import Select from "../../components/ReactSelectDropdown";
import DatePicker from "../../components/SingleDatePicker/SingleDatePicker";
import FilterDropDownStyle from "../../components/DropdownStyle/FilterDropDownStyle";
import {
  PageTitle,
  HeaderContainer,
  InputWithLabelSection,
  FilterLabel,
  InputField,
  Content,
  CommonGrid,
  CommonFlex,
} from "../../styledComponents/common";
import {
  WhiteButtonWithIcon,
  PrimaryButton,
  TabButtonContainer,
  TabButton,
  ColoredButtonWithIcon,
  ButtonIcon,
  ColoredButton,
} from "../../styledComponents/buttons";
import { Container } from "../../styledComponents/generateReceipt";
import { FilterContainer } from "../../styledComponents/myReceipts";

const MyReceipts = (props) => {
  const [selectItems, setSelectedItems] = useState([]);
  const [allSelect, setAllSelect] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [deleteCounter, setDeleteCounter] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredList, setFilteredList] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState({
    CategoryId: 0,
    value: "",
    label: "All Receipts",
  });
  const [receiptFromDate, setReceiptFromDate] = useState(
    moment().subtract(1, "months")
  );
  const [receiptToDate, setReceiptToDate] = useState(moment());
  const statusOptions = [
    { CategoryId: 0, value: "", label: "All Receipts" },
    { CategoryId: 1, value: "General Receipts", label: "General Receipts" },
    {
      CategoryId: 2,
      value: "Gas Petrol Fuel Receipts",
      label: "Gas Petrol Fuel Receipts",
    },
    { CategoryId: 3, value: "Grocery Receipts", label: "Grocery Receipts" },
    { CategoryId: 4, value: "Parking Receipts", label: "Parking Receipts" },
    { CategoryId: 5, value: "Pharmacy Receipts", label: "Pharmacy Receipts" },
    {
      CategoryId: 6,
      value: "Phone Internet Receipts",
      label: "Phone Internet Receipts",
    },
    {
      CategoryId: 7,
      value: "Restaurant Receipts",
      label: "Restaurant Receipts",
    },
    { CategoryId: 8, value: "Taxi Receipts", label: "Taxi Receipts" },
    { CategoryId: 9, value: "Toll Receipts", label: "Toll Receipts" },
  ];
  const navigate = useNavigate();

  useEffect(() => {
    if (props.userId) {
      let payload = {
        CategoryId: 0,
        UserId: props.userId,
        RecieptFromDate: receiptFromDate.format("MM/DD/YYYY"),
        RecieptToDate: receiptToDate.format("MM/DD/YYYY"),
      };
      props.searchBillReceipt(payload);
    }
  }, []);

  useEffect(() => {
    if (
      props.billReceiptList &&
      props.billReceiptList.length > 0 &&
      !selectedStatus.value &&
      !searchTerm
    ) {
      setFilteredList(props.billReceiptList);
    }
    // if (campaignList && campaignList.count) {
    //   let pages = Math.ceil(campaignList.count / numberPerPage);
    //   let tempArr = [];
    //   for (let i = 1; i <= pages; i++) {
    //     tempArr.push(i);
    //   }
    //   setPagesArray(tempArr);
    // }
  }, [props.billReceiptList]);

  useEffect(() => {
    if (filteredList && filteredList.length > 0) {
      let tempArray = [];
      filteredList.forEach((element) => {
        tempArray.push({ id: "" });
      });
      setSelectedItems(tempArray);
    }
  }, [filteredList]);

  useEffect(() => {
    if (
      filteredList &&
      selectItems.length > 0 &&
      selectItems.length === filteredList.length
    ) {
      let haveAnyId = false;
      selectItems.forEach((element) => {
        if (element.id !== "") {
          haveAnyId = true;
        }
      });
      setShowDelete(haveAnyId);
    }
  }, [selectItems]);

  useEffect(() => {
    if (props.deletedReceiptCallback && isDeleted && props.userId) {
      popupToggle();
      let payload = {
        CategoryId: 0,
        UserId: props.userId,
        RecieptFromDate: receiptFromDate.format("MM/DD/YYYY"),
        RecieptToDate: receiptToDate.format("MM/DD/YYYY"),
      };
      props.searchBillReceipt(payload);
      setAllSelect(false);
    }
  }, [props.deletedReceiptCallback]);

  useEffect(() => {
    if (props.billReceiptList && props.billReceiptList.length > 0) {
      let newList = [...props.billReceiptList];
      let filterExist = false;
      if (selectedStatus.value && selectedStatus.value !== "") {
        newList = newList.filter(
          (item) => item.CategoryName === selectedStatus.value
        );
        setFilteredList(newList);
        filterExist = true;
      }
      if (searchTerm && searchTerm !== "") {
        newList = newList.filter((item) =>
          item.Business.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredList(newList);
        filterExist = true;
      }
      !filterExist && setFilteredList(newList);
    }
  }, [props.billReceiptList, selectedStatus.value, searchTerm]);

  const onStatusSelect = (e) => {
    setSelectedStatus(e);
  };

  const onSearchTermChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
  };

  const onReceiptFromDateChange = (date) => {
    setReceiptFromDate(date);
  };

  const onReceiptToDateChange = (date) => {
    setReceiptToDate(date);
  };

  const onFilterSearchBillReceipt = () => {
    let payload = {
      CategoryId: 0,
      UserId: props.userId,
      RecieptFromDate: receiptFromDate.format("MM/DD/YYYY"),
      RecieptToDate: receiptToDate.format("MM/DD/YYYY"),
    };
    props.searchBillReceipt(payload);
  };

  const toogleSelectAllCampaign = () => {
    setAllSelect(!allSelect);
    let tempArray = [...selectItems];
    if (allSelect) {
      tempArray.forEach((item) => {
        item.id = "";
      });
      setSelectedItems(tempArray);
    } else {
      filteredList.forEach((item, index) => {
        tempArray[index].id = item.BillRecieptId;
      });
      setSelectedItems(tempArray);
    }
  };

  const toggleSelectCampaign = (id, index) => {
    let tempArray = [...selectItems];
    tempArray[index].id === ""
      ? (tempArray[index].id = id)
      : (tempArray[index].id = "");
    setSelectedItems(tempArray);
  };

  const popupToggle = () => {
    setPopupOpen(!popupOpen);
  };

  const onDeleteReceipt = () => {
    selectItems.forEach((item) => {
      if (item && item.id) {
        props.deleteBillReceiptById({
          bill_reciept_id: item.id,
        });
        setIsDeleted(true);
        setShowDelete(false);
        setDeleteCounter((deleteCounter) => deleteCounter + 1);
      }
    });
  };

  return (
    <Container gap="2em" alignContent="start" minHeight="calc(100vh - 71px)">
      {selectItems &&
        filteredList &&
        selectItems.length === filteredList.length &&
        popupOpen && (
          <DeletePopup
            type="receipt"
            isOpen={popupOpen}
            toggle={popupToggle}
            isDeleteLoading={props.isDeleteLoading}
            onDelete={onDeleteReceipt}
          />
        )}
      <PageTitle>My Receipts</PageTitle>
      <CommonFlex gap="15px" style={{ marginLeft: `auto` }}>
        {showDelete && (
          <ColoredButton type="delete" onClick={() => popupToggle()}>
            Delete
          </ColoredButton>
        )}
        <PrimaryButton nowrap onClick={() => navigate("/user/dashboard")}>
          Create New
        </PrimaryButton>
      </CommonFlex>
        <FilterContainer>
          <InputWithLabelSection gap="10px">
            <FilterLabel>Choose Category</FilterLabel>
            <Select
              isSearchable
              value={selectedStatus}
              options={statusOptions}
              onChange={(e) => {
                onStatusSelect(e);
              }}
              styles={FilterDropDownStyle({ height: "39px" })}
            />
          </InputWithLabelSection>

          <InputWithLabelSection gap="10px">
            <FilterLabel>Search</FilterLabel>
            <InputField
              type="text"
              placeholder="Search by business name"
              value={searchTerm}
              width="250px"
              onChange={(e) => onSearchTermChange(e)}
            />
          </InputWithLabelSection>

          <InputWithLabelSection style={{ minWidth: `230px` }} gap="10px">
            <FilterLabel>Receipt From Date</FilterLabel>
            <DatePicker
              id="receipFromtDate"
              date={receiptFromDate}
              onDateChange={onReceiptFromDateChange}
              dateDisplayFormat={"DD/MM/YYYY"}
              placeholder={"Select date"}
              allowPreviousDates
            />
          </InputWithLabelSection>

          <InputWithLabelSection style={{ minWidth: `230px` }} gap="10px">
            <FilterLabel>Receipt To Date</FilterLabel>
            <DatePicker
              id="receiptToDate"
              date={receiptToDate}
              onDateChange={onReceiptToDateChange}
              dateDisplayFormat={"DD/MM/YYYY"}
              placeholder={"Select date"}
              allowPreviousDates
            />
          </InputWithLabelSection>
          <PrimaryButton onClick={() => onFilterSearchBillReceipt()}>
            Apply
          </PrimaryButton>
        </FilterContainer>
      <MyReceiptTable
        billReceiptList={filteredList}
        isLoading={props.isLoading}
        selectItems={selectItems}
        allSelect={allSelect}
        toogleSelectAllCampaign={toogleSelectAllCampaign}
        toggleSelectCampaign={toggleSelectCampaign}
        popupToggle={popupToggle}
      />
    </Container>
  );
};

export default MyReceipts;
