import styled from "styled-components";

export const NoPlanContainer = styled.div`
  display: grid;
  grid-template-columns: 100%;
  justify-items: center;
`;

export const NoPlanArtwork = styled.img`
  height: 185px;
`;

export const NoPlanMainText = styled.div`
  font-size: ${({ theme }) => theme.font.pageText.size};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  text-align: center;
`;
export const NoPlanSecondaryText = styled.div`
  font-size: 13px;
  text-align: center;
  color: ${({ theme }) => theme.colors.main.labelText};
  margin-top: 20px;
`;

// Trial End Bar
export const TrialEndBarWrapper = styled.div`
  display: ${({ show }) => (show ? "block" : "none")};
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5000;
`;

export const TrialEndBarContainer = styled.div`
  padding: 8px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ status, theme }) =>
    status === "suspended" || status === "cancelled" || status === "paused"
      ? theme.colors.main.error
      : theme.colors.main.textSecondary};
`;

export const TrialEndText = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.main.white};
`;

// change subscription current plan badge
export const SubscriptionCurrentPlanBadge = styled.div`
  display: ${({ isCurrent }) => (isCurrent ? `flex` : `none`)};
  justify-content: center;
  align-items: center;
  padding: 6px 12px 2px;
  border-radius: 12px 12px 0px 0px;
  background: ${({ plan }) =>
    plan === "Elite"
      ? "#d2e4ff"
      : plan === "Premium"
      ? "#f2f2fb"
      : plan === "Standard"
      ? "#eaf2f9"
      : "#eaf9f7"};
  position: absolute;
  left: 20px;
  top: -25px;
`;
export const CurrentPlanDot = styled.div`
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: ${({ plan }) =>
    plan === "Elite"
      ? "#4D94FF"
      : plan === "Premium"
      ? "#9A96F9"
      : plan === "Standard"
      ? "#5D687E"
      : "#20BEAD"};
`;
export const CurrentPlanBadgeText = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: #5d687e;
  margin-left: 5px;
`;

// discount
export const DiscountTextAndTagDiv = styled.div`
  display: flex;
  align-items: center;
`;
export const CouponAlert = styled.div`
  font-size: 13px;
  color: #f15762;
  margin-top: 10px;
`;

export const SeatedDiscountDiv = styled.div`
  border-radius: 6px;
  background-color: #fdf3e4;
  color: #f3a936;
  padding: 12px 30px;
  min-width: 600px;
  font-size: 14px;
  font-weight: 600;
  margin: 15px 0px;
  width: fit-content;
`;

// yearly save badge
export const YearlySaveBadge = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -28px;
  left: -50px;
  padding: 3px 10px;
  border-radius: 6px;
  font-size: 13px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  color: ${({ isActive }) => (isActive ? `#fdf3e4` : `#FFC107`)};
  background-color: ${({ isActive }) => (isActive ? `#FFC107` : `#fdf3e4`)};
  transform: rotate(-25deg);
  transition: all 0.12s ease;
  &::before,
  ::after {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &::after {
    border-color: rgba(255, 255, 255, 0);
    border-top-color: ${({ isActive }) => (isActive ? `#FFC107` : `#fdf3e4`)};
    border-width: 6px;
    margin-left: -6px;
  }
  &::before {
    border-color: rgba(194, 204, 225, 0);
    border-top-color: ${({ isActive }) => (isActive ? `#FFC107` : `#fdf3e4`)};
    border-width: 7px;
    margin-left: -7px;
  }
`;

// secure payment section
export const SecurePaymentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const SecurePaymentIcon = styled.img``;

export const CancelCircle = styled.div`
  position: absolute;
  right: ${({ right }) => right || `-40px`};
  top: ${({ top }) => top || `-20px`};
  display: flex;
  justify-content: center;
  width: ${({ width }) => width || `35px`};
  height: ${({ height }) => height || `35px`};
  border-radius: 50%;
  cursor: pointer;
  background-color: #fff;
  border: ${({ border, theme }) =>
    border || `1px solid ${theme.colors.border.secondary}`};
`;
export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(230px, 275px));
  gap: 30px;
  justify-content: center;
  padding: 3em;
  @media (max-width: 1000px) {
    grid-template-columns: repeat(auto-fill, minmax(230px, 275px));
  }
`;
export const Card = styled.div`
  position: relative;
  display: grid;
  align-content: space-between;
  gap: 2em;
  padding: 30px;
  border: 1px solid #ffffff;
  border: ${({ yearly }) => yearly && `1px solid #635BFF`};
  border-radius: 10px;
  box-shadow: 0 20px 40px #94a6ce29;
  background-color: #fff;
  opacity: ${({ plan }) => plan && `0.3`};
  z-index: ${({ plan }) => plan && `-1`};
  &:hover {
    border-color: ${({ hoverBorder }) => hoverBorder && hoverBorder};
  }
`;
export const CurrentPlanTab = styled.div`
  position: absolute;
  top: 42%;
  left: 18%;
  border-radius: 10px;
  color: #fff;
  padding: 10px 30px;
  font-size: 14px;
  background-color: ${({ bgColor }) => bgColor || `#635BFF`};
`;
export const CardTitle = styled.h4`
  color: ${({ color }) => color || `#635BFF`};
  font-size: 600;
  text-align: center;
  margin-bottom: 0%;
`;
export const PricingSection = styled.div`
  padding: 20px;
  background-color: ${({ bgColor }) => bgColor || `#635BFF`};
  border-radius: 10px;
  margin: 0 -15px;
  text-align: center;
`;
export const CardPricingTitle = styled.p`
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 10px;
  color: #ffffff;
`;
export const CardPricingText = styled.p`
  color: #fff;
  font-size: 11px;
`;
export const CardFeatureTextSection = styled.div`
  display: grid;
  grid-template-columns: 22px 1fr;
  gap: 15px 10px;
  align-items: center;
`;
export const TickCircle = styled.div`
  display: flex;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #b9c3e6;
  padding: 4px;
`;
export const FeatureText = styled.p`
  font-size: 14px;
  margin: 0;
  @media (max-width: 575px) {
    font-size: 13px;
  }
`;
