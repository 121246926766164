import { all, fork } from "redux-saga/effects";

// import profileSagas from "./pages/UserProfile/userProfileSagas";
import loginSagas from "./pages/Login/loginSagas";
import signUpSagas from "./pages/SignUp/signUpSagas";
import forgotPasswordSagas from "./pages/ForgotPassword/forgotPasswordSagas";
import organizationSagas from "./pages/Organization/OrganizationSagas";
import invoiceSagas from "./pages/Invoice/invoiceSagas";
import generateReceiptSagas from "./pages/GenerateReceipt/generateReceiptSagas";
import myReceiptsSagas from "./pages/MyReceipts/myReceiptsSagas";
// import requestJoinOrgSaga from "./pages/Onboarding/RequestJoinOrg/requestJoinOrgSaga";
// import onboardingSaga from "./pages/Onboarding/OnboardingSteps/onboardingStepsSagas";
// import redeemCodeSaga from "./pages/RedeemCode/redeemCodeSaga";
// import redeemDiscountCodeSaga from "./pages/RedeemBlackFriday/redeemDiscountCodeSaga";
// import redeemLimitedTimeCodeSaga from "./pages/RedeemAppsumo/redeemCodeSaga";
// import adminSaga from "./layouts/Admin/adminSagas";
import subscriptionSagas from "./pages/Subscription/subscriptionSagas";
// import settingsSaga from "./pages/Settings/settingsSagas";
// import activitySettingsSaga from "./pages/ActivitySettings/activitySettingsSagas";

/**
 * rootSaga
 */
export default function* root() {
  yield all([
    fork(loginSagas),
    fork(signUpSagas),
    fork(forgotPasswordSagas),
    // fork(resetPasswordSagas),
    // fork(profileSagas),
    fork(organizationSagas),
    fork(invoiceSagas),
    fork(generateReceiptSagas),
    fork(myReceiptsSagas),
    // fork(requestJoinOrgSaga),
    // fork(onboardingSaga),
    // fork(redeemCodeSaga),
    // fork(redeemDiscountCodeSaga),
    // fork(redeemLimitedTimeCodeSaga),
    fork(subscriptionSagas),
    // fork(adminSaga),
    // fork(settingsSaga),
    // fork(activitySettingsSaga),
  ]);
}
