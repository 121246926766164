import { connect } from "react-redux";

// Component
import GenerateReceipt from "./GenerateReceipt";

// Actions
import {
  getFontList,
  getTaxList,
  getCategoryReceiptById,
  addUpdateBillReceipt,
  getBillReceiptById,
} from "./generateReceiptActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  userId: localStorage.getItem("user_id"),
  isLoading: state.generateReceipt.isLoading,
  fontListLoading: state.generateReceipt.fontListLoading,
  taxListLoading: state.generateReceipt.taxListLoading,
  isReceiptLoading: state.generateReceipt.isReceiptLoading,
  fontList: state.generateReceipt.fontList,
  taxList: state.generateReceipt.taxList,
  receiptByIdInfo: state.generateReceipt.receiptByIdInfo,
  receiptID: state.generateReceipt.receiptID,
  generatedReceiptInfo: state.generateReceipt.generatedReceiptInfo,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  getFontList: (details) => dispatch(getFontList({ ...details })),
  getTaxList: (details) => dispatch(getTaxList({ ...details })),
  getCategoryReceiptById: (details) =>
    dispatch(getCategoryReceiptById({ ...details })),
  addUpdateBillReceipt: (details) =>
    dispatch(addUpdateBillReceipt({ ...details })),
  getBillReceiptById: (details) => dispatch(getBillReceiptById({ ...details })),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(GenerateReceipt);
