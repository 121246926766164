import {
  GET_WEEKLY_EMAIL_SETTINGS,
  GET_WEEKLY_EMAIL_SETTINGS_SUCCESS,
  GET_WEEKLY_EMAIL_SETTINGS_FAILURE,
  UPDATE_WEEKLY_EMAIL_SETTINGS,
  UPDATE_WEEKLY_EMAIL_SETTINGS_SUCCESS,
  UPDATE_WEEKLY_EMAIL_SETTINGS_FAILURE,
} from "../../utils/constants";
import { createNotification } from "../../utils/notificationManager";

// ------------------------------------
// Action handler methods
// ------------------------------------
export const getWeeklyEmailSettingsHandler = (state, action) => {
  return {
    ...state,
    isLoading: false,
  };
};
export const getWeeklyEmailSettingsSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      isLoading: false,
      weeklyEmail: data.data.receive_weekly_email,
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};
export const getWeeklyEmailSettingsFailureHandler = (state, action) => {
  return {
    ...state,
    isLoading: false,
    weeklyEmail: false,
  };
};

export const updateWeeklyEmailSettingsHandler = (state, action) => {
  return {
    ...state,
    isLoading: false,
  };
};
export const updateWeeklyEmailSettingsSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    createNotification("success", "Weekly email settings updated successfully.");
    return {
      ...state,
      isLoading: false,
      weeklyEmail: data.data.receive_weekly_email,
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};
export const updateWeeklyEmailSettingsFailureHandler = (state, action) => {
  createNotification("error", "Weekly email settings updated failure. Please try again.");
  return {
    ...state,
    isLoading: false,
    weeklyEmail: false,
  };
};

// Action Handlers
const ACTION_HANDLERS = {
  [GET_WEEKLY_EMAIL_SETTINGS]: getWeeklyEmailSettingsHandler,
  [GET_WEEKLY_EMAIL_SETTINGS_SUCCESS]: getWeeklyEmailSettingsSuccessHandler,
  [GET_WEEKLY_EMAIL_SETTINGS_FAILURE]: getWeeklyEmailSettingsFailureHandler,

  [UPDATE_WEEKLY_EMAIL_SETTINGS]: updateWeeklyEmailSettingsHandler,
  [UPDATE_WEEKLY_EMAIL_SETTINGS_SUCCESS]:
    updateWeeklyEmailSettingsSuccessHandler,
  [UPDATE_WEEKLY_EMAIL_SETTINGS_FAILURE]:
    updateWeeklyEmailSettingsFailureHandler,
};

// default initial state
const initialState = {
  isLoading: false,
  weeklyEmail: false,
};

export default function settingsReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
