export const colors = {
  primary: "#635BFF",
  secondary : "#132742",
  textMain: "#132742",
  textSecondary: "#3d4d69",
  labelText: "#617186",
  placeholderText: "rgba(97, 113, 134, 0.7)",
  success: "#36C451",
  warning: "#FFA71F",
  error: "#FE5969",
  primaryLight: "#F2F4F8",
  successLight: "#f0fbf9",
  warningLight: "#f2c741",
  errorLight: "#fff6f7",

  primaryDisabled: "#dce2ef",
  errorDisabled: "#ffc7cc",

  purple: "#6f7aff",
  purpleLight: "#9a96f9",
  blue: "#00D4FF",
  blueLight: "#d2e4fe",
  gray: "#7f9dbf", // mark as paid
  grayLight: "#9eafc2", // save as draft

  // plan gray color
  planGray: "#6686a2",
  playGrayLight: "#eaf2f9",

  blackButton: "#465065",
  focusShadow: "#F6F6FF",

  white: "#fff",
  whiteBg: "#F6F9FB",
};

export const hoverColors = {
  primary: "#5C59F0",
  error: "#ed5160",
  purpleLight: "#8985ea",
  blue: "#3f86f0",
  gray: "#6d8caf",
  grayLight: "#8ea1b5",
  planGray: "#617f9a",
  blackButton: "#1f2939",
};

export const borderColors = {
  primary: colors.primary,
  secondary: "#c2cce1",
  secondaryLight: "#e4e7eb",
  blackButton: "#1f2939",
  error: colors.error,
};

export const hoverBorderColors = {
  secondaryLight: "#635BFF",
};
