import template1 from "../assets/img/templates/template-1.jpg";
import template2 from "../assets/img/templates/template-2.jpg";
import template3 from "../assets/img/templates/template-3.jpg";
import template4 from "../assets/img/templates/template-4.jpg";
import template5 from "../assets/img/templates/template-5.jpg";
import template6 from "../assets/img/templates/template-6.jpg";
import template7 from "../assets/img/templates/template-7.jpg";
import template8 from "../assets/img/templates/template-8.jpg";
import template9 from "../assets/img/templates/template-9.jpeg";
import template10 from "../assets/img/templates/template-10.jpg";
import template11 from "../assets/img/templates/template-11.jpg";
import template12 from "../assets/img/templates/template-12.jpg";
import template13 from "../assets/img/templates/template-13.jpg";
import template14 from "../assets/img/templates/template-14.jpg";
import template15 from "../assets/img/templates/template-15.jpg";
import template16 from "../assets/img/templates/template-16.jpg";
import template17 from "../assets/img/templates/template-17.jpg";
import template18 from "../assets/img/templates/template-18.jpg";
import template19 from "../assets/img/templates/template-19.jpg";
import template20 from "../assets/img/templates/template-20.jpg";
import template21 from "../assets/img/templates/template-21.jpg";
import template22 from "../assets/img/templates/template-22.jpg";
import template23 from "../assets/img/templates/template-23.jpg";
import template24 from "../assets/img/templates/template-24.jpg";
import template25 from "../assets/img/templates/template-25.jpg";
import template26 from "../assets/img/templates/template-26.jpg";
import template27 from "../assets/img/templates/template-27.jpg";
import template28 from "../assets/img/templates/template-28.jpg";
import template29 from "../assets/img/templates/template-29.jpg";
import template30 from "../assets/img/templates/template-30.jpg";
import template31 from "../assets/img/templates/template-31.jpg";
import template32 from "../assets/img/templates/template-32.jpg";
import template33 from "../assets/img/templates/template-33.jpg";
import template34 from "../assets/img/templates/template-34.jpg";
import template35 from "../assets/img/templates/template-35.jpg";
import template36 from "../assets/img/templates/template-36.png";
import template37 from "../assets/img/templates/template-37.jpg";
import template38 from "../assets/img/templates/template-38.jpg";
import template39 from "../assets/img/templates/template-39.jpg";
import template40 from "../assets/img/templates/template-40.jpg";
import template41 from "../assets/img/templates/template-41.jpg";
import template42 from "../assets/img/templates/template-42.jpg";
import template43 from "../assets/img/templates/template-43.jpg";
import template44 from "../assets/img/templates/template-44.png";
import template45 from "../assets/img/templates/template-45.png";
import template46 from "../assets/img/templates/template-46.jpg";
import template47 from "../assets/img/templates/template-47.jpg";
import template48 from "../assets/img/templates/template-48.png";
import template49 from "../assets/img/templates/template-49.png";
import template50 from "../assets/img/templates/template-50.jpg";
import template51 from "../assets/img/templates/template-51.jpg";
import template52 from "../assets/img/templates/template-52.jpg";
import template53 from "../assets/img/templates/template-53.jpg";
import template54 from "../assets/img/templates/template-54.jpg";

const templates = [
  {
    id: 2,
    name: "1. General-Receipt-1",
    category: "General Receipts",
    image: template1,
    formInputs: [
      "FontId",
      "RecieptDate",
      "Business",
      "Address",
      "CityState",
      "CurrencyId",
      "PaymentType",
      "Trans",
      "Mcc",
      "BillRecieptTaxInfoDc",
      "BillRecieptItemInfoDc"
    ],
  },
  {
    id: 3,
    name: "2. General-Receipt-Logo-2",
    category: "General Receipts",
    image: template2,
    formInputs: [
      "FontId",
      "RecieptDate",
      "Business",
      "Address",
      "CityState",
      "CurrencyId",
      "PaymentType",
      "Auth",
      "ApprCode",
      "RecieptLogo",
      "BillRecieptTaxInfoDc",
      "BillRecieptItemInfoDc"
    ],
  },

  {
    id: 4,
    name: "3. General-Simple Receipt",
    category: "General Receipts",
    image: template3,
    formInputs: [
      "FontId",
      "RecieptDate",
      "Business",
      "Address",
      "CityState",
      "CurrencyId",
      "PaymentType",
      "Trans",
      "Mcc",
      "BillRecieptTaxInfoDc",
      "BillRecieptItemInfoDc"
    ],
  },

  {
    id: 5,
    name: "4. Generic Receipt",
    category: "General Receipts",
    image: template4,
    formInputs: [
      "FontId",
      "RecieptDate",
      "Business",
      "Address",
      "MobileNo",
      "CurrencyId",
      "PaymentType",
      "Trans",
      "Auth",
      "BillRecieptTaxInfoDc",
      "BillRecieptItemInfoDc"
    ],
  },

  {
    id: 6,
    name: "5. Map Receipt",
    category: "General Receipts",
    image: template5,
    formInputs: [
      "FontId",
      "RecieptDate",
      "Business",
      "Address",
      "CityState",
      "CurrencyId",
      "PaymentType",
      "Trans",
      "Auth",
      "BillRecieptTaxInfoDc",
      "BillRecieptItemInfoDc"
    ],
  },

  {
    id: 7,
    name: "6. Gas Fuel Receipt",
    category: "Gas Petrol Fuel Receipt",
    image: template6,
    formInputs: [
      "FontId",
      "RecieptDate",
      "Business",
      "Address",
      "RecieptNo",
      "CurrencyId",
      "PaymentType",
      "Auth",
      "ApprCode",
      "WebSiteName",
      "DocNo",
      "Pump",
      "BillRecieptItemInfoDc"
    ],
  },
  {
    id: 8,
    name: "7. Gas Petrol 1",
    category: "Gas Petrol Fuel Receipt",
    image: template7,
    formInputs: [
      "FontId",
      "RecieptDate",
      "Business",
      "Address",
      "MobileNo",
      "LandlineNo",
      "CurrencyId",
      "Trans",
      "GSTNo",
      "BillNo",
      "AtndId",
      "FPId",
      "NozlNo",
      "Fuel",
      "Density",
      "VehicleNo",
      "RecieptLogo",
      "BillRecieptItemInfoDc"
    ],
  },

  {
    id: 9,
    name: "8. Gas petrol-indian-oil",
    category: "Gas Petrol Fuel Receipt",
    image: template8,
    formInputs: [
      "FontId",
      "RecieptDate",
      "Business",
      "Address",
      "RecieptNo",
      "CityState",
      "MobileNo",
      "LandlineNo",
      "CurrencyId",
      "PaymentType",
      "Pump",
      "NozlNo",
      "TinNo",
      "TRXId",
      "VehicleNo",
      "VehicleType",
      "RecieptLogo",
      "BillRecieptItemInfoDc"
    ],
  },

  {
    id: 10,
    name: "9. Gas Petrol Receipt",
    category: "Gas Petrol Fuel Receipt",
    image: template9,
    formInputs: [
      "FontId",
      "RecieptDate",
      "Business",
      "Address",
      "PickupAddress",
      "DropAddress",
      "RecieptNo",
      "CurrencyId",
      "PaymentType",
      "Auth",
      "Pump",
      "AccountNo",
      "BillRecieptItemInfoDc"
    ],
  },
  {
    id: 11,
    name: "10. Gas petrol-receipt-sample1",
    category: "Gas Petrol Fuel Receipt",
    image: template10,
    formInputs: [
      "FontId",
      "RecieptDate",
      "Business",
      "Address",
      "RecieptNo",
      "CurrencyId",
      "PompaSalans",
      "NomarNota",
      "JenisBBM",
      "Operator",
      "BillRecieptItemInfoDc"
    ],
  },

  {
    id: 12,
    name: "11. Gas Fuel Receipt 3",
    category: "Gas Petrol Fuel Receipt",
    image: template11,
    formInputs: [
      "FontId",
      "RecieptDate",
      "Business",
      "Address",
      "CityState",
      "CurrencyId",
      "PaymentType",
      "Trans",
      "Auth",
      "BillRecieptItemInfoDc"
    ],
  },

  {
    id: 13,
    name: "12. Gas Receipt",
    category: "Gas Petrol Fuel Receipt",
    image: template12,
    formInputs: [
      "FontId",
      "RecieptDate",
      "Business",
      "Address",
      "CityState",
      "CurrencyId",
      "PaymentType",
      "Auth",
      "Pump",
      "StationNo",
      "BillRecieptTaxInfoDc",
      "BillRecieptItemInfoDc"
    ],
  },

  {
    id: 14,
    name: "13. Modern Gas Receipt",
    category: "Gas Petrol Fuel Receipt",
    image: template13,
    formInputs: [
      "FontId",
      "RecieptDate",
      "Business",
      "Address",
      "RecieptNo",
      "CurrencyId",
      "Auth",
      "WebSiteName",
      "Pump",
      "AccountNo",
      "BillRecieptItemInfoDc"
    ],
  },

  {
    id: 15,
    name: "14. Grocery-big-bazaar",
    category: "Grocery Receipts",
    image: template14,
    formInputs: [
      "FontId",
      "RecieptDate",
      "Business",
      "Address",
      "CityState",
      "MobileNo",
      "LandlineNo",
      "HelplineNo",
      "CurrencyId",
      "TinNo",
      "CINNo",
      "PayAmount",
      "BarCode",
      "BillRecieptTaxInfoDc",
      "BillRecieptItemInfoDc"
    ],
  },

  {
    id: 16,
    name: "15. Grocery-BigBox Store",
    category: "Grocery Receipts",
    image: template15,
    formInputs: [
      "FontId",
      "RecieptDate",
      "Business",
      "Address",
      "CityState",
      "MobileNo",
      "CurrencyId",
      "Trans",
      "ApprCode",
      "AccountNo",
      "STNo",
      "OPNo",
      "TENo",
      "REFNo",
      "Validation",
      "PaymentService",
      "TerminalId",
      "TRNNo",
      "TCId",
      "PayAmount",
      "RecieptLogo",
      "BarCode",
      "BillRecieptTaxInfoDc",
      "BillRecieptItemInfoDc"
    ],
  },

  {
    id: 17,
    name: "16. Grocery-Discount Retail Store",
    category: "Grocery Receipts",
    image: template16,
    formInputs: [
      "FontId",
      "RecieptDate",
      "Business",
      "RecieptNo",
      "MobileNo",
      "CurrencyId",
      "PaymentType",
      "ExitDate",
      "RecieptLogo",
      "BarCode",
      "BillRecieptTaxInfoDc",
      "BillRecieptItemInfoDc"
    ],
  },

  {
    id: 18,
    name: "17. Grocery-receipt-2",
    category: "Grocery Receipts",
    image: template17,
    formInputs: [
      "FontId",
      "RecieptDate",
      "Business",
      "Address",
      "CityState",
      "CurrencyId",
      "Trans",
      "Lane",
      "Clerk",
      "Cashier",
      "PayAmount",
      "BillRecieptTaxInfoDc",
      "BillRecieptItemInfoDc"
    ],
  },

  {
    id: 19,
    name: "18. Grocery Store Receipt",
    category: "Grocery Receipts",
    image: template18,
    formInputs: [
      "FontId",
      "RecieptDate",
      "Business",
      "Address",
      "CityState",
      "CurrencyId",
      "PaymentType",
      "Auth",
      "Lane",
      "Cashier",
      "MRCH",
      "EPSSequence",
      "TerminalId",
      "SNNo",
      "ExitDate",
      "RecieptLogo",
      "BillRecieptItemInfoDc"
    ],
  },

  {
    id: 20,
    name: "19. grocery-walmart-receipt",
    category: "Grocery Receipts",
    image: template19,
    formInputs: [
      "FontId",
      "RecieptDate",
      "Business",
      "Address",
      "CityState",
      "MobileNo",
      "CurrencyId",
      "STNo",
      "OPNo",
      "TENo",
      "TRNNo",
      "TCId",
      "PayAmount",
      "RecieptLogo",
      "BarCode",
      "BillRecieptTaxInfoDc",
      "BillRecieptItemInfoDc"
    ],
  },

  {
    id: 21,
    name: "20. Grocery-Walmart-receipt-lookup",
    category: "Grocery Receipts",
    image: template20,
    formInputs: [
      "FontId",
      "RecieptDate",
      "Business",
      "Address",
      "CityState",
      "MobileNo",
      "CurrencyId",
      "AccountNo",
      "STNo",
      "OPNo",
      "TENo",
      "TCNo",
      "REFNo",
      "PaymentService",
      "NetworkId",
      "TerminalId",
      "TRNNo",
      "Payments",
      "PayAmount",
      "RecieptLogo",
      "BarCode",
      "BillRecieptTaxInfoDc",
      "BillRecieptItemInfoDc"
    ],
  },

  {
    id: 22,
    name: "21. Parking Receipt",
    category: "Parking Receipts",
    image: template21,
    formInputs: [
      "FontId",
      "RecieptDate",
      "Business",
      "Address",
      "CityState",
      "CurrencyId",
      "BaseFare",
      "Taxes",
    ],
  },

  {
    id: 23,
    name: "22. parking-receipt",
    category: "Parking Receipts",
    image: template22,
    Payments: "Total Due",
    PayAmount: "Total Paid",
    formInputs: [
      "FontId",
      "RecieptDate",
      "Business",
      "MobileNo",
      "CurrencyId",
      "PaymentType",
      "SpaceNo",
      "TicketNo",
      "SNNo",
      "Lot_Location",
      "PayedAt",
      "ExitDate",
      "Payments",
      "PayAmount",
    ],
  },

  {
    id: 24,
    name: "23. Parking Receipt2",
    category: "Parking Receipts",
    image: template23,
    formInputs: [
      "FontId",
      "RecieptDate",
      "Business",
      "Address",
      "MobileNo",
      "CurrencyId",
      "PaymentType",
      "EntryDate",
      "ExitDate",
      "PayAmount",
    ],
  },

  {
    id: 25,
    name: "24. Parking Receipt3",
    category: "Parking Receipts",
    Payments: "Total Due",
    PayAmount: "Total Paid",
    image: template24,
    formInputs: [
      "FontId",
      "RecieptDate",
      "Business",
      "Address",
      "CurrencyId",
      "PaymentType",
      "Trans",
      "ApprCode",
      "StationNo",
      "TicketNo",
      "EntryDate",
      "ExitDate",
      "Payments",
      "PayAmount",
      "Taxes",
    ],
  },

  {
    id: 26,
    name: "25. Parking Receipt4",
    category: "Parking Receipts",
    Lot_Location: "Location",
    Payments: "Parking Fee",
    PayAmount: "Fee Paid",
    image: template25,
    formInputs: [
      "FontId",
      "RecieptDate",
      "Business",
      "CurrencyId",
      "Auth",
      "AccountNo",
      "TicketNo",
      "SNNo",
      "Lot_Location",
      "MachName",
      "Payments",
      "PayAmount",
      "BarCode",
    ],
  },

  {
    id: 28,
    name: "26. parking-receipt-airport",
    category: "Parking Receipts",
    image: template26,
    formInputs: [
      "FontId",
      "RecieptDate",
      "Business",
      "Address",
      "RecieptNo",
      "CurrencyId",
      "EntryDate",
      "ExitDate",
      "VehicleNo",
      "VehicleType",
      "BaseFare",
    ],
  },

  {
    id: 29,
    name: "27. Parking-Receipt-Template",
    category: "Parking Receipts",
    EntryDate: "From",
    ExitDate: "To",
    Taxes: "Vat",
    image: template27,
    formInputs: [
      "FontId",
      "RecieptDate",
      "Business",
      "Address",
      "DropAddress",
      "CurrencyId",
      "EntryDate",
      "ExitDate",
      "PayAmount",
      "Taxes",
      "BarCode",
    ],
  },

  {
    id: 30,
    name: "28. Pharmacy Receipt 1",
    category: "Pharmacy Receipts",
    image: template28,
    formInputs: [
      "FontId",
      "RecieptDate",
      "Business",
      "CityState",
      "MobileNo",
      "CurrencyId",
      "PaymentType",
      "ApprCode",
      "TCNo",
      "REFNo",
      "TerminalId",
      "RegNo",
      "TRNNo",
      "CSHRNo",
      "STRNo",
      "HelpedBy",
      "AID",
      "CVM",
      "RecieptLogo",
      "BillRecieptItemInfoDc"
    ],
  },

  {
    id: 31,
    name: "29. Pharmacy Receipt 2",
    category: "Pharmacy Receipts",
    AID: "App no.",
    PayAmount: "tendered",
    image: template29,
    formInputs: [
      "FontId",
      "RecieptDate",
      "Business",
      "Address",
      "CityState",
      "CurrencyId",
      "PaymentType",
      "REFNo",
      "Cashier",
      "RegNo",
      "AID",
      "StoreNo",
      "PayAmount",
      "RecieptLogo",
      "BillRecieptTaxInfoDc",
      "BillRecieptItemInfoDc"
    ],
  },

  {
    id: 32,
    name: "30. Pharmacy-receipt-3",
    category: "Pharmacy Receipts",
    ExitDate: "Return Date",
    PayAmount: "Cash",
    image: template30,
    formInputs: [
      "FontId",
      "RecieptDate",
      "Business",
      "Address",
      "CurrencyId",
      "PaymentType",
      "WebSiteName",
      "TCNo",
      "ExitDate",
      "RegNo",
      "TRNNo",
      "CSHRNo",
      "STRNo",
      "StoreNo",
      "PharmacyNo",
      "PayAmount",
      "RecieptLogo",
      "BarCode",
      "BillRecieptTaxInfoDc",
      "BillRecieptItemInfoDc"
    ],
  },
  {
    id: 33,
    name: "31. Pharmacy-receipt-4",
    category: "Pharmacy Receipts",
    image: template31,
    formInputs: [
      "FontId",
      "RecieptDate",
      "Business",
      "CurrencyId",
      "REFNo",
      "BillTo",
      "PayAmount",
      "RecieptLogo",
      "BillRecieptItemInfoDc"
    ],
  },

  {
    id: 34,
    name: "32. amazon-Invoice-1",
    category: "Phone Internet Receipts",
    image: template32,
    formInputs: [
      "FontId",
      "RecieptDate",
      "Business",
      "Address",
      "PickupAddress",
      "DropAddress",
      "RecieptNo",
      "CurrencyId",
      "GSTNo",
      "OrderNo",
      "OrderDate",
      "BillTo",
      "ShipTo",
      "BillDate",
      "PANNo",
      "InvoiceDetails",
      "RecieptLogo",
      "BillRecieptTaxInfoDc",
      "BillRecieptItemInfoDc"
    ],
  },

  {
    id: 35,
    name: "33. FIXEDLINE AND BROADBAND SERVICES",
    category: "Phone Internet Receipts",
    Payments: "Payments",
    image: template33,
    formInputs: [
      "FontId",
      "RecieptDate",
      "Business",
      "Address",
      "CityState",
      "CurrencyId",
      "BillNo",
      "BillTo",
      "BillToEmailId",
      "RelationShipNo",
      "BillDate",
      "LandMark",
      "BillPeriod",
      "BillPayDate",
      "SecurityDeposit",
      "PreviousBalance",
      "Payments",
      "MonthlyRentals",
      "UsageCharges",
      "OneTimeCharges",
      "Taxes",
      "FixedlineNo",
      "BroadbandId",
      "AlternateMobileNo",
      "BillGenerateBy",
      "RecieptLogo",
      "BarCode",
    ],
  },

  // {
  //   id: 36,
  //   name: "34. phone-airtel-postpaid",
  //   category: "Phone Internet Receipts",
  //   image: template34,
  //   formInputs: [
  //     "FontId",
  //     "RecieptDate",
  //     "Business",
  //     "Address",
  //     "CityState",
  //     "MobileNo",
  //     "CurrencyId",
  //     "WebSiteName",
  //     "BillNo",
  //     "RegNo",
  //     "BillTo",
  //     "RelationShipNo",
  //     "BillDate",
  //     "LandMark",
  //     "BillPeriod",
  //     "BillPayDate",
  //     "CreditLimit",
  //     "SecurityDeposit",
  //     "PreviousBalance",
  //     "Payments",
  //     "Adjustments",
  //     "LatePaymentFee",
  //     "MonthlyRentals",
  //     "UsageCharges",
  //     "OneTimeCharges",
  //     "Taxes",
  //     "RecieptLogo",
  //     "BarCode",
  //   ],
  // },

  // {
  //   id: 37,
  //   name: "35. Jio DIGITAL LIFE",
  //   category: "Phone Internet Receipts",
  //   image: template35,
  //   formInputs: [
  //     "FontId",
  //     "RecieptDate",
  //     "Business",
  //     "Address",
  //     "DropAddress",
  //     "CityState",
  //     "CurrencyId",
  //     "GSTNo",
  //     "BillNo",
  //     "AccountNo",
  //     "BillTo",
  //     "BillToEmailId",
  //     "BillDate",
  //     "DueDate",
  //     "CreditLimit",
  //     "SecurityDeposit",
  //     "PreviousBalance",
  //     "Payments",
  //     "Adjustments",
  //     "MonthlyRentals",
  //     "OneTimeCharges",
  //     "BroadbandId",
  //     "PANNo",
  //     "AadharNo",
  //     "MemberShip",
  //     "UsageP_ISD_IRCalling",
  //     "UsageDataCharges",
  //     "UsageSMSCharges",
  //     "UsageVASCharges",
  //     "CurruntMonthDiscount",
  //     "BillDiscountWithTax",
  //     "RecieptLogo",
  //     "BillRecieptTaxInfoDc",
  //   ],
  // },

  {
    id: 38,
    name: "36. Restaurant eopa-bluetooth-printed-receipt",
    category: "Restaurant Receipts",
    image: template36,
    formInputs: [
      "FontId",
      "RecieptDate",
      "Business",
      "Address",
      "PickupAddress",
      "DropAddress",
      "CityState",
      "LandlineNo",
      "CurrencyId",
      "WebSiteName",
      "OrderNo",
      "OrderDate",
      "DeliveryDate",
      "FaxNo",
      "BillTo",
      "ShipTo",
      "CustomerNo",
      "CustomerPONo",
      "RecieptLogo",
      "BillRecieptItemInfoDc"
    ],
  },

  {
    id: 39,
    name: "37. Restaurant Sales Reciept",
    category: "Restaurant Receipts",
    SNNo: "Sequence no.",
    PayAmount: "Total Payment",
    image: template37,
    formInputs: [
      "FontId",
      "RecieptDate",
      "Business",
      "Address",
      "CityState",
      "CurrencyId",
      "PaymentType",
      "Auth",
      "MRCH",
      "TerminalId",
      "SNNo",
      "ExitDate",
      "Host",
      "PayAmount",
      "Taxes",
      "RecieptLogo",
    ],
  },

  {
    id: 40,
    name: "38. Restaurant-Custom Logo Receipt",
    category: "Restaurant Receipts",
    image: template38,
    formInputs: [
      "FontId",
      "RecieptDate",
      "Business",
      "Address",
      "CityState",
      "CurrencyId",
      "PaymentType",
      "OrderNo",
      "Host",
      "RecieptLogo",
      "BillRecieptTaxInfoDc",
      "BillRecieptItemInfoDc"
    ],
  },

  {
    id: 41,
    name: "39. Restaurant Itemized Logo",
    category: "Restaurant Receipts",
    image: template39,
    formInputs: [
      "FontId",
      "RecieptDate",
      "Business",
      "Address",
      "CityState",
      "CurrencyId",
      "PaymentType",
      "RecieptLogo",
      "BillRecieptTaxInfoDc",
      "BillRecieptItemInfoDc"
    ],
  },

  {
    id: 42,
    name: "40. Restaurant Itemized Reciept",
    category: "Restaurant Receipts",
    image: template40,
    formInputs: [
      "FontId",
      "RecieptDate",
      "Business",
      "Address",
      "CityState",
      "CurrencyId",
      "Clerk",
      "BillRecieptItemInfoDc"
    ],
  },

  {
    id: 43,
    name: "41. Restaurant Receipt",
    category: "Restaurant Receipts",
    image: template41,
    formInputs: [
      "FontId",
      "RecieptDate",
      "Business",
      "Address",
      "CityState",
      "MobileNo",
      "CurrencyId",
      "WebSiteName",
      "GSTNo",
      "RecieptLogo",
      "BillRecieptTaxInfoDc",
      "BillRecieptItemInfoDc"
    ],
  },

  {
    id: 44,
    name: "42. Restaurant Receipt 1",
    category: "Restaurant Receipts",
    RecieptNo: "ID",
    PaymentType: "Card Type",
    PayAmount: "Payment",
    Trans: "Trans Type",
    image: template42,
    formInputs: [
      "FontId",
      "RecieptDate",
      "Business",
      "Address",
      "RecieptNo",
      "CityState",
      "CurrencyId",
      "PaymentType",
      "Trans",
      "Auth",
      "AccountNo",
      "CardEntry",
      "TransType",
      "CheckNo",
      "CheckId",
      "Tip",
      "PayAmount",
      "SoldBy",
    ],
  },

  {
    id: 45,
    name: "43. Taxi-easy-cabs",
    category: "Taxi Receipts",
    BaseFare: "Fare",
    image: template43,
    formInputs: [
      "FontId",
      "RecieptDate",
      "Business",
      "Address",
      "PickupTime",
      "DropTime",
      "TaxiKM",
      "CityState",
      "CurrencyId",
      "BaseFare",
      "TaxiCallNo",
    ],
  },

  {
    id: 46,
    name: "44. Taxi-lyft-1",
    category: "Taxi Receipts",
    BaseFare: "Line Ride Fare",
    Taxes: "Prime Time Tax(%)",
    image: template44,
    formInputs: [
      "FontId",
      "RecieptDate",
      "Business",
      "PickupAddress",
      "DropAddress",
      "PickupTime",
      "DropTime",
      "CurrencyId",
      "PaymentType",
      "BaseFare",
      "LiftLineDiscountPrice",
      "PromotionalPricing",
      "RideBy",
      "Taxes",
      "RecieptLogo",
      "ProfileImage",
    ],
  },

  {
    id: 47,
    name: "45. Taxi Receipt 2",
    category: "Taxi Receipts",
    image: template45,
    formInputs: [
      "FontId",
      "RecieptDate",
      "Business",
      "Address",
      "RecieptNo",
      "CurrencyId",
      "PaymentType",
      "BaseFare",
    ],
  },

  {
    id: 48,
    name: "46. Taxi Receipt 3",
    category: "Taxi Receipts",
    image: template46,
    formInputs: [
      "FontId",
      "RecieptDate",
      "Business",
      "Address",
      "RecieptNo",
      "CityState",
      "CurrencyId",
      "PaymentType",
      "BillRecieptTaxInfoDc",
      "BillRecieptItemInfoDc"
    ],
  },

  {
    id: 49,
    name: "47. Taxi-SpecialReceipt-a-to-b",
    category: "Taxi Receipts",
    BaseFare: "Trip Fare",
    image: template47,
    formInputs: [
      "FontId",
      "RecieptDate",
      "Business",
      "PickupAddress",
      "DropAddress",
      "PickupTime",
      "DropTime",
      "TaxiKM",
      "CurrencyId",
      "PaymentType",
      "CarName",
      "BaseFare",
      "SubCharges",
      "Rating",
    ],
  },
  {
    id: 50,
    name: "48. Taxi-uber-1",
    category: "Taxi Receipts",
    BaseFare: "Trip Fare",
    PromotionalPricing: "Promotions",
    RideBy: "Ride with",
    image: template48,
    formInputs: [
      "FontId",
      "RecieptDate",
      "Business",
      "PickupAddress",
      "DropAddress",
      "PickupTime",
      "DropTime",
      "TaxiKM",
      "CurrencyId",
      "PaymentType",
      "BillTo",
      "BaseFare",
      "PromotionalPricing",
      "RideBy",
      "LicensePlate",
      "ProfileImage",
      "BillRecieptTaxInfoDc",
    ],
  },

  {
    id: 51,
    name: "49. Taxi-uber-receipt-2",
    category: "Taxi Receipts",
    BaseFare: "Trip Fare",
    RideBy: "Ride with",
    SoldBy: "Company",
    image: template49,
    formInputs: [
      "FontId",
      "RecieptDate",
      "Business",
      "PickupAddress",
      "DropAddress",
      "PickupTime",
      "DropTime",
      "TaxiKM",
      "CurrencyId",
      "PaymentType",
      "BillTo",
      "CarName",
      "BaseFare",
      "DistanceFare",
      "TimeFare",
      "SafeRideFee",
      "RideBy",
      "SoldBy",
      "ProfileImage",
    ],
  },

  {
    id: 52,
    name: "50. Toll Reciept 1",
    category: "Toll Receipts",
    PaymentService: "Payment Method",
    BaseFare: "Fee",
    image: template50,
    formInputs: [
      "FontId",
      "RecieptDate",
      "Business",
      "Address",
      "CityState",
      "CurrencyId",
      "Trans",
      "PaymentService",
      "Lane",
      "ExitDate",
      "VehicleNo",
      "VehicleType",
      "BaseFare",
      "TollPlaza",
      "Operator",
    ],
  },

  {
    id: 53,
    name: "51. Toll Reciept 2",
    category: "Toll Receipts",
    BaseFare: "Fee",
    Operator: "Booth & Operator",
    image: template51,
    formInputs: [
      "FontId",
      "RecieptDate",
      "Business",
      "Address",
      "CityState",
      "CurrencyId",
      "TicketNo",
      "VehicleNo",
      "VehicleType",
      "BaseFare",
      "TollPlaza",
      "Operator",
      "Section",
      "VehicleStanderedWeight",
      "VehicleActualWeight",
      "OverloadedVehicleFee",
      "Journey",
    ],
  },

  {
    id: 55,
    name: "52. Toll Reciept 3",
    category: "Toll Receipts",
    EntryDate: "Valid From",
    ExitDate: "Valid To",
    VehicleActualWeight: "Gross Weight",
    RelationShipNo: "BarCode no.",
    PayAmount: "Amount Paid",
    image: template52,
    formInputs: [
      "FontId",
      "RecieptDate",
      "Business",
      "Address",
      "RecieptNo",
      "CityState",
      "MobileNo",
      "CurrencyId",
      "Lane",
      "EntryDate",
      "ExitDate",
      "VehicleNo",
      "VehicleType",
      "VehicleActualWeight",
      "Shift",
      "Journey",
      "TCId",
      "RelationShipNo",
      "PayAmount",
      "BarCode",
    ],
  },

  {
    id: 56,
    name: "53. toll-receipt-sample",
    category: "Toll Receipts",
    VehicleType: "Class",
    BaseFare: "Fare",
    TollPlaza: "Plaza",
    Operator: "Collector",
    image: template53,
    formInputs: [
      "FontId",
      "RecieptDate",
      "Business",
      "Address",
      "RecieptNo",
      "CityState",
      "CurrencyId",
      "PaymentType",
      "Trans",
      "Lane",
      "VehicleType",
      "RegNo",
      "BaseFare",
      "TollPlaza",
      "Operator",
      "CSH",
      "BarCode",
    ],
  },

  {
    id: 58,
    name: "54. Taxi-Ola-1",
    category: "Toll Receipts",
    RecieptNo: "CRN No",
    BaseFare: "Trip Fare",
    SafeRideFee: "Play Convenience Fee",
    RideBy: "Ride with",
    image: template54,
    formInputs: [
        "FontId",
        "RecieptDate",
        "Business",
        "PickupAddress",
        "DropAddress",
        "PickupTime",
        "DropTime",
        "RecieptNo",
        "CurrencyId",
        "PaymentType",
        "BillTo",
        "CarName",
        "BaseFare",
        "SafeRideFee",
        "RideBy",
        "RecieptLogo",
        "ProfileImage",
    ]
  }
];

export default templates;
