import { connect } from "react-redux";

import MyReceipts from "./MyReceipts";

// Actions
import {
  getRegistrationDropdown,
  searchCategory,
  searchBillReceipt,
  deleteBillReceiptById,
} from "./myReceiptsActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  userId: localStorage.getItem("user_id"),
  isLoading: state.myReceipts.isLoading,
  isCategoryLoading: state.myReceipts.isCategoryLoading,
  isDeleteLoading: state.myReceipts.isDeleteLoading,
  registrationDropdownInfo: state.myReceipts.registrationDropdownInfo,
  searchCategoryList: state.myReceipts.searchCategoryList,
  billReceiptList: state.myReceipts.billReceiptList,
  deletedReceiptCallback: state.myReceipts.deletedReceiptCallback,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  getRegistrationDropdown: (details) =>
    dispatch(getRegistrationDropdown({ ...details })),
  searchCategory: (details) => dispatch(searchCategory({ ...details })),
  searchBillReceipt: (details) => dispatch(searchBillReceipt({ ...details })),
  deleteBillReceiptById: (details) =>
    dispatch(deleteBillReceiptById({ ...details })),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(MyReceipts);
