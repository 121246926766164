import elonAvatar from "../assets/img/dataImages/avatar-spacex-elon-musk.svg";
import peleAvatar from "../assets/img/dataImages/avatar-spacex-pele-collins.svg";
import craigAvatar from "../assets/img/dataImages/avatar-spacex-craig-mews.svg";
import josephAvatar from "../assets/img/dataImages/avatar-spacex-joseph-griffin.svg";
import milanAvatar from "../assets/img/dataImages/avatar-spacex-milan-justel.svg";
import seanAvatar from "../assets/img/dataImages/avatar-spacex-sean-sommer.svg";

import jonathanAvatar from "../assets/img/dataImages/avatar-oceana-jonathan-smith.svg";
import magnusAvatar from "../assets/img/dataImages/avatar-oceana-magnus-lincon.svg";
import dexterAvatar from "../assets/img/dataImages/avatar-oceana-dexter-edison.svg";
import byronAvatar from "../assets/img/dataImages/avatar-oceana-byron-jett.svg";
import maverickAvatar from "../assets/img/dataImages/avatar-oceana-maverick-fox.svg";
import orsonAvatar from "../assets/img/dataImages/avatar-oceana-orson-zane.svg";

const getMembersShortList = (list) => {
  const shortList = [];
  list.map((member, index) => {
    const { id, is_admin, is_member_on, user } = member;
    shortList.push({
      id,
      is_admin,
      is_member_on,
      project_manager: index === 1,
      user,
    });
  });
  return shortList;
};

export const ownerProfileData = {
  id: "u4ia9ba5-f97e-4cc1-b401-af95e816252b",
  email: "elon_musk@spacex.com",
  first_name: "Elon",
  last_name: "Musk",
  thumbnail: elonAvatar,
  image: elonAvatar,
  timezone: "US/Pacific",
};

export const membersListSpaceX = [
  {
    id: "u4ia9ba5-f97e-4cc1-b401-af95e816252b",
    is_active: true,
    is_admin: false,
    is_member_on: true,
    role: "owner",
    payment: {
      bill_rate: 6500,
      currency: "USD",
      pay_rate: 5000,
    },
    projects: [
      {
        id: "fdb8rree-e861-4c9d-bd9a-fdb8rree6b427",
        name: "Falcom Heavy",
        is_active: true,
      },
      {
        id: "4c9d6b42-e861-4c9d-bd9a-fdb8rree6b427",
        name: "Starship",
        is_active: true,
      },
    ],
    teams: [
      {
        id: "a1cee3fb-dc30-47f3-84b6-1075dde7f742",
        name: "Robo Engineers",
      },
      {
        id: "482ea22d-3d54-4e7f-8eeb-abd507545699",
        name: "Eagle-Eyed",
      },
    ],
    earnings: {
      week: {
        total_time: 91800,
        avg_activity: 0.5658,
        total_earned: 1275.0,
        currency: "USD",
      },
      month: {
        total_time: 91800,
        avg_activity: 0.5658,
        total_earned: 1275.0,
        currency: "USD",
      },
    },
    user: {
      id: "34r869e0-4d28-4a6d-a05c-7be1errutce4d",
      avatar: elonAvatar,
      email: "elon_musk@spacex.com",
      first_name: "Elon",
      last_name: "Musk",
      timezone: { tz: "US/Pacific", offset: "-0500" },
    },
  },
  {
    id: "tuya9ba5-f97e-4cc1-b401-af95e816252b",
    is_active: true,
    is_admin: true,
    is_member_on: true,
    role: "admin",
    payment: {
      bill_rate: 4500,
      currency: "USD",
      pay_rate: 3900,
    },
    projects: [
      {
        id: "4c9d6b42-e861-4c9d-bd9a-fdb8rree6b427",
        name: "Starship",
        is_active: true,
      },
      {
        id: "ty67oree-e861-4c9d-bd9a-fdb8rree6b427",
        name: "Grasshopper",
        is_active: true,
      },
    ],
    teams: [
      {
        id: "f4f9354c-016b-4abd-bdf7-be776ba935d2",
        name: "Ace Coders",
      },
    ],
    user: {
      id: "ty67u9e0-4d28-4a6d-a05c-7be1errutce4d",
      avatar: peleAvatar,
      email: "pele_collins@spacex.com",
      first_name: "Pele",
      last_name: "Collins",
      timezone: { tz: "US/Pacific", offset: "-0500" },
    },
  },
  {
    id: "y85a9ba5-f97e-4cc1-b401-af95e816252b",
    is_active: true,
    is_admin: false,
    is_member_on: true,
    role: "member",
    payment: {
      bill_rate: 4000,
      currency: "USD",
      pay_rate: 3000,
    },
    projects: [
      {
        id: "fdb8rree-e861-4c9d-bd9a-fdb8rree6b427",
        name: "Falcom Heavy",
        is_active: true,
      },
    ],
    teams: [],
    user: {
      id: "5t67u9e0-56y8-4a6d-a05c-7be1errutce4d",
      avatar: craigAvatar,
      email: "craig_mews@spacex.com",
      first_name: "Craig",
      last_name: "Mews",
      timezone: { tz: "US/Pacific", offset: "-0500" },
    },
  },
  {
    id: "4r5t6a5-f97e-4cc1-b401-af95e816252b",
    is_active: true,
    is_admin: false,
    is_member_on: true,
    role: "member",
    payment: {
      bill_rate: 5500,
      currency: "USD",
      pay_rate: 4500,
    },
    projects: [
      {
        id: "fdb8rree-e861-4c9d-bd9a-fdb8rree6b427",
        name: "Falcom Heavy",
        is_active: true,
      },
      {
        id: "4c9d6b42-e861-4c9d-bd9a-fdb8rree6b427",
        name: "Starship",
        is_active: true,
      },
      {
        id: "ty67oree-e861-4c9d-bd9a-fdb8rree6b427",
        name: "Grasshopper",
        is_active: true,
      },
    ],
    teams: [
      {
        id: "482ea22d-3d54-4e7f-8eeb-abd507545699",
        name: "Eagle-Eyed",
      },
      {
        id: "f4f9354c-016b-4abd-bdf7-be776ba935d2",
        name: "Ace Coders",
      },
    ],
    user: {
      id: "yu67u9e0-4d28-4a6d-a05c-7be1errutce4d",
      avatar: josephAvatar,
      email: "joseph_griffin@spacex.com",
      first_name: "Joseph",
      last_name: "Griffin",
      timezone: { tz: "US/Pacific", offset: "-0500" },
    },
  },
  {
    id: "4rya5ta5-f97e-4cc1-b401-af95e816252b",
    is_active: true,
    is_admin: false,
    is_member_on: true,
    role: "member",
    payment: {
      bill_rate: 4500,
      currency: "USD",
      pay_rate: 5000,
    },
    projects: [
      {
        id: "4c9d6b42-e861-4c9d-bd9a-fdb8rree6b427",
        name: "Starship",
        is_active: true,
      },
    ],
    teams: [
      {
        id: "a1cee3fb-dc30-47f3-84b6-1075dde7f742",
        name: "Robo Engineers",
      },
    ],
    user: {
      id: "6uy7u9e0-4d28-4a6d-a05c-7be1ertutce4d",
      avatar: milanAvatar,
      email: "milan_justel@spacex.com",
      first_name: "Milan",
      last_name: "Justel",
      timezone: { tz: "US/Pacific", offset: "-0500" },
    },
  },
  {
    id: "8ioya5ta5-f97e-4cc1-b401-af95e81r52b",
    is_active: true,
    is_admin: false,
    is_member_on: true,
    role: "member",
    payment: {
      bill_rate: 3800,
      currency: "USD",
      pay_rate: 3500,
    },
    projects: [
      {
        id: "fdb8rree-e861-4c9d-bd9a-fdb8rree6b427",
        name: "Falcom Heavy",
        is_active: true,
      },
      {
        id: "4c9d6b42-e861-4c9d-bd9a-fdb8rree6b427",
        name: "Starship",
        is_active: true,
      },
    ],
    teams: [],
    user: {
      id: "8yu7u9e0-4d28-4a6d-a05c-7be1ertutce4d",
      avatar: seanAvatar,
      email: "sean_sommer@spacex.com",
      first_name: "Sean",
      last_name: "Sommer",
      timezone: { tz: "US/Pacific", offset: "-0500" },
    },
  },
];
export const membersShortListSpaceX = getMembersShortList(membersListSpaceX);

export const membersListOceana = [
  {
    id: "u4ia9ba5-f97e-4cc1-b401-af95e816252b",
    is_active: true,
    is_admin: false,
    is_member_on: true,
    role: "owner",
    payment: {
      bill_rate: 6000,
      currency: "USD",
      pay_rate: 4800,
    },
    projects: [
      {
        id: "bd9ree6e-e861-4c9d-bd9a-fdb8rree6b427",
        name: "Box of Crayons",
        is_active: true,
      },
      {
        id: "4c9der6e-e861-4c9d-bd9a-fdb8rree6b427",
        name: "White Feather",
        is_active: true,
      },
    ],
    teams: [
      {
        id: "b07d401f-a99b-4137-aa10-f63392ed4660",
        name: "Articraft Ninjas",
      },
      {
        id: "22b69691-2c4a-43ee-99d7-6f35b24eb4f6",
        name: "Marketing Geeks",
      },
    ],
    earnings: {
      week: {
        total_time: 91800,
        avg_activity: 0.5658,
        total_earned: 1275.0,
        currency: "USD",
      },
      month: {
        total_time: 91800,
        avg_activity: 0.5658,
        total_earned: 1275.0,
        currency: "USD",
      },
    },
    user: {
      id: "34r869e0-4d28-4a6d-a05c-7be1errutce4d",
      avatar: elonAvatar,
      email: "elon_musk@spacex.com",
      first_name: "Elon",
      last_name: "Musk",
      timezone: { tz: "US/Pacific", offset: "-0500" },
    },
  },
  {
    id: "af959ba5-f97e-4cc1-b401-af95e816252b",
    is_active: true,
    is_admin: true,
    is_member_on: true,
    role: "admin",
    payment: {
      bill_rate: 5500,
      currency: "USD",
      pay_rate: 4500,
    },
    projects: [
      {
        id: "6b427r6e-e861-4c9d-bd9a-fdb8rree6b427",
        name: "Debug Entity",
        is_active: true,
      },
      {
        id: "bd9ree6e-e861-4c9d-bd9a-fdb8rree6b427",
        name: "Box of Crayons",
        is_active: true,
      },
    ],
    teams: [
      {
        id: "b07d401f-a99b-4137-aa10-f63392ed4660",
        name: "Articraft Ninjas",
      },
    ],
    user: {
      id: "a05c69e0-4d28-4a6d-a05c-7be1errutce4d",
      avatar: jonathanAvatar,
      email: "jonathan_smith@oceana.com",
      first_name: "Jonathan",
      last_name: "Smith",
      timezone: { tz: "America/New_York", offset: "-0400" },
    },
  },
  {
    id: "252b9ba5-f97e-4cc1-b401-af95e816252b",
    is_active: true,
    is_admin: true,
    is_member_on: true,
    role: "admin",
    payment: {
      bill_rate: 4500,
      currency: "USD",
      pay_rate: 5000,
    },
    projects: [
      {
        id: "4c9der6e-e861-4c9d-bd9a-fdb8rree6b427",
        name: "White Feather",
        is_active: true,
      },
      {
        id: "bd9ree6e-e861-4c9d-bd9a-fdb8rree6b427",
        name: "Box of Crayons",
        is_active: true,
      },
    ],
    teams: [],
    user: {
      id: "4d2869e0-4d28-4a6d-a05c-7be1errutce4d",
      avatar: magnusAvatar,
      email: "magnus_lincon@oceana.com",
      first_name: "Magnus",
      last_name: "Lincon",
      timezone: { tz: "America/New_York", offset: "-0400" },
    },
  },
  {
    id: "5e819ba5-f97e-4cc1-b401-af95e816252b",
    is_active: true,
    is_admin: false,
    is_member_on: true,
    role: "member",
    payment: {
      bill_rate: 3500,
      currency: "USD",
      pay_rate: 2500,
    },
    projects: [
      {
        id: "6b427r6e-e861-4c9d-bd9a-fdb8rree6b427",
        name: "Debug Entity",
        is_active: true,
      },
    ],
    teams: [
      {
        id: "22b69691-2c4a-43ee-99d7-6f35b24eb4f6",
        name: "Marketing Geeks",
      },
    ],
    user: {
      id: "a05c69e0-4d28-4a6d-a05c-7be1errutce4d",
      avatar: dexterAvatar,
      email: "dexter_edison@oceana.com",
      first_name: "Dexter",
      last_name: "Edison",
      timezone: { tz: "America/New_York", offset: "-0400" },
    },
  },
  {
    id: "f97e9ba5-f97e-4cc1-b401-af95e816252b",
    is_active: true,
    is_admin: false,
    is_member_on: true,
    role: "member",
    payment: {
      bill_rate: 4500,
      currency: "USD",
      pay_rate: 3600,
    },
    projects: [
      {
        id: "4c9der6e-e861-4c9d-bd9a-fdb8rree6b427",
        name: "White Feather",
        is_active: true,
      },
      {
        id: "6b427r6e-e861-4c9d-bd9a-fdb8rree6b427",
        name: "Debug Entity",
        is_active: true,
      },
    ],
    teams: [
      {
        id: "b07d401f-a99b-4137-aa10-f63392ed4660",
        name: "Articraft Ninjas",
      },
    ],
    user: {
      id: "be1e69e0-4d28-4a6d-a05c-7be1errutce4d",
      avatar: byronAvatar,
      email: "byron_jett@oceana.com",
      first_name: "Byron",
      last_name: "Jett",
      timezone: { tz: "America/New_York", offset: "-0400" },
    },
  },
  {
    id: "p0859ba5-f97e-4cc1-b401-af95e816252b",
    is_active: true,
    is_admin: false,
    is_member_on: true,
    role: "member",
    payment: {
      bill_rate: 4800,
      currency: "USD",
      pay_rate: 4000,
    },
    projects: [
      {
        id: "6b427r6e-e861-4c9d-bd9a-fdb8rree6b427",
        name: "Debug Entity",
        is_active: true,
      },
    ],
    teams: [
      {
        id: "22b69691-2c4a-43ee-99d7-6f35b24eb4f6",
        name: "Marketing Geeks",
      },
    ],
    user: {
      id: "be1e69e0-4d28-4a6d-a05c-7be1errutce4d",
      avatar: maverickAvatar,
      email: "maverick_fox@oceana.com",
      first_name: "Maverick",
      last_name: "Fox",
      timezone: { tz: "America/New_York", offset: "-0400" },
    },
  },
  {
    id: "t6y79ba5-f97e-4cc1-b401-af95e816252b",
    is_active: true,
    is_admin: false,
    is_member_on: true,
    role: "member",
    payment: {
      bill_rate: 4500,
      currency: "USD",
      pay_rate: 5000,
    },
    projects: [
      {
        id: "bd9ree6e-e861-4c9d-bd9a-fdb8rree6b427",
        name: "Box of Crayons",
        is_active: true,
      },
      {
        id: "4c9der6e-e861-4c9d-bd9a-fdb8rree6b427",
        name: "White Feather",
        is_active: true,
      },
      {
        id: "6b427r6e-e861-4c9d-bd9a-fdb8rree6b427",
        name: "Debug Entity",
        is_active: true,
      },
    ],
    teams: [
      {
        id: "a06204ec-fef3-432a-8638-e6cb67aac02a",
        name: "Tech Crews",
      },
      {
        id: "22b69691-2c4a-43ee-99d7-6f35b24eb4f6",
        name: "Marketing Geeks",
      },
    ],
    user: {
      id: "o89u69e0-4d28-4a6d-a05c-7be1errutce4d",
      avatar: orsonAvatar,
      email: "orson_zane@oceana.com",
      first_name: "Orson",
      last_name: "Zane",
      timezone: { tz: "America/New_York", offset: "-0400" },
    },
  },
];
export const membersShortListOceana = getMembersShortList(membersListOceana);

export const membersListData = {
  "s1dty88f-5yfd-4fg5-b6c2-eeae27f8347c": {
    members: membersListSpaceX,
    membersShort: membersShortListSpaceX,
  },
  "4bcfb309-e861-4c9d-bd9a-fdb8rree6b427": {
    members: membersListOceana,
    membersShort: membersShortListOceana,
  },
};
