//Effects
import { all, put, takeLatest, select } from "redux-saga/effects";

import { invoiceListData } from "../../datasets/Invoice/invoiceList";

//Constants
import {
  GET_INVOICE_LIST,
  GET_INVOICE_LIST_SUCCESS,
  GET_INVOICE_LIST_FAILURE,
  CREATE_INVOICE_REQUEST,
  CREATE_INVOICE_REQUEST_SUCCESS,
  CREATE_INVOICE_REQUEST_FAILURE,
  GET_INVOICE_DETAILS,
  GET_INVOICE_DETAILS_SUCCESS,
  GET_INVOICE_DETAILS_FAILURE,
  UPDATE_INVOICE_REQUEST,
  UPDATE_INVOICE_REQUEST_SUCCESS,
  UPDATE_INVOICE_REQUEST_FAILURE,
  DELETE_INVOICE,
  DELETE_INVOICE_SUCCESS,
  DELETE_INVOICE_FAILURE,
  CREATE_INVOICE_ITEM,
  CREATE_INVOICE_ITEM_SUCCESS,
  CREATE_INVOICE_ITEM_FAILURE,
  UPDATE_INVOICE_ITEM,
  UPDATE_INVOICE_ITEM_SUCCESS,
  UPDATE_INVOICE_ITEM_FAILURE,
  DELETE_INVOICE_ITEM,
  DELETE_INVOICE_ITEM_SUCCESS,
  DELETE_INVOICE_ITEM_FAILURE,
  CREATE_INVOICE_TAX,
  CREATE_INVOICE_TAX_SUCCESS,
  CREATE_INVOICE_TAX_FAILURE,
  UPDATE_INVOICE_TAX,
  UPDATE_INVOICE_TAX_SUCCESS,
  UPDATE_INVOICE_TAX_FAILURE,
  GET_INVOICE_TAX,
  GET_INVOICE_TAX_SUCCESS,
  GET_INVOICE_TAX_FAILURE,
  UPDATE_INVOICE_DISCOUNT,
  UPDATE_INVOICE_DISCOUNT_SUCCESS,
  UPDATE_INVOICE_DISCOUNT_FAILURE,
  SEND_INVOICE_EMAIL,
  SEND_INVOICE_EMAIL_SUCCESS,
  SEND_INVOICE_EMAIL_FAILURE,
  GET_INVOICE_NUMBER,
  GET_INVOICE_NUMBER_SUCCESS,
  GET_INVOICE_NUMBER_FAILURE,
  RECORD_PAYMENT_INVOICE,
  RECORD_PAYMENT_INVOICE_SUCCESS,
  RECORD_PAYMENT_INVOICE_FAILURE,
  GET_RECORD_PAYMENT_INVOICE,
  GET_RECORD_PAYMENT_INVOICE_SUCCESS,
  GET_RECORD_PAYMENT_INVOICE_FAILURE,
  DELETE_RECORD_PAYMENT_INVOICE,
  DELETE_RECORD_PAYMENT_INVOICE_SUCCESS,
  DELETE_RECORD_PAYMENT_INVOICE_FAILURE,
  GET_INVOICE_HISTORY,
  GET_INVOICE_HISTORY_SUCCESS,
  GET_INVOICE_HISTORY_FAILURE,
  INVOICE_PUBLIC_VIEW,
  INVOICE_PUBLIC_VIEW_SUCCESS,
  INVOICE_PUBLIC_VIEW_FAILURE,
  INVOICE_UPDATE_SEND_LOG,
  INVOICE_UPDATE_SEND_LOG_SUCCESS,
  INVOICE_UPDATE_SEND_LOG_FAILURE,
  INVOICE_LINK_PROJECT,
  INVOICE_LINK_PROJECT_SUCCESS,
  INVOICE_LINK_PROJECT_FAILURE,
} from "../../utils/constants";

export function* handleGetInvoiceList({ payload }) {
  if (!payload) return;
  const { organization_id } = payload;
  try {
    //let APIUrl = `${organization_id}/invoices/`;
    yield put({
      type: GET_INVOICE_LIST_SUCCESS,
      payload: { data: invoiceListData[organization_id] },
    });
  } catch (err) {
    yield put({
      type: GET_INVOICE_LIST_FAILURE,
      payload: err,
    });
  }
}

// export function* createInvoiceRequest({ payload }) {
//   try {
//     let { organization_id, saveAndSend, ...details } = payload;
//     let url = `${organization_id}/invoices/`;

//     yield put({
//       type: CREATE_INVOICE_REQUEST_SUCCESS,
//       payload: { data: response, notify: "POST" },
//       saveAndSend,
//     });
//   } catch (err) {
//     yield put({
//       type: CREATE_INVOICE_REQUEST_FAILURE,
//       payload: err,
//     });
//   }
// }

// export function* getInvoiceDetailsRequest({ payload }) {
//   try {
//     const { organization_id, invoice_id } = payload;
//     let url = `${organization_id}/invoices/${invoice_id}/`;
//     yield put({
//       type: GET_INVOICE_DETAILS_SUCCESS,
//       payload: { data: invoiceListData[organization_id] },
//     });
//   } catch (err) {
//     yield put({
//       type: GET_INVOICE_DETAILS_FAILURE,
//       payload: err,
//     });
//   }
// }

// export function* updateInvoiceRequest({ payload }) {
//   try {
//     const { organization_id, invoice_id, ...details } = payload;
//     let url = `${organization_id}/invoices/${invoice_id}/`;
//     yield put({
//       type: UPDATE_INVOICE_REQUEST_SUCCESS,
//       payload: { data: response },
//     });
//   } catch (err) {
//     yield put({
//       type: UPDATE_INVOICE_REQUEST_FAILURE,
//       payload: err,
//     });
//   }
// }

// export function* deleteInvoiceRequest({ payload }) {
//   try {
//     const { organization_id, invoice_id } = payload;
//     let url = `${organization_id}/invoices/${invoice_id}/`;
//     yield put({
//       type: DELETE_INVOICE_SUCCESS,
//       payload: { data: response, invoice_id, notify: "DELETE" },
//     });
//   } catch (err) {
//     yield put({
//       type: DELETE_INVOICE_FAILURE,
//       payload: err,
//     });
//   }
// }

// export function* createInvoiceItemRequest({ payload }) {
//   try {
//     let { organization_id, invoice_id, ...details } = payload;
//     let url = `${organization_id}/invoices/${invoice_id}/items/`;

//     yield put({
//       type: CREATE_INVOICE_ITEM_SUCCESS,
//       payload: { data: response, notify: "POST" },
//     });
//   } catch (err) {
//     yield put({
//       type: CREATE_INVOICE_ITEM_FAILURE,
//       payload: err,
//     });
//   }
// }

// export function* updateInvoiceItemRequest({ payload }) {
//   try {
//     const { organization_id, invoice_id, item_id, ...details } = payload;
//     let url = `${organization_id}/invoices/${invoice_id}/items/${item_id}/`;
//     yield put({
//       type: UPDATE_INVOICE_ITEM_SUCCESS,
//       payload: { data: response },
//     });
//   } catch (err) {
//     yield put({
//       type: UPDATE_INVOICE_ITEM_FAILURE,
//       payload: err,
//     });
//   }
// }

// export function* deleteInvoiceItemRequest({ payload }) {
//   try {
//     const { organization_id, invoice_id, item_id } = payload;
//     let url = `${organization_id}/invoices/${invoice_id}/items/${item_id}/`;

//     yield put({
//       type: DELETE_INVOICE_ITEM_SUCCESS,
//       payload: { data: response, notify: "DELETE" },
//     });
//   } catch (err) {
//     yield put({
//       type: DELETE_INVOICE_ITEM_FAILURE,
//       payload: err,
//     });
//   }
// }

// //TAX
// export function* createInvoiceTaxRequest({ payload }) {
//   try {
//     let { organization_id, invoice_id, ...details } = payload;
//     let url = `${organization_id}/invoices/${invoice_id}/tax/`;
    
//     yield put({
//       type: CREATE_INVOICE_TAX_SUCCESS,
//       payload: { data: response, notify: "POST" },
//     });
//   } catch (err) {
//     yield put({
//       type: CREATE_INVOICE_TAX_FAILURE,
//       payload: err,
//     });
//   }
// }

// export function* updateInvoiceTaxRequest({ payload }) {
//   try {
//     const { organization_id, invoice_id, tax_item_id, ...details } = payload;
//     let url = `${organization_id}/invoices/${invoice_id}/tax/${tax_item_id}/`;

//     yield put({
//       type: UPDATE_INVOICE_TAX_SUCCESS,
//       payload: { data: response },
//     });
//   } catch (err) {
//     yield put({
//       type: UPDATE_INVOICE_TAX_FAILURE,
//       payload: err,
//     });
//   }
// }

// export function* getInvoiceTaxRequest({ payload }) {
//   try {
//     const { organization_id, invoice_id, tax_item_id } = payload;
//     let url = `${organization_id}/invoices/${invoice_id}/tax/${tax_item_id}/`;
    
//     yield put({
//       type: GET_INVOICE_TAX_SUCCESS,
//       payload: { data: response },
//     });
//   } catch (err) {
//     yield put({
//       type: GET_INVOICE_TAX_FAILURE,
//       payload: err,
//     });
//   }
// }

// export function* updateInvoiceDiscountRequest({ payload }) {
//   try {
//     const { organization_id, invoice_id, discount_id, discount, ...details } =
//       payload;
//     let url = `${organization_id}/invoices/${invoice_id}/discounts/${discount_id}/`;
//     yield put({
//       type: UPDATE_INVOICE_DISCOUNT_SUCCESS,
//       payload: { data: response },
//     });
//   } catch (err) {
//     yield put({
//       type: UPDATE_INVOICE_DISCOUNT_FAILURE,
//       payload: err,
//     });
//   }
// }

// export function* sendInvoiceEmailRequest({ payload }) {
//   try {
//     const { organization_id, invoice_id, ...details } = payload;
//     let url = `${organization_id}/invoices/${invoice_id}/send/`;

//     yield put({
//       type: SEND_INVOICE_EMAIL_SUCCESS,
//       payload: { data: response, notify: "POST" },
//     });
//   } catch (err) {
//     yield put({
//       type: SEND_INVOICE_EMAIL_FAILURE,
//       payload: err,
//     });
//   }
// }

// export function* getInvoiceNumberRequest({ payload }) {
//   try {
//     const { organization_id } = payload;
//     let url = `${organization_id}/invoices/invoice-number`;
    
//     yield put({
//       type: GET_INVOICE_NUMBER_SUCCESS,
//       payload: { data: response },
//     });
//   } catch (err) {
//     yield put({
//       type: GET_INVOICE_NUMBER_FAILURE,
//       payload: err,
//     });
//   }
// }

// export function* recordPaymentInvoiceRequest({ payload }) {
//   try {
//     const { organization_id, invoice_id, ...details } = payload;
//     let url = `${organization_id}/invoices/${invoice_id}/payments/`;

//     yield put({
//       type: RECORD_PAYMENT_INVOICE_SUCCESS,
//       payload: { data: response, notify: "POST" },
//     });
//   } catch (err) {
//     yield put({
//       type: RECORD_PAYMENT_INVOICE_FAILURE,
//       payload: err,
//     });
//   }
// }

// export function* getRecordPaymentInvoiceRequest({ payload }) {
//   try {
//     const { organization_id, invoice_id, payment_id } = payload;
//     let url = `${organization_id}/invoices/${invoice_id}/payments/${payment_id}/`;
    
//     yield put({
//       type: GET_RECORD_PAYMENT_INVOICE_SUCCESS,
//       payload: { data: response },
//     });
//   } catch (err) {
//     yield put({
//       type: GET_RECORD_PAYMENT_INVOICE_FAILURE,
//       payload: err,
//     });
//   }
// }

// export function* deleteRecordPaymentInvoiceRequest({ payload }) {
//   try {
//     const { organization_id, invoice_id, payment_id } = payload;
//     let url = `${organization_id}/invoices/${invoice_id}/payments/${payment_id}/`;
    
//     yield put({
//       type: DELETE_RECORD_PAYMENT_INVOICE_SUCCESS,
//       payload: { data: response, notify: "DELETE" },
//     });
//   } catch (err) {
//     yield put({
//       type: DELETE_RECORD_PAYMENT_INVOICE_FAILURE,
//       payload: err,
//     });
//   }
// }

// export function* getInvoiceHistoryRequest({ payload }) {
//   try {
//     const { organization_id, invoice_id } = payload;
//     let url = `${organization_id}/invoices/${invoice_id}/events/`;
    
//     yield put({
//       type: GET_INVOICE_HISTORY_SUCCESS,
//       payload: { data: response },
//     });
//   } catch (err) {
//     yield put({
//       type: GET_INVOICE_HISTORY_FAILURE,
//       payload: err,
//     });
//   }
// }

// export function* invoicePublicViewRequest({ payload }) {
//   try {
//     const { send_log } = payload;
//     let url = `view-invoice/${send_log}/`;
    
//     yield put({
//       type: INVOICE_PUBLIC_VIEW_SUCCESS,
//       payload: { data: response },
//     });
//   } catch (err) {
//     yield put({
//       type: INVOICE_PUBLIC_VIEW_FAILURE,
//       payload: err,
//     });
//   }
// }

// export function* invoiceUpdateSendLogRequest({ payload }) {
//   try {
//     const { organization_id, invoice_id } = payload;
//     let url = `${organization_id}/invoices/${invoice_id}/update-send/`;
    
//     yield put({
//       type: INVOICE_UPDATE_SEND_LOG_SUCCESS,
//       payload: { data: response, notify: "POST" },
//     });
//   } catch (err) {
//     yield put({
//       type: INVOICE_UPDATE_SEND_LOG_FAILURE,
//       payload: err,
//     });
//   }
// }

// export function* invoiceLinkProjectRequest({ payload }) {
//   try {
//     const { organization_id, ...details } = payload;
//     let url = `${organization_id}/link-project/`;
    
//     yield put({
//       type: INVOICE_LINK_PROJECT_SUCCESS,
//       payload: { data: response, notify: "POST" },
//     });
//   } catch (err) {
//     yield put({
//       type: INVOICE_LINK_PROJECT_FAILURE,
//       payload: err,
//     });
//   }
// }

export default function* root() {
  yield all([
    // Get invoice list
    takeLatest(GET_INVOICE_LIST, handleGetInvoiceList),
    // Create
    // takeLatest(CREATE_INVOICE_REQUEST, createInvoiceRequest),
    // //Get invoice details
    // takeLatest(GET_INVOICE_DETAILS, getInvoiceDetailsRequest),
    // //Update
    // takeLatest(UPDATE_INVOICE_REQUEST, updateInvoiceRequest),
    // //Delete Invoice
    // takeLatest(DELETE_INVOICE, deleteInvoiceRequest),
    // //Create Invoice Item
    // takeLatest(CREATE_INVOICE_ITEM, createInvoiceItemRequest),
    // //Update Invoice Item
    // takeLatest(UPDATE_INVOICE_ITEM, updateInvoiceItemRequest),
    // //Delete Invoice Item
    // takeLatest(DELETE_INVOICE_ITEM, deleteInvoiceItemRequest),

    // //Create Tax
    // takeLatest(CREATE_INVOICE_TAX, createInvoiceTaxRequest),
    // //Update Tax
    // takeLatest(UPDATE_INVOICE_TAX, updateInvoiceTaxRequest),
    // //Get Tax
    // takeLatest(GET_INVOICE_TAX, getInvoiceTaxRequest),

    // //Update Discount
    // takeLatest(UPDATE_INVOICE_DISCOUNT, updateInvoiceDiscountRequest),

    // //Send Email
    // takeLatest(SEND_INVOICE_EMAIL, sendInvoiceEmailRequest),
    // //Get Invoice Number
    // takeLatest(GET_INVOICE_NUMBER, getInvoiceNumberRequest),
    // //Record Payment
    // takeLatest(RECORD_PAYMENT_INVOICE, recordPaymentInvoiceRequest),
    // //get Record Payment
    // takeLatest(GET_RECORD_PAYMENT_INVOICE, getRecordPaymentInvoiceRequest),
    // //Delete Record Payment
    // takeLatest(
    //   DELETE_RECORD_PAYMENT_INVOICE,
    //   deleteRecordPaymentInvoiceRequest
    // ),
    // //get Invoice History
    // takeLatest(GET_INVOICE_HISTORY, getInvoiceHistoryRequest),
    // //Invoice Public View
    // takeLatest(INVOICE_PUBLIC_VIEW, invoicePublicViewRequest),
    // //Invoice Update Send Log Id
    // takeLatest(INVOICE_UPDATE_SEND_LOG, invoiceUpdateSendLogRequest),
    // //Invoice project link bill
    // takeLatest(INVOICE_LINK_PROJECT, invoiceLinkProjectRequest),
  ]);
}
