import { connect } from "react-redux";

// Component
import Settings from "./Settings";

// Actions
// import { getOrganizationSubscription } from "../Subscription/subscriptionActions";
import {
  getWeeklyEmailSettings,
  updateWeeklyEmailSettings,
} from "./settingsActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  // isLoading: state.subscription.isLoading,
  selectedOrganization: state.organization.selectedOrganization,
  // currentPlan: state.subscription.currentSubscription,
  settingsIsLoading: state.settings.isLoading,
  weeklyEmail: state.settings.weeklyEmail,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  // getOrganizationSubscription: (payload) =>
  //   dispatch(getOrganizationSubscription(payload)),
  getWeeklyEmailSettings: (payload) =>
    dispatch(getWeeklyEmailSettings(payload)),
  updateWeeklyEmailSettings: (payload) =>
    dispatch(updateWeeklyEmailSettings(payload)),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(Settings);
