import React, { useState, useEffect } from "react";
import Select from "../../components/ReactSelectDropdown";
import createProjectDropdown from "../../components/DropdownStyle/CreateProjectDropdown";
import {
  Container,
  CommonText,
  RoundLetterSection,
  TableText,
  AssigneeMoreText,
  StyledLink,
  ResendInvite,
} from "../../styledComponents/common";
import { TableItem, AssignedProjectBox } from "../../styledComponents/teams";
import {
  MembersTableHeadContainer,
  MembersTableContainer,
  TeamSection,
  InviteHeadSection,
  InviteTableContainer,
  InviteProjectSection,
  MemberNameDiv,
  InviteLink,
} from "../../styledComponents/members";
import {
  CommImageNameComp,
  CommImageInviteComp,
  CommFirstRoundLetterComp,
} from "./CommonComponents";

import ComponentCircleLoader from "../../components/Loaders/ComponentCircleLoader";
import CommonTooltip from "../../components/Tooltip/CommonTooltip";
import ProjectListPopup from "../../components/ProjectListPopup/ProjectListPopup";

import Avatar5 from "../../assets/img/avatar_5.svg";

const MembersTable = (props) => {
  const [memberList, setMemberList] = useState([]);
  const [invitedMemberList, setInvitedMemberList] = useState([]);
  const [hoveredTeam, setHoveredTeam] = useState(null);
  const [memberProjectList, setMemberProjectList] = useState(null);

  const actionOptions = [
    { value: "edit", label: "Edit Member" },
    { value: "remove", label: "Remove Member" },
  ];
  const inviteActionOptions = [
    { value: "resend", label: "Resend Invitation" },
    { value: "delete", label: "Delete Invitation" },
  ];

  const {
    membersList,
    invitedMembersList,
    toggleDeleteModal,
    toggleInvitationDeleteModal,
    selectedOrganization,
    inviteMember,
    tabSelect,
    searchTerm,
    isLoading,
    navigate,
  } = props;

  useEffect(() => {
    if (membersList && membersList.length > 0) {
      let list = [];
      membersList.map((member) => {
        list.push({
          id: member.id,
          name: `${member.user.first_name}${
            member.user.last_name ? ` ${member.user.last_name}` : ``
          }`,
          avatar: member.user.avatar ? member.user.avatar : Avatar5,
          role: member.role,
          teams: member.teams,
          assignedProjects: member.projects,
          payment: member.payment
            ? `Hourly ${member.payment.currency} ${(
                member.payment.pay_rate / 100
              ).toFixed(2)}`
            : "",
          weeklyLimit: "",
        });
      });
      setMemberList(list);
    } else if (membersList && membersList.length === 0) {
      setMemberList([]);
    }
  }, [membersList]);

  useEffect(() => {
    if (invitedMembersList && invitedMembersList.length > 0) {
      let list = [];
      invitedMembersList.map((member) => {
        list.push({
          id: member.id,
          email: member.email,
          avatar: Avatar5,
          role: member.role,
          assignedProjects: member.projects,
        });
      });
      setInvitedMemberList(list);
    } else if (invitedMembersList && invitedMembersList.length === 0) {
      setInvitedMemberList([]);
    }
  }, [invitedMembersList]);

  const onActionChange = (e, memberId) => {
    if (e.value === "edit") {
      navigate(`/user/member-details?id=${memberId}`);
    } else if (e.value === "remove") {
      toggleDeleteModal(memberId);
    }
  };

  const onInviteActionChange = (e, invitation) => {
    if (e.value === "delete") {
      toggleInvitationDeleteModal(invitation.id);
    } else if (e.value === "resend") {
      resendMemberInvitation(invitation);
    }
  };

  const resendMemberInvitation = (invitation) => {
    if (invitation) {
      let projectList = [];
      if (
        invitation.assignedProjects &&
        invitation.assignedProjects.length > 0
      ) {
        invitation.assignedProjects.map((project) => {
          projectList.push(project.id);
        });
      }
      if (selectedOrganization && selectedOrganization.id) {
        let payload = {
          organization_id: selectedOrganization.id,
          email: invitation.email,
          project_ids: projectList,
          resend: true,
        };
        inviteMember(payload);
      }
    }
  };

  if (tabSelect === "active") {
    return (
      <>
        <MembersTableHeadContainer
          style={{ borderBottom: `1px solid #C2CCE1` }}
        >
          <TableItem>
            <TableText>Member Name</TableText>
          </TableItem>
          <TableItem>
            <TableText>Role</TableText>
          </TableItem>
          <TableItem>
            <TableText>Teams</TableText>
          </TableItem>
          <TableItem>
            <TableText>Assigned Projects</TableText>
          </TableItem>
          <TableItem>
            <TableText>Payment</TableText>
          </TableItem>
          {/* <TableItem>
              <TableText >Weekly Limit</TableText>
            </TableItem> */}
          {selectedOrganization && selectedOrganization.role !== "member" && (
            <TableItem>
              <TableText>Actions</TableText>
            </TableItem>
          )}
        </MembersTableHeadContainer>
        {memberList.map((member, index) => (
          <MembersTableContainer key={index}>
            <TableItem>
              <MemberNameDiv
                onClick={() => navigate(`/user/member-details?id=${member.id}`)}
              >
                <CommImageNameComp
                  imgSource={member.avatar}
                  name={member.name}
                  size="36px"
                />
              </MemberNameDiv>
            </TableItem>
            <TableItem>
              <TableText style={{ textTransform: "capitalize" }} name>
                {member.role}
              </TableText>
            </TableItem>
            <TeamSection>
              {member.teams.length > 0 ? (
                member.teams.map((team, index) => (
                  <div
                    style={{ position: "relative" }}
                    onMouseEnter={() =>
                      setHoveredTeam({
                        member: member.id,
                        team: team.id,
                      })
                    }
                    onMouseLeave={() => setHoveredTeam(null)}
                  >
                    <CommFirstRoundLetterComp
                      text={team.name}
                      backColor={index}
                      size="30px"
                      key={index}
                    />
                    {hoveredTeam &&
                      hoveredTeam.team === team.id &&
                      hoveredTeam.member === member.id && (
                        <CommonTooltip
                          tooltipText={
                            team.name.length < 12
                              ? team.name
                              : `${team.name.slice(0, 12)}...`
                          }
                          left="-48px"
                          top="-40px"
                          width="130px"
                          height="32px"
                        />
                      )}
                  </div>
                ))
              ) : (
                <TableItem>
                  <TableText name>No Team</TableText>
                </TableItem>
              )}
            </TeamSection>
            <TableItem>
              <RoundLetterSection>
                {member.assignedProjects.length > 0 && (
                  <AssignedProjectBox>
                    {member.assignedProjects[0].name.length < 12
                      ? member.assignedProjects[0].name
                      : `${member.assignedProjects[0].name.slice(0, 12)}...`}
                  </AssignedProjectBox>
                )}
                {member.assignedProjects.length > 1 && (
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onMouseEnter={() => setMemberProjectList(member.id)}
                    onMouseLeave={() => setMemberProjectList(null)}
                  >
                    <AssigneeMoreText>
                      {`${member.assignedProjects.length - 1} More`}
                    </AssigneeMoreText>
                    {memberProjectList && memberProjectList === member.id && (
                      <ProjectListPopup projectList={member.assignedProjects} />
                    )}
                  </div>
                )}
              </RoundLetterSection>
            </TableItem>
            <TableItem>
              <TableText name>
                {member.payment === ""
                  ? "No pay/ Bill rate set"
                  : member.payment}
              </TableText>
              {member.payment === "" &&
                selectedOrganization &&
                selectedOrganization.role !== "member" && (
                  <ResendInvite
                    onClick={() =>
                      navigate(`/user/member-details?id=${member.id}`)
                    }
                  >
                    Set Pay Rate
                  </ResendInvite>
                )}
            </TableItem>
            {/* <TableItem>
                <TableText name>
                  {member.weeklyLimit === ""
                    ? "No Limit Set"
                    : member.weeklyLimit}
                </TableText>
              </TableItem> */}
            {selectedOrganization && selectedOrganization.role !== "member" && (
              <TableItem>
                <Select
                  isSearchable={false}
                  value={null}
                  options={actionOptions}
                  placeholder="Actions"
                  onChange={(e) => {
                    onActionChange(e, member.id);
                  }}
                  styles={createProjectDropdown({ menuWidth: "145px" })}
                />
              </TableItem>
            )}
          </MembersTableContainer>
        ))}
      </>
    );
  }
  return (
    <>
      {invitedMemberList && invitedMemberList.length > 0 && (
        <>
          <InviteHeadSection>
            <TableItem>
              <TableText>Member</TableText>
            </TableItem>
            <TableItem>
              <TableText>Projects</TableText>
            </TableItem>
            <TableItem>
              <TableText>Actions</TableText>
            </TableItem>
          </InviteHeadSection>
          {invitedMemberList.map((member, index) => (
            <InviteTableContainer key={index}>
              <TableItem>
                <CommImageInviteComp
                  imgSource={member.avatar}
                  member={member}
                  size="36px"
                  resendInvite={resendMemberInvitation}
                />
              </TableItem>
              <TableItem>
                <InviteProjectSection>
                  {member.assignedProjects &&
                    member.assignedProjects.length > 0 &&
                    member.assignedProjects.map((project, index) => (
                      <AssignedProjectBox key={index}>
                        {project.name.length < 12
                          ? project.name
                          : `${project.name.slice(0, 12)}...`}
                      </AssignedProjectBox>
                    ))}
                  {member.assignedProjects &&
                    member.assignedProjects.length === 0 && (
                      <TableText name>No projects</TableText>
                    )}
                </InviteProjectSection>
              </TableItem>
              <TableItem style={{ margin: `auto 0` }}>
                <Select
                  isSearchable={false}
                  value={null}
                  options={inviteActionOptions}
                  placeholder="Actions"
                  onChange={(e) => {
                    onInviteActionChange(e, member);
                  }}
                  styles={createProjectDropdown(null)}
                />
              </TableItem>
            </InviteTableContainer>
          ))}
        </>
      )}
      {invitedMemberList && invitedMemberList.length === 0 && !isLoading && (
        <div
          style={{
            padding: "40px 0px 20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {!searchTerm ? (
            <span>
              You don't have invited members.{" "}
              <InviteLink onClick={() => navigate("/user/member-invitation")}>
                Click here to invite one.
              </InviteLink>
            </span>
          ) : (
            `No member found with this email.`
          )}
        </div>
      )}
    </>
  );
};

export default MembersTable;
