import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "../../../components/ReactSelectDropdown";
import MembersDropdownStyle from "../../../components/DropdownStyle/MembersDropdownStyle";
import InfoTooltip from "../../../components/Tooltip/InfoTooltip";
import LinearActivityBar from "../../../components/ProgressBar/LinearActivityBar";
import queryString from "query-string";

import { CommImageNameComp } from "../CommonComponents";
import AddProjectDropdown from "../../../components/CustomDropdown/AddProjectDropdown";
import AddTeamDropdown from "../../../components/CustomDropdown/AddTeamDropdown";

import {
  PaddingBox,
  UpperTitleButtonSection,
  UpperHalfSection,
  PersonSection,
  ActivityCard,
  ActivityTextSection,
  FlexSection,
  PesonActivitySection,
  TextSwitch,
  Dot,
  TextLink,
  ButtonFlexContainer,
  PaymentConfigTitle,
  Line,
  AddPayConfigTextSection,
  AddPayConfigMainSection,
} from "../../../styledComponents/members";
import {
  PageTitle,
  CardTitle,
  CommonText,
  Container,
  InputWithLabelSection,
  InputLabel,
  InputField,
  // CommonColorButton,
  // CommonWhButton,
  TextInputSection,
  ContentHeader,
} from "../../../styledComponents/common";
import {
  WhiteButtonWithIcon,
  ButtonIcon,
  ColoredButton,
  WhiteButton,
  PrimaryButton,
} from "../../../styledComponents/buttons";
import { HoveredSection, RoundHover } from "../../../styledComponents/clients";

import { toHHMMSS, getHours } from "../../../utils/helper";

import BackButtonComponent from "../../../components/BackButtonComponent/BackButtonComponent";
// import MemberDeleteModal from "../MemberDeleteModal";

import Bean from "../../../assets/img/icons/delete.svg";
import Avatar5 from "../../../assets/img/avatar_5.svg";
import Chat from "../../../assets/img/icons/chat.svg";
import CrossIcon from "../../../assets/img/icons/cross_black.svg";

import AssignButtonLoader from "../../../components/AssignButtonLoader/AssignButtonLoader";
import ComponentCircleLoader from "../../../components/Loaders/ComponentCircleLoader";
import ButtonTextLoader from "../../../components/Loaders/ButtonTextLoader";
import CommonTooltip from "../../../components/Tooltip/CommonTooltip";

const toHours = (secs) => {
  let sec_num = parseInt(secs, 10);
  let hours = Math.floor(sec_num / 3600);
  return hours;
};

const MemberDetails = (props) => {
  const [memberDetail, setMemberDetail] = useState(null);
  const [weeklyData, setWeeklyData] = useState(null);
  const [monthlyData, setMonthlyData] = useState(null);
  const [payPeriodValue, setPayPeriodValue] = useState({
    value: "1",
    label: "Weekly",
  });
  const [payTypeValue, setPayTypeValue] = useState({
    value: "1",
    label: "Hourly Payment",
  });
  const RoleOptions = [
    //{ value: "owner", label: "Owner" },
    { value: "admin", label: "Admin" },
    { value: "member", label: "Member" },
  ];
  const payPeriodOptions = [
    { value: "1", label: "Weekly" },
    { value: "2", label: "Monthly" },
  ];
  const payTypeOptions = [
    { value: "1", label: "Hourly Payment" },
    { value: "2", label: "Fixed Payment" },
  ];
  const [editWeeklyLimit, setEditWeeklyLimit] = useState(false);
  const [weeklyLimitValue, setWeeklyLimitValue] = useState("");
  const [payRateValue, setPayRateValue] = useState("");
  const [billRateValue, setBillRateValue] = useState("");
  const [tabSelect, setTabSelect] = useState("week");
  const colorList = [
    { color: "#9A96F9" },
    { color: "#73C0FF" },
    { color: "#F9828B" },
    { color: "#F9D059" },
  ];
  const [modal, setModal] = useState(false);
  const [projectIndex, setProjectIndex] = useState(-1);
  const [teamIndex, setTeamIndex] = useState(-1);
  const [tooltip, setTooltip] = useState(-1);

  const location = useLocation();
  const navigate = useNavigate();

  const {
    clearMemberDetailsData,
    selectedOrganization,
    getMemberDetails,
    memberDetails,
    isLoading,
  } = props;

  useEffect(() => {
    return () => {
      clearMemberDetailsData();
    };
  }, []);

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      if (location.search) {
        const queryParsed = queryString.parse(location.search);
        let payload = {
          organization_id: selectedOrganization.id,
          member_id: queryParsed.id,
        };
        getMemberDetails(payload);
      }
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (memberDetails && memberDetails !== null) {
      let details = {
        id: memberDetails.id,
        userId: memberDetails.user.id,
        name: `${memberDetails.user.first_name}${
          memberDetails.user.last_name ? ` ${memberDetails.user.last_name}` : ``
        }`,
        avatar: memberDetails.user.avatar ? memberDetails.user.avatar : Avatar5,
        role: {
          value: memberDetails.role,
          label: `${memberDetails.role
            .charAt(0)
            .toUpperCase()}${memberDetails.role.slice(1)}`,
        },
        teams: memberDetails.teams,
        assignedProjects: memberDetails.projects,
        payment: memberDetails.payment ? memberDetails.payment : null,
        weeklyEarning: memberDetails.earnings
          ? memberDetails.earnings.week
          : null,
        monthlyEarning: memberDetails.earnings
          ? memberDetails.earnings.month
          : null,
      };
      setMemberDetail(details);
      if (memberDetails.payment) {
        if (memberDetails.payment.pay_rate) {
          setPayRateValue((memberDetails.payment.pay_rate / 100).toFixed(2));
        }
        if (memberDetails.payment.bill_rate) {
          setBillRateValue((memberDetails.payment.bill_rate / 100).toFixed(2));
        }
      }
    }
  }, [memberDetails]);

  const toggleDeleteModal = () => {
    setModal(!modal);
  };

  const handleDeleteMember = () => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      memberDetail &&
      memberDetail.id
    ) {
      let payload = {
        organization_id: selectedOrganization.id,
        member_id: memberDetail.id,
      };
      props.removeMember(payload);
      setTimeout(() => {
        toggleDeleteModal();
        navigate("/user/members");
      }, 300);
    }
  };

  const onRoleChange = (e) => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      memberDetail &&
      memberDetail.id &&
      memberDetail.role
    ) {
      let payload = {
        organization_id: selectedOrganization.id,
        member_id: memberDetail.id,
      };
      if (
        (memberDetail.role.value === "admin" ||
          memberDetail.role.value === "project manager") &&
        e.value === "member"
      ) {
        payload.is_admin = false;
        props.updateMember(payload);
      } else if (
        (memberDetail.role.value === "member" ||
          memberDetail.role.value === "project manager") &&
        e.value === "admin"
      ) {
        payload.is_admin = true;
        props.updateMember(payload);
      }
    }
  };

  const onTabSwitch = (value) => {
    setTabSelect(value);
  };

  const onPayPeriodChange = (e) => {
    setPayPeriodValue(e);
  };
  const onPayTypeChange = (e) => {
    setPayTypeValue(e);
  };

  const onPayRateChange = (e) => {
    const { value } = e.target;
    setPayRateValue(value);
  };
  const onBillRateChange = (e) => {
    const { value } = e.target;
    setBillRateValue(value);
  };

  const handleAddTeam = (team) => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      team &&
      team.id &&
      memberDetail &&
      memberDetail.id &&
      memberDetail.userId
    ) {
      let payload = {
        organization_id: selectedOrganization.id,
        team_id: team.id,
        user_id: memberDetail.userId,
        memberId: memberDetail.id,
        getMemberData: true,
      };
      props.addTeam(payload);
    }
  };

  const handleAssignProject = (project) => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      memberDetail &&
      memberDetail.id &&
      memberDetail.userId &&
      project &&
      project.id
    ) {
      let payload = {
        organization_id: selectedOrganization.id,
        project_id: project.id,
        user_id: memberDetail.userId,
        memberId: memberDetail.id,
        getMemberData: true,
      };
      props.assignProject(payload);
    }
  };

  const handleSavePayment = () => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      memberDetail &&
      memberDetail.id &&
      memberDetail.userId &&
      payRateValue
    ) {
      let payload = {
        organization_id: selectedOrganization.id,
        member_id: memberDetail.id,
        user_id: memberDetail.userId,
        pay_rate: parseFloat(payRateValue).toFixed(2) * 100,
        currency:
          (selectedOrganization.configuration &&
            selectedOrganization.configuration.currency) ||
          "USD",
      };
      if (billRateValue) {
        payload.bill_rate = parseFloat(billRateValue).toFixed(2) * 100;
      }
      props.setPaymentConfiguration(payload);
    }
  };

  const onProjectHover = (index) => {
    setProjectIndex(index);
  };

  const onTeamHover = (index) => {
    setTeamIndex(index);
  };

  const tooltipChange = (index) => {
    setTooltip(index);
  };

  const handleDeleteProject = (projectId) => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      projectId &&
      memberDetail &&
      memberDetail.userId
    ) {
      let payload = {
        organization_id: selectedOrganization.id,
        project_id: projectId,
        user_id: memberDetail.userId,
      };
      props.deleteProjectAssignee(payload);
    }
  };

  const handleDeleteTeam = (teamId) => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      teamId &&
      memberDetail &&
      memberDetail.userId
    ) {
      let payload = {
        organization_id: selectedOrganization.id,
        team_id: teamId,
        user_id: memberDetail.userId,
        fromMember: true,
      };
      props.removeTeamMember(payload);
    }
  };

  return (
    <div className="content">
      <PageTitle>Members</PageTitle>
      <ContentHeader>
        <BackButtonComponent
          onClick={() => navigate("/user/members")}
          subTitle="Back to all members"
        />
      </ContentHeader>
      <Container style={{ padding: `30px 0` }}>
        {isLoading ? (
          <ComponentCircleLoader />
        ) : memberDetail && memberDetail !== null ? (
          <>
            <PaddingBox>
              <UpperTitleButtonSection>
                <CardTitle>Member Details</CardTitle>
                {selectedOrganization &&
                  selectedOrganization.role !== "member" && (
                    <UpperTitleButtonSection gap="0">
                      {memberDetail.role.value !== "owner" && (
                        <WhiteButtonWithIcon
                          type="delete"
                          fontSize="14px"
                          onClick={() => toggleDeleteModal()}
                        >
                          <ButtonIcon src={Bean} alt="" width="18px" />
                          Delete Member
                        </WhiteButtonWithIcon>
                      )}

                      {/* <MemberDeleteModal
                        isOpen={modal}
                        toggle={toggleDeleteModal}
                        handleDelete={handleDeleteMember}
                        isLoading={props.deleteMemberLoading}
                      /> */}
                    </UpperTitleButtonSection>
                  )}
              </UpperTitleButtonSection>

              <UpperHalfSection>
                <PersonSection>
                  <CommImageNameComp
                    imgSource={memberDetail.avatar}
                    name={memberDetail.name}
                  />
                  <InputWithLabelSection style={{ justifyContent: "flex-end" }}>
                    {/* <InfoTooltip
                      mainText="Role"
                      toolTipText="Project Type Sed ut perspiciatis unde omnis iste natus error sit"
                    /> */}
                    <InputLabel>Role</InputLabel>
                    {selectedOrganization &&
                    selectedOrganization.role !== "member" &&
                    memberDetail.role.value !== "owner" ? (
                      <>
                        {props.updateMemberLoading ? (
                          <AssignButtonLoader
                            padding="9px 10px"
                            borderColor="rgb(228, 231, 235)"
                            width="150px"
                          />
                        ) : (
                          <Select
                            isSearchable
                            value={memberDetail.role}
                            options={RoleOptions}
                            onChange={(e) => {
                              onRoleChange(e);
                            }}
                            styles={MembersDropdownStyle({
                              minHeight: "40px",
                              minWidth: "150px",
                            })}
                          />
                        )}
                      </>
                    ) : (
                      <CommonText>{memberDetail.role.label}</CommonText>
                    )}
                  </InputWithLabelSection>
                </PersonSection>
                <ActivityCard>
                  <FlexSection>
                    <TextSwitch
                      onClick={() => onTabSwitch("week")}
                      tabSelect={tabSelect}
                      index={"week"}
                    >
                      This Week
                    </TextSwitch>
                    <TextSwitch
                      onClick={() => onTabSwitch("month")}
                      tabSelect={tabSelect}
                      index={"month"}
                    >
                      Month
                    </TextSwitch>
                  </FlexSection>
                  <PesonActivitySection
                    earned={
                      tabSelect === "week"
                        ? memberDetail.weeklyEarning &&
                          memberDetail.weeklyEarning.total_earned > 0
                        : memberDetail.monthlyEarning &&
                          memberDetail.monthlyEarning.total_earned > 0
                    }
                  >
                    <ActivityTextSection>
                      <CommonText fontSize="16px" title>
                        {tabSelect === "week"
                          ? memberDetail.weeklyEarning &&
                            getHours(
                              toHHMMSS(memberDetail.weeklyEarning.total_time)
                            )
                          : memberDetail.monthlyEarning &&
                            getHours(
                              toHHMMSS(memberDetail.monthlyEarning.total_time)
                            )}
                      </CommonText>
                      <CommonText label fontWeight="500">
                        Time Worked
                      </CommonText>
                    </ActivityTextSection>
                    <ActivityTextSection>
                      <FlexSection>
                        <LinearActivityBar
                          percent={
                            tabSelect === "week"
                              ? memberDetail.weeklyEarning &&
                                (
                                  memberDetail.weeklyEarning.avg_activity * 100
                                ).toFixed(2)
                              : memberDetail.monthlyEarning &&
                                (
                                  memberDetail.monthlyEarning.avg_activity * 100
                                ).toFixed(2)
                          }
                        />
                        <CommonText fontSize="16px" title>
                          {tabSelect === "week"
                            ? `${
                                memberDetail.weeklyEarning &&
                                (
                                  memberDetail.weeklyEarning.avg_activity * 100
                                ).toFixed(2)
                              }%`
                            : `${
                                memberDetail.monthlyEarning &&
                                (
                                  memberDetail.monthlyEarning.avg_activity * 100
                                ).toFixed(2)
                              }%`}
                        </CommonText>
                      </FlexSection>
                      <CommonText label fontWeight="500">
                        Activity
                      </CommonText>
                    </ActivityTextSection>
                    <ActivityTextSection>
                      <CommonText fontSize="16px" title>
                        {tabSelect === "week"
                          ? memberDetail.payment &&
                            memberDetail.payment.pay_rate &&
                            memberDetail.weeklyEarning
                            ? `${memberDetail.weeklyEarning.currency} ${memberDetail.weeklyEarning.total_earned}`
                            : `N/A`
                          : memberDetail.payment &&
                            memberDetail.payment.pay_rate &&
                            memberDetail.monthlyEarning
                          ? `${memberDetail.monthlyEarning.currency} ${memberDetail.monthlyEarning.total_earned}`
                          : `N/A`}
                      </CommonText>
                      <CommonText label fontWeight="500">
                        Earned
                      </CommonText>
                    </ActivityTextSection>
                    {/* {tabSelect === "week" ? (
                      weeklyData && weeklyData.total_earned > 0 ? (
                        <CommonColorButton
                          padding="13px 25px"
                          style={{ margin: `auto 0` }}
                        >
                          View Payment Info
                        </CommonColorButton>
                      ) : null
                    ) : monthlyData && monthlyData.total_earned > 0 ? (
                      <CommonColorButton
                        padding="13px 25px"
                        style={{ margin: `auto 0` }}
                      >
                        View Payment Info
                      </CommonColorButton>
                    ) : null} */}
                  </PesonActivitySection>
                  {/* <FlexSection>
                    <TextLink>View Timesheet</TextLink>
                    <Dot />
                    <TextLink>View Activity</TextLink>
                  </FlexSection> */}
                </ActivityCard>
              </UpperHalfSection>
              <CommonText label>Teams he works for</CommonText>
              <ButtonFlexContainer>
                {memberDetail.teams.map((team, index) => (
                  <ColoredButton
                    bgColor={colorList[index > 3 ? index % 4 : index].color}
                    key={index}
                    onMouseEnter={() => onTeamHover(index)}
                    onMouseLeave={() => onTeamHover(-1)}
                    style={{ position: "relative" }}
                  >
                    <span
                      onClick={() =>
                        navigate(`/user/team-details?id=${team.id}`)
                      }
                    >
                      {team.name}
                    </span>
                    {teamIndex === index && (
                      <HoveredSection
                        style={{
                          gridTemplateColumns: "1fr",
                          right: "5px",
                        }}
                      >
                        <RoundHover
                          onMouseEnter={() => tooltipChange(1)}
                          onMouseLeave={() => tooltipChange(-1)}
                          onClick={() => handleDeleteTeam(team.id)}
                        >
                          <img src={CrossIcon} alt="cross" />
                          {tooltip === 1 && (
                            <CommonTooltip
                              tooltipText="Remove Member"
                              left="-58px"
                              top="-40px"
                              width="140px"
                            />
                          )}
                        </RoundHover>
                      </HoveredSection>
                    )}
                  </ColoredButton>
                ))}
                {props.teamMemberIsLoading ? (
                  <AssignButtonLoader />
                ) : (
                  selectedOrganization &&
                  selectedOrganization.role !== "member" && (
                    <AddTeamDropdown
                      isLoading={props.teamListIsLoading}
                      selectedOrganization={selectedOrganization}
                      assignedTeams={memberDetail.teams}
                      getTeamList={props.getTeamShortList}
                      updateState={handleAddTeam}
                      teamsList={props.teamList}
                      buttonPadding="10px 15px"
                    />
                  )
                )}
              </ButtonFlexContainer>
              <CommonText label>Assigned Projects</CommonText>
              <ButtonFlexContainer>
                {memberDetail.assignedProjects.map((project, index) => (
                  <WhiteButton
                    type="cancel"
                    key={index}
                    onMouseEnter={() => onProjectHover(index)}
                    onMouseLeave={() => onProjectHover(-1)}
                    style={{ position: "relative" }}
                  >
                    <span
                      onClick={() =>
                        navigate(
                          `/user/project-details?id=${project.id}&type=${
                            project.is_active ? "active" : "archived"
                          }`
                        )
                      }
                    >
                      {project.name}
                    </span>
                    {projectIndex === index && (
                      <HoveredSection
                        style={{
                          gridTemplateColumns: "1fr",
                          right: "5px",
                        }}
                      >
                        <RoundHover
                          onMouseEnter={() => tooltipChange(1)}
                          onMouseLeave={() => tooltipChange(-1)}
                          onClick={() => handleDeleteProject(project.id)}
                        >
                          <img src={CrossIcon} alt="cross" />
                          {tooltip === 1 && (
                            <CommonTooltip
                              tooltipText="Remove Member"
                              left="-58px"
                              top="-40px"
                              width="140px"
                            />
                          )}
                        </RoundHover>
                      </HoveredSection>
                    )}
                  </WhiteButton>
                ))}
                {props.projectAssigneeIsLoading ? (
                  <AssignButtonLoader />
                ) : (
                  selectedOrganization &&
                  selectedOrganization.role !== "member" && (
                    <AddProjectDropdown
                      isLoading={props.projectListIsLoading}
                      selectedOrganization={selectedOrganization}
                      assignedProjects={memberDetail.assignedProjects}
                      getProjectList={props.getProjectShortList}
                      updateState={handleAssignProject}
                      projectsList={props.projectList}
                      buttonPadding="9px 15px"
                    />
                  )
                )}
              </ButtonFlexContainer>

              <ActivityTextSection>
                <CommonText label>Payment</CommonText>
                {memberDetail && memberDetail.payment && (
                  <CommonText>
                    {(memberDetail.payment.pay_rate / 100).toFixed(2)}{" "}
                    {memberDetail.payment.currency}/hour
                  </CommonText>
                )}
              </ActivityTextSection>

              {/* <ActivityTextSection style={{ marginTop: `30px` }}>
                <CommonText fontWeight="600">Weekly Limit</CommonText>
                <WeeklyLimitSection>
                  {editWeeklyLimit ? (
                    <FlexSection gap="13px">
                      <InputField
                        style={{ width: `70px` }}
                        type="text"
                        placeholder="00.00"
                        value={weeklyLimitValue}
                        onChange={(e) => onWeeklyLimitChange(e)}
                      />
                      <CommonText>Hours</CommonText>
                    </FlexSection>
                  ) : (
                    <CommonText>
                      {personObject.weeklyLimit
                        ? `$${personObject.weeklyLimit}`
                        : `No weekly limit is set`}
                    </CommonText>
                  )}
                  {editWeeklyLimit ? (
                    <CommonColorButton
                      padding="11px 20px"
                      onClick={() => setEditWeeklyLimit(false)}
                    >
                      Set Limit
                    </CommonColorButton>
                  ) : (
                    <CommonWhButton
                      padding="11px 20px"
                      onClick={() => setEditWeeklyLimit(true)}
                    >
                      Edit Limit
                    </CommonWhButton>
                  )}
                </WeeklyLimitSection>
              </ActivityTextSection> */}
            </PaddingBox>
            {selectedOrganization && selectedOrganization.role !== "member" && (
              <>
                <PaymentConfigTitle>
                  Add Payment Configuration
                </PaymentConfigTitle>
                <Line />
                <PaddingBox>
                  <AddPayConfigTextSection>
                    <CommonText label>Member Name</CommonText>
                    <CommonText>{memberDetail.name}</CommonText>
                    {selectedOrganization && (
                      <>
                        <CommonText label>Organization Name</CommonText>
                        <CommonText>{selectedOrganization.name}</CommonText>
                      </>
                    )}
                  </AddPayConfigTextSection>

                  <AddPayConfigMainSection>
                    {/* <TextInputSection>
                        <InfoTooltip
                          mainText="Pay Period"
                          toolTipText="Project Type Sed ut perspiciatis unde omnis iste natus error sit"
                        />
                        <Select
                          isSearchable
                          value={payPeriodValue}
                          options={payPeriodOptions}
                          onChange={(e) => {
                            onPayPeriodChange(e);
                          }}
                          styles={MembersDropdownStyle()}
                        />
                      </TextInputSection>
                      <TextInputSection>
                        <InfoTooltip
                          mainText="Payment Type"
                          toolTipText="Project Type Sed ut perspiciatis unde omnis iste natus error sit"
                        />
                        <Select
                          isSearchable
                          value={payTypeValue}
                          options={payTypeOptions}
                          onChange={(e) => {
                            onPayTypeChange(e);
                          }}
                          styles={MembersDropdownStyle()}
                        />
                      </TextInputSection> */}
                    <TextInputSection>
                      <InfoTooltip
                        mainText="Pay Rate"
                        toolTipText="Pay rate is used to manage your employee's payroll"
                        title
                        label
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <CommonText style={{ marginRight: "10px" }}>
                          {selectedOrganization &&
                            selectedOrganization.configuration &&
                            selectedOrganization.configuration.currency}
                        </CommonText>
                        <InputField
                          height="36px"
                          style={{ width: "90%" }}
                          type="text"
                          placeholder="00"
                          value={payRateValue}
                          onChange={(e) => onPayRateChange(e)}
                        />
                      </div>
                    </TextInputSection>
                    <TextInputSection>
                      <InfoTooltip
                        mainText="Bill Rate"
                        toolTipText="Bill rate is used to invoice your client"
                        title
                        label
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <CommonText style={{ marginRight: "10px" }}>
                          {selectedOrganization &&
                            selectedOrganization.configuration &&
                            selectedOrganization.configuration.currency}
                        </CommonText>
                        <InputField
                          height="36px"
                          style={{ width: "90%" }}
                          type="text"
                          placeholder="00"
                          value={billRateValue}
                          onChange={(e) => onBillRateChange(e)}
                        />
                      </div>
                    </TextInputSection>
                    <PrimaryButton
                      height="36px"
                      style={{ alignSelf: "flex-end" }}
                      padding={
                        props.paymentConfigurationLoading ? "0 15px" : "0 30px"
                      }
                      onClick={() => handleSavePayment()}
                      disabled={!payRateValue}
                    >
                      {props.paymentConfigurationLoading ? (
                        <ButtonTextLoader
                          loadingText="Saving"
                          fontSize="13px"
                        />
                      ) : (
                        "Save"
                      )}
                    </PrimaryButton>
                  </AddPayConfigMainSection>
                </PaddingBox>
                {/* <ButtonSection>
                    <Button save={false} onClick={() => cancelFunction()}>
                      Cancel
                    </Button>
                    <Button save onClick={() => handleSave()}>
                      Save
                    </Button>
                  </ButtonSection> */}
              </>
            )}
          </>
        ) : null}
      </Container>
    </div>
  );
};

export default MemberDetails;
