import { connect } from "react-redux";

// Component
import Login from "./Login";
// Actions
import { loginRequest, loginRedirectReset } from "./loginActions";

import {
  searchSubscription
} from "../Subscription/subscriptionActions"

// Store props to map with current state
const mapStateToProps = (state) => ({
  auth: state.login.auth,
  userId: state.login.userId,
  redirect: state.login.redirect,
  isLoading: state.login.isLoading,
  notVerified: state.login.notVerified,
  errorMessage: state.login.errorMessage,
  currentPlan: state.subscription.currentSubscription,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  loginRequest: (details) => dispatch(loginRequest({ ...details })),
  loginRedirectReset: () => dispatch(loginRedirectReset()),
  searchSubscription: (payload) => dispatch(searchSubscription(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
