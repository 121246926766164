import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import MembersTable from "./MembersTable";
import { TabLeftGrid } from "../../styledComponents/members";
import {
  PageTitle,
  HeaderContainer,
  InputWithLabelSection,
  FilterLabel,
  InputField,
  Container,
} from "../../styledComponents/common";
import {
  TabButtonContainer,
  TabButton,
  ColoredButtonWithIcon,
  ButtonIcon,
} from "../../styledComponents/buttons";
// import MemberDeleteModal from "./MemberDeleteModal";
// import DeleteInvitationModal from "./DeleteInvitationModal";

import ComponentCircleLoader from "../../components/Loaders/ComponentCircleLoader";

import Plus from "../../assets/img/icons/plus_white.svg";

// import Pagination from "../../components/Pagination/PaginationComponent";

const Members = (props) => {
  const [memberList, setMemberList] = useState([]);
  const [invitedList, setInvitedMembersList] = useState([]);
  const [tabSelect, setTabSelect] = useState("active");
  const [searchTerm, setSearchTerm] = useState("");
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isDeleteInvitationModalVisible, setIsDeleteInvitationModalVisible] =
    useState(false);
  const [memberDeleteId, setMemberDeleteId] = useState("");
  const [invitationDeleteId, setInvitationDeleteId] = useState("");
  const [roleValue, setRoleValue] = useState({
    value: "1",
    label: "All Roles",
  });
  const RoleOptions = [
    { value: "1", label: "All Roles" },
    { value: "2", label: "Specific Roles" },
    { value: "3", label: "Test Project" },
  ];

  const location = useLocation();
  const navigate = useNavigate();

  const {
    selectedOrganization,
    isLoading,
    getMembersList,
    getInvitedMembersList,
    membersList,
    invitedMembersList,
    removeMember,
    deleteMemberInvitation,
    deleteMemberLoading,
    deleteInvitationLoading,
    inviteMember,
  } = props;

  useEffect(() => {
    if (location.search) {
      let queryParsed = queryString.parse(location.search);
      if (queryParsed.tab && queryParsed.tab === "invited") {
        setTabSelect("invited");
      }
    }
  }, []);

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      let payload = {
        organization_id: selectedOrganization.id,
      };
      getMembersList(payload);
      if (tabSelect && tabSelect === "invited") {
        getInvitedMembersList(payload);
      }
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
      };
      if (tabSelect && tabSelect === "invited") {
        getInvitedMembersList(payload);
      }
    }
  }, [tabSelect]);

  useEffect(() => {
    if (membersList && membersList.length > 0) {
      setMemberList(membersList);
    } else if (membersList && membersList.length === 0) {
      setMemberList([]);
    }
  }, [membersList]);

  useEffect(() => {
    if (invitedMembersList && invitedMembersList.length > 0) {
      setInvitedMembersList(invitedMembersList);
    } else if (invitedMembersList && invitedMembersList.length === 0) {
      setInvitedMembersList([]);
    }
  }, [invitedMembersList]);

  useEffect(() => {
    if (
      tabSelect &&
      searchTerm &&
      searchTerm !== "" &&
      membersList &&
      membersList.length > 0
    ) {
      let originalList;
      if (tabSelect === "active") {
        originalList = membersList;
      } else if (tabSelect === "invited") {
        originalList = invitedMembersList;
      }
      let filteredList = [];
      originalList.forEach((e) => {
        let searchField;
        if (tabSelect === "active") {
          searchField = `${e.user.first_name}${
            e.user.last_name ? ` ${e.user.last_name}` : ``
          }`;
        } else if (tabSelect === "invited") {
          searchField = e.email;
        }
        //let memberName = `${e.user.first_name} ${e.user.last_name}`;
        if (
          searchTerm &&
          searchField.toLowerCase().indexOf(searchTerm.toLowerCase().trim()) ===
            -1
        ) {
          return;
        }
        if (selectedOrganization) {
          filteredList.push(e);
        }
      });
      if (tabSelect === "active") {
        setMemberList(filteredList);
      } else if (tabSelect === "invited") {
        setInvitedMembersList(filteredList);
      }
    } else if (searchTerm === "") {
      setMemberList(membersList);
      if (tabSelect === "active") {
        setMemberList(membersList);
      } else if (tabSelect === "invited") {
        setInvitedMembersList(invitedMembersList);
      }
    }
  }, [searchTerm]);

  const onTabSwitch = (value) => {
    if (value === "invited") {
      if (selectedOrganization && selectedOrganization.id) {
        let payload = {
          organization_id: selectedOrganization.id,
        };
        getInvitedMembersList(payload);
      }
    }
    setTabSelect(value);
  };

  const handleDeleteMember = () => {
    if (selectedOrganization && selectedOrganization.id && memberDeleteId) {
      let payload = {
        organization_id: selectedOrganization.id,
        member_id: memberDeleteId,
      };
      removeMember(payload);
      setTimeout(() => {
        setIsDeleteModalVisible(!isDeleteModalVisible);
      }, 300);
    }
  };

  const handleDeleteInvitation = () => {
    if (selectedOrganization && selectedOrganization.id && invitationDeleteId) {
      let payload = {
        organization_id: selectedOrganization.id,
        invitation_id: invitationDeleteId,
      };
      deleteMemberInvitation(payload);
      setTimeout(() => {
        setIsDeleteInvitationModalVisible(!isDeleteInvitationModalVisible);
      }, 1200);
    }
  };

  const toggleDeleteModal = (memberId) => {
    if (!isDeleteModalVisible) {
      setMemberDeleteId(memberId);
    }
    setIsDeleteModalVisible(!isDeleteModalVisible);
  };

  const toggleInvitationDeleteModal = (id) => {
    if (!isDeleteInvitationModalVisible) {
      setInvitationDeleteId(id);
    }
    setIsDeleteInvitationModalVisible(!isDeleteInvitationModalVisible);
  };

  const onSearchTermChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
  };
  const onRoleChange = (e) => {
    setRoleValue(e);
  };

  return (
    <div className="content">
      {/* <MemberDeleteModal
        isOpen={isDeleteModalVisible}
        toggle={() => setIsDeleteModalVisible(!isDeleteModalVisible)}
        handleDelete={handleDeleteMember}
        isLoading={deleteMemberLoading}
      /> */}
      {/* <DeleteInvitationModal
        isOpen={isDeleteInvitationModalVisible}
        toggle={() =>
          setIsDeleteInvitationModalVisible(!isDeleteInvitationModalVisible)
        }
        handleDelete={handleDeleteInvitation}
        isLoading={deleteInvitationLoading}
      /> */}
      <PageTitle>Members</PageTitle>
      {selectedOrganization && selectedOrganization.role !== "member" && (
        <HeaderContainer>
          <TabLeftGrid>
            <InputWithLabelSection>
              <FilterLabel>Type</FilterLabel>
              <TabButtonContainer>
                <TabButton
                  onClick={() => onTabSwitch("active")}
                  selected={tabSelect}
                  index={"active"}
                >
                  Active
                </TabButton>
                <TabButton
                  onClick={() => onTabSwitch("invited")}
                  selected={tabSelect}
                  index={"invited"}
                >
                  Invited
                </TabButton>
              </TabButtonContainer>
            </InputWithLabelSection>

            <InputWithLabelSection>
              <FilterLabel>Search Member</FilterLabel>
              <InputField
                type="text"
                placeholder={`Search member by ${
                  tabSelect === "active" ? "name" : "email"
                }...`}
                value={searchTerm}
                onChange={(e) => onSearchTermChange(e)}
              />
            </InputWithLabelSection>
          </TabLeftGrid>

          <ColoredButtonWithIcon
            onClick={() => navigate("/user/member-invitation")}
          >
            <ButtonIcon src={Plus} alt="" />
            Invite Member
          </ColoredButtonWithIcon>
        </HeaderContainer>
      )}

      <Container padding="0 0 10px 0" marginTop="0">
        {isLoading ? (
          <ComponentCircleLoader />
        ) : (
          <MembersTable
            selectedOrganization={selectedOrganization}
            tabSelect={tabSelect}
            membersList={memberList}
            invitedMembersList={invitedList}
            inviteMember={inviteMember}
            navigate={navigate}
            toggleDeleteModal={toggleDeleteModal}
            toggleInvitationDeleteModal={toggleInvitationDeleteModal}
            searchTerm={searchTerm}
            isLoading={isLoading}
          />
        )}
      </Container>

      {/* <Pagination /> */}
    </div>
  );
};

export default Members;
