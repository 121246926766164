import React, { useState, useEffect } from "react";
import Select from "../../components/ReactSelectDropdown";
import { useLocation, useNavigate } from "react-router-dom";
import FilterDropDownStyle from "../../components/DropdownStyle/FilterDropDownStyle";
import InvoiceTable from "./InvoiceTable";
import { FilterContainer } from "../../styledComponents/invoice";
import {
  PageTitle,
  InputWithLabelSection,
  FilterLabel,
  InputField,
} from "../../styledComponents/common";
import {
  ColoredButtonWithIcon,
  ButtonIcon,
} from "../../styledComponents/buttons";

import Plus from "../../assets/img/icons/plus_white.svg";

const Invoice = (props) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStatus, setSelectedStatus] = useState({
    value: "",
    label: "All",
  });
  const statusOptions = [
    { value: "", label: "All" },
    { value: "draft", label: "Draft" },
    { value: "sent", label: "Sent" },
    { value: "paid", label: "Paid" },
    { value: "partial", label: "Partially Paid" },
  ];
  const [sentState, setSentState] = useState(false);
  const [recordPaymentState, setRecordPaymentState] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [filteredInvoiceList, setFilteredInvoiceList] = useState([]);
  const navigate = useNavigate();

  const onSearchTermChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
  };

  const onStatusSelect = (e) => {
    setSelectedStatus(e);
  };

  const onSend = () => {
    setSentState(true);
  };

  const onRecordPay = () => {
    setRecordPaymentState(true);
  };

  const onDelete = () => {
    setDeleted(true);
  };

  useEffect(() => {
    if (props.selectedOrganization && props.selectedOrganization.id) {
      let payload = {
        organization_id: props.selectedOrganization.id,
      };
      props.getInvoiceList(payload);
    }
  }, [props.selectedOrganization]);

  useEffect(() => {
    if (
      props.selectedOrganization &&
      props.selectedOrganization.id &&
      props.sendInvoiceEmailInfo &&
      props.sendInvoiceEmailInfo.log_id &&
      sentState
    ) {
      const payload = {
        organization_id: props.selectedOrganization.id,
        background: true,
      };
      const timer = setTimeout(() => {
        props.getInvoiceList(payload);
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, [props.sendInvoiceEmailInfo]);

  useEffect(() => {
    if (
      props.selectedOrganization &&
      props.selectedOrganization.id &&
      props.recordPaymentInfo &&
      props.recordPaymentInfo.id &&
      recordPaymentState
    ) {
      const payload = {
        organization_id: props.selectedOrganization.id,
        background: true,
      };
      props.getInvoiceList(payload);
    }
  }, [props.recordPaymentInfo]);

  // useEffect(() => {
  //   if (
  //     props.selectedOrganization &&
  //     props.selectedOrganization.id &&
  //     props.deletedInvoiceCallback &&
  //     deleted
  //   ) {
  //     const payload = {
  //       organization_id: props.selectedOrganization.id,
  //     };
  //     props.getInvoiceList(payload);
  //   }
  // }, [props.deletedInvoiceCallback]);

  useEffect(() => {
    if (
      props.invoiceList &&
      props.invoiceList.results &&
      props.invoiceList.results.length > 0
    ) {
      let newList = [...props.invoiceList.results];
      let filterExist = false;
      if (selectedStatus.value && selectedStatus.value !== "") {
        newList = newList.filter(
          (item) => item.status === selectedStatus.value
        );
        setFilteredInvoiceList(newList);
        filterExist = true;
      }
      if (searchTerm && searchTerm !== "") {
        newList = newList.filter((item) =>
          item.client_name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredInvoiceList(newList);
        filterExist = true;
      }
      !filterExist && setFilteredInvoiceList(newList);
    } else if (
      !props.invoiceList ||
      (props.invoiceList.results && props.invoiceList.results.length === 0)
    ) {
      setFilteredInvoiceList([]);
    }
  }, [props.invoiceList, selectedStatus.value, searchTerm]);


  return (
    <div className="content">
      <PageTitle>Invoices</PageTitle>
      <FilterContainer>
        <InputWithLabelSection>
          <FilterLabel>Search Client</FilterLabel>
          <InputField
            type="text"
            placeholder="Search by client name..."
            value={searchTerm}
            onChange={(e) => onSearchTermChange(e)}
          />
        </InputWithLabelSection>
        <InputWithLabelSection>
          <FilterLabel>Status</FilterLabel>
          <Select
            isSearchable
            value={selectedStatus}
            options={statusOptions}
            onChange={(e) => {
              onStatusSelect(e);
            }}
            styles={FilterDropDownStyle({ height: "39px" })}
          />
        </InputWithLabelSection>
        <ColoredButtonWithIcon
          style={{ alignSelf: "flex-end" }}
          margin="0 0 0 auto"
          onClick={() => navigate("/user/create-invoice")}
        >
          <ButtonIcon src={Plus} />
          Create New Invoice
        </ColoredButtonWithIcon>
      </FilterContainer>

      {props.selectedOrganization &&
        props.selectedOrganization.id &&
        filteredInvoiceList &&
        props.profileData && (
          <InvoiceTable
            isLoading={props.isLoading}
            sendMailLoading={props.sendMailLoading}
            recordPaymentLoading={props.recordPaymentLoading}
            deleteInvoiceLoading={props.deleteInvoiceLoading}
            selectedOrganization={props.selectedOrganization}
            invoiceList={filteredInvoiceList}
            navigate={navigate}
            recordPaymentInvoice={props.recordPaymentInvoice}
            createInvoiceUpdateSendLog={props.createInvoiceUpdateSendLog}
            updateInvoiceSendLog={props.updateInvoiceSendLog}
            sendInvoiceEmail={props.sendInvoiceEmail}
            senderName={props.profileData.first_name}
            deleteInvoice={props.deleteInvoice}
            onSend={onSend}
            onRecordPay={onRecordPay}
            onDelete={onDelete}
            noData={props.noData}
            selectedStatus={selectedStatus}
            searchTerm={searchTerm}
          />
        )}
    </div>
  );
};

export default Invoice;
