// light
import { fontsConfiguration as fontsLight } from "./light/fonts";
import { buttonsConfiguration as buttonsLight } from "./light/buttons";
import { inputConfiguration as inputLight } from "./light/inputs";
import { cardsConfiguration as cardsLight } from "./light/cards";
import { sidebarConfiguration as sidebarLight } from "./light/sidebar";
import { dropdownConfiguration as dropdownLight } from "./light/dropdown";
import {
  colors as colorsLight,
  hoverColors as hoverColorsLight,
  borderColors as borderColorsLight,
} from "./light/colors";

// common
import { radiusConfig, fontWeightConfig } from "./common";

const defaultFontFamily = '"Montserrat", sans-serif';

export const lightTheme = {
  defaultFontFamily,
  font: fontsLight,
  button: buttonsLight,
  card: cardsLight,
  input: inputLight,
  colors: {
    main: colorsLight,
    hover: hoverColorsLight,
    border: borderColorsLight,
  },
  fontWeights: fontWeightConfig,
  borderRadii: radiusConfig,

  sidebar: sidebarLight,
  dropdown: dropdownLight,
};

export const darkTheme = {
  font: {},
  button: {},
};
