export const clientsListSpaceX = [
  {
    id: "97414171-513f-4a74-acfe-3c751ccb8b4c",
    email: "robert.daniel@spacex.com",
    is_active: true,
    status: "Invoiced Before",
    name: "Robert Daniel",
    office_location: "4379 Millbrook Road, South Salen",
    phone_number: "+1 425 555 0100",
    projects: [],
    invoices: [],
  },
  {
    id: "4a744171-513f-4a74-acfe-3c751ccb8b4c",
    email: "mattkenneth@spacex.com",
    is_active: true,
    status: "Not Invoiced Yet",
    name: "Matthew Kenneth",
    office_location: "1216 Bend River Road, Lexington",
    phone_number: "+1 334 112 9878",
    projects: [],
    invoices: [],
  },
  {
    id: "3c754171-513f-4a74-acfe-3c751ccb8b4c",
    email: "kevin_mark@spacex.com",
    is_active: true,
    status: "Invoiced Before",
    name: "Kevin Mark",
    office_location: "17 Bates Brothers Road, Columbus",
    phone_number: "+1 567 767 0154",
    projects: [],
    invoices: [],
  },
];

export const clientsListOceana = [
  {
    id: "751c4171-513f-4a74-acfe-3c751ccb8b4c",
    email: "daniel.zep@oceana.com",
    is_active: true,
    status: "Not Invoiced Yet",
    name: "Daniel Zephyr",
    office_location: "47 Bond Street, Regent Park",
    phone_number: "+49 49 112 29 117",
    projects: [],
    invoices: [],
  },
  {
    id: "acfe4171-513f-4a74-acfe-3c751ccb8b4c",
    email: "roscoe_rufus@oceana.com",
    is_active: true,
    status: "Invoiced Before",
    name: "Rufus Roscoe",
    office_location: "327 Ersel Street, Montreal",
    phone_number: "+74 88 771 12 089",
    projects: [],
    invoices: [],
  },
  {
    id: "ccb8b171-513f-4a74-acfe-3c751ccb8b4c",
    email: "milo_otis@oceana.com",
    is_active: true,
    status: "Not Invoiced Yet",
    name: "Milo Otis",
    office_location: "95 Saint Francis Way, Dallas",
    phone_number: "+33 12 208 31 971",
    projects: [],
    invoices: [],
  },
];

export const clientsListData = {
  "s1dty88f-5yfd-4fg5-b6c2-eeae27f8347c": {
    clients: clientsListSpaceX,
  },
  "4bcfb309-e861-4c9d-bd9a-fdb8rree6b427": {
    clients: clientsListOceana,
  },
};
