//Effects
import { all, put, takeLatest } from "redux-saga/effects";

//Service
import { defaultApi } from "../../utils/axiosApi";

//Constants
import {
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_REQUEST_SUCCESS,
  FORGOT_PASSWORD_REQUEST_FAILURE
} from "../../utils/constants";

//Handle login request
export function* forgotPasswordRequest({ payload }) {
  const { email } = payload;
  try {
    let url = `registration/ForgotPassword?EmailId=${email}`;
    let method = "GET";
    const response = yield defaultApi(url, method);
    yield put({
      type: FORGOT_PASSWORD_REQUEST_SUCCESS,
      payload: { data: response, notify: "GET" },
    });
  } catch (err) {
    yield put({
      type: FORGOT_PASSWORD_REQUEST_FAILURE,
      payload: err
    });
  }
}

export default function* root() {
  yield all([takeLatest(FORGOT_PASSWORD_REQUEST, forgotPasswordRequest)]);
}
