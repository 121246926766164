import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  width: ${(props) =>
    `calc(22% * ${props.itemsCount} + 4% * (${props.itemsCount} - 1))`};
`;
export const Input = styled.input`
  position: absolute;
  top: 0;
  left: ${(props) =>
    `calc(${props.activeIndex} * 22% + 4% * ${props.activeIndex})`};
  opacity: ${(props) => (props.activeIndex >= 0 ? "1" : "0")};
  font-size: 34px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.colors.main.textMain};
  text-align: center;
  width: 22%;
  height: 5rem;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.primary};
  &:focus {
    outline: none;
  }
`;

// item
export const Item = styled.div`
  width: 22%;
  height: 5rem;
  padding: 0;
  font-size: 34px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  line-height: 5rem;
  text-align: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.secondaryLight};
`;
