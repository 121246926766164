import React from "react";
import { components } from "react-select";
import styled from "styled-components";

import indicatorIcon from "../../assets/img/Sidebar/sidebar-arrow-down.svg";

const DropdownIndicatorIcon = styled.img`
  height: ${({ size }) => size || "22px"};
  width: ${({ size }) => size || "22px"};
`;

export const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <DropdownIndicatorIcon
        src={indicatorIcon}
        alt=""
        size={props.selectProps.indicatorIconSize}
      />
    </components.DropdownIndicator>
  );
};

export const IndicatorSeparator = ({ innerProps }) => <span {...innerProps} />;
