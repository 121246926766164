import {
  REQUEST_ORGANIZATION_LIST,
  REQUEST_ORGANIZATION_LIST_SUCCESS,
  REQUEST_ORGANIZATION_LIST_FAILURE,
  SELECT_ORGANIZATION,
  GET_ORGANIZATION_TYPES,
  GET_ORGANIZATION_TYPES_SUCCESS,
  GET_ORGANIZATION_TYPES_FAILURE,
  CREATE_ORGANIZATION,
  CREATE_ORGANIZATION_SUCCESS,
  CREATE_ORGANIZATION_FAILURE,
  UPDATE_ORGANIZATION,
  UPDATE_ORGANIZATION_SUCCESS,
  UPDATE_ORGANIZATION_FAILURE,
  GET_ORGANIZATION_MEMBERS,
  GET_ORGANIZATION_MEMBERS_SUCCESS,
  GET_ORGANIZATION_MEMBERS_FAILURE,
  LOGIN_PROFILE_CREATED_RESET,
  GET_ORGANIZATION_PLANS,
  GET_ORGANIZATION_PLANS_SUCCESS,
  GET_ORGANIZATION_PLANS_FAILURE,
  DELETE_ORGANIZATION,
  DELETE_ORGANIZATION_SUCCESS,
  DELETE_ORGANIZATION_FAILURE,
  CLEAR_ORG_CREATED,
} from "../../utils/constants";
import { createNotification } from "../../utils/notificationManager";

import { organizationsListData } from "../../datasets/organizations";

const colorList = ["#708CEB", "#76C794", "#73C0FC", "#F9828B"];

// ------------------------------------
// Action handler methods
// ------------------------------------
export const organizationListRequestHandler = (state, action) => {
  return {
    ...state,
    isLoading: true,
    // list: organizationsListData,
  };
};

export const organizationListRequestSuccessHandler = (state, action) => {
  const {
    payload: { data, notify },
  } = action;
  // if (data.status === 200) {
  //   if (data.data && data.data.length > 0) {
  //     const orgList = [];
  //     data.data.map((org, index) => {
  //       let item = { ...org };
  //       item.color = colorList[index % 4];
  //       orgList.push(item);
  //     });
  //     state.list = orgList; //If I don't mutate it, in activity pages, members are not loaded
  //   } else {
  //     state.list = data.data;
  //   }
  //   if (notify === true) {
  //     createNotification("success", "organizationList update success", 3000);
  //   }
  // }

  return {
    ...state,
    isLoading: false,
    list: data,
  };
};

export const organizationListRequestFailureHandler = (state, action) => {
  if (action.payload.response === undefined) {
    createNotification("error", "No internet connection found", 3000);
    return {
      ...state,
      isLoading: false,
    };
  }
  const {
    payload: {
      response: { data, status },
    },
  } = action;
  if (status === 400) {
    if (data.phone) {
      createNotification("error", data.phone[0], 3000);
    }
  }
  return {
    ...state,
    isLoading: false,
  };
};

export const selectOrganizationHandler = (state, action) => {
  if (action.payload) {
    return {
      ...state,
      selectedOrganization: action.payload,
    };
  }

  return {
    ...state,
    // selectedOrganization: null,
  };
};

//Get organization types
export const getOrganizationTypesHandler = (state, action) => {
  return {
    ...state,
    isLoading: false,
  };
};

export const getOrganizationTypesSuccessHandler = (state, action) => {
  return {
    ...state,
    isLoading: false,
    types: action.payload.data,
  };
};

export const getOrganizationTypesFailureHandler = (state, action) => {
  return {
    ...state,
    isLoading: false,
  };
};

//Get organization plans
export const getOrganizationPlansHandler = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const getOrganizationPlansSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    let plans = [];
    data.data.map((item) => {
      if (item.interval === "monthly") {
        plans.push(item);
      }
    });
    return {
      ...state,
      isLoading: false,
      plans: plans,
      allPlans: data.data,
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};

export const getOrganizationPlansFailureHandler = (state, action) => {
  return {
    ...state,
    isLoading: false,
    plans: [],
    allPlans: [],
  };
};

//Create organization
export const createOrganizationHandler = (state, action) => {
  return {
    ...state,
    isLoading: true,
    orgCreated: false,
  };
};
export const createOrganizationSuccessHandler = (state, action) => {
  createNotification("success", "Successfully created!", 3000);
  localStorage.setItem("selected_organization", action.payload.data.name);
  localStorage.setItem("selected_organization_id", action.payload.data.id);
  localStorage.setItem("is_owner", true);
  action.payload.data.color = colorList[state.list.length % 4];
  return {
    ...state,
    isLoading: false,
    redirect: true,
    orgCreated: true,
    createdOrgId: action.payload.data.id,
    createdOrgIsActive: action.payload.data.is_active,
    list: [...state.list, action.payload.data],
  };
};
export const createOrganizationFailureHandler = (state, action) => {
  createNotification("error", "Something went wrong!", 3000);
  return {
    ...state,
    isLoading: false,
    orgCreated: false,
    createdOrgId: null,
    createdOrgIsActive: false,
  };
};

//Update organization
export const updateOrganizationHandler = (state, action) => {
  return {
    ...state,
    orgUpdateLoading: true,
    orgUpdated: false,
  };
};
export const updateOrganizationSuccessHandler = (state, action) => {
  let data = [...state.list];
  const index = state.list.findIndex((el) => {
    return el.id === action.payload.data.id;
  });
  data[index] = action.payload.data;
  state.list = data;
  if (
    // (action.payload.data.is_active === false &&
    //   state.selectedOrganization &&
    //   state.selectedOrganization.id &&
    //   action.payload.data &&
    //   action.payload.data.id &&
    //   state.selectedOrganization.id === action.payload.data.id) ||
    !state.selectedOrganization
  ) {
    let newSelected = state.list.find((org) => org.is_active === true);
    state.selectedOrganization = newSelected;
  }
  if (
    state.selectedOrganization &&
    state.selectedOrganization.id === action.payload.data.id
  ) {
    localStorage.setItem("selected_organization", action.payload.data.name);
    localStorage.setItem("selected_organization_id", action.payload.data.id);
    localStorage.setItem(
      "selected_organization_role",
      action.payload.data.role
    );
    state.selectedOrganization = {
      color: state.selectedOrganization.color,
      ...action.payload.data,
    };
  }
  createNotification("success", "Successfully updated!", 3000);
  return {
    ...state,
    orgUpdateLoading: false,
    orgUpdated: true,
  };
};
export const updateOrganizationFailureHandler = (state, action) => {
  createNotification("error", "Something went wrong!", 3000);
  return {
    ...state,
    orgUpdateLoading: false,
    orgUpdated: false,
  };
};

export const handleDeleteOrganization = (state, action) => {
  return {
    ...state,
    orgUpdateLoading: true,
  };
};
export const handleDeleteOrganizationSuccess = (state, action) => {
  const filteredState = state.list.filter(
    (item) => item.id !== action.payload.data
  );
  createNotification(
    "success",
    "Organization is being deleted. You will be notified via email.",
    3000
  );
  return {
    ...state,
    orgUpdateLoading: false,
    list: [...filteredState],
  };
};
export const handleDeleteOrganizationFailure = (state, action) => {
  if (action.payload.response) {
    if (action.payload.response.data && action.payload.response.data.failure) {
      createNotification("error", action.payload.response.data.failure, 3000);
    } else {
      createNotification("error", "Something went wrong!", 3000);
    }
  } else {
    createNotification("error", "Check internet connection", 3000);
  }
  return {
    ...state,
    orgUpdateLoading: false,
  };
};

export const organizationMembersRequestHandler = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const organizationMembersRequestSuccessHandler = (state, action) => {
  const {
    payload: { data, notify },
  } = action;

  if (data.status === 200) {
    if (notify === true) {
      createNotification("success", "organizationList update success", 3000);
    }
    return {
      ...state,
      isLoading: false,
      members: data.data,
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};

export const organizationMembersRequestFailureHandler = (state, action) => {
  if (action.payload.response === undefined) {
    createNotification("error", "No internet connection found", 3000);
    return {
      ...state,
      isLoading: false,
    };
  }
  const {
    payload: {
      response: { data, status },
    },
  } = action;
  if (status === 400) {
    if (data.phone) {
      createNotification("error", data.phone[0], 3000);
    }
  } else if (status === 403) {
    return {
      ...state,
      isLoading: false,
      members: [],
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};

export const handleClearOrgCreated = (state, action) => {
  return {
    ...state,
    orgCreated: false,
  };
};

export const handleSubscriptionUpdatedRequest = (state, action) => {
  const {
    payload: { data },
  } = action;
  let subscriptionData;
  if (data.data && data.data.id) {
    subscriptionData = {
      id: data.data.id,
      plan: data.data.plan,
      prepurchased_count: data.data.prepurchased_count,
      status: data.data.status,
      trial_till: data.data.trial_till,
    };

    if (subscriptionData) {
      let selectedOrg = { ...state.selectedOrganization };
      let orgList = [...state.list];

      selectedOrg.subscription = subscriptionData;
      selectedOrg.plan = subscriptionData.plan;

      orgList &&
        orgList.length > 0 &&
        orgList.map((org) => {
          if (org.id === data.data.organization) {
            org.subscription = subscriptionData;
            org.plan = subscriptionData.plan;
          }
        });
      return {
        ...state,
        selectedOrganization: { ...selectedOrg },
        list: [...orgList],
      };
    } else
      return {
        ...state,
      };
  }
};

export const handleLogoutRequest = (state, action) => {
  return {
    ...state,
    ...initialState,
  };
};

// Action Handlers
const ACTION_HANDLERS = {
  [REQUEST_ORGANIZATION_LIST]: organizationListRequestHandler,
  [REQUEST_ORGANIZATION_LIST_SUCCESS]: organizationListRequestSuccessHandler,
  [REQUEST_ORGANIZATION_LIST_FAILURE]: organizationListRequestFailureHandler,

  [SELECT_ORGANIZATION]: selectOrganizationHandler,

  [GET_ORGANIZATION_TYPES]: getOrganizationTypesHandler,
  [GET_ORGANIZATION_TYPES_SUCCESS]: getOrganizationTypesSuccessHandler,
  [GET_ORGANIZATION_TYPES_FAILURE]: getOrganizationTypesFailureHandler,

  [GET_ORGANIZATION_PLANS]: getOrganizationPlansHandler,
  [GET_ORGANIZATION_PLANS_SUCCESS]: getOrganizationPlansSuccessHandler,
  [GET_ORGANIZATION_PLANS_FAILURE]: getOrganizationPlansFailureHandler,

  [CREATE_ORGANIZATION]: createOrganizationHandler,
  [CREATE_ORGANIZATION_SUCCESS]: createOrganizationSuccessHandler,
  [CREATE_ORGANIZATION_FAILURE]: createOrganizationFailureHandler,

  [UPDATE_ORGANIZATION]: updateOrganizationHandler,
  [UPDATE_ORGANIZATION_SUCCESS]: updateOrganizationSuccessHandler,
  [UPDATE_ORGANIZATION_FAILURE]: updateOrganizationFailureHandler,

  [DELETE_ORGANIZATION]: handleDeleteOrganization,
  [DELETE_ORGANIZATION_SUCCESS]: handleDeleteOrganizationSuccess,
  [DELETE_ORGANIZATION_FAILURE]: handleDeleteOrganizationFailure,

  [CLEAR_ORG_CREATED]: handleClearOrgCreated,

  [GET_ORGANIZATION_MEMBERS]: organizationMembersRequestHandler,
  [GET_ORGANIZATION_MEMBERS_SUCCESS]: organizationMembersRequestSuccessHandler,
  [GET_ORGANIZATION_MEMBERS_FAILURE]: organizationMembersRequestFailureHandler,

  [LOGIN_PROFILE_CREATED_RESET]: handleLogoutRequest,
};

// default initial state
const initialState = {
  isLoading: false,
  orgUpdateLoading: false,
  redirect: true,
  failure: false,
  selectedOrganization: null,
  list: [],
  members: [],
  types: [],
  plans: [],
  allPlans: [],
  orgCreated: false,
  orgUpdated: false,
  createdOrgId: null,
  createdOrgIsActive: false,
};

export default function verifyReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
