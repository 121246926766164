import axios from "axios";

const reqData = {
  method: "GET",
  url: "",
  queryParams: {},
  formData: {},
  bodyParams: {},
  pathParams: [],
  data: {},
};

function makeHeaders() {
  const headers = {
    "Content-Type": "application/json",
  };
  return headers;
}

/*eslint-disable */
function makeHeaderWithToken(token) {
  const headers = {
    Authorization: `${"Bearer" + " "}${token}`,
    "Content-Type": "application/json",
  };
  return headers;
}

function makeHeadersWithPayload(payload) {
  const headers = {
    "Content-Type":
      "multipart/form-data; boundary=----WebKitFormBoundaryqDEyzAYT6ds4yfTl",
    requestmodel: JSON.stringify(payload),
  };
  return headers;
}

export function defaultApi(url, method, details) {
  const token = localStorage.getItem("access_token");

  // axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
  // axios.defaults.xsrfCookieName = "csrftoken";
  axios.defaults.withCredentials = false;

  const URL = `${process.env.REACT_APP_API_URL}/api/`.toString();

  const api = axios.create({
    baseURL: URL,
    headers: token ? makeHeaderWithToken(token) : makeHeaders(),
  });

  let requestDetails = { ...reqData };
  requestDetails.url = url;
  requestDetails.method = method;
  requestDetails.data = details;
  return api(requestDetails).then((response) => response);
}

export function headerPayloadApi(url, method, details) {
  const token = localStorage.getItem("access_token");

  // axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
  // axios.defaults.xsrfCookieName = "csrftoken";
  axios.defaults.withCredentials = false;

  const URL = `${process.env.REACT_APP_API_URL}/api/`.toString();

  const api = axios.create({
    baseURL: URL,
    headers: makeHeadersWithPayload(details),
  });

  let requestDetails = { ...reqData };
  requestDetails.url = url;
  requestDetails.method = method;
  requestDetails.data = details.FormDataImages;

  return api(requestDetails).then((response) => response);
}
