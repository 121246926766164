import React from "react";
import FirstRoundLetter from "../ColorLetterRound/FirstRoundLetter";
import {
  AssigneesManageImgTextGrid,
  AssigneesManageRoundImage,
} from "../../styledComponents/createProject";
import { RoundMiniImage, CommonText } from "../../styledComponents/common";

const RoundImageNameComp = (props) => (
  <AssigneesManageImgTextGrid>
    <AssigneesManageRoundImage imageSize={props.imageSize}>
      {props.imgSource !== null ? (
        <RoundMiniImage
          src={props.imgSource}
          alt={props.name}
          imageSize={props.imageSize}
        />
      ) : (
        <FirstRoundLetter
          text={props.name}
          backColor={props.index}
          size={props.imageSize}
          fontSize={props.size || `16px`}
        />
      )}
    </AssigneesManageRoundImage>
    <CommonText
      // name={props.fill}
      fontSize={props.fontSize}
      fontWeight={props.fontWeight}
    >
      {props.name}
    </CommonText>
  </AssigneesManageImgTextGrid>
);

export default RoundImageNameComp;
