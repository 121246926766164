import {
  GET_REGISTRATION_DROPDOWN,
  SEARCH_CATEGORY,
  SEARCH_BILL_RECEIPT,
  DELETE_BILL_RECEIPT_BY_ID,
} from "../../utils/constants";

// ------------------------------------
// Actions
// ------------------------------------

export function getRegistrationDropdown(value) {
  return {
    type: GET_REGISTRATION_DROPDOWN,
    payload: value,
  };
}

export function searchCategory(value) {
  return {
    type: SEARCH_CATEGORY,
    payload: value,
  };
}

export function searchBillReceipt(value) {
  return {
    type: SEARCH_BILL_RECEIPT,
    payload: value,
  };
}

export function deleteBillReceiptById(value) {
  return {
    type: DELETE_BILL_RECEIPT_BY_ID,
    payload: value,
  };
}
