import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  padding: 20px 8% 30px;
  background-color: #F6F9FB;
  min-height: calc(100vh - 80px);
  align-content:  start;
`
export const FilterContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 30px;
  margin: 30px 0;
  justify-content: start;
`
export const TemplateContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(290px, 300px));
  justify-content: center;
  gap: 20px;
`
export const TemplateImage = styled.img`
  width:100%;
  transition: transform 0.15s linear;
`
export const TemplateCard = styled.div`
  display: grid;
  gap: 30px;
  padding: 30px 20px 20px;
  background-color: #FFFFFF;
  text-align: center;
  cursor: pointer;
  box-shadow: 0px 20px 40px #94a6ce1a;
  align-content: start;
  border: 1px solid #FFF;
  transition: transform 0.15s linear;
  &:hover {
    transform: translateY(-5px);
  }
  &:hover ${TemplateImage} {
    transform: scale(1.02);
  }
`