import React from "react";

import { TabLeftGrid } from "../../styledComponents/members";
import {
  PageTitle,
  Container,
  HeaderContainer,
  InputWithLabelSection,
  FilterLabel,
  InputField,
} from "../../styledComponents/common";
import {
  TabButtonContainer,
  TabButton,
  ColoredButtonWithIcon,
  ButtonIcon,
} from "../../styledComponents/buttons";

// import EditOrganizationModal from "./EditOrganizationModal";
import OrganizationsTable from "./OrganizationsTable";

import ComponentCircleLoader from "../../components/Loaders/ComponentCircleLoader";
import NoDataComponent from "../../components/NoDataComponent/NoDataComponent";

import Plus from "../../assets/img/icons/plus_white.svg";

class Organizations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      selectedOrganization: null,
      organizationModalVisible: false,
      search: "",
      activeList: [],
      inactiveList: [],
    };
    this.archive = this.archive.bind(this);
    this.unarchive = this.unarchive.bind(this);
    this.edit = this.edit.bind(this);
    this.createOrganization = this.createOrganization.bind(this);
    this.toggle = this.toggle.bind(this);
    this.filter = this.filter.bind(this);
    this.onTextChange = this.onTextChange.bind(this);
  }

  componentDidMount() {
    // this.props.getOrganizationTypes();
    // this.props.getOrganizationPlans();
    this.props.getOrganizationList();
    // this.props.getTrialInfo();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.orgUpdateLoading && !this.props.orgUpdateLoading) {
      this.setState({
        organizationModalVisible: false,
        selectedOrganization: null,
        search: "",
      });
    }
    if (prevProps.organizations !== this.props.organizations) {
      this.filter(this.props.organizations.list);
    }
    if (
      this.props.orgCreated &&
      this.props.orgCreated !== prevProps.orgCreated
    ) {
      if (
        this.props.createdOrgId &&
        this.props.createdOrgId !== prevProps.createdOrgId &&
        this.props.createdOrgId !== null &&
        this.props.createdOrgIsActive === false
      ) {
        if (this.props.location.pathname === "/user/organizations") {
          this.props.history.push(
            `/user/subscription?organization_id=${this.props.createdOrgId}`
          );
        }
      }
    }
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  toggleModal = () => {
    const { organizationModalVisible } = this.state;
    this.setState({
      organizationModalVisible: !organizationModalVisible,
      selectedOrganization: null,
    });
  };

  archive(org) {
    this.props.updateOrganization({ id: org.id, is_active: false });
  }

  unarchive(organization) {
    this.props.selectOrganization(organization);
    // this.props.history.push(
    //   `/user/subscription?organization_id=${organization.id}`
    // );
    this.props.history.push({
      pathname: "/user/subscription",
      orgId: organization.id,
      unarchive: true,
    });
  }

  edit(id) {
    const selectedOrganization = this.props.organizations.list.find((org) => {
      return org.id === id;
    });
    this.setState({ selectedOrganization, organizationModalVisible: true });
  }

  createOrganization(data) {
    this.props.createOrganization(data);
  }

  onTextChange(e) {
    const search = e.target.value;
    if (search.length - this.state.search.length > 1) {
      return;
    }
    this.setState({ search });
    this.filter(this.props.organizations.list, search);
  }

  filter(data, search) {
    let activeList = [];
    let inactiveList = [];
    data.forEach((e) => {
      if (search && e.name.toLowerCase().indexOf(search.toLowerCase()) === -1) {
        return;
      }
      if (e.is_active) {
        activeList.push(e);
      } else if (!e.is_active) {
        inactiveList.push(e);
      }
    });
    this.setState({ activeList, inactiveList });
  }

  render() {
    const {
      organizationModalVisible,
      selectedOrganization,
      activeList,
      inactiveList,
      search,
      activeTab,
    } = this.state;
    const { organizations, isLoading, orgUpdateLoading } = this.props;
    const types = organizations && organizations.types;
    const plans = organizations && organizations.plans;
    return (
      <div className="content">
        {/* <EditOrganizationModal
          isOpen={organizationModalVisible}
          toggle={this.toggleModal}
          mode={selectedOrganization ? "update" : "create"}
          updateOrganization={this.props.updateOrganization}
          types={types}
          plans={plans}
          organization={selectedOrganization}
          isLoading={orgUpdateLoading}
        /> */}
        <PageTitle>Organizations</PageTitle>
        <HeaderContainer>
          <TabLeftGrid>
            <InputWithLabelSection>
              <FilterLabel>Type</FilterLabel>
              <TabButtonContainer>
                <TabButton
                  onClick={() => this.toggle("1")}
                  selected={activeTab}
                  index={"1"}
                >
                  Active
                </TabButton>
                <TabButton
                  onClick={() => this.toggle("2")}
                  selected={activeTab}
                  index={"2"}
                >
                  Archived
                </TabButton>
              </TabButtonContainer>
            </InputWithLabelSection>

            <InputWithLabelSection>
              <FilterLabel>Search Organization</FilterLabel>
              <InputField
                type="text"
                placeholder="Search organization by name"
                value={search}
                onChange={(e) => this.onTextChange(e)}
              />
            </InputWithLabelSection>
          </TabLeftGrid>

          <ColoredButtonWithIcon
            onClick={() => this.props.history.push("/user/add-organization")}
          >
            <ButtonIcon src={Plus} alt="" />
            Add Organization
          </ColoredButtonWithIcon>
        </HeaderContainer>
        <Container padding="0 0 10px 0" marginTop="0">
          {isLoading ? (
            <ComponentCircleLoader padding="8vh 0" />
          ) : activeTab === "1" ? (
            activeList.length ? (
              <OrganizationsTable
                edit={this.edit}
                toggleArchive={this.archive}
                organizationsList={activeList}
                deleteOrganization={this.props.deleteOrganization}
              />
            ) : (
              <NoDataComponent
                padding="4vh 0"
                title={
                  search.trim()
                    ? "No organization found with this name!"
                    : "You don't have active organizations"
                }
              />
            )
          ) : activeTab === "2" ? (
            inactiveList.length ? (
              <OrganizationsTable
                edit={this.edit}
                toggleArchive={this.unarchive}
                organizationsList={inactiveList}
                deleteOrganization={this.props.deleteOrganization}
              />
            ) : (
              <NoDataComponent
                padding="4vh 0"
                title={
                  search.trim()
                    ? "No organization found with this name!"
                    : "You don't have archived organizations"
                }
              />
            )
          ) : (
            <NoDataComponent
              padding="4vh 0"
              title={
                search.trim()
                  ? "No organization found with this name!"
                  : `You don't have any ${
                      activeTab === "1"
                        ? " active"
                        : activeTab === "2"
                        ? " archived"
                        : ""
                    } organizations`
              }
            />
          )}
        </Container>
      </div>
    );
  }
}

export default Organizations;
