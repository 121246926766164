import React from "react";
import {
  FeatureImageContainer,
  FeatureImage,
  FeatureImageTitle,
  FeatureImageSubtitle,
} from "../../styledComponents/common";

const FeatureImageWithText = ({ image, title, subtitle, padding }) => {
  return (
    <FeatureImageContainer padding={padding}>
      <FeatureImage src={image} alt="" />
      <FeatureImageTitle>{title}</FeatureImageTitle>
      <FeatureImageSubtitle>
        {subtitle ||
          `This feature is already available in the main app. Currently it's not
        available in the demo.`}
      </FeatureImageSubtitle>
    </FeatureImageContainer>
  );
};

export default FeatureImageWithText;
