// Redux integrated routes
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import ForgotPassword from "./pages/ForgotPassword";
import VerificationCode from "./pages/VerificationCode";
import ResetPassword from "./pages/ResetPassword";

import Dashboard from "./pages/Dashboard";
import GenerateReceipt from "./pages/GenerateReceipt";
import MyReceipts from "./pages/MyReceipts";
import CurrentPlan from "./pages/Subscription/CurrentPlan";
// import UserProfile from "./custom_modules/UserProfile";

// // import OnboardingSteps from "./custom_modules/Onboarding/OnboardingSteps";
// // import OnboardingLoader from "./custom_modules/Onboarding/OnboardingSteps/OnboardingLoader/onboardingLoaderContainer";

// import ActivityLocations from "./pages/Activity/Location";

// import ActivityScreenshots from "./pages/ActivityNew/Screenshots";
// import ActivityApps from "./pages/ActivityNew/Apps";
// import ActivityURLs from "./pages/ActivityNew/URLs";

// import CurrentlyTracking from "./pages/LiveFeed/CurrentlyTracking";
// import InstantScreenshots from "./pages/LiveFeed/InstantScreenshots";

// import TimesheetDaily from "./pages/Timesheet/Daily";
// import TimesheetWeekly from "./pages/Timesheet/Weekly";
// import TimesheetBiweekly from "./pages/Timesheet/Biweekly";
// import TimesheetMonthly from "./pages/Timesheet/Monthly";
// import TimesheetCustomRange from "./pages/Timesheet/CustomRange";

// import Projects from "./pages/Projects";
// import ProjectDetails from "./pages/Projects/ProjectDetails/projectDetailsContainer";
// import CreateProject from "./custom_modules/Projects/CreateProject/createProjectContainer";
// import EditProject from "./custom_modules/Projects/EditProject/editProjectContainer";

// import Tasks from "./pages/Tasks";
// import ClockInOut from "./pages/ClockInOut";

import Organizations from "./pages/Organization";
// import AddOrganization from "./custom_modules/AddOrganization";

// import Clients from "./pages/Clients";
// import ClientDetails from "./pages/Clients/ClientDetails/clientDetailsContainer";
// import AddClient from "./pages/Clients/AddClient/addClientContainer";
// import EditClient from "./pages/Clients/EditClient/editClientContainer";

// import Payroll from "./pages/Payroll";

import Members from "./pages/Members";
import MemberDetails from "./pages/Members/MemberDetails/memberDetailsContainer";
// import MemberInvitation from "./custom_modules/Members/MemberInvitation/memberInvitationContainer";

// import TimeActivityReport from "./pages/ReportsNew/TimeActivity";
// import ManualTimeReport from "./pages/ReportsNew/ManualTime";
// import AppsUrlUsageReport from "./pages/ReportsNew/AppsUrlUsage";

// import Teams from "./pages/Teams";
// import AddTeam from "./pages/Teams/AddTeam/addTeamContainer";
// import EditTeam from "./pages/Teams/EditTeam/editTeamContainer";
// import TeamDetails from "./pages/Teams/TeamDetails/teamDetailsContainer";

import Invoice from "./pages/Invoice";
import CreateInvoice from "./pages/Invoice/CreateInvoice/createInvoiceContainer";
// import EditInvoice from "./custom_modules/Invoice/EditInvoice/editInvoiceContainer";
// import InvoicePreview from "./custom_modules/Invoice/PreviewInvoice/PreviewInvoiceContainer";
// import InvoiceClientView from "./custom_modules/Invoice/InvoiceClientView/InvoiceClientViewContainer";

// import IntegrationLayout from "./layouts/Integrations/Integrations";
// import Integrations from "./pages/IntegrationsNew/integrationsContainer";

import Settings from "./pages/Settings";

const routes = [
  {
    path: "login",
    name: "Login",
    component: Login,
    layout: "/auth",
  },
  {
    path: "register",
    name: "register",
    component: SignUp,
    layout: "/auth",
  },
  {
    path: "forgot-password",
    name: "Forgot Password",
    component: ForgotPassword,
    layout: "/auth",
  },
  // {
  //   path: "verify-code",
  //   name: "Verification Code",
  //   component: VerificationCode,
  //   layout: "/auth",
  // },
  // {
  //   path: "reset_password/:confirmation_key",
  //   name: "ResetPassword",
  //   component: ResetPassword,
  //   layout: "/auth",
  // },
  // {
  //   path: "/add-or-join",
  //   name: "Add or Join Organization",
  //   mini: "AJ",
  //   // component: AddOrJoinOrg,
  //   layout: "/setup",
  //   redirect: true,
  // },
  // {
  //   path: "/request-to-join",
  //   name: "Request to Join Organization",
  //   mini: "RJ",
  //   // component: RequestJoinOrg,
  //   layout: "/setup",
  //   redirect: true,
  // },
  // {
  //   path: "/onboarding",
  //   name: "Onboarding",
  //   mini: "OB",
  //   // component: OnboardingSteps,
  //   layout: "/setup",
  //   redirect: true,
  // },
  // {
  //   path: "/onboarding-loader",
  //   name: "Onboarding Loader",
  //   mini: "OL",
  //   // component: OnboardingLoader,
  //   layout: "/setup",
  //   redirect: true,
  // },
  // {
  //   path: "demo",
  //   name: "Demo Setup",
  //   mini: "DS",
  //   component: DemoSetup,
  //   layout: "/setup",
  //   redirect: true,
  // },
  {
    path: "dashboard",
    group: "Analyze",
    name: "Dashboard",
    icon: "dashboardIcon",
    component: Dashboard,
    layout: "/user",
  },
  {
    path: "/user-profile",
    name: "Profile",
    mini: "P",
    // component: UserProfile,
    layout: "/user",
    redirect: true,
  },
  {
    path: "generate-receipt",
    name: "Generate Receipt",
    component: GenerateReceipt,
    layout: "/user",
  },
  {
    path: "my-receipts",
    name: "My Receipts",
    component: MyReceipts,
    layout: "/user",
  },
  {
    path: "current-plan",
    name: "Current Plan",
    component: CurrentPlan,
    layout: "/user",
  },
  // {
  //   path: "live-feed",
  //   group: "Analyze",
  //   name: "Live Feed",
  //   icon: "dashboardIcon",
  //   component: CurrentlyTracking,
  //   layout: "/user",
  //   new: true,
  //   beta: true,
  //   live: true,
  // },
  // {
  //   path: "instant-screenshots",
  //   name: "Instant Screenshots",
  //   mini: "IS",
  //   component: InstantScreenshots,
  //   layout: "/user",
  //   redirect: true,
  // },
  // {
  //   collapse: true,
  //   group: "Analyze",
  //   name: "RemoteTrack",
  //   icon: "remoteTrackIcon",
  //   state: "activityCollapse",
  //   monitored: true,
  //   views: [
  //     {
  //       path: "activity/screenshots",
  //       name: "Screenshots",
  //       mini: "S",
  //       component: ActivityScreenshots,
  //       layout: "/user",
  //     },
  //     {
  //       path: "activity/apps",
  //       name: "Apps",
  //       mini: "A",
  //       component: ActivityApps,
  //       layout: "/user",
  //     },
  //     {
  //       path: "activity/urls",
  //       name: "URLs",
  //       mini: "U",
  //       component: ActivityURLs,
  //       layout: "/user",
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   group: "Analyze",
  //   name: "Timesheets",
  //   icon: "timesheetIcon",
  //   state: "timesheetCollapse",
  //   views: [
  //     {
  //       path: "timesheet/daily",
  //       name: "Daily",
  //       mini: "D",
  //       component: TimesheetDaily,
  //       layout: "/user",
  //     },
  //     {
  //       path: "timesheet/weekly",
  //       name: "Weekly",
  //       mini: "W",
  //       component: TimesheetWeekly,
  //       layout: "/user",
  //     },
  //     {
  //       path: "timesheet/bi-weekly",
  //       name: "Bi-Weekly",
  //       mini: "B",
  //       component: TimesheetBiweekly,
  //       layout: "/user",
  //     },
  //     {
  //       path: "timesheet/monthly",
  //       name: "Monthly",
  //       mini: "M",
  //       component: TimesheetMonthly,
  //       layout: "/user",
  //     },
  //     {
  //       path: "timesheet/custom-range",
  //       name: "Custom",
  //       mini: "C",
  //       component: TimesheetCustomRange,
  //       layout: "/user",
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   group: "Analyze",
  //   name: "Reports",
  //   icon: "reportsIcon",
  //   state: "reportCollapse",
  //   views: [
  //     {
  //       path: "reports/time-and-activity",
  //       name: "Time and Activity",
  //       mini: "T",
  //       component: TimeActivityReport,
  //       layout: "/user",
  //     },
  //     {
  //       path: "reports/manual-time",
  //       name: "Manual Time",
  //       mini: "M",
  //       component: ManualTimeReport,
  //       layout: "/user",
  //     },
  //     {
  //       path: "reports/apps-url-usage",
  //       name: "Apps & URLs Usage",
  //       mini: "A",
  //       component: AppsUrlUsageReport,
  //       layout: "/user",
  //       monitored: true,
  //       // canView: "Apploye RemoteTrack",
  //       // canViewArchived: "monitored_monthly",
  //     },
  // {
  //   path: "/reports/app-usage",
  //   name: "App Usage",
  //   mini: "AU",
  //   // component: AppUsageReport,
  //   layout: "/user",
  //   canView: "Apploye RemoteTrack",
  //   canViewArchived: "monitored_monthly",
  // },
  // {
  //   path: "/reports/due-amount",
  //   name: "Due Amount",
  //   mini: "DA",
  //   // component: DueAmountReport,
  //   layout: "/user",
  // },
  // {
  //   path: "/reports/paid-amount",
  //   name: "Paid Amount",
  //   mini: "PA",
  //   // component: PaidAmountReport,
  //   layout: "/user",
  // },
  // {
  //   path: "/reports/one-time-pay",
  //   name: "One Time Payment",
  //   mini: "OP",
  //   // component: OneTimeReport,
  //   layout: "/user",
  // },
  //   ],
  // },
  // {
  //   path: "tasks",
  //   group: "Manage",
  //   name: "Tasks",
  //   icon: "tasksIcon",
  //   component: Tasks,
  //   layout: "/user",
  // },
  // {
  //   path: "clock-in-out",
  //   group: "Manage",
  //   name: "Clock In/Out",
  //   icon: "clockInOutIcon",
  //   component: ClockInOut,
  //   layout: "/user",
  //   new: true,
  // },
  // {
  //   collapse: true,
  //   group: "Manage",
  //   name: "GPSTrack",
  //   icon: "gpsTrackIcon",
  //   state: "locationCollapse",
  //   canView: "Apploye RemoteTrack",
  //   canViewArchived: "monitored_monthly",
  //   canViewSecondary: "Apploye FieldTrack",
  //   views: [
  //     {
  //       path: "activity/locations",
  //       name: "Locations",
  //       mini: "L",
  //       component: ActivityLocations,
  //       layout: "/user",
  //     },
  //   ],
  // },
  // {
  //   path: "projects",
  //   group: "Manage",
  //   name: "Projects",
  //   icon: "projectsIcon",
  //   component: Projects,
  //   layout: "/user",
  // },
  // {
  //   path: "project-details",
  //   name: "ProjectDetails",
  //   component: ProjectDetails,
  //   layout: "/user",
  //   redirect: true,
  // },
  // {
  //   path: "/create-project",
  //   name: "CreateProject",
  //   // component: CreateProject,
  //   layout: "/user",
  //   redirect: true,
  // },
  // {
  //   path: "/edit-project",
  //   name: "EditProject",
  //   // component: EditProject,
  //   layout: "/user",
  //   redirect: true,
  // },
  {
    path: "invoice",
    group: "Manage",
    name: "Invoice",
    icon: "invoiceIcon",
    component: Invoice,
    layout: "/user",
  },
  {
    path: "create-invoice",
    name: "Create Invoice",
    mini: "CI",
    component: CreateInvoice,
    layout: "/user",
    redirect: true,
  },
  // {
  //   path: "/edit-invoice",
  //   name: "Edit Invoice",
  //   mini: "EI",
  //   // component: EditInvoice,
  //   layout: "/user",
  //   redirect: true,
  // },
  {
    path: "/invoice-preview",
    name: "Invoice Preview",
    mini: "IP",
    // component: InvoicePreview,
    layout: "/user",
    redirect: true,
  },
  {
    path: "/invoice-client-view",
    name: "Invoice Client View",
    mini: "ICV",
    // component: InvoiceClientView,
    layout: "/user",
    redirect: true,
  },
  // {
  //   path: "clients",
  //   group: "Manage",
  //   name: "Clients",
  //   icon: "clientsIcon",
  //   component: Clients,
  //   layout: "/user",
  // },
  // {
  //   path: "client-details",
  //   name: "ClientDetails",
  //   component: ClientDetails,
  //   layout: "/user",
  //   redirect: true,
  // },
  // {
  //   path: "add-client",
  //   name: "AddClient",
  //   component: AddClient,
  //   layout: "/user",
  //   redirect: true,
  // },
  // {
  //   path: "edit-client",
  //   name: "EditClient",
  //   component: EditClient,
  //   layout: "/user",
  //   redirect: true,
  // },
  // {
  //   path: "teams",
  //   group: "Admin",
  //   name: "Teams",
  //   icon: "teamsIcon",
  //   component: Teams,
  //   layout: "/user",
  // },
  // {
  //   path: "add-team",
  //   name: "Add Team",
  //   mini: "AT",
  //   component: AddTeam,
  //   layout: "/user",
  //   redirect: true,
  // },
  // {
  //   path: "edit-team",
  //   name: "Edit Team",
  //   mini: "ET",
  //   component: EditTeam,
  //   layout: "/user",
  //   redirect: true,
  // },
  // {
  //   path: "team-details",
  //   name: "Team Details",
  //   mini: "TD",
  //   component: TeamDetails,
  //   layout: "/user",
  //   redirect: true,
  // },
  {
    path: "members",
    group: "Admin",
    name: "Members",
    icon: "membersIcon",
    component: Members,
    layout: "/user",
  },
  {
    path: "member-details",
    name: "MemberDetails",
    component: MemberDetails,
    layout: "/user",
    redirect: true,
  },
  // {
  //   path: "/member-invitation",
  //   name: "MemberInvitation",
  //   // component: MemberInvitation,
  //   layout: "/user",
  //   redirect: true,
  // },
  // {
  //   path: "payroll",
  //   group: "Admin",
  //   name: "Payroll",
  //   icon: "payrollIcon",
  //   component: Payroll,
  //   layout: "/user",
  // },
  // {
  //   path: "integrations",
  //   group: "Admin",
  //   new: true,
  //   name: "Integrations",
  //   icon: "integrationsIcon",
  //   // component: IntegrationLayout,
  //   component: Integrations,
  //   layout: "/user",
  // },
  {
    path: "organizations",
    group: "Admin",
    name: "Organizations",
    icon: "organizationsIcon",
    component: Organizations,
    layout: "/user",
  },
  {
    path: "/add-organization",
    name: "Add Organization",
    mini: "aw",
    // component: AddOrganization,
    layout: "/user",
    redirect: true,
  },
  {
    path: "settings",
    group: "Admin",
    name: "Settings",
    icon: "settingsIcon",
    component: Settings,
    layout: "/user",
  },
];

export default routes;
