import styled, { css } from "styled-components";

export const HovarTab = styled.div`
  display: none;
  gap: 10px;
  border: 1px solid #bccadd;
  border-radius: 6px;
  background-color: #fff;
  padding: 4px 20px;
  margin: auto 0;
`;
export const MyReceiptsTableRow = styled.div`
  display: grid;
  grid-template-columns:
    20px minmax(140px, 170px) minmax(170px, 200px) minmax(110px, 130px)
    minmax(120px, 170px) minmax(110px, 120px) minmax(110px, 120px) 90px;
  column-gap: 20px;
  justify-content: start;
  padding: 0 45px;
  padding-bottom: ${({ heading }) => heading && `20px`};
  border-bottom: ${({ heading, theme }) =>
    !heading && `1px solid ${theme.colors.border.secondaryLight}`};
  &:hover {
    background-color: ${({ heading }) => !heading && `#F6F9FB`};
  }
  &:hover ${HovarTab} {
    display: flex;
  }
`;

export const FilterContainer = styled.div`
  grid-column: 1 / span 2;
  display: grid;
  grid-template-columns: auto auto 230px 230px auto;
  column-gap: 20px;
  justify-content: start;
  align-items: end;
  @media (max-width: 1250px) {
    grid-template-columns: auto auto 230px 230px auto;
  }
`;
export const IconImg = styled.img`
  cursor: pointer;
  &:hover {
    filter: brightness(0.4) contrast(2);
  }
`
