import React, { Fragment, useState, useEffect } from "react";
import Select from "../../components/ReactSelectDropdown";
import FilterDropDownStyle from "../../components/DropdownStyle/FilterDropDownStyle";
import NoDataComponent from "../../components/NoDataComponent/NoDataComponent";
import RoundImageNameComp from "../../components/RoundImageName/RoundImageNameComp";
// import RecordPaymentModal from "./RecordPaymentModal";
// import DeleteInvoicePopup from "./DeleteInvoicePopup";
import moment from "moment";
import { Container, TableText } from "../../styledComponents/common";
import {
  InvoiceTableContainer,
  TableItem,
  StatusBox,
} from "../../styledComponents/invoice";
import ComponentCircleLoader from "../../components/Loaders/ComponentCircleLoader";

const InvoiceTable = (props) => {
  const [selectedActions, setSelectedActions] = useState("");
  const drafOptions = [
    { value: "editInvoice", label: "Edit Invoice" },
    { value: "sendMail", label: "Send Mail" },
    { value: "preview", label: "Preview Invoice" },
    { value: "deleteInvoice", label: "Delete Invoice" },
  ];
  const sentOptions = [
    { value: "recordPayment", label: "Record Payment" },
    { value: "sendMail", label: "Send Mail" },
    { value: "preview", label: "Preview Invoice" },
    { value: "deleteInvoice", label: "Delete Invoice" },
  ];
  const paidOptions = [
    { value: "preview", label: "Preview Invoice" },
    { value: "deleteInvoice", label: "Delete Invoice" },
  ];
  const [popupOpen, setPopupOpen] = useState(false);
  const [sendEmailOpen, setSendEmailOpen] = useState(false);
  const [deletePopupOpen, setDeletePopupOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const popupToggle = () => {
    setPopupOpen(!popupOpen);
  };

  const sendEmailToggle = () => {
    setSendEmailOpen(!sendEmailOpen);
  };

  const deletePopupToggle = () => {
    setDeletePopupOpen(!deletePopupOpen);
  };

  const onActionSelect = (e, invoiceId, clientId, index) => {
    if (e.value === "editInvoice") {
      props.navigate(`/user/edit-invoice?id=${invoiceId}`);
    } else if (e.value === "recordPayment") {
      popupToggle();
    } else if (e.value === "sendMail") {
      const payload = {
        organization_id: props.selectedOrganization.id,
        client_id: clientId,
      };
      // props.getClientDetails(payload);
      sendEmailToggle();
    } else if (e.value === "preview") {
      props.navigate(`/user/invoice-preview?id=${invoiceId}`);
    } else if (e.value === "deleteInvoice") {
      deletePopupToggle();
    }
    setSelectedIndex(index);
  };

  const gotoPreview = (invoiceId) => {
    props.navigate(`/user/invoice-preview?id=${invoiceId}`);
  };

  return (
    <Container style={{ paddingBottom: `20px` }}>
      {props.isLoading ? (
        <ComponentCircleLoader />
      ) : (
        <>
          {props.invoiceList.length > 0 &&
            selectedIndex >= 0 &&
            props.invoiceList[selectedIndex] &&
            props.invoiceList[selectedIndex].id &&
            props.selectedOrganization &&
            props.selectedOrganization.id && (
              <Fragment>
                {/* <RecordPaymentModal
                  isOpen={popupOpen}
                  isLoading={props.recordPaymentLoading}
                  toggle={popupToggle}
                  invoice={props.invoiceList[selectedIndex]}
                  selectedOrganization={props.selectedOrganization}
                  recordPaymentInvoice={props.recordPaymentInvoice}
                  localCallback
                  onRecordPay={props.onRecordPay}
                /> */}
                {/* <DeleteInvoicePopup
                  isOpen={deletePopupOpen}
                  toggle={deletePopupToggle}
                  invoice_id={props.invoiceList[selectedIndex].id}
                  organization_id={props.selectedOrganization.id}
                  deleteInvoice={props.deleteInvoice}
                  onDelete={props.onDelete}
                  isLoading={props.deleteInvoiceLoading}
                /> */}
              </Fragment>
            )}
          {/* {props.invoiceList.length > 0 &&
            selectedIndex >= 0 &&
            props.invoiceList[selectedIndex] &&
            props.invoiceList[selectedIndex].id &&
            props.selectedOrganization &&
            props.selectedOrganization.id &&
            props.clientDetails &&
            props.clientDetails.email && (
              <SendMailPopup
                isOpen={sendEmailOpen}
                isLoading={props.sendMailLoading}
                toggle={sendEmailToggle}
                organization_id={props.selectedOrganization.id}
                invoice_id={props.invoiceList[selectedIndex].id}
                senderName={props.senderName}
                invoiceNumber={props.invoiceList[selectedIndex].invoice_number}
                issuedDate={props.invoiceList[selectedIndex].issued_date}
                dueDate={
                  props.invoiceList[selectedIndex].due_date
                    ? props.invoiceList[selectedIndex].due_date
                    : null
                }
                email={props.clientDetails.email}
                log={props.updateInvoiceSendLog}
                createInvoiceUpdateSendLog={props.createInvoiceUpdateSendLog}
                sendInvoiceEmail={props.sendInvoiceEmail}
                onSend={props.onSend}
              />
            )} */}
          {props.invoiceList.length > 0 && (
            <InvoiceTableContainer heading>
              <TableItem>
                <TableText>Client Name</TableText>
              </TableItem>
              <TableItem>
                <TableText>Invoice Number</TableText>
              </TableItem>
              <TableItem>
                <TableText>Issued Date</TableText>
              </TableItem>
              <TableItem>
                <TableText>Paid Amount / Total Amount</TableText>
              </TableItem>
              <TableItem>
                <TableText>Status</TableText>
              </TableItem>
              <TableItem>
                <TableText>Actions</TableText>
              </TableItem>
            </InvoiceTableContainer>
          )}

          {props.invoiceList.length > 0 &&
            props.invoiceList.map((invoice, index) => (
              <Fragment key={index}>
                <InvoiceTableContainer key={index}>
                  <TableItem
                    buttonPadding
                    hoverPointer
                    onClick={() =>
                      props.navigate(
                        `/user/client-details?id=${invoice.client_id}`
                      )
                    }
                  >
                    <RoundImageNameComp
                      imgSource={null}
                      name={invoice.client_name}
                      index={index}
                      imageSize="36px"
                      size="14px"
                      fill
                    />
                  </TableItem>
                  <TableItem>
                    <TableText
                      name
                      hoverUnderline
                      hoverPointer
                      onClick={() => gotoPreview(invoice.id)}
                    >
                      {invoice.invoice_number}
                    </TableText>
                  </TableItem>
                  <TableItem>
                    <TableText name>
                      {moment(invoice.issued_date).format("DD-MM-YYYY")}
                    </TableText>
                  </TableItem>
                  <TableItem>
                    <TableText name>
                      <span style={{ whiteSpace: `nowrap` }}>{`${
                        invoice.currency
                      } ${invoice.paid_amount.toFixed(2)}`}</span>{" "}
                      /{" "}
                      <span style={{ whiteSpace: `nowrap` }}>{`${
                        invoice.currency
                      } ${invoice.discounted_amount.toFixed(2)}`}</span>
                    </TableText>
                  </TableItem>
                  <TableItem>
                    <StatusBox status={invoice.status}>
                      {invoice.status === "partial"
                        ? "Partially Paid"
                        : invoice.status}
                    </StatusBox>
                  </TableItem>
                  <TableItem buttonPadding>
                    <Select
                      isSearchable={false}
                      value={selectedActions}
                      options={
                        invoice.status === "draft"
                          ? drafOptions
                          : invoice.status === "paid"
                          ? paidOptions
                          : sentOptions
                      }
                      placeholder="Actions"
                      onChange={(e) => {
                        onActionSelect(e, invoice.id, invoice.client_id, index);
                      }}
                      styles={FilterDropDownStyle({
                        height: "39px",
                        width: `150px`,
                        menuWidth: `150px`,
                      })}
                    />
                  </TableItem>
                </InvoiceTableContainer>
              </Fragment>
            ))}
          {props.invoiceList &&
            props.invoiceList.length === 0 &&
            props.noData && (
              <NoDataComponent
                title={
                  props.searchTerm
                    ? "Sorry! no invoice found with the given client name"
                    : props.selectedStatus && props.selectedStatus.value
                    ? `No invoice found with status "${props.selectedStatus.label}"`
                    : "You haven’t created any invoice yet."
                }
              />
            )}
        </>
      )}
    </Container>
  );
};

export default InvoiceTable;
