import { connect } from "react-redux";

// Component
import SignUp from "./SignUp";
// Actions
import { signUp, addRegistration, resetSignUp } from "./signUpActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  isLoading: state.signUp.isLoading,
  redirect: state.signUp.redirect,
  getCredential: state.signUp.getCredential,
  isRegisteredLoading: state.signUp.isRegisteredLoading,
  paddleRegInfo: state.signUp.paddleRegInfo,
  registeredInfo: state.signUp.registeredInfo,
  auth: state.login.auth,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  signUp: (details) => dispatch(signUp({ ...details })),
  addRegistration: (details) => dispatch(addRegistration({ ...details })),
  resetSignUp: (details) => dispatch(resetSignUp({ ...details })),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
