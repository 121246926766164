/* eslint-disable import/no-anonymous-default-export */
import { combineReducers } from "redux";

import loginReducer from "./pages/Login/loginReducer";

import signUpReducer from "./pages/SignUp/signUpReducer";

import forgotPasswordReducer from "./pages/ForgotPassword/forgotPasswordReducer";

import adminReducer from "./layouts/Admin/adminReducer";

import profileReducer from "./pages/UserProfile/userProfileReducer";

import organizationReducer from "./pages/Organization/OrganizationReducer";

import invoiceReducer from "./pages/Invoice/invoiceReducer";

import settingsReducer from "./pages/Settings/settingsReducer";

import subscriptionReducer from "./pages/Subscription/subscriptionReducer";

import generateReceiptReducer from "./pages/GenerateReceipt/generateReceiptReducer";
import myReceiptsReducer from "./pages/MyReceipts/myReceiptsReducer";

export default () =>
  combineReducers({
    login: loginReducer,
    signUp: signUpReducer,
    forgot: forgotPasswordReducer,
    admin: adminReducer,
    profile: profileReducer,
    organization: organizationReducer,
    invoice: invoiceReducer,
    settings: settingsReducer,
    generateReceipt: generateReceiptReducer,
    myReceipts: myReceiptsReducer,
    subscription: subscriptionReducer,
  });
