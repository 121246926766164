import { connect } from "react-redux";

// Component
import Dashboard from "./Dashboard";

// Actions
import { clearGeneratedState } from "../GenerateReceipt/generateReceiptActions";

// Store props to map with current state
// const mapStateToProps = (state) => ({
//   dashboard: state.dashboard,
//   organization: state.organization.selectedOrganization,
//   auth: state.auth,
// });

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  clearGeneratedState: (details) =>
    dispatch(clearGeneratedState({ ...details })),
});

// connect states and dispatchers with components
export default connect(null, mapDispatchToProps)(Dashboard);
