import styled from "styled-components";

export const NavbarContainer = styled.div`
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #FFFFFF;
    padding: 0 8% 0;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    border-bottom: 1px solid #F6F9FB;
    align-items: center;
    z-index: 10;
`
export const LogoLinkContainer = styled.div`
    display: grid;
    grid-template-columns: 180px auto;
    gap: 30%;
    align-items: center;
`
export const LinkSection = styled.div`
    display: grid;
    grid-template-columns: repeat(3, auto);
    gap: 15%;
`
export const LinkItem = styled.div`
    display: grid;
    height: 70px;
    line-height: 70px;
    color: ${({isSelected}) => isSelected ? `#635BFF` : `#132742`};
    font-size: 14px;
    white-space: nowrap;
    border-bottom: ${({isSelected}) => isSelected ? `3px solid #635BFF` : `3px solid #FFFFFF`};
    cursor: pointer;
`
// export const LinkText = styled.div`


// `
// export const LinkUnderline = styled.div`
//     height: 3px;
//     border-radius: 2px;
//     background-color: ;
// `

export const ProfileTab = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: auto 20px;
    gap: 30px;
    justify-content: space-between;
    align-items: center;
    min-width: 230px;
    border-radius: 25px;
    border: 1px solid #BCCADD;
    padding: 7px;
    cursor: pointer;
`
export const RoundNameSection = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    gap: 13px;
    justify-content: start;
    font-size: 14px;
    align-items: center;
`
export const DropDownHolder = styled.div`
  position: absolute;
  top: 40px;
  left: 0;
  visibility: ${({active}) => active ? `visible` : `hidden`};
`;
export const DropDownSection = styled.div`
  background-color: #FFFFFF;
  border: 1px solid #BCCADD;
  border-radius: 16px;
  opacity: ${({active}) => active ? `1` : `0.25`};
  min-width: 230px;
  display: grid;
  gap: 20px;
  padding: 20px;
  transition: opacity 0.6s ease, transform 0.3s ease;
  transform: ${({active}) => active && `translate(0px, 10px)`};
  @media (max-width: 575px) {
    opacity: 1;
    width: initial;
    margin-top: 15px;
  }
`;
export const RotatingIcon = styled.img`
  transition: transform 0.2s ease;
  transform: ${({active}) => active && `rotate(180deg)`} ;
`;