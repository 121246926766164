import React, { useState, useEffect } from "react";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { createNotification } from "../../../utils/notificationManager";
import {
  PageTitle,
  Container,
  CardTitle,
  CommonText,
  ColoredText,
  CommonGrid,
  Content,
  InputWithLabelSection,
} from "../../../styledComponents/common";
import {
  PrimaryButton,
  WhiteButton,
  ColoredButtonWithIcon,
  ButtonIcon,
} from "../../../styledComponents/buttons";
import {
  PlanSection,
  CapsuleAndPriceContainer,
  PlanCapsule,
  MemeberInfoSection,
  MemeberBlock,
  SaveTag,
} from "../../../styledComponents/billing";
import {
  BillingTextTooltip,
  BillingQues,
  BillingTooltip,
  BillingTooltipText,
} from "../../../styledComponents/createProject";
import {
  NoPlanContainer,
  NoPlanArtwork,
  NoPlanMainText,
  NoPlanSecondaryText,
  SeatedDiscountDiv,
} from "../subscriptionStyles";

import BackButtonComponent from "../../../components/BackButtonComponent/BackButtonComponent";
import ComponentCircleLoader from "../../../components/Loaders/ComponentCircleLoader";

import CancelSubscriptionModal from "./CancelSubscriptionModal";
import PricingPlanModal from "./PricingPlanModal";
import crown from "../../../assets/img/common/crown.svg";
import noPlanArtwork from "../../../assets/img/common/no-plan-artwork.svg";
import cancelledIcon from "../../../assets/img/subscription-status-cancelled.svg";
import planActive from "../../../assets/img/subscription-status-active.svg";

import visa from "../../../assets/img/payment-methods/card-visa.svg";
import masterCard from "../../../assets/img/payment-methods/card-master.svg";
import americanExpress from "../../../assets/img/payment-methods/card-american-express.svg";
import discover from "../../../assets/img/payment-methods/card-visa.svg";
import jcb from "../../../assets/img/payment-methods/card-visa.svg";
import maestro from "../../../assets/img/payment-methods/card-visa.svg";
import dinersclub from "../../../assets/img/payment-methods/card-dinersclub.svg";
import unionpay from "../../../assets/img/payment-methods/card-unionpay.svg";
import paypal from "../../../assets/img/payment-methods/card-paypal.svg";

const planDetails = {
  Solo:
    "Ideal for freelancers & contractors to track time, keep focus & bill clients.",
  Standard: "Ideal for the in-house teams or teams of any size & shape.",
  Premium:
    "Ideal for Mobile teams or a combination of in-house & Mobile teams.",
  Elite:
    "Ideal for Remote Teams or a combination of in-house, mobile & remote teams.",
};

const NoPlanSection = (props) => {
  return (
    <NoPlanContainer>
      <NoPlanArtwork src={noPlanArtwork} alt="" />
      <NoPlanMainText>
        does not have any plan and is archived now
      </NoPlanMainText>
      <NoPlanSecondaryText>
        Please Upgrade your plan to unlock the archived organization.
      </NoPlanSecondaryText>
      <ColoredButtonWithIcon
        margin="30px 0 0 0"
        onClick={() => props.navigate("/user/subscription")}
      >
        <ButtonIcon src={crown} alt="" />
        UPGRADE
      </ColoredButtonWithIcon>
    </NoPlanContainer>
  );
};

const PaymentMethodList = {
  master: masterCard,
  visa: visa,
  american_express: americanExpress,
  discover: discover,
  jcb: jcb,
  maestro: maestro,
  diners_club: dinersclub,
  union_pay: unionpay,
};

const CurrentPlan = (props) => {
  const navigate = useNavigate();
  const [planInterval, setPlanInterval] = useState("");
  const [cancelled, setCancelled] = useState(false);
  const [isUpdatePlanClicked, setIsUpdatePlanClicked] = useState(false);
  const [changeClicked, setChangeClicked] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isPlanModalOpen, setIsPlanModalOpen] = useState(false);
  const {
    isLoading,
    currentPlan,
    searchSubscription,
    userId,
    isCancelled,
    updateSubscriptionIsLoading,
    subscriptionUpdateInfo,
    cancelSubscription,
    changePaymentMethodInfo,
    updatePaymentMethod,
    updateSubscriptionPlan,
  } = props;

  useEffect(() => {
    if (userId) {
      const payload = {
        UserId: userId,
        SubscriptionPlanId: 0,
        Currency: 0,
      };
      searchSubscription(payload);
    }
  }, []);

  useEffect(() => {
    if (
      currentPlan &&
      currentPlan.UserSubscriptionId &&
      currentPlan.UserSubscriptionId !== ""
    ) {
      updatePaymentMethod({
        subscription_id: currentPlan.UserSubscriptionId,
      });
    }
  }, [currentPlan]);

  useEffect(() => {
    if (isCancelled && cancelled && isCancelModalOpen) {
      toggleCancelModal();
      localStorage.setItem("plan_status", "inactive");
      if (userId) {
        const payload = {
          UserId: userId,
          SubscriptionPlanId: 0,
          Currency: 0,
        };
        searchSubscription(payload);
      }
    }
  }, [isCancelled]);

  useEffect(() => {
    if (
      changeClicked &&
      changePaymentMethodInfo &&
      changePaymentMethodInfo.response &&
      changePaymentMethodInfo.response.length > 0 &&
      changePaymentMethodInfo.response[0].update_url
    ) {
      window.open(changePaymentMethodInfo.response[0].update_url, "_blank");
      createNotification(
        "success",
        "Payment method updated successfully.",
        2000
      );
      setChangeClicked(false);
    }
  }, [changePaymentMethodInfo]);

  useEffect(() => {
    if (subscriptionUpdateInfo) {
      togglePlanModal();
      if (userId) {
        const payload = {
          UserId: userId,
          SubscriptionPlanId: 0,
          Currency: 0,
        };
        searchSubscription(payload);
      }
    }
  }, [subscriptionUpdateInfo]);

  const toggleCancelModal = () => {
    setIsCancelModalOpen(!isCancelModalOpen);
  };

  const togglePlanModal = () => {
    setIsPlanModalOpen(!isPlanModalOpen);
  };

  const handleCancelSubscription = () => {
    if (
      !cancelled &&
      !props.isCancelled &&
      currentPlan &&
      currentPlan.UserSubscriptionId &&
      currentPlan.UserSubscriptionId !== ""
    ) {
      cancelSubscription({
        subscription_id: currentPlan.UserSubscriptionId,
      });
      setCancelled(true);
    } else if (!cancelled && !props.isCancelled) {
      window.open(
        "https://docs.google.com/forms/d/e/1FAIpQLScqnwrXWRSETJNOrDNkeRU6iaftKV0KYoiWonYBuAEBlHUNAg/viewform",
        "_blank"
      );
      setCancelled(true);
    }
  };

  const handleUpdatePlan = (plan) => {
    if (
      isPlanModalOpen &&
      currentPlan &&
      currentPlan.UserSubscriptionId &&
      currentPlan.UserSubscriptionId !== ""
    ) {
      const payload = {
        subscription_id: currentPlan.UserSubscriptionId,
        plan_id: plan.SubscriptionPlanId,
        SubscriptionPlanName: plan.SubscriptionPlanName
      };
      setIsUpdatePlanClicked(true);
      updateSubscriptionPlan(payload);
    }
  };

  const onChangeCard = () => {
    if (
      currentPlan &&
      currentPlan.UserSubscriptionId &&
      currentPlan.UserSubscriptionId !== ""
    ) {
      updatePaymentMethod({
        subscription_id: currentPlan.UserSubscriptionId,
      });
      setChangeClicked(true);
    }
  };

  return (
    <Content padding="30px 8%">
      {isCancelModalOpen && (
        <CancelSubscriptionModal
          isLoading={isLoading}
          isOpen={isCancelModalOpen}
          toggle={toggleCancelModal}
          cancelSubscription={handleCancelSubscription}
        />
      )}
      {currentPlan &&
        Object.keys(currentPlan).length > 0 &&
        isPlanModalOpen && (
          <PricingPlanModal
            isLoading={updateSubscriptionIsLoading}
            plan={currentPlan.SubscriptionPlan}
            isOpen={isPlanModalOpen}
            toggle={togglePlanModal}
            updatePlan={handleUpdatePlan}
          />
        )}
      <PageTitle style={{ marginBottom: "30px" }}>
        My Subscription Plan
      </PageTitle>

      <Container padding="40px" style={{ maxWidth: `1050px` }}>
        {isLoading ? (
          <ComponentCircleLoader />
        ) : (
          <>
            {currentPlan && Object.keys(currentPlan).length > 0 && (
              <>
                <CommonText>Current Plan</CommonText>
                {localStorage.getItem("plan_status") !== "active" ? (
                  <>
                    <PlanSection>
                      <CommonGrid columns="1fr">
                        <CommonText name fontSize="24px">
                          No Plan Selected
                        </CommonText>
                        <CommonText margin="20px 0 0">Status</CommonText>
                        <CommonGrid
                          justifyContent="start"
                          padding="0 0 20px"
                          gap="10px"
                        >
                          <img
                            src={cancelledIcon}
                            alt="cancelled"
                            width="100%"
                          />
                          <CommonText
                            name
                            style={{ textTransform: `capitalize` }}
                          >
                            {/* {currentPlan.Status == null
                                ? `Cancelled`
                                : currentPlan.Status} */}
                            Inactive
                          </CommonText>
                        </CommonGrid>
                        <CommonText>
                          Member since{" "}
                          <span style={{ color: `#132742` }}>
                            {" "}
                            {currentPlan.SubscriptionFromDate &&
                              moment(
                                currentPlan.SubscriptionFromDate.slice(
                                  0,
                                  currentPlan.SubscriptionFromDate.indexOf("T")
                                ),
                                "YYYY-MM-DD"
                              ).format("DD MMM YYYY")}
                          </span>
                        </CommonText>
                      </CommonGrid>
                      {/* <div>
                          <ColoredButtonWithIcon
                            width="100%"
                            onClick={() => navigate("/user/subscription")}
                          >
                            <ButtonIcon src={crown} alt="" />
                            UPGRADE
                          </ColoredButtonWithIcon>
                        </div> */}
                    </PlanSection>
                  </>
                ) : (
                  <>
                    <PlanSection>
                      <CommonGrid columns="1fr">
                        <CommonText
                          style={{ textTransform: `capitalize` }}
                          name
                          fontSize="24px"
                        >
                          {currentPlan.SubscriptionPlan}
                        </CommonText>
                        <CommonText margin="20px 0 0">Status</CommonText>
                        <CommonGrid
                          justifyContent="start"
                          padding="0 0 20px"
                          gap="10px"
                        >
                          <img
                            src={planActive}
                            alt="plan active"
                            width="100%"
                          />
                          <CommonText
                            name
                            style={{ textTransform: `capitalize` }}
                          >
                            {currentPlan.SubscriptionPlan}
                          </CommonText>
                        </CommonGrid>
                        <CommonText>
                          Member since{" "}
                          <span style={{ color: `#132742` }}>
                            {" "}
                            {currentPlan.SubscriptionFromDate &&
                              moment(
                                currentPlan.SubscriptionFromDate.slice(
                                  0,
                                  currentPlan.SubscriptionFromDate.indexOf("T")
                                ),
                                "YYYY-MM-DD"
                              ).format("DD MMM YYYY")}
                          </span>
                        </CommonText>
                        <CommonText>
                          Next Billing Period on{" "}
                          <span style={{ color: `#132742` }}>
                            {" "}
                            {currentPlan.SubscriptionToDate &&
                              moment(
                                currentPlan.SubscriptionToDate.slice(
                                  0,
                                  currentPlan.SubscriptionToDate.indexOf("T")
                                ),
                                "YYYY-MM-DD"
                              ).format("DD MMM YYYY")}
                          </span>
                        </CommonText>
                      </CommonGrid>
                      <div>
                        <PrimaryButton
                          onClick={() => togglePlanModal()}
                          width="100%"
                        >
                          Change Plan
                        </PrimaryButton>
                        {!cancelled && !isCancelled && (
                          <WhiteButton
                            type="cancel"
                            margin="20px 0 0 0"
                            onClick={() => toggleCancelModal()}
                          >
                            Cancel Subscription
                          </WhiteButton>
                        )}
                      </div>
                    </PlanSection>
                    <CommonGrid
                      gap="30px"
                      margin="25px -40px 0"
                      padding="30px 40px 0"
                      style={{ borderTop: `1px solid #E4E7EB` }}
                    >
                      <CommonGrid justifyContent="start" gap="3em">
                        <InputWithLabelSection
                          style={{ justifyItems: `start` }}
                        >
                          <CommonText>Payment Method</CommonText>
                          <CommonText>
                            {changePaymentMethodInfo &&
                              changePaymentMethodInfo.response &&
                              changePaymentMethodInfo.response.length > 0 && (
                                <>
                                  <span>
                                    {changePaymentMethodInfo.response[0]
                                      .payment_information.payment_method.toLowerCase() ===
                                    "paypal" ? (
                                      <img
                                        src={paypal}
                                        alt="payment method icon"
                                        width="100%"
                                      />
                                    ) : (
                                      <img
                                        src={
                                          PaymentMethodList[
                                            changePaymentMethodInfo.response[0]
                                              .payment_information.card_type
                                          ]
                                        }
                                        alt="payment method icon"
                                        width="100%"
                                      />
                                    )}
                                  </span>
                                  {currentPlan.CardNumber &&
                                    `**** ${currentPlan.CardNumber.slice(4)}`}
                                </>
                              )}
                          </CommonText>
                        </InputWithLabelSection>
                        <InputWithLabelSection>
                          <CommonText>Expiry Date</CommonText>
                          <CommonText>
                            {currentPlan.SubscriptionToDate &&
                              moment(
                                currentPlan.SubscriptionToDate.slice(
                                  0,
                                  currentPlan.SubscriptionToDate.indexOf("T")
                                ),
                                "YYYY-MM-DD"
                              ).format("DD MMM YYYY")}
                          </CommonText>
                        </InputWithLabelSection>
                      </CommonGrid>
                      {currentPlan.UserSubscriptionId &&
                        currentPlan.UserSubscriptionId !== "" && (
                          <div
                            style={{ marginTop: `auto`, cursor: `pointer` }}
                            onClick={() => onChangeCard()}
                          >
                            <ColoredText primary fontSize="14px">
                              Change Card
                            </ColoredText>
                          </div>
                        )}
                    </CommonGrid>
                  </>
                )}
              </>
            )}
          </>
        )}
      </Container>
    </Content>
  );
};

export default CurrentPlan;
