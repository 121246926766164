import React, { useState, useRef, useEffect } from "react";

import {
  AssignListContainer,
  AssignListItem,
  AssignListText,
} from "../../styledComponents/common";
import {
  WhiteButtonWithIcon,
  ButtonIcon,
} from "../../styledComponents/buttons";

import DropdownLoader from "../DropdownLoader/DropdownLoader";
import FirstRoundLetter from "../ColorLetterRound/FirstRoundLetter";

import PlusBlack from "../../assets/img/icons/plus_black.svg";

const AddTeamDropdown = ({
  isLoading,
  selectedOrganization,
  assignedTeams,
  index,
  updateState,
  teamsList,
  getTeamList,
  buttonPadding,
  buttonText,
}) => {
  const [addTeam, setAddTeam] = useState(false);
  const wrapperRef1 = useRef(null);
  const wrapperRef2 = useRef(null);
  const [addList, setAddList] = useState([]);

  useEffect(() => {
    if (teamsList && teamsList.length > 0) {
      let newArr = teamsList;
      if (assignedTeams && assignedTeams.length > 0) {
        assignedTeams.map((project) => {
          newArr = newArr.filter((item) => item.id !== project.id);
        });
      }
      setAddList(newArr);
    } else if (teamsList && teamsList.length === 0) {
      setAddList([]);
    }
  }, [teamsList, assignedTeams]);

  //function
  useOutsideClickHandler(wrapperRef1, wrapperRef2);

  const showAssignee = () => {
    setAddTeam(true);
    if (selectedOrganization && selectedOrganization.id) {
      let payload = {
        organization_id: selectedOrganization.id,
      };
      getTeamList(payload);
    }
  };

  function useOutsideClickHandler(ref1, ref2) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (
          ref1.current &&
          !ref1.current.contains(event.target) &&
          ref2.current &&
          !ref2.current.contains(event.target)
        ) {
          setAddTeam(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref1, ref2]);
  }
  return (
    <div style={{ position: `relative` }}>
      <WhiteButtonWithIcon
        padding={
          buttonPadding && buttonPadding !== "" ? buttonPadding : `0 15px`
        }
        hoverBorderColor="#20bead"
        onClick={() => {
          showAssignee();
        }}
        ref={wrapperRef2}
      >
        <ButtonIcon src={PlusBlack} alt="" width="15px" />
        {buttonText && buttonText !== "" ? buttonText : `Add to a team`}
      </WhiteButtonWithIcon>

      {addTeam && (
        <AssignListContainer ref={wrapperRef1} padding="5px 0">
          <DropdownLoader loading={isLoading} />
          {addList &&
            addList.length > 0 &&
            addList.map((team, i) => (
              <AssignListItem
                key={i}
                onClick={() => {
                  updateState(team, index);
                }}
                columns="25px auto"
              >
                <FirstRoundLetter
                  text={team.name}
                  backColor={i}
                  size="25px"
                  fontSize="13px"
                />
                <AssignListText>{team.name}</AssignListText>
              </AssignListItem>
            ))}
        </AssignListContainer>
      )}
    </div>
  );
};

export default AddTeamDropdown;
