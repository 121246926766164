import {
  GET_REGISTRATION_DROPDOWN,
  GET_REGISTRATION_DROPDOWN_SUCCESS,
  GET_REGISTRATION_DROPDOWN_FAILURE,
  SEARCH_CATEGORY,
  SEARCH_CATEGORY_SUCCESS,
  SEARCH_CATEGORY_FAILURE,
  SEARCH_BILL_RECEIPT,
  SEARCH_BILL_RECEIPT_SUCCESS,
  SEARCH_BILL_RECEIPT_FAILURE,
  DELETE_BILL_RECEIPT_BY_ID,
  DELETE_BILL_RECEIPT_BY_ID_SUCCESS,
  DELETE_BILL_RECEIPT_BY_ID_FAILURE,
} from "../../utils/constants";

export const registrationDropdownRequestHandler = (state, action) => {
  return {
    ...state,
  };
};
export const registrationDropdownRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;

  return {
    ...state,
    registrationDropdownInfo: data.data,
  };
};
export const registrationDropdownRequestFailureHandler = (state, action) => {
  return {
    ...state,
    registrationDropdownInfo: [],
  };
};

export const searchCategoryRequestHandler = (state, action) => {
  return {
    ...state,
    isCategoryLoading: true,
  };
};

export const searchCategoryRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 200) {
    // createNotification("success", "campaign created successfully", 2000);
    return {
      ...state,
      isCategoryLoading: false,
      searchCategoryList: data.data,
    };
  }
  return {
    ...state,
    isCategoryLoading: false,
    searchCategoryList: [],
  };
};

export const searchCategoryRequestFailureHandler = (state, action) => {
  if (action.payload.data === undefined) {
    // createNotification("error", "Something went wrong", 2000);
    return {
      ...state,
      isCategoryLoading: false,
      searchCategoryList: [],
    };
  }
  const {
    payload: { data },
  } = action;
  if (data.status === 400) {
    // createNotification("error", "Failed!! please try again", 2000);
  }
  return {
    ...state,
    isCategoryLoading: false,
    searchCategoryList: [],
  };
};

//CREATE RECEIPT
export const billreceiptRequestHandler = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const billreceiptRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 200) {
    // createNotification("success", "campaign created successfully", 2000);
    return {
      ...state,
      isLoading: false,
      billReceiptList: data.data,
    };
  }
  return {
    ...state,
    isLoading: false,
    billReceiptList: [],
  };
};

export const billreceiptRequestFailureHandler = (state, action) => {
  if (action.payload.data === undefined) {
    // createNotification("error", "Something went wrong", 2000);
    return {
      ...state,
      isLoading: false,
      billReceiptList: [],
    };
  }
  const {
    payload: { data },
  } = action;
  if (data.status === 400) {
    // createNotification("error", "Failed!! please try again", 2000);
  }
  return {
    ...state,
    isLoading: false,
    billReceiptList: [],
  };
};

export const deleteBillReceiptByIdRequestHandler = (state, action) => {
  return {
    ...state,
    isDeleteLoading: true,
    deletedReceiptCallback: false,
  };
};
export const deleteBillReceiptByIdRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    isDeleteLoading: false,
    deletedReceiptCallback: true,
    isDeletedReceipt: data.data,
  };
};
export const deleteBillReceiptByIdRequestFailureHandler = (state, action) => {
  return {
    ...state,
    isDeleteLoading: false,
    deletedReceiptCallback: false,
    isDeletedReceipt: null,
  };
};

// Action Handlers
const ACTION_HANDLERS = {
  [GET_REGISTRATION_DROPDOWN]: registrationDropdownRequestHandler,
  [GET_REGISTRATION_DROPDOWN_SUCCESS]: registrationDropdownRequestSuccessHandler,
  [GET_REGISTRATION_DROPDOWN_FAILURE]: registrationDropdownRequestFailureHandler,

  [SEARCH_CATEGORY]: searchCategoryRequestHandler,
  [SEARCH_CATEGORY_SUCCESS]: searchCategoryRequestSuccessHandler,
  [SEARCH_CATEGORY_FAILURE]: searchCategoryRequestFailureHandler,

  [SEARCH_BILL_RECEIPT]: billreceiptRequestHandler,
  [SEARCH_BILL_RECEIPT_SUCCESS]: billreceiptRequestSuccessHandler,
  [SEARCH_BILL_RECEIPT_FAILURE]: billreceiptRequestFailureHandler,

  [DELETE_BILL_RECEIPT_BY_ID]: deleteBillReceiptByIdRequestHandler,
  [DELETE_BILL_RECEIPT_BY_ID_SUCCESS]: deleteBillReceiptByIdRequestSuccessHandler,
  [DELETE_BILL_RECEIPT_BY_ID_FAILURE]: deleteBillReceiptByIdRequestFailureHandler,
};

// default initial state
const initialState = {
  isLoading: false,
  isCategoryLoading: false,
  isDeleteLoading: false,
  registrationDropdownInfo: [],
  searchCategoryList: [],
  billReceiptList: [],
  isDeletedReceipt: null,
  deletedReceiptCallback: false,
};

export default function generateReceiptReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
