import {
  GET_FONT,
  GET_TAX,
  GET_CATEGORY_RECEIPT_BY_ID,
  ADD_UPDATE_BILL_RECEIPT,
  GET_BILL_RECEIPT_BY_ID,
  CLEAR_GENERATED_STATE,
} from "../../utils/constants";

// ------------------------------------
// Actions
// ------------------------------------

export function getFontList(value) {
    return {
      type: GET_FONT,
      payload: value,
    };
  }

  export function getTaxList(value) {
    return {
      type: GET_TAX,
      payload: value,
    };
  }

  export function getCategoryReceiptById(value) {
    return {
      type: GET_CATEGORY_RECEIPT_BY_ID,
      payload: value,
    };
  }

  export function addUpdateBillReceipt(value) {
    return {
      type: ADD_UPDATE_BILL_RECEIPT,
      payload: value,
    };
  }

  export function getBillReceiptById(value) {
    return {
      type: GET_BILL_RECEIPT_BY_ID,
      payload: value,
    };
  }

  export function clearGeneratedState() {
    return {
      type: CLEAR_GENERATED_STATE,
    };
  }