import React, { Fragment, useState, useEffect } from "react";
import Select from "../../components/ReactSelectDropdown";
import FilterDropDownStyle from "../../components/DropdownStyle/FilterDropDownStyle";
import NoDataComponent from "../../components/NoDataComponent/NoDataComponent";
// import RecordPaymentModal from "./RecordPaymentModal";
// import DeleteInvoicePopup from "./DeleteInvoicePopup";
import moment from "moment";
import {
  Container,
  TableText,
  CommonText,
  NoDataContainer,
} from "../../styledComponents/common";
import {
  InvoiceTableContainer,
  TableItem,
  StatusBox,
} from "../../styledComponents/invoice";
import ComponentCircleLoader from "../../components/Loaders/ComponentCircleLoader";
import {
  HovarTab,
  MyReceiptsTableRow,
  IconImg,
} from "../../styledComponents/myReceipts";
import { CheckboxDiv, CheckboxTickIcon } from "../../styledComponents/settings";
import tickWhite from "../../assets/img/icons/tick_2.svg";
import noDataArtWork from "../../assets/img/artwork.svg";
import deleteIcon from "../../assets/img/icons/delete.svg";
import downloadIcon from "../../assets/img/icons/download.svg";

const MyReceiptTable = (props) => {
  const downloadImage = (href) => {
    fetch(href, {
      method: "GET",
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function(buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `generated-receipt.png`); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <Container padding="40px 0" style={{ gridColumn: `1 / span 2` }}>
      {props.isLoading ? (
        <ComponentCircleLoader />
      ) : (
        <>
          <MyReceiptsTableRow heading>
            <TableItem>
              <CheckboxDiv
                active={props.allSelect}
                onClick={props.toogleSelectAllCampaign}
              >
                <CheckboxTickIcon src={tickWhite} alt="tick white" />
              </CheckboxDiv>
            </TableItem>
            <TableItem>
              <TableText>Business</TableText>
            </TableItem>
            <TableItem>
              <TableText>Address</TableText>
            </TableItem>
            <TableItem>
              <TableText>Payment Type</TableText>
            </TableItem>
            <TableItem>
              <TableText>Category</TableText>
            </TableItem>
            <TableItem>
              <TableText>Receipt Date</TableText>
            </TableItem>
            <TableItem>
              <TableText>Created Date</TableText>
            </TableItem>
          </MyReceiptsTableRow>
          {props.billReceiptList &&
          props.billReceiptList.length > 0 &&
          props.selectItems.length === props.billReceiptList.length ? (
            props.billReceiptList.map((receipt, i) => (
              <MyReceiptsTableRow key={i}>
                <TableItem>
                  <CheckboxDiv
                    active={props.selectItems[i].id === receipt.BillRecieptId}
                    onClick={() =>
                      props.toggleSelectCampaign(receipt.BillRecieptId, i)
                    }
                  >
                    <CheckboxTickIcon src={tickWhite} alt="tick white" />
                  </CheckboxDiv>
                </TableItem>
                <TableItem>
                  <TableText name>
                    {" "}
                    {receipt.Business && receipt.Business}{" "}
                  </TableText>
                </TableItem>
                <TableItem>
                  <TableText name>
                    {receipt.Address && receipt.Address}
                  </TableText>
                </TableItem>
                <TableItem>
                  <TableText name>
                    {receipt.PaymentType && receipt.PaymentType}
                  </TableText>
                </TableItem>
                <TableItem>
                  <TableText name>
                    {receipt.CategoryName && receipt.CategoryName}
                  </TableText>
                </TableItem>
                <TableItem>
                  <TableText name>
                    {receipt.RecieptDate &&
                      moment(
                        receipt.RecieptDate.slice(
                          0,
                          receipt.RecieptDate.indexOf("T")
                        ),
                        "YYYY-MM-DD"
                      ).format("DD MMM YYYY")}
                  </TableText>
                </TableItem>
                <TableItem>
                  <TableText name>
                    {receipt.CreatedDate &&
                      moment(
                        receipt.CreatedDate.slice(
                          0,
                          receipt.CreatedDate.indexOf("T")
                        ),
                        "YYYY-MM-DD"
                      ).format("DD MMM YYYY")}
                  </TableText>
                </TableItem>
                <HovarTab>
                  <a
                    target="_blank"
                    href={`${process.env.REACT_APP_API_URL}/RecieptPdf/bill_reciept_${receipt.BillRecieptId}.png`}
                    onClick={() =>
                      downloadImage(
                        `${process.env.REACT_APP_API_URL}/RecieptPdf/bill_reciept_${receipt.BillRecieptId}.png`
                      )
                    }
                  >
                    <IconImg
                      src={downloadIcon}
                      alt="download icon"
                      width="20px%"
                    />
                  </a>
                  <IconImg
                    src={deleteIcon}
                    alt="delete icon"
                    width="20px"
                    onClick={() => {
                      props.popupToggle();
                      props.toggleSelectCampaign(receipt.BillRecieptId, i);
                    }}
                  />
                </HovarTab>
              </MyReceiptsTableRow>
            ))
          ) : (
            <NoDataContainer>
              <img
                src={noDataArtWork}
                alt="no campaigns"
                width="100%"
                style={{ maxWidth: `300px` }}
              />
              <CommonText style={{ textAlign: `center` }}>
                No Receipts.
              </CommonText>
            </NoDataContainer>
          )}
        </>
      )}
    </Container>
  );
};

export default MyReceiptTable;
