import {
  SEARCH_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION_PLAN,
  APPLY_COUPON,
  CLEAR_CURRENT_SUBSCRIPTION,
  CLEAR_SUBSCRIPTION_UPDATED,
  CLEAR_DISCOUNT_INFO,
  CLEAR_DISCOUNT_ERROR_MESSAGE,
  CANCEL_SUBSCRIPTION,
  UPDATE_PAYMENT_METHOD,
  UPGRADE_PLAN,
} from "../../utils/constants";

export function searchSubscription(payload) {
  return {
    type: SEARCH_SUBSCRIPTION,
    payload,
  };
}

export function updateSubscriptionPlan(payload) {
  return {
    type: UPDATE_SUBSCRIPTION_PLAN,
    payload,
  };
}

export function applyCoupon(payload) {
  return {
    type: APPLY_COUPON,
    payload,
  };
}

export function cancelSubscription(payload) {
  return {
    type: CANCEL_SUBSCRIPTION,
    payload,
  };
}

export function updatePaymentMethod (payload) {
  return {
    type: UPDATE_PAYMENT_METHOD,
    payload,
  };
}

export function upgradePlan(payload) {
  return {
    type: UPGRADE_PLAN,
    payload,
  };
}

export function clearCurrentSubscription() {
  return {
    type: CLEAR_CURRENT_SUBSCRIPTION,
  };
}

export function clearSubscriptionUpdated() {
  return {
    type: CLEAR_SUBSCRIPTION_UPDATED,
  };
}

export function clearDiscountInfo() {
  return {
    type: CLEAR_DISCOUNT_INFO,
  };
}

export function clearDiscountErrorMessage() {
  return {
    type: CLEAR_DISCOUNT_ERROR_MESSAGE,
  };
}
