import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { createBrowserHistory } from "history";

import "./index.css";
import 'bootstrap/dist/css/bootstrap.css';
// Deafult CSS files

import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import "react-dates/lib/css/_datepicker.css";
import "react-datepicker/dist/react-datepicker.css";

// store
import { store } from "./configureStore";

// Provider
import { Provider, ReactReduxContext } from "react-redux";

// react-dates
import "react-dates/initialize";

import { ThemeProvider } from "styled-components";
import { GlobalStyles, lightTheme } from "./styles";

import App from "./App";

const history = createBrowserHistory();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store} context={ReactReduxContext}>
      <ThemeProvider theme={lightTheme}>
        <GlobalStyles />
        <BrowserRouter navigator={history} context={ReactReduxContext}>
          <App />
        </BrowserRouter>
        <ToastContainer
          position="top-right"
          autoClose={4000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          transition={Slide}
          pauseOnHover
        />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
