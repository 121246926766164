import {
  SIGN_UP_REQUEST,
  SIGN_UP_RESET,
  ADD_REGISTRATION,
  CHECK_TOKEN,
  PADDLE_REGISTER,
} from "../../utils/constants";

// ------------------------------------
// Actions
// ------------------------------------
export function signUp(value) {
  return {
    type: SIGN_UP_REQUEST,
    payload: value,
  };
}
//
export function addPaddleRegisterInfo(value) {
  return {
    type: PADDLE_REGISTER,
    payload: value,
  };
}

//add registration after paddle checkout
export function addRegistration(value) {
  return {
    type: ADD_REGISTRATION,
    payload: value,
  };
}

export function resetSignUp() {
  return {
    type: SIGN_UP_RESET,
  };
}

export function checkToken() {
  return {
    type: CHECK_TOKEN
  }
}
