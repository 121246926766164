import React from "react";
import {
  CommonText,
  ModalButtonSection,
  PreviewPopup,
  PreviewContainer,
} from "../../../styledComponents/common";
import { WhiteButton, ColoredButton } from "../../../styledComponents/buttons";
import ButtonTextLoader from "../../../components/Loaders/ButtonTextLoader";

const CancelSubscriptionModal = ({ isOpen, toggle, cancelSubscription, isLoading }) => {
  const unsubscribe = () => {
    cancelSubscription();
  };
  return (
    <PreviewContainer>
      <PreviewPopup
        maxWidth="750px"
        padding="30px"
        minHeight="340px"
        alignItems="center"
        justifyItems="center"
      >
        <CommonText name fontSize="16px">
          Are you sure you want to cancel subscription?
        </CommonText>
        <CommonText style={{ textAlign: `center` }}>
          Remember, once you cancel subscription you won’t be able to use any
          template afterwards.
        </CommonText>
        <ModalButtonSection details>
          <WhiteButton type="cancel" onClick={toggle}>
            Not Now
          </WhiteButton>
          <ColoredButton type="delete" onClick={() => unsubscribe()}>
          {isLoading ? (
                <ButtonTextLoader loadingText="Canceling" fontSize="14px" />
              ) : (
                "Cancel Subscription"
              )}
          </ColoredButton>
        </ModalButtonSection>
      </PreviewPopup>
    </PreviewContainer>
  );
};

export default CancelSubscriptionModal;
