import { connect } from "react-redux";

import MemberDetails from "./MemberDetails";

// Actions
import {
  getMemberDetails,
  getMemberWeeklyEarning,
  getMemberMonthlyEarning,
  clearMemberDetailsData,
  updateMember,
  removeMember,
  setPaymentConfiguration,
} from "../membersActions";

// import {
//   getProjectShortList,
//   addProjectAssignee,
//   deleteProjectAssignee,
// } from "../../Projects/projectsActions";
// import {
//   getTeamShortList,
//   addTeamMember,
//   removeTeamMember,
// } from "../../Teams/teamsActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  isLoading: state.member.isLoading,
  paymentConfigurationLoading: state.member.paymentConfigurationLoading,
  updateMemberLoading: state.member.updateMemberLoading,
  deleteMemberLoading: state.member.deleteMemberLoading,
  // teamListIsLoading: state.team.shortListIsLoading,
  // teamMemberIsLoading: state.team.teamMemberIsLoading,
  // projectListIsLoading: state.project.shortListIsLoading,
  // projectAssigneeIsLoading: state.project.projectAssigneeIsLoading,
  selectedOrganization: state.organization.selectedOrganization,
  memberDetails: state.member.memberDetails,
  weeklyEarning: state.member.weeklyEarning,
  monthlyEarning: state.member.monthlyEarning,
  // teamList: state.team.teamsShortList,
  // projectList: state.project.projectShortList,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  getMemberDetails: (details) => dispatch(getMemberDetails({ ...details })),
  getMemberWeeklyEarning: (details) =>
    dispatch(getMemberWeeklyEarning({ ...details })),
  getMemberMonthlyEarning: (details) =>
    dispatch(getMemberMonthlyEarning({ ...details })),
  clearMemberDetailsData: () => dispatch(clearMemberDetailsData()),
  // getTeamShortList: (details) => dispatch(getTeamShortList({ ...details })),
  // addTeam: (details) => dispatch(addTeamMember({ ...details })),
  // getProjectShortList: (details) =>
  //   dispatch(getProjectShortList({ ...details })),
  // assignProject: (details) => dispatch(addProjectAssignee({ ...details })),
  updateMember: (details) => dispatch(updateMember({ ...details })),
  removeMember: (details) => dispatch(removeMember({ ...details })),
  setPaymentConfiguration: (details) =>
    dispatch(setPaymentConfiguration({ ...details })),
  // deleteProjectAssignee: (details) =>
  //   dispatch(deleteProjectAssignee({ ...details })),
  // removeTeamMember: (details) => dispatch(removeTeamMember({ ...details })),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(MemberDetails);
