import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import Select from "react-select";
import templates from "../../utils/templates";
import TimePicker from "../../utils/TimePickerNew";
import FilterDropDownStyle from "../../components/DropdownStyle/FilterDropDownStyle";
import BackButtonComponent from "../../components/BackButtonComponent/BackButtonComponent";
import DatePicker from "../../components/SingleDatePicker/SingleDatePicker";
import CurrencyList from "../../utils/APIcurrencies.json";
import payload from "../../utils/payload";
import ComponentCircleLoader from "../../components/Loaders/ComponentCircleLoader";
import {
  Container,
  Form,
  ImageContainer,
  ColoredTab,
  GenerateButtonSection,
  ImageSection,
  TaxInputSection,
  TaxRowTable,
  ItemRowContainer,
  ItemInputContainer,
  DividedSection,
  ItemRowTable,
  ItemRowCell,
  FileUpdloadLogo,
  RemoveImageIcon,
  ImgPreviewSection,
  ImageAlert,
} from "../../styledComponents/generateReceipt";
import {
  ColoredButtonWithIcon,
  ButtonIcon,
  PrimaryButton,
  WhiteButton,
  ColoredButton,
} from "../../styledComponents/buttons";
import {
  InputWithLabelSection,
  InputLabel,
  InputField,
  RedStar,
  CommonGrid,
  ColoredText,
  PreviewContainer,
  PageTitle,
  FormAlert,
  CommonText,
} from "../../styledComponents/common";
import downloadWhite from "../../assets/img/icons/download-white.png";
import cross from "../../assets/img/icons/cross_black.svg";

const GenerateReceipt = (props) => {
  const navigate = useNavigate();
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedForm, setSelectedForm] = useState({});
  const [formDate, setFormDate] = useState(moment());
  const [formTime, setFormTime] = useState(moment().format("HH:mm"));
  const [expireDate, setExpireDate] = useState(moment());
  const [expireTime, setExpireTime] = useState(moment().format("HH:mm"));
  const [entryDate, setEntryDate] = useState(moment());
  const [entryTime, setEntryTime] = useState(moment().format("HH:mm"));
  const [orderDate, setOrderDate] = useState(moment());
  const [orderTime, setOrderTime] = useState(moment().format("HH:mm"));
  const [deliveryDate, setDeliveryDate] = useState(moment());
  const [deliveryTime, setDeliveryTime] = useState(moment().format("HH:mm"));
  const [billDate, setBillDate] = useState(moment());
  const [billTime, setBillTime] = useState(moment().format("HH:mm"));

  const [pickupDate, setPickupDate] = useState(moment());
  const [pickupTime, setPickupTime] = useState(moment().format("HH:mm"));

  const [dropDate, setDropDate] = useState(moment());
  const [dropTime, setDropTime] = useState(moment().format("HH:mm"));

  const [billPayDate, setBillPayDate] = useState(moment());
  const [billPayTime, setBillPayTime] = useState(moment().format("HH:mm"));
  const [fontsOption, setFontsOption] = useState([]);
  const [logoPreview, setLogoPreview] = useState("");
  const [logoFile, setLogoFile] = useState(null);
  const [taxOption, setTaxOption] = useState([]);
  const [barcodePreview, setBarcodePreview] = useState("");
  const [barcodeFile, setBarcodeFile] = useState(null);
  const [profileImagePreview, setProfileImagePreview] = useState("");
  const [profileImageFile, setProfileImageFile] = useState(null);
  const [selectedTax, setSelectedTax] = useState({
    TaxId: "1",
    TaxInPercentage: "",
    value: "1",
    label: "TAX",
  });
  const [selectedItem, setSelectedItem] = useState({
    BillRecieptItemId: 0,
    ItemName: "",
    Quantity: "",
    Price: "",
    ItemNo: "",
    UOM: "",
  });
  const [errorList, setErrorList] = useState({});
  const [isGenerated, setIsGenerated] = useState(false);

  useEffect(() => {
    if (window.location.search && window.location.search !== "") {
      let selectedTemplate = window.location.search.slice(
        window.location.search.lastIndexOf("id=") + 3
      );
      setSelectedTemplateId(selectedTemplate);
      props.getFontList();
      props.getTaxList({
        is_active: true,
      });
      props.getCategoryReceiptById({
        category_reciept_id: selectedTemplate,
      });
    }
  }, []);

  useEffect(() => {
    if (selectedTemplateId) {
      let newTemplate = templates.find(
        (template) => `${template.id}` === selectedTemplateId
      );
      setSelectedTemplate(newTemplate);
      let newForm = {};
      newTemplate.formInputs &&
        newTemplate.formInputs.length > 0 &&
        newTemplate.formInputs.forEach((input) => {
          newForm[input] = "";
        });
      newForm.BillRecieptTaxInfoDc !== undefined &&
        newForm.BillRecieptTaxInfoDc === "" &&
        (newForm.BillRecieptTaxInfoDc = []);

      newForm.BillRecieptItemInfoDc !== undefined &&
        newForm.BillRecieptItemInfoDc === "" &&
        (newForm.BillRecieptItemInfoDc = []);

      newForm.CurrencyId !== undefined &&
        newForm.CurrencyId === "" &&
        (newForm.CurrencyId = {
          CurrencyId: 3,
          value: "$",
          label: "United States dollar",
        });
      setSelectedForm(newForm);
    }
  }, [selectedTemplateId]);

  useEffect(() => {
    if (props.fontList && props.fontList.length > 0) {
      let newOptionList = [];
      props.fontList.forEach((font, i) => {
        newOptionList.push({
          FontId: font.FontId,
          value: font.FontId,
          label: font.FontStyle,
        });
      });
      setFontsOption(newOptionList);
    }
  }, [props.fontList]);

  useEffect(() => {
    if (props.taxList && props.taxList.length > 0) {
      let newOptionList = [];
      props.taxList.forEach((tax, i) => {
        newOptionList.push({
          TaxId: tax.TaxId,
          value: tax.TaxId,
          label: tax.Tax,
        });
      });
      setTaxOption(newOptionList);
    }
  }, [props.taxList]);

  useEffect(() => {
    if (
      props.receiptByIdInfo &&
      Object.keys(props.receiptByIdInfo).length > 0 &&
      props.userId
    ) {
      payload.UserId = props.userId;
      payload.CategoryRecieptId = props.receiptByIdInfo.CategoryRecieptId;
      payload.CategoryRecieptPDFDc.BillRecieptSampleImage =
        props.receiptByIdInfo.BillRecieptSampleImage;
      payload.CategoryRecieptPDFDc.RecieptType =
        props.receiptByIdInfo.RecieptType;
      payload.CategoryRecieptPDFDc.IsRecieptLogo =
        props.receiptByIdInfo.IsRecieptLogo;

      payload.CategoryRecieptPDFDc.ReceiptWidth =
        props.receiptByIdInfo.ReceiptWidth;

      payload.CategoryRecieptPDFDc.ReceiptHight =
        props.receiptByIdInfo.ReceiptHight;
    }
  }, [props.receiptByIdInfo]);

  useEffect(() => {
    if (isGenerated && props.receiptID && props.receiptID !== "") {
      props.getBillReceiptById({
        bill_reciept_id: props.receiptID,
      });
    }
  }, [props.receiptID]);

  const onTextInputChange = (key, value) => {
    let formCopy = { ...selectedForm };
    formCopy[key] = value;
    setSelectedForm(formCopy);

    let errors = { ...errorList };
    delete errors[key];
    setErrorList(errors);
  };

  const onFormDateChange = (date) => {
    setFormDate(date);
  };

  const onExpireDateChange = (date) => {
    setExpireDate(date);
  };

  const onEntryDateChange = (date) => {
    setEntryDate(date);
  };

  const onOrderDateChange = (date) => {
    setOrderDate(date);
  };

  const onDeliveryDateChange = (date) => {
    setDeliveryDate(date);
  };

  const onBillDateChange = (date) => {
    setBillDate(date);
  };

  const onPickupDateChange = (date) => {
    setPickupDate(date);
  };

  const onDropDateChange = (date) => {
    setDropDate(date);
  };

  const onBillPayDateChange = (date) => {
    setBillPayDate(date);
  };

  const onTimeCountHourChange = (value) => {
    setFormTime(value);
  };

  const onExpireTimeChange = (value) => {
    setExpireTime(value);
  };

  const onEntryTimeChange = (value) => {
    setEntryTime(value);
  };

  const onOrderTimeChange = (value) => {
    setOrderTime(value);
  };

  const onDeliveryTimeChange = (value) => {
    setDeliveryTime(value);
  };

  const onBillTimeChange = (value) => {
    setBillTime(value);
  };

  const onPickupTimeChange = (value) => {
    setPickupTime(value);
  };

  const onDropTimeChange = (value) => {
    setDropTime(value);
  };

  const onBillPayTimeChange = (value) => {
    setBillPayTime(value);
  };

  const onCurrencyChange = (value) => {
    let formCopy = { ...selectedForm };
    formCopy.CurrencyId = value;
    setSelectedForm(formCopy);
    let errors = { ...errorList };
    delete errors.CurrencyId;
    setErrorList(errors);
  };

  const handleLogoPreview = (e) => {
    let image_as_base64 = URL.createObjectURL(e.target.files[0]);
    let image_as_files = e.target.files[0];

    setLogoPreview(image_as_base64);
    setLogoFile(image_as_files);

    let formCopy = { ...selectedForm };
    formCopy.RecieptLogo = `RecieptLogo/${e.target.files[0].name}`;
    setSelectedForm(formCopy);

    let errors = { ...errorList };
    delete errors.RecieptLogo;
    setErrorList(errors);
  };

  const handleLogoRemove = () => {
    if (logoPreview && logoFile) {
      setLogoPreview("");
      setLogoFile(null);

      let formCopy = { ...selectedForm };
      formCopy.RecieptLogo = "";
      setSelectedForm(formCopy);

      let errors = { ...errorList };
      delete errors.RecieptLogo;
      setErrorList(errors);
    }
  };

  const handleBarcodePreview = (e) => {
    let image_as_base64 = URL.createObjectURL(e.target.files[0]);
    let image_as_files = e.target.files[0];

    setBarcodePreview(image_as_base64);
    setBarcodeFile(image_as_files);

    let formCopy = { ...selectedForm };
    formCopy.BarCode = `RecieptLogo/${e.target.files[0].name}`;
    setSelectedForm(formCopy);

    let errors = { ...errorList };
    delete errors.BarCode;
    setErrorList(errors);
  };

  const handleBarcodeRemove = () => {
    if (barcodePreview && barcodeFile) {
      setBarcodePreview("");
      setBarcodeFile(null);

      let formCopy = { ...selectedForm };
      formCopy.BarCode = "";
      setSelectedForm(formCopy);

      let errors = { ...errorList };
      delete errors.BarCode;
      setErrorList(errors);
    }
  };

  const handleProfileImagePreview = (e) => {
    let image_as_base64 = URL.createObjectURL(e.target.files[0]);
    let image_as_files = e.target.files[0];

    setProfileImagePreview(image_as_base64);
    setProfileImageFile(image_as_files);

    let formCopy = { ...selectedForm };
    formCopy.ProfileImage = `RecieptLogo/${e.target.files[0].name}`;
    setSelectedForm(formCopy);

    let errors = { ...errorList };
    delete errors.ProfileImage;
    setErrorList(errors);
  };

  const handleProfileImageRemove = () => {
    if (profileImagePreview && profileImageFile) {
      setProfileImagePreview("");
      setProfileImageFile(null);

      let formCopy = { ...selectedForm };
      formCopy.ProfileImage = "";
      setSelectedForm(formCopy);

      let errors = { ...errorList };
      delete errors.ProfileImage;
      setErrorList(errors);
    }
  };

  const onTaxChange = (type, value) => {
    let taxObject = { ...selectedTax };

    if (type === "type") {
      taxObject.TaxId = value.TaxId;
      taxObject.label = value.label;
      taxObject.value = value.value;
    } else {
      taxObject.TaxInPercentage = value;
    }
    setSelectedTax(taxObject);
  };

  const addTax = () => {
    if (
      selectedForm.BillRecieptTaxInfoDc.length < 2 &&
      selectedTax.TaxInPercentage !== "" &&
      selectedTax.TaxId &&
      selectedTax.label !== ""
    ) {
      let formCopy = { ...selectedForm };
      formCopy.BillRecieptTaxInfoDc.push({
        BillRecieptTaxId: 0,
        TaxId: selectedTax.TaxId,
        TaxInPercentage: selectedTax.TaxInPercentage,
        Tax: selectedTax.label,
      });
      setSelectedForm(formCopy);
      setSelectedTax({
        TaxId: "1",
        TaxInPercentage: "",
        value: "1",
        label: "TAX",
      });
    }
  };

  const onDeleteTax = (index) => {
    let formCopy = { ...selectedForm };
    if (index === 0) {
      formCopy.BillRecieptTaxInfoDc.shift();
    } else {
      formCopy.BillRecieptTaxInfoDc.pop();
    }
    setSelectedForm(formCopy);
  };

  const onItemValueChange = (type, value) => {
    let itemObject = { ...selectedItem };
    itemObject[type] = value;
    setSelectedItem(itemObject);
  };

  const addItem = () => {
    if (
      selectedItem.ItemName !== "" &&
      selectedItem.Quantity !== "" &&
      selectedItem.Price !== ""
    ) {
      let formCopy = { ...selectedForm };
      formCopy.BillRecieptItemInfoDc.push({
        BillRecieptItemId: 0,
        ItemName: selectedItem.ItemName,
        Quantity: selectedItem.Quantity,
        Price: selectedItem.Price,
        ItemNo: selectedItem.ItemNo,
        UOM: selectedItem.UOM,
      });
      setSelectedForm(formCopy);
      setSelectedItem({
        BillRecieptItemId: 0,
        ItemName: "",
        Quantity: "",
        Price: "",
        ItemNo: "",
        UOM: "",
      });
    }
  };

  const onDeleteItem = (index) => {
    let formCopy = { ...selectedForm };
    if (formCopy.BillRecieptItemInfoDc.indexOf(index)) {
      formCopy.BillRecieptItemInfoDc.splice(index, 1);
    }
    setSelectedForm(formCopy);
  };

  const checkError = (formCopy) => {
    let formIsValid = true;
    let saveErrors = {};
    if (formCopy && Object.keys(formCopy).length > 0) {
      for (const prop in formCopy) {
        if (
          prop !== "BillRecieptTaxInfoDc" &&
          prop !== "BillRecieptItemInfoDc" &&
          prop !== "RecieptLogo" &&
          prop !== "BarCode" &&
          prop !== "ProfileImage" &&
          prop !== "EntryDate" &&
          prop !== "ExitDate" &&
          prop !== "OrderDate" &&
          prop !== "DeliveryDate" &&
          prop !== "BillDate" &&
          prop !== "PickupTime" &&
          prop !== "DropTime" &&
          prop !== "BillPayDate" &&
          (!formCopy[prop] || formCopy[prop] === "")
        ) {
          saveErrors[prop] = `${prop} is required`;
          formIsValid = false;
        }
      }
      setErrorList(saveErrors);
      return formIsValid;
    }
  };

  const onGenerateReceipt = () => {
    let formCopy = { ...selectedForm };

    for (let key in selectedForm) {
      if (key === "FontId") {
        payload[key] = parseInt(selectedForm.FontId.FontId);
      } else if (key === "CurrencyId") {
        payload[key] = selectedForm.CurrencyId.CurrencyId;
      } else if (key === "RecieptLogo") {
        payload.CategoryRecieptPDFDc.IsRecieptLogo = true;
        payload[key] = selectedForm[key];
      } else if (key === "EntryDate") {
        let dateTime = moment(
          entryDate.format("DD/MM/YYYY") + " " + entryTime,
          "DD/MM/YYYY hh:mm A"
        ).format("MM/DD/YYYY hh:mm A");
        payload.EntryDate = dateTime;
        formCopy.EntryDate = dateTime;
      } else if (key === "ExitDate") {
        let dateTime = moment(
          expireDate.format("DD/MM/YYYY") + " " + expireTime,
          "DD/MM/YYYY hh:mm A"
        ).format("MM/DD/YYYY hh:mm A");
        payload.ExitDate = dateTime;
        formCopy.ExitDate = dateTime;
      } else if (key === "OrderDate") {
        let dateTime = moment(
          orderDate.format("DD/MM/YYYY") + " " + orderTime,
          "DD/MM/YYYY hh:mm A"
        ).format("MM/DD/YYYY hh:mm A");
        payload.OrderDate = dateTime;
        formCopy.OrderDate = dateTime;
      } else if (key === "DeliveryDate") {
        let dateTime = moment(
          deliveryDate.format("DD/MM/YYYY") + " " + deliveryTime,
          "DD/MM/YYYY hh:mm A"
        ).format("MM/DD/YYYY hh:mm A");
        payload.DeliveryDate = dateTime;
        formCopy.DeliveryDate = dateTime;
      } else if (key === "BillDate") {
        let dateTime = moment(
          billDate.format("DD/MM/YYYY") + " " + billTime,
          "DD/MM/YYYY hh:mm A"
        ).format("MM/DD/YYYY hh:mm A");
        payload.BillDate = dateTime;
        formCopy.BillDate = dateTime;
      } else if (key === "PickupTime") {
        let dateTime = moment(
          pickupDate.format("DD/MM/YYYY") + " " + pickupTime,
          "DD/MM/YYYY hh:mm A"
        ).format("MM/DD/YYYY hh:mm A");
        payload.PickupTime = dateTime;
        formCopy.PickupTime = dateTime;
      } else if (key === "DropTime") {
        let dateTime = moment(
          dropDate.format("DD/MM/YYYY") + " " + dropTime,
          "DD/MM/YYYY hh:mm A"
        ).format("MM/DD/YYYY hh:mm A");
        payload.DropTime = dateTime;
        formCopy.DropTime = dateTime;
      } else if (key === "BillPayDate") {
        let dateTime = moment(
          billPayDate.format("DD/MM/YYYY") + " " + billPayTime,
          "DD/MM/YYYY hh:mm A"
        ).format("MM/DD/YYYY hh:mm A");
        payload.BillPayDate = dateTime;
        formCopy.BillPayDate = dateTime;
      } else {
        payload[key] = selectedForm[key];
      }
    }
    let dateTime = moment(
      formDate.format("DD/MM/YYYY") + " " + formTime,
      "DD/MM/YYYY hh:mm A"
    ).format("MM/DD/YYYY hh:mm A");
    payload.RecieptDate = dateTime;
    formCopy.RecieptDate = dateTime;

    let formData = new FormData();
    if (logoFile) {
      formData.append("file", logoFile);
    }
    if (profileImageFile) {
      formData.append("file1", profileImageFile);
    }
    if (barcodeFile) {
      formData.append("file2", barcodeFile);
    }

    payload.FormDataImages = formData;

    if (checkError(formCopy)) {
      console.log("check passed");
      props.addUpdateBillReceipt(payload);
      setIsGenerated(true);
    }
  };

  const downloadImage = (href) => {
    fetch(href, {
      method: "GET",
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function(buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `generated-receipt.png`); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <Container minHeight="calc(100vh - 71px)">
      {selectedTemplate &&
        Object.keys(selectedTemplate).length > 0 &&
        selectedForm &&
        Object.keys(selectedForm).length > 0 && (
          <>
            {(props.isLoading || props.isReceiptLoading) && (
              <PreviewContainer>
                <div style={{ gridColumn: `1 / span 2` }}>
                  <ComponentCircleLoader padding="2vh 0" size={50} />
                  {props.isLoading && (
                    <PageTitle
                      fontSize="28px"
                      style={{
                        textShadow: `4px 4px 16px #635bff`,
                      }}
                    >
                      Generating Receipts...
                    </PageTitle>
                  )}
                </div>
              </PreviewContainer>
            )}
            <div style={{ marginRight: `auto` }}>
              <BackButtonComponent
                onClick={() => navigate("/user/dashboard")}
                subTitle="Back to All Receipts"
              />
            </div>

            {isGenerated && props.receiptID && props.receiptID !== "" ? (
              <div style={{ marginLeft: `auto` }}>
                <a
                  target="_blank"
                  href={`${process.env.REACT_APP_API_URL}/RecieptPdf/bill_reciept_${props.receiptID}.png`}
                  onClick={() =>
                    downloadImage(
                      `${process.env.REACT_APP_API_URL}/RecieptPdf/bill_reciept_${props.receiptID}.png`
                    )
                  }
                >
                  <ColoredButtonWithIcon
                    style={{ alignSelf: "flex-end" }}
                    margin="0 0 0 auto"
                    padding="0 15px"
                  >
                    <ButtonIcon src={downloadWhite} />
                    Download
                  </ColoredButtonWithIcon>
                </a>
              </div>
            ) : (
              <div />
            )}
            <Form>
              <ColoredTab form>CUSTOMIZE</ColoredTab>

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm["FontId"] !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>Font Style</InputLabel>
                    <Select
                      isSearchable
                      value={selectedForm.FontId}
                      options={fontsOption}
                      placeholder="Select Font..."
                      onChange={(e) => {
                        onTextInputChange("FontId", e);
                      }}
                      styles={FilterDropDownStyle({
                        height: "40px",
                        width: "180px",
                        error: errorList && errorList.FontId,
                      })}
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm["RecieptDate"] !== undefined && (
                  <CommonGrid justifyContent="start">
                    <InputWithLabelSection>
                      <InputLabel>Receipt Date</InputLabel>
                      <DatePicker
                        id="receiptDate"
                        date={formDate}
                        onDateChange={onFormDateChange}
                        dateDisplayFormat={"DD/MM/YYYY"}
                        placeholder={"Select date"}
                        allowPreviousDates
                      />
                    </InputWithLabelSection>
                    <InputWithLabelSection>
                      <InputLabel>Receipt Time</InputLabel>
                      <TimePicker
                        hour12Format
                        value={formTime}
                        onChange={(value) => onTimeCountHourChange(value)}
                      />
                    </InputWithLabelSection>
                  </CommonGrid>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.Business !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>Business</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Your business name here..."
                      value={selectedForm.Business}
                      error={errorList && errorList.Business}
                      onChange={(e) =>
                        onTextInputChange("Business", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.Address !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>Address</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Your address here..."
                      value={selectedForm.Address}
                      error={errorList && errorList.Address}
                      onChange={(e) =>
                        onTextInputChange("Address", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.PickupAddress !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>Pickup Address</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Your Pickup Address..."
                      value={selectedForm.PickupAddress}
                      error={errorList && errorList.PickupAddress}
                      onChange={(e) =>
                        onTextInputChange("PickupAddress", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.DropAddress !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>Drop Address</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Your Drop Address..."
                      value={selectedForm.DropAddress}
                      error={errorList && errorList.DropAddress}
                      onChange={(e) =>
                        onTextInputChange("DropAddress", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.PickupTime !== undefined && (
                  <CommonGrid justifyContent="start">
                    <InputWithLabelSection>
                      <InputLabel>
                        {selectedTemplate.PickupTime !== undefined &&
                        selectedTemplate.PickupTime !== ""
                          ? selectedTemplate.PickupTime
                          : `Pickup Date`}
                      </InputLabel>
                      <DatePicker
                        id="pickupDate"
                        date={pickupDate}
                        onDateChange={onPickupDateChange}
                        dateDisplayFormat={"DD/MM/YYYY"}
                        placeholder={"Select date"}
                        allowPreviousDates
                      />
                    </InputWithLabelSection>
                    <InputWithLabelSection>
                      <InputLabel
                        style={
                          selectedTemplate.PickupTime !== undefined &&
                          selectedTemplate.PickupTime !== ""
                            ? { opacity: `0` }
                            : { opacity: `1` }
                        }
                      >
                        Pickup Time
                      </InputLabel>
                      <TimePicker
                        hour12Format
                        value={pickupTime}
                        onChange={(value) => onPickupTimeChange(value)}
                      />
                    </InputWithLabelSection>
                  </CommonGrid>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.DropTime !== undefined && (
                  <CommonGrid justifyContent="start">
                    <InputWithLabelSection>
                      <InputLabel>
                        {selectedTemplate.DropTime !== undefined &&
                        selectedTemplate.DropTime !== ""
                          ? selectedTemplate.DropTime
                          : `Drop Date`}
                      </InputLabel>
                      <DatePicker
                        id="dropDate"
                        date={dropDate}
                        onDateChange={onDropDateChange}
                        dateDisplayFormat={"DD/MM/YYYY"}
                        placeholder={"Select date"}
                        allowPreviousDates
                      />
                    </InputWithLabelSection>
                    <InputWithLabelSection>
                      <InputLabel
                        style={
                          selectedTemplate.DropTime !== undefined &&
                          selectedTemplate.DropTime !== ""
                            ? { opacity: `0` }
                            : { opacity: `1` }
                        }
                      >
                        Drop Time
                      </InputLabel>
                      <TimePicker
                        hour12Format
                        value={dropTime}
                        onChange={(value) => onDropTimeChange(value)}
                      />
                    </InputWithLabelSection>
                  </CommonGrid>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.MobileNo !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>Mobile no.</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Mobile No here..."
                      value={selectedForm.MobileNo}
                      error={errorList && errorList.MobileNo}
                      onChange={(e) =>
                        onTextInputChange("MobileNo", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.LandlineNo !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>Landline no.</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Landline no here..."
                      value={selectedForm.LandlineNo}
                      error={errorList && errorList.LandlineNo}
                      onChange={(e) =>
                        onTextInputChange("LandlineNo", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.HelplineNo !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>Helpline no.</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Helpline no here..."
                      value={selectedForm.HelplineNo}
                      error={errorList && errorList.HelplineNo}
                      onChange={(e) =>
                        onTextInputChange("HelplineNo", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.RecieptNo !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>
                      {selectedTemplate.RecieptNo !== undefined &&
                      selectedTemplate.RecieptNo !== ""
                        ? selectedTemplate.RecieptNo
                        : `Reciept No`}
                    </InputLabel>
                    <InputField
                      type="text"
                      placeholder="Reciept No here..."
                      value={selectedForm.RecieptNo}
                      error={errorList && errorList.RecieptNo}
                      onChange={(e) =>
                        onTextInputChange("RecieptNo", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.TaxiKM !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>
                      {selectedTemplate.TaxiKM !== undefined &&
                      selectedTemplate.TaxiKM !== ""
                        ? selectedTemplate.TaxiKM
                        : `KM`}
                    </InputLabel>
                    <InputField
                      type="text"
                      placeholder={
                        selectedTemplate.TaxiKM !== undefined &&
                        selectedTemplate.TaxiKM !== ""
                          ? selectedTemplate.TaxiKM
                          : `Type KM...`
                      }
                      value={selectedForm.TaxiKM}
                      error={errorList && errorList.TaxiKM}
                      onChange={(e) =>
                        onTextInputChange("TaxiKM", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.CityState !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>City</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Type your city name..."
                      value={selectedForm.CityState}
                      error={errorList && errorList.CityState}
                      onChange={(e) =>
                        onTextInputChange("CityState", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.CurrencyId !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>Currency</InputLabel>
                    <Select
                      isSearchable
                      value={selectedForm.CurrencyId}
                      options={CurrencyList}
                      placeholder="Select Currency..."
                      onChange={(e) => {
                        onCurrencyChange(e);
                      }}
                      styles={FilterDropDownStyle({
                        height: "40px",
                        width: "180px",
                      })}
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.CarName !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>
                      {selectedTemplate.CarName !== undefined &&
                      selectedTemplate.CarName !== ""
                        ? selectedTemplate.CarName
                        : `Car`}
                    </InputLabel>
                    <InputField
                      type="text"
                      placeholder="Car Name..."
                      value={selectedForm.CarName}
                      error={errorList && errorList.CarName}
                      onChange={(e) =>
                        onTextInputChange("CarName", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.PaymentType !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>
                      {selectedTemplate.PaymentType !== undefined &&
                      selectedTemplate.PaymentType !== ""
                        ? selectedTemplate.PaymentType
                        : `Payment Type`}
                    </InputLabel>
                    <InputField
                      type="text"
                      placeholder="payment type..."
                      value={selectedForm.PaymentType}
                      error={errorList && errorList.PaymentType}
                      onChange={(e) =>
                        onTextInputChange("PaymentType", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.Trans !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>
                      {selectedTemplate.Trans !== undefined &&
                      selectedTemplate.Trans !== ""
                        ? selectedTemplate.Trans
                        : `Trans`}
                    </InputLabel>
                    <InputField
                      type="text"
                      placeholder="Type Trans..."
                      value={selectedForm.Trans}
                      error={errorList && errorList.Trans}
                      onChange={(e) =>
                        onTextInputChange("Trans", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.Mcc !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>MCC</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Type MCC..."
                      value={selectedForm.Mcc}
                      error={errorList && errorList.Mcc}
                      onChange={(e) => onTextInputChange("Mcc", e.target.value)}
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.SpaceNo !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>Space no.</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Type Space no..."
                      value={selectedForm.SpaceNo}
                      error={errorList && errorList.SpaceNo}
                      onChange={(e) =>
                        onTextInputChange("SpaceNo", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.TicketNo !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>Ticket no.</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Type Ticket no..."
                      value={selectedForm.TicketNo}
                      error={errorList && errorList.TicketNo}
                      onChange={(e) =>
                        onTextInputChange("TicketNo", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.Lane !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>Lane</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Type Lane..."
                      value={selectedForm.Lane}
                      error={errorList && errorList.Lane}
                      onChange={(e) =>
                        onTextInputChange("Lane", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.Clerk !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>Clerk</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Clerk..."
                      value={selectedForm.Clerk}
                      error={errorList && errorList.Clerk}
                      onChange={(e) =>
                        onTextInputChange("Clerk", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.Cashier !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>Cashier</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Cashier..."
                      value={selectedForm.Cashier}
                      error={errorList && errorList.Cashier}
                      onChange={(e) =>
                        onTextInputChange("Cashier", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.MRCH !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>
                      {selectedTemplate.MRCH !== undefined &&
                      selectedTemplate.MRCH !== ""
                        ? selectedTemplate.MRCH
                        : `MRCH`}
                    </InputLabel>
                    <InputField
                      type="text"
                      placeholder={
                        selectedTemplate.MRCH !== undefined &&
                        selectedTemplate.MRCH !== ""
                          ? selectedTemplate.MRCH
                          : `MRCH...`
                      }
                      value={selectedForm.MRCH}
                      error={errorList && errorList.MRCH}
                      onChange={(e) =>
                        onTextInputChange("MRCH", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.EPSSequence !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>EPS Sequence</InputLabel>
                    <InputField
                      type="text"
                      placeholder="EPS Sequence..."
                      value={selectedForm.EPSSequence}
                      error={errorList && errorList.EPSSequence}
                      onChange={(e) =>
                        onTextInputChange("EPSSequence", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.GSTNo !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>GST no.</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Type GST..."
                      value={selectedForm.GSTNo}
                      error={errorList && errorList.GSTNo}
                      onChange={(e) =>
                        onTextInputChange("GSTNo", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.OrderNo !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>Order no.</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Type Order no..."
                      value={selectedForm.OrderNo}
                      error={errorList && errorList.OrderNo}
                      onChange={(e) =>
                        onTextInputChange("OrderNo", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.BillNo !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>Bill no.</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Type Bill..."
                      value={selectedForm.BillNo}
                      error={errorList && errorList.BillNo}
                      onChange={(e) =>
                        onTextInputChange("BillNo", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.AtndId !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>Atnd Id</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Type Atnd Id..."
                      value={selectedForm.AtndId}
                      error={errorList && errorList.AtndId}
                      onChange={(e) =>
                        onTextInputChange("AtndId", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.FPId !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>FP ID</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Type FP ID..."
                      value={selectedForm.FPId}
                      error={errorList && errorList.FPId}
                      onChange={(e) =>
                        onTextInputChange("FPId", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}
              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.NozlNo !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>Nozl no.</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Type Nozl no..."
                      value={selectedForm.NozlNo}
                      error={errorList && errorList.NozlNo}
                      onChange={(e) =>
                        onTextInputChange("NozlNo", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}
              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.TinNo !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>Tin no.</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Type Tin no..."
                      value={selectedForm.TinNo}
                      error={errorList && errorList.TinNo}
                      onChange={(e) =>
                        onTextInputChange("TinNo", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}
              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.TRXId !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>TRX Id</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Type TRX Id..."
                      value={selectedForm.TRXId}
                      error={errorList && errorList.TRXId}
                      onChange={(e) =>
                        onTextInputChange("TRXId", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}
              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.CINNo !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>CIN no.</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Type CIN no..."
                      value={selectedForm.CINNo}
                      error={errorList && errorList.CINNo}
                      onChange={(e) =>
                        onTextInputChange("CINNo", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}
              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.Fuel !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>Fuel</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Type Fuel..."
                      value={selectedForm.Fuel}
                      error={errorList && errorList.Fuel}
                      onChange={(e) =>
                        onTextInputChange("Fuel", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}
              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.Density !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>Density</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Type Density..."
                      value={selectedForm.Density}
                      error={errorList && errorList.Density}
                      onChange={(e) =>
                        onTextInputChange("Density", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}
              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.VehicleNo !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>Vehicle no.</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Type Vehicle no..."
                      value={selectedForm.VehicleNo}
                      error={errorList && errorList.VehicleNo}
                      onChange={(e) =>
                        onTextInputChange("VehicleNo", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.VehicleType !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>
                      {selectedTemplate.VehicleType !== undefined &&
                      selectedTemplate.VehicleType !== ""
                        ? selectedTemplate.VehicleType
                        : `Vehicle type`}
                    </InputLabel>
                    <InputField
                      type="text"
                      placeholder={
                        selectedTemplate.VehicleType !== undefined &&
                        selectedTemplate.VehicleType !== ""
                          ? selectedTemplate.VehicleType
                          : `Vehicle type...`
                      }
                      value={selectedForm.VehicleType}
                      error={errorList && errorList.VehicleType}
                      onChange={(e) =>
                        onTextInputChange("VehicleType", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.Auth !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>Auth</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Type Auth..."
                      value={selectedForm.Auth}
                      error={errorList && errorList.Auth}
                      onChange={(e) =>
                        onTextInputChange("Auth", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.ApprCode !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>Appr Code</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Type Appr Code..."
                      value={selectedForm.ApprCode}
                      error={errorList && errorList.ApprCode}
                      onChange={(e) =>
                        onTextInputChange("ApprCode", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.TCNo !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>TC no.</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Type TC..."
                      value={selectedForm.TCNo}
                      error={errorList && errorList.TCNo}
                      onChange={(e) =>
                        onTextInputChange("TCNo", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.AccountNo !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>Account no.</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Type Account no..."
                      value={selectedForm.AccountNo}
                      error={errorList && errorList.AccountNo}
                      onChange={(e) =>
                        onTextInputChange("AccountNo", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.CardEntry !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>Card Entry</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Card Entry..."
                      value={selectedForm.CardEntry}
                      error={errorList && errorList.CardEntry}
                      onChange={(e) =>
                        onTextInputChange("CardEntry", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.TransType !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>Trans Type</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Trans Type..."
                      value={selectedForm.TransType}
                      error={errorList && errorList.TransType}
                      onChange={(e) =>
                        onTextInputChange("TransType", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.CheckNo !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>Check</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Check no..."
                      value={selectedForm.CheckNo}
                      error={errorList && errorList.CheckNo}
                      onChange={(e) =>
                        onTextInputChange("CheckNo", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.CheckId !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>Check ID</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Type Check ID..."
                      value={selectedForm.CheckId}
                      error={errorList && errorList.CheckId}
                      onChange={(e) =>
                        onTextInputChange("CheckId", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.Tip !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>Tip</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Type Tip..."
                      value={selectedForm.Tip}
                      error={errorList && errorList.Tip}
                      onChange={(e) => onTextInputChange("Tip", e.target.value)}
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.STNo !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>ST no.</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Type ST no..."
                      value={selectedForm.STNo}
                      error={errorList && errorList.STNo}
                      onChange={(e) =>
                        onTextInputChange("STNo", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}
              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.OPNo !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>OP no.</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Type OP no..."
                      value={selectedForm.OPNo}
                      error={errorList && errorList.OPNo}
                      onChange={(e) =>
                        onTextInputChange("OPNo", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}
              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.TENo !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>TE no.</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Type TE no..."
                      value={selectedForm.TENo}
                      error={errorList && errorList.TENo}
                      onChange={(e) =>
                        onTextInputChange("TENo", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}
              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.REFNo !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>REF no.</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Type REF no..."
                      value={selectedForm.REFNo}
                      error={errorList && errorList.REFNo}
                      onChange={(e) =>
                        onTextInputChange("REFNo", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}
              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.Validation !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>Validation</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Type Validation..."
                      value={selectedForm.Validation}
                      error={errorList && errorList.Validation}
                      onChange={(e) =>
                        onTextInputChange("Validation", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}
              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.PaymentService !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>
                      {selectedTemplate.PaymentService !== undefined &&
                      selectedTemplate.PaymentService !== ""
                        ? selectedTemplate.PaymentService
                        : `Payment Service`}
                    </InputLabel>
                    <InputField
                      type="text"
                      placeholder={
                        selectedTemplate.PaymentService !== undefined &&
                        selectedTemplate.PaymentService !== ""
                          ? selectedTemplate.PaymentService
                          : `Type Payment Service...`
                      }
                      value={selectedForm.PaymentService}
                      error={errorList && errorList.PaymentService}
                      onChange={(e) =>
                        onTextInputChange("PaymentService", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.FaxNo !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>Fax no.</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Fax no..."
                      value={selectedForm.FaxNo}
                      error={errorList && errorList.FaxNo}
                      onChange={(e) =>
                        onTextInputChange("FaxNo", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.BillTo !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>Bill To</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Bill to..."
                      value={selectedForm.BillTo}
                      error={errorList && errorList.BillTo}
                      onChange={(e) =>
                        onTextInputChange("BillTo", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}
              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.ShipTo !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>Ship To</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Ship to..."
                      value={selectedForm.ShipTo}
                      error={errorList && errorList.ShipTo}
                      onChange={(e) =>
                        onTextInputChange("ShipTo", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.BillToEmailId !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>Email ID</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Email ID..."
                      value={selectedForm.BillToEmailId}
                      error={errorList && errorList.BillToEmailId}
                      onChange={(e) =>
                        onTextInputChange("BillToEmailId", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.RelationShipNo !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>
                      {selectedTemplate.RelationShipNo !== undefined &&
                      selectedTemplate.RelationShipNo !== ""
                        ? selectedTemplate.RelationShipNo
                        : `Relation no.`}
                    </InputLabel>
                    <InputField
                      type="text"
                      placeholder={
                        selectedTemplate.RelationShipNo !== undefined &&
                        selectedTemplate.RelationShipNo !== ""
                          ? selectedTemplate.RelationShipNo
                          : `Relation no...`
                      }
                      value={selectedForm.RelationShipNo}
                      error={errorList && errorList.RelationShipNo}
                      onChange={(e) =>
                        onTextInputChange("RelationShipNo", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.LandMark !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>LandMark</InputLabel>
                    <InputField
                      type="text"
                      placeholder="LandMark..."
                      value={selectedForm.LandMark}
                      error={errorList && errorList.LandMark}
                      onChange={(e) =>
                        onTextInputChange("LandMark", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.BillPeriod !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>Bill Period</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Bill Period..."
                      value={selectedForm.BillPeriod}
                      error={errorList && errorList.BillPeriod}
                      onChange={(e) =>
                        onTextInputChange("BillPeriod", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.NetworkId !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>Network ID</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Type Network ID..."
                      value={selectedForm.NetworkId}
                      error={errorList && errorList.NetworkId}
                      onChange={(e) =>
                        onTextInputChange("NetworkId", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.TerminalId !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>Terminal ID</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Type Terminal ID..."
                      value={selectedForm.TerminalId}
                      error={errorList && errorList.TerminalId}
                      onChange={(e) =>
                        onTextInputChange("TerminalId", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.RegNo !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>Reg no.</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Type Reg no..."
                      value={selectedForm.RegNo}
                      error={errorList && errorList.RegNo}
                      onChange={(e) =>
                        onTextInputChange("RegNo", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}
              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.SNNo !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>
                      {selectedTemplate.SNNo !== undefined &&
                      selectedTemplate.SNNo !== ""
                        ? selectedTemplate.SNNo
                        : `SN no.`}
                    </InputLabel>
                    <InputField
                      type="text"
                      placeholder={
                        selectedTemplate.SNNo !== undefined &&
                        selectedTemplate.SNNo !== ""
                          ? selectedTemplate.SNNo
                          : `Type SN no...`
                      }
                      value={selectedForm.SNNo}
                      error={errorList && errorList.SNNo}
                      onChange={(e) =>
                        onTextInputChange("SNNo", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.Lot_Location !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>
                      {selectedTemplate.Lot_Location !== undefined &&
                      selectedTemplate.Lot_Location !== ""
                        ? selectedTemplate.Lot_Location
                        : `Lot`}
                    </InputLabel>
                    <InputField
                      type="text"
                      placeholder="Type Lot..."
                      value={selectedForm.Lot_Location}
                      error={errorList && errorList.Lot_Location}
                      onChange={(e) =>
                        onTextInputChange("Lot_Location", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}
              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.PayedAt !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>Payed At</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Type payed at..."
                      value={selectedForm.PayedAt}
                      error={errorList && errorList.PayedAt}
                      onChange={(e) =>
                        onTextInputChange("PayedAt", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}
              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.MachName !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>Mach Name</InputLabel>
                    <InputField
                      type="text"
                      placeholder="mach name..."
                      value={selectedForm.MachName}
                      error={errorList && errorList.MachName}
                      onChange={(e) =>
                        onTextInputChange("MachName", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}
              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.TRNNo !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>TRN no.</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Type TRN no..."
                      value={selectedForm.TRNNo}
                      error={errorList && errorList.TRNNo}
                      onChange={(e) =>
                        onTextInputChange("TRNNo", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}
              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.CSHRNo !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>CSHR no.</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Type CSHR no..."
                      value={selectedForm.CSHRNo}
                      error={errorList && errorList.CSHRNo}
                      onChange={(e) =>
                        onTextInputChange("CSHRNo", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}
              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.STRNo !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>STR no.</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Type STR no..."
                      value={selectedForm.STRNo}
                      error={errorList && errorList.STRNo}
                      onChange={(e) =>
                        onTextInputChange("STRNo", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}
              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.HelpedBy !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>Helped By</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Type Helped By..."
                      value={selectedForm.HelpedBy}
                      error={errorList && errorList.HelpedBy}
                      onChange={(e) =>
                        onTextInputChange("HelpedBy", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}
              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.AID !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>
                      {selectedTemplate.AID !== undefined &&
                      selectedTemplate.AID !== ""
                        ? selectedTemplate.AID
                        : `AID`}
                    </InputLabel>
                    <InputField
                      type="text"
                      placeholder="Type AID..."
                      value={selectedForm.AID}
                      error={errorList && errorList.AID}
                      onChange={(e) => onTextInputChange("AID", e.target.value)}
                    />
                  </InputWithLabelSection>
                )}
              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.StoreNo !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>
                      {selectedTemplate.StoreNo !== undefined &&
                      selectedTemplate.StoreNo !== ""
                        ? selectedTemplate.StoreNo
                        : `Store no.`}
                    </InputLabel>
                    <InputField
                      type="text"
                      placeholder="Type Store no..."
                      value={selectedForm.StoreNo}
                      error={errorList && errorList.StoreNo}
                      onChange={(e) =>
                        onTextInputChange("StoreNo", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}
              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.PharmacyNo !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>
                      {selectedTemplate.PharmacyNo !== undefined &&
                      selectedTemplate.PharmacyNo !== ""
                        ? selectedTemplate.PharmacyNo
                        : `Pharmacy no.`}
                    </InputLabel>
                    <InputField
                      type="text"
                      placeholder="Type Pharmacy no..."
                      value={selectedForm.PharmacyNo}
                      error={errorList && errorList.PharmacyNo}
                      onChange={(e) =>
                        onTextInputChange("PharmacyNo", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}
              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.CVM !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>CVM</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Type CVM..."
                      value={selectedForm.CVM}
                      error={errorList && errorList.CVM}
                      onChange={(e) => onTextInputChange("CVM", e.target.value)}
                    />
                  </InputWithLabelSection>
                )}
              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.TCId !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>TC ID</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Type TC ID..."
                      value={selectedForm.TCId}
                      error={errorList && errorList.TCId}
                      onChange={(e) =>
                        onTextInputChange("TCId", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}
              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.WebSiteName !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>Website Name</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Type Website Name..."
                      value={selectedForm.WebSiteName}
                      error={errorList && errorList.WebSiteName}
                      onChange={(e) =>
                        onTextInputChange("WebSiteName", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.DocNo !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>Doc no.</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Type Doc no...."
                      value={selectedForm.DocNo}
                      error={errorList && errorList.DocNo}
                      onChange={(e) =>
                        onTextInputChange("DocNo", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.Pump !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>Pump</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Type Pump..."
                      value={selectedForm.Pump}
                      error={errorList && errorList.Pump}
                      onChange={(e) =>
                        onTextInputChange("Pump", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.PompaSalans !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>Pompa Salans</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Type Pompa Salans..."
                      value={selectedForm.PompaSalans}
                      error={errorList && errorList.PompaSalans}
                      onChange={(e) =>
                        onTextInputChange("PompaSalans", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.NomarNota !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>Nomar Nota</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Type Nomar Nota..."
                      value={selectedForm.NomarNota}
                      error={errorList && errorList.NomarNota}
                      onChange={(e) =>
                        onTextInputChange("NomarNota", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.JenisBBM !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>Jenis BBM</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Type Jenis BBM..."
                      value={selectedForm.JenisBBM}
                      error={errorList && errorList.JenisBBM}
                      onChange={(e) =>
                        onTextInputChange("JenisBBM", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.CustomerNo !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>Customer no.</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Customer no..."
                      value={selectedForm.CustomerNo}
                      error={errorList && errorList.CustomerNo}
                      onChange={(e) =>
                        onTextInputChange("CustomerNo", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.CustomerPONo !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>Customer PO no.</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Customer PO no..."
                      value={selectedForm.CustomerPONo}
                      error={errorList && errorList.CustomerPONo}
                      onChange={(e) =>
                        onTextInputChange("CustomerPONo", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.StationNo !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>Station no.</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Type Station no..."
                      value={selectedForm.StationNo}
                      error={errorList && errorList.StationNo}
                      onChange={(e) =>
                        onTextInputChange("StationNo", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.SecurityDeposit !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>
                      {selectedTemplate.SecurityDeposit !== undefined &&
                      selectedTemplate.SecurityDeposit !== ""
                        ? selectedTemplate.SecurityDeposit
                        : `Security Deposit`}
                    </InputLabel>
                    <InputField
                      type="number"
                      placeholder="Security Deposit..."
                      value={selectedForm.SecurityDeposit}
                      error={errorList && errorList.SecurityDeposit}
                      onChange={(e) =>
                        onTextInputChange("SecurityDeposit", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.PreviousBalance !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>
                      {selectedTemplate.PreviousBalance !== undefined &&
                      selectedTemplate.PreviousBalance !== ""
                        ? selectedTemplate.PreviousBalance
                        : `Previous Balance`}
                    </InputLabel>
                    <InputField
                      type="number"
                      placeholder="Previous Balance..."
                      value={selectedForm.PreviousBalance}
                      error={errorList && errorList.PreviousBalance}
                      onChange={(e) =>
                        onTextInputChange("PreviousBalance", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.Payments !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>
                      {selectedTemplate.Payments !== undefined &&
                      selectedTemplate.Payments !== ""
                        ? selectedTemplate.Payments
                        : `Card Tend`}
                    </InputLabel>
                    <InputField
                      type="number"
                      placeholder="Payments..."
                      value={selectedForm.Payments}
                      error={errorList && errorList.Payments}
                      onChange={(e) =>
                        onTextInputChange("Payments", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.PayAmount !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>
                      {selectedTemplate.PayAmount !== undefined &&
                      selectedTemplate.PayAmount !== ""
                        ? selectedTemplate.PayAmount
                        : `Pay Amount`}
                    </InputLabel>
                    <InputField
                      type="number"
                      placeholder="Type Pay Amount..."
                      value={selectedForm.PayAmount}
                      error={errorList && errorList.PayAmount}
                      onChange={(e) =>
                        onTextInputChange("PayAmount", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.MonthlyRentals !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>
                      {selectedTemplate.MonthlyRentals !== undefined &&
                      selectedTemplate.MonthlyRentals !== ""
                        ? selectedTemplate.MonthlyRentals
                        : `Monthly Rentals`}
                    </InputLabel>
                    <InputField
                      type="number"
                      placeholder="Monthly Rentals..."
                      value={selectedForm.MonthlyRentals}
                      error={errorList && errorList.MonthlyRentals}
                      onChange={(e) =>
                        onTextInputChange("MonthlyRentals", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.UsageCharges !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>
                      {selectedTemplate.UsageCharges !== undefined &&
                      selectedTemplate.UsageCharges !== ""
                        ? selectedTemplate.UsageCharges
                        : `Usage Charges`}
                    </InputLabel>
                    <InputField
                      type="number"
                      placeholder="Usage Charges..."
                      value={selectedForm.UsageCharges}
                      error={errorList && errorList.UsageCharges}
                      onChange={(e) =>
                        onTextInputChange("UsageCharges", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.OneTimeCharges !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>
                      {selectedTemplate.OneTimeCharges !== undefined &&
                      selectedTemplate.OneTimeCharges !== ""
                        ? selectedTemplate.OneTimeCharges
                        : `One Time Charges`}
                    </InputLabel>
                    <InputField
                      type="number"
                      placeholder="One Time Charges..."
                      value={selectedForm.OneTimeCharges}
                      error={errorList && errorList.OneTimeCharges}
                      onChange={(e) =>
                        onTextInputChange("OneTimeCharges", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.BaseFare !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>
                      {selectedTemplate.BaseFare !== undefined &&
                      selectedTemplate.BaseFare !== ""
                        ? selectedTemplate.BaseFare
                        : `Base Fare`}
                    </InputLabel>
                    <InputField
                      type="number"
                      placeholder={
                        selectedTemplate.BaseFare !== undefined &&
                        selectedTemplate.BaseFare !== ""
                          ? selectedTemplate.BaseFare
                          : `Type Base Fare...`
                      }
                      value={selectedForm.BaseFare}
                      error={errorList && errorList.BaseFare}
                      onChange={(e) =>
                        onTextInputChange("BaseFare", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.SubCharges !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>
                      {selectedTemplate.SubCharges !== undefined &&
                      selectedTemplate.SubCharges !== ""
                        ? selectedTemplate.SubCharges
                        : `Tolle, Sub Charges and Fees`}
                    </InputLabel>
                    <InputField
                      type="number"
                      placeholder={
                        selectedTemplate.SubCharges !== undefined &&
                        selectedTemplate.SubCharges !== ""
                          ? selectedTemplate.SubCharges
                          : `Sub-Charges...`
                      }
                      value={selectedForm.SubCharges}
                      error={errorList && errorList.SubCharges}
                      onChange={(e) =>
                        onTextInputChange("SubCharges", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.DistanceFare !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>
                      {selectedTemplate.DistanceFare !== undefined &&
                      selectedTemplate.DistanceFare !== ""
                        ? selectedTemplate.DistanceFare
                        : `Distance Fare`}
                    </InputLabel>
                    <InputField
                      type="number"
                      placeholder={
                        selectedTemplate.DistanceFare !== undefined &&
                        selectedTemplate.DistanceFare !== ""
                          ? selectedTemplate.DistanceFare
                          : `Distance Fare...`
                      }
                      value={selectedForm.DistanceFare}
                      error={errorList && errorList.DistanceFare}
                      onChange={(e) =>
                        onTextInputChange("DistanceFare", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.TimeFare !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>
                      {selectedTemplate.TimeFare !== undefined &&
                      selectedTemplate.TimeFare !== ""
                        ? selectedTemplate.TimeFare
                        : `Time Fare`}
                    </InputLabel>
                    <InputField
                      type="number"
                      placeholder={
                        selectedTemplate.TimeFare !== undefined &&
                        selectedTemplate.TimeFare !== ""
                          ? selectedTemplate.TimeFare
                          : `Time Fare...`
                      }
                      value={selectedForm.TimeFare}
                      error={errorList && errorList.TimeFare}
                      onChange={(e) =>
                        onTextInputChange("TimeFare", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.SafeRideFee !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>
                      {selectedTemplate.SafeRideFee !== undefined &&
                      selectedTemplate.SafeRideFee !== ""
                        ? selectedTemplate.SafeRideFee
                        : `Safe Ride Fee`}
                    </InputLabel>
                    <InputField
                      type="number"
                      placeholder={
                        selectedTemplate.SafeRideFee !== undefined &&
                        selectedTemplate.SafeRideFee !== ""
                          ? selectedTemplate.SafeRideFee
                          : `Safe Ride Fee...`
                      }
                      value={selectedForm.SafeRideFee}
                      error={errorList && errorList.SafeRideFee}
                      onChange={(e) =>
                        onTextInputChange("SafeRideFee", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.Rating !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>
                      {selectedTemplate.Rating !== undefined &&
                      selectedTemplate.Rating !== ""
                        ? selectedTemplate.Rating
                        : `Rating (1-5)`}
                    </InputLabel>
                    <InputField
                      type="number"
                      placeholder={
                        selectedTemplate.Rating !== undefined &&
                        selectedTemplate.Rating !== ""
                          ? selectedTemplate.Rating
                          : `Rating...`
                      }
                      value={selectedForm.Rating}
                      error={errorList && errorList.Rating}
                      onChange={(e) =>
                        onTextInputChange("Rating", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.LiftLineDiscountPrice !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>
                      {selectedTemplate.LiftLineDiscountPrice !== undefined &&
                      selectedTemplate.LiftLineDiscountPrice !== ""
                        ? selectedTemplate.LiftLineDiscountPrice
                        : `Lift Line Discount`}
                    </InputLabel>
                    <InputField
                      type="number"
                      placeholder={
                        selectedTemplate.LiftLineDiscountPrice !== undefined &&
                        selectedTemplate.LiftLineDiscountPrice !== ""
                          ? selectedTemplate.LiftLineDiscountPrice
                          : `Lift Line Discount...`
                      }
                      value={selectedForm.LiftLineDiscountPrice}
                      error={errorList && errorList.LiftLineDiscountPrice}
                      onChange={(e) =>
                        onTextInputChange(
                          "LiftLineDiscountPrice",
                          e.target.value
                        )
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.PromotionalPricing !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>
                      {selectedTemplate.PromotionalPricing !== undefined &&
                      selectedTemplate.PromotionalPricing !== ""
                        ? selectedTemplate.PromotionalPricing
                        : `Promotional Pricing`}
                    </InputLabel>
                    <InputField
                      type="number"
                      placeholder={
                        selectedTemplate.PromotionalPricing !== undefined &&
                        selectedTemplate.PromotionalPricing !== ""
                          ? selectedTemplate.PromotionalPricing
                          : `Promotional Pricing...`
                      }
                      value={selectedForm.PromotionalPricing}
                      error={errorList && errorList.PromotionalPricing}
                      onChange={(e) =>
                        onTextInputChange("PromotionalPricing", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.RideBy !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>
                      {selectedTemplate.RideBy !== undefined &&
                      selectedTemplate.RideBy !== ""
                        ? selectedTemplate.RideBy
                        : `Ride By`}
                    </InputLabel>
                    <InputField
                      type="text"
                      placeholder={
                        selectedTemplate.RideBy !== undefined &&
                        selectedTemplate.RideBy !== ""
                          ? selectedTemplate.RideBy
                          : `Ride By...`
                      }
                      value={selectedForm.RideBy}
                      error={errorList && errorList.RideBy}
                      onChange={(e) =>
                        onTextInputChange("RideBy", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.LicensePlate !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>License Plate</InputLabel>
                    <InputField
                      type="text"
                      placeholder="License Plate..."
                      value={selectedForm.LicensePlate}
                      error={errorList && errorList.LicensePlate}
                      onChange={(e) =>
                        onTextInputChange("LicensePlate", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.TollPlaza !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>
                      {selectedTemplate.TollPlaza !== undefined &&
                      selectedTemplate.TollPlaza !== ""
                        ? selectedTemplate.TollPlaza
                        : `Toll Plaza`}
                    </InputLabel>
                    <InputField
                      type="text"
                      placeholder={
                        selectedTemplate.TollPlaza !== undefined &&
                        selectedTemplate.TollPlaza !== ""
                          ? selectedTemplate.TollPlaza
                          : `Toll Plaza...`
                      }
                      value={selectedForm.TollPlaza}
                      error={errorList && errorList.TollPlaza}
                      onChange={(e) =>
                        onTextInputChange("TollPlaza", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.Operator !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>
                      {selectedTemplate.Operator !== undefined &&
                      selectedTemplate.Operator !== ""
                        ? selectedTemplate.Operator
                        : `Operator`}
                    </InputLabel>
                    <InputField
                      type="text"
                      placeholder={
                        selectedTemplate.Operator !== undefined &&
                        selectedTemplate.Operator !== ""
                          ? selectedTemplate.Operator
                          : `Operator...`
                      }
                      value={selectedForm.Operator}
                      error={errorList && errorList.Operator}
                      onChange={(e) =>
                        onTextInputChange("Operator", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.Section !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>
                      {selectedTemplate.Section !== undefined &&
                      selectedTemplate.Section !== ""
                        ? selectedTemplate.Section
                        : `Section`}
                    </InputLabel>
                    <InputField
                      type="text"
                      placeholder={
                        selectedTemplate.Section !== undefined &&
                        selectedTemplate.Section !== ""
                          ? selectedTemplate.Section
                          : `Section...`
                      }
                      value={selectedForm.Section}
                      error={errorList && errorList.Section}
                      onChange={(e) =>
                        onTextInputChange("Section", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.CSH !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>
                      {selectedTemplate.CSH !== undefined &&
                      selectedTemplate.CSH !== ""
                        ? selectedTemplate.CSH
                        : `CSH`}
                    </InputLabel>
                    <InputField
                      type="text"
                      placeholder={
                        selectedTemplate.CSH !== undefined &&
                        selectedTemplate.CSH !== ""
                          ? selectedTemplate.CSH
                          : `CSH...`
                      }
                      value={selectedForm.CSH}
                      error={errorList && errorList.CSH}
                      onChange={(e) => onTextInputChange("CSH", e.target.value)}
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.VehicleStanderedWeight !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>
                      {selectedTemplate.VehicleStanderedWeight !== undefined &&
                      selectedTemplate.VehicleStanderedWeight !== ""
                        ? selectedTemplate.VehicleStanderedWeight
                        : `Vehicle Standard Weight`}
                    </InputLabel>
                    <InputField
                      type="number"
                      placeholder={
                        selectedTemplate.VehicleStanderedWeight !== undefined &&
                        selectedTemplate.VehicleStanderedWeight !== ""
                          ? selectedTemplate.VehicleStanderedWeight
                          : `Vehicle Standard Weight...`
                      }
                      value={selectedForm.VehicleStanderedWeight}
                      error={errorList && errorList.VehicleStanderedWeight}
                      onChange={(e) =>
                        onTextInputChange(
                          "VehicleStanderedWeight",
                          e.target.value
                        )
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.VehicleActualWeight !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>
                      {selectedTemplate.VehicleActualWeight !== undefined &&
                      selectedTemplate.VehicleActualWeight !== ""
                        ? selectedTemplate.VehicleActualWeight
                        : `Vehicle Actual Weight`}
                    </InputLabel>
                    <InputField
                      type="number"
                      placeholder={
                        selectedTemplate.VehicleActualWeight !== undefined &&
                        selectedTemplate.VehicleActualWeight !== ""
                          ? selectedTemplate.VehicleActualWeight
                          : `Vehicle Actual Weight...`
                      }
                      value={selectedForm.VehicleActualWeight}
                      error={errorList && errorList.VehicleActualWeight}
                      onChange={(e) =>
                        onTextInputChange("VehicleActualWeight", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.OverloadedVehicleFee !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>
                      {selectedTemplate.OverloadedVehicleFee !== undefined &&
                      selectedTemplate.OverloadedVehicleFee !== ""
                        ? selectedTemplate.OverloadedVehicleFee
                        : `Overloaded Vehicle Fee`}
                    </InputLabel>
                    <InputField
                      type="number"
                      placeholder={
                        selectedTemplate.OverloadedVehicleFee !== undefined &&
                        selectedTemplate.OverloadedVehicleFee !== ""
                          ? selectedTemplate.OverloadedVehicleFee
                          : `Overloaded Vehicle Fee...`
                      }
                      value={selectedForm.OverloadedVehicleFee}
                      error={errorList && errorList.OverloadedVehicleFee}
                      onChange={(e) =>
                        onTextInputChange(
                          "OverloadedVehicleFee",
                          e.target.value
                        )
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.Shift !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>
                      {selectedTemplate.Shift !== undefined &&
                      selectedTemplate.Shift !== ""
                        ? selectedTemplate.Shift
                        : `Shift`}
                    </InputLabel>
                    <InputField
                      type="text"
                      placeholder={
                        selectedTemplate.Shift !== undefined &&
                        selectedTemplate.Shift !== ""
                          ? selectedTemplate.Shift
                          : `Shift...`
                      }
                      value={selectedForm.Shift}
                      error={errorList && errorList.Shift}
                      onChange={(e) =>
                        onTextInputChange("Shift", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.Journey !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>
                      {selectedTemplate.Journey !== undefined &&
                      selectedTemplate.Journey !== ""
                        ? selectedTemplate.Journey
                        : `Journey`}
                    </InputLabel>
                    <InputField
                      type="text"
                      placeholder={
                        selectedTemplate.Journey !== undefined &&
                        selectedTemplate.Journey !== ""
                          ? selectedTemplate.Journey
                          : `Journey...`
                      }
                      value={selectedForm.Journey}
                      error={errorList && errorList.Journey}
                      onChange={(e) =>
                        onTextInputChange("Journey", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.Taxes !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>
                      {selectedTemplate.Taxes !== undefined &&
                      selectedTemplate.Taxes !== ""
                        ? selectedTemplate.Taxes
                        : `Taxes`}
                    </InputLabel>
                    <InputField
                      type="number"
                      placeholder="Type Tax..."
                      value={selectedForm.Taxes}
                      error={errorList && errorList.Taxes}
                      onChange={(e) =>
                        onTextInputChange("Taxes", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.TaxiCallNo !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>
                      {selectedTemplate.TaxiCallNo !== undefined &&
                      selectedTemplate.TaxiCallNo !== ""
                        ? selectedTemplate.TaxiCallNo
                        : `Taxi Call No`}
                    </InputLabel>
                    <InputField
                      type="number"
                      placeholder="Taxi Call No..."
                      value={selectedForm.TaxiCallNo}
                      error={errorList && errorList.TaxiCallNo}
                      onChange={(e) =>
                        onTextInputChange("TaxiCallNo", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.SoldBy !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>
                      {selectedTemplate.SoldBy !== undefined &&
                      selectedTemplate.SoldBy !== ""
                        ? selectedTemplate.SoldBy
                        : `Server`}
                    </InputLabel>
                    <InputField
                      type="text"
                      placeholder={
                        selectedTemplate.SoldBy !== undefined &&
                        selectedTemplate.SoldBy !== ""
                          ? selectedTemplate.SoldBy
                          : `Server...`
                      }
                      value={selectedForm.SoldBy}
                      error={errorList && errorList.SoldBy}
                      onChange={(e) =>
                        onTextInputChange("SoldBy", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.FixedlineNo !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>Fixed line no.</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Fixed line no..."
                      value={selectedForm.FixedlineNo}
                      error={errorList && errorList.FixedlineNo}
                      onChange={(e) =>
                        onTextInputChange("FixedlineNo", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.BroadbandId !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>Broadband ID</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Broadband ID..."
                      value={selectedForm.BroadbandId}
                      error={errorList && errorList.BroadbandId}
                      onChange={(e) =>
                        onTextInputChange("BroadbandId", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.AlternateMobileNo !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>Alternate Mobile no.</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Alternate Mobile no..."
                      value={selectedForm.AlternateMobileNo}
                      error={errorList && errorList.AlternateMobileNo}
                      onChange={(e) =>
                        onTextInputChange("AlternateMobileNo", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.BillGenerateBy !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>
                      {selectedTemplate.BillGenerateBy !== undefined &&
                      selectedTemplate.BillGenerateBy !== ""
                        ? selectedTemplate.BillGenerateBy
                        : `Manager Name`}
                    </InputLabel>
                    <InputField
                      type="text"
                      placeholder="Type..."
                      value={selectedForm.BillGenerateBy}
                      error={errorList && errorList.BillGenerateBy}
                      onChange={(e) =>
                        onTextInputChange("BillGenerateBy", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.Host !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>Host</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Type Host..."
                      value={selectedForm.Host}
                      error={errorList && errorList.Host}
                      onChange={(e) =>
                        onTextInputChange("Host", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.EntryDate !== undefined && (
                  <CommonGrid justifyContent="start">
                    <InputWithLabelSection>
                      <InputLabel>
                        {selectedTemplate.EntryDate !== undefined &&
                        selectedTemplate.EntryDate !== ""
                          ? selectedTemplate.EntryDate
                          : `Entry Date`}
                      </InputLabel>
                      <DatePicker
                        id="entryDate"
                        date={entryDate}
                        onDateChange={onEntryDateChange}
                        dateDisplayFormat={"DD/MM/YYYY"}
                        placeholder={"Select date"}
                        allowPreviousDates
                      />
                    </InputWithLabelSection>
                    <InputWithLabelSection>
                      <InputLabel
                        style={
                          selectedTemplate.EntryDate !== undefined &&
                          selectedTemplate.EntryDate !== ""
                            ? { opacity: `0` }
                            : { opacity: `1` }
                        }
                      >
                        Entry Time
                      </InputLabel>
                      <TimePicker
                        hour12Format
                        value={entryTime}
                        onChange={(value) => onEntryTimeChange(value)}
                      />
                    </InputWithLabelSection>
                  </CommonGrid>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.ExitDate !== undefined && (
                  <CommonGrid justifyContent="start">
                    <InputWithLabelSection>
                      <InputLabel>
                        {selectedTemplate.ExitDate !== undefined &&
                        selectedTemplate.ExitDate !== ""
                          ? selectedTemplate.ExitDate
                          : `Expire Date`}
                      </InputLabel>
                      <DatePicker
                        id="expireDate"
                        date={expireDate}
                        onDateChange={onExpireDateChange}
                        dateDisplayFormat={"DD/MM/YYYY"}
                        placeholder={"Select date"}
                        allowPreviousDates
                      />
                    </InputWithLabelSection>
                    <InputWithLabelSection>
                      <InputLabel
                        style={
                          selectedTemplate.ExitDate !== undefined &&
                          selectedTemplate.ExitDate !== ""
                            ? { opacity: `0` }
                            : { opacity: `1` }
                        }
                      >
                        Expire Time
                      </InputLabel>
                      <TimePicker
                        hour12Format
                        value={expireTime}
                        onChange={(value) => onExpireTimeChange(value)}
                      />
                    </InputWithLabelSection>
                  </CommonGrid>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.OrderDate !== undefined && (
                  <CommonGrid justifyContent="start">
                    <InputWithLabelSection>
                      <InputLabel>
                        {selectedTemplate.OrderDate !== undefined &&
                        selectedTemplate.OrderDate !== ""
                          ? selectedTemplate.OrderDate
                          : `Order Date`}
                      </InputLabel>
                      <DatePicker
                        id="orderDate"
                        date={orderDate}
                        onDateChange={onOrderDateChange}
                        dateDisplayFormat={"DD/MM/YYYY"}
                        placeholder={"Select date"}
                        allowPreviousDates
                      />
                    </InputWithLabelSection>
                    <InputWithLabelSection>
                      <InputLabel
                        style={
                          selectedTemplate.OrderDate !== undefined &&
                          selectedTemplate.OrderDate !== ""
                            ? { opacity: `0` }
                            : { opacity: `1` }
                        }
                      >
                        Order Time
                      </InputLabel>
                      <TimePicker
                        hour12Format
                        value={orderTime}
                        onChange={(value) => onOrderTimeChange(value)}
                      />
                    </InputWithLabelSection>
                  </CommonGrid>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.DeliveryDate !== undefined && (
                  <CommonGrid justifyContent="start">
                    <InputWithLabelSection>
                      <InputLabel>
                        {selectedTemplate.DeliveryDate !== undefined &&
                        selectedTemplate.DeliveryDate !== ""
                          ? selectedTemplate.DeliveryDate
                          : `Delivery Date`}
                      </InputLabel>
                      <DatePicker
                        id="deliveryDate"
                        date={deliveryDate}
                        onDateChange={onDeliveryDateChange}
                        dateDisplayFormat={"DD/MM/YYYY"}
                        placeholder={"Select date"}
                        allowPreviousDates
                      />
                    </InputWithLabelSection>
                    <InputWithLabelSection>
                      <InputLabel
                        style={
                          selectedTemplate.DeliveryDate !== undefined &&
                          selectedTemplate.DeliveryDate !== ""
                            ? { opacity: `0` }
                            : { opacity: `1` }
                        }
                      >
                        Delivery Time
                      </InputLabel>
                      <TimePicker
                        hour12Format
                        value={deliveryTime}
                        onChange={(value) => onDeliveryTimeChange(value)}
                      />
                    </InputWithLabelSection>
                  </CommonGrid>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.BillDate !== undefined && (
                  <CommonGrid justifyContent="start">
                    <InputWithLabelSection>
                      <InputLabel>
                        {selectedTemplate.BillDate !== undefined &&
                        selectedTemplate.BillDate !== ""
                          ? selectedTemplate.BillDate
                          : `Bill Date`}
                      </InputLabel>
                      <DatePicker
                        id="billDate"
                        date={billDate}
                        onDateChange={onBillDateChange}
                        dateDisplayFormat={"DD/MM/YYYY"}
                        placeholder={"Select date"}
                        allowPreviousDates
                      />
                    </InputWithLabelSection>
                    <InputWithLabelSection>
                      <InputLabel
                        style={
                          selectedTemplate.BillDate !== undefined &&
                          selectedTemplate.BillDate !== ""
                            ? { opacity: `0` }
                            : { opacity: `1` }
                        }
                      >
                        Bill Time
                      </InputLabel>
                      <TimePicker
                        hour12Format
                        value={billTime}
                        onChange={(value) => onBillTimeChange(value)}
                      />
                    </InputWithLabelSection>
                  </CommonGrid>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.BillPayDate !== undefined && (
                  <CommonGrid justifyContent="start">
                    <InputWithLabelSection>
                      <InputLabel>
                        {selectedTemplate.BillPayDate !== undefined &&
                        selectedTemplate.BillPayDate !== ""
                          ? selectedTemplate.BillPayDate
                          : `Bill Pay Date`}
                      </InputLabel>
                      <DatePicker
                        id="billPayDate"
                        date={billPayDate}
                        onDateChange={onBillPayDateChange}
                        dateDisplayFormat={"DD/MM/YYYY"}
                        placeholder={"Select date"}
                        allowPreviousDates
                      />
                    </InputWithLabelSection>
                    <InputWithLabelSection>
                      <InputLabel
                        style={
                          selectedTemplate.BillPayDate !== undefined &&
                          selectedTemplate.BillPayDate !== ""
                            ? { opacity: `0` }
                            : { opacity: `1` }
                        }
                      >
                        Bill Time
                      </InputLabel>
                      <TimePicker
                        hour12Format
                        value={billPayTime}
                        onChange={(value) => onBillPayTimeChange(value)}
                      />
                    </InputWithLabelSection>
                  </CommonGrid>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.PANNo !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>PAN no.</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Type PAN no..."
                      value={selectedForm.PANNo}
                      error={errorList && errorList.PANNo}
                      onChange={(e) =>
                        onTextInputChange("PANNo", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.InvoiceDetails !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>Invoice Details</InputLabel>
                    <InputField
                      type="text"
                      placeholder="Invoice Details..."
                      value={selectedForm.InvoiceDetails}
                      error={errorList && errorList.InvoiceDetails}
                      onChange={(e) =>
                        onTextInputChange("InvoiceDetails", e.target.value)
                      }
                    />
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.RecieptLogo !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>Logo</InputLabel>
                    {logoPreview && (
                      <ImgPreviewSection>
                        <img
                          src={logoPreview}
                          alt="image preview"
                          width="100px"
                        />
                        <RemoveImageIcon onClick={() => handleLogoRemove()}>
                          <img src={cross} alt="remove image" width />
                        </RemoveImageIcon>
                      </ImgPreviewSection>
                    )}
                    <InputField
                      type="file"
                      style={{ padding: `30px 10px` }}
                      placeholder="Drag Image here o choose file"
                      error={errorList && errorList.RecieptLogo}
                      onChange={(e) => handleLogoPreview(e)}
                    />
                    <CommonText>
                      <RedStar>*</RedStar>
                      <ImageAlert>Image size not more than 150kb</ImageAlert>
                    </CommonText>
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.BarCode !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>Barcode</InputLabel>
                    {barcodePreview && (
                      <ImgPreviewSection>
                        <img
                          src={barcodePreview}
                          alt="barcode preview"
                          width="200px"
                        />
                        <RemoveImageIcon onClick={() => handleBarcodeRemove()}>
                          <img src={cross} alt="remove image" width />
                        </RemoveImageIcon>
                      </ImgPreviewSection>
                    )}

                    <InputField
                      type="file"
                      style={{ padding: `30px 10px` }}
                      error={errorList && errorList.BarCode}
                      onChange={(e) => handleBarcodePreview(e)}
                    />
                    <CommonText>
                      <RedStar>*</RedStar>
                      <ImageAlert>Image size not more than 150kb</ImageAlert>
                    </CommonText>
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.ProfileImage !== undefined && (
                  <InputWithLabelSection>
                    <InputLabel>Profile Image</InputLabel>
                    {profileImagePreview && (
                      <ImgPreviewSection>
                        <img
                          src={profileImagePreview}
                          alt="profile image preview"
                          width="200px"
                        />
                        <RemoveImageIcon
                          onClick={() => handleProfileImageRemove()}
                        >
                          <img src={cross} alt="remove image" width />
                        </RemoveImageIcon>
                      </ImgPreviewSection>
                    )}

                    <InputField
                      type="file"
                      style={{ padding: `30px 10px` }}
                      error={errorList && errorList.ProfileImage}
                      onChange={(e) => handleProfileImagePreview(e)}
                    />
                    <CommonText>
                      <RedStar>*</RedStar>
                      <ImageAlert>Image size not more than 150kb</ImageAlert>
                    </CommonText>
                  </InputWithLabelSection>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.BillRecieptTaxInfoDc !== undefined && (
                  <DividedSection>
                    <InputLabel>Tax</InputLabel>
                    <TaxInputSection>
                      <Select
                        isSearchable
                        value={selectedTax}
                        options={taxOption}
                        placeholder="Select Tax..."
                        onChange={(e) => {
                          onTaxChange("type", e);
                        }}
                        styles={FilterDropDownStyle({
                          height: "40px",
                          width: "180px",
                        })}
                      />
                      <InputField
                        type="number"
                        placeholder="Tax %"
                        value={selectedTax.TaxInPercentage}
                        onChange={(e) => onTaxChange("amount", e.target.value)}
                      />
                      {selectedForm.BillRecieptTaxInfoDc !== undefined &&
                        selectedForm.BillRecieptTaxInfoDc.length < 2 && (
                          <WhiteButton
                            padding="0 15px"
                            borderColor="#635BFF"
                            hoverBorderColor="#5C59F0"
                            onClick={() => addTax()}
                          >
                            Add Tax
                          </WhiteButton>
                        )}
                    </TaxInputSection>
                  </DividedSection>
                )}

              {selectedForm.BillRecieptTaxInfoDc &&
                selectedForm.BillRecieptTaxInfoDc.length > 0 && (
                  <div>
                    <TaxRowTable>
                      <InputLabel>no.</InputLabel>
                      <InputLabel>tax type</InputLabel>
                      <InputLabel>tax amount (%)</InputLabel>
                    </TaxRowTable>
                    {selectedForm.BillRecieptTaxInfoDc.map((taxRow, i) => (
                      <TaxRowTable key={i}>
                        <InputLabel>{i + 1}.</InputLabel>
                        <InputLabel> {taxRow.Tax} </InputLabel>
                        <InputLabel>{taxRow.TaxInPercentage}%</InputLabel>
                        <ColoredButton
                          type="delete"
                          onClick={() => onDeleteTax(i)}
                        >
                          Delete
                        </ColoredButton>
                      </TaxRowTable>
                    ))}
                  </div>
                )}

              {selectedTemplate.formInputs &&
                selectedTemplate.formInputs.length > 0 &&
                selectedForm.BillRecieptItemInfoDc !== undefined && (
                  <DividedSection>
                    <InputLabel>Items</InputLabel>
                    <ItemRowContainer>
                      <ItemInputContainer>
                        <InputWithLabelSection>
                          <InputLabel>Item no.</InputLabel>
                          <InputField
                            type="text"
                            placeholder="Type no..."
                            value={selectedItem.ItemNo}
                            onChange={(e) =>
                              onItemValueChange("ItemNo", e.target.value)
                            }
                          />
                        </InputWithLabelSection>

                        <InputWithLabelSection>
                          <InputLabel>Item name</InputLabel>
                          <InputField
                            type="text"
                            placeholder="Type name..."
                            value={selectedItem.ItemName}
                            onChange={(e) =>
                              onItemValueChange("ItemName", e.target.value)
                            }
                          />
                        </InputWithLabelSection>

                        <InputWithLabelSection>
                          <InputLabel>UOM</InputLabel>
                          <InputField
                            type="text"
                            placeholder="Type UOM..."
                            value={selectedItem.UOM}
                            onChange={(e) =>
                              onItemValueChange("UOM", e.target.value)
                            }
                          />
                        </InputWithLabelSection>

                        <InputWithLabelSection>
                          <InputLabel>Item quantity</InputLabel>
                          <InputField
                            type="number"
                            placeholder="Type quantity..."
                            value={selectedItem.Quantity}
                            onChange={(e) =>
                              onItemValueChange("Quantity", e.target.value)
                            }
                          />
                        </InputWithLabelSection>

                        <InputWithLabelSection>
                          <InputLabel>Item price</InputLabel>
                          <InputField
                            type="number"
                            placeholder="Type price..."
                            value={selectedItem.Price}
                            onChange={(e) =>
                              onItemValueChange("Price", e.target.value)
                            }
                          />
                        </InputWithLabelSection>
                      </ItemInputContainer>
                      <WhiteButton
                        padding="0 15px"
                        borderColor="#635BFF"
                        hoverBorderColor="#5C59F0"
                        onClick={() => addItem()}
                      >
                        Add Item
                      </WhiteButton>
                    </ItemRowContainer>
                  </DividedSection>
                )}

              {selectedForm.BillRecieptItemInfoDc &&
                selectedForm.BillRecieptItemInfoDc.length > 0 && (
                  <div>
                    <ItemRowTable heading>
                      <ItemRowCell>Serial</ItemRowCell>
                      <ItemRowCell>no.</ItemRowCell>
                      <ItemRowCell>name</ItemRowCell>
                      <ItemRowCell>UOM</ItemRowCell>
                      <ItemRowCell>quantity</ItemRowCell>
                      <ItemRowCell>price</ItemRowCell>
                    </ItemRowTable>
                    {selectedForm.BillRecieptItemInfoDc.map((itemRow, i) => (
                      <ItemRowTable key={i}>
                        <ItemRowCell>{i + 1}.</ItemRowCell>
                        <ItemRowCell> {itemRow.ItemNo} </ItemRowCell>
                        <ItemRowCell> {itemRow.ItemName} </ItemRowCell>
                        <ItemRowCell> {itemRow.UOM} </ItemRowCell>
                        <ItemRowCell> {itemRow.Quantity} </ItemRowCell>
                        <ItemRowCell> {itemRow.Price} </ItemRowCell>
                        <ItemRowCell>
                          <ColoredButton
                            type="delete"
                            onClick={() => onDeleteItem(i)}
                          >
                            Delete
                          </ColoredButton>
                        </ItemRowCell>
                      </ItemRowTable>
                    ))}
                  </div>
                )}

              <GenerateButtonSection>
                <PrimaryButton onClick={() => onGenerateReceipt()}>
                  Generate Receipt
                </PrimaryButton>
              </GenerateButtonSection>
            </Form>
            <ImageContainer>
              <ColoredTab>PREVIEW</ColoredTab>
              <ImageSection>
                {isGenerated && props.receiptID && props.receiptID !== "" ? (
                  <img
                    src={`${process.env.REACT_APP_API_URL}/RecieptPdf/bill_reciept_${props.receiptID}.png`}
                    style={{ boxShadow: `0px 20px 40px #51679836` }}
                    alt="template"
                    width="100%"
                  />
                ) : (
                  <img
                    src={selectedTemplate.image}
                    style={{ boxShadow: `0px 20px 40px #51679836` }}
                    alt="template"
                    width="100%"
                  />
                )}
              </ImageSection>
            </ImageContainer>
          </>
        )}
    </Container>
  );
};

export default GenerateReceipt;
