import moment from "moment";
import { clientsListSpaceX, clientsListOceana } from "../clients";
let fifthDate = moment().subtract(3, "d").format("YYYY-MM-DD");
let sixthDate = moment().subtract(2, "d").format("YYYY-MM-DD");
let seventhDate = moment().subtract(1, "d").format("YYYY-MM-DD");

export const invoiceListSpaceX = {
  count: 8,
  next: null,
  previous: null,
  results: [
    {
      id: "0a5bf0c6-ba7d-41fc-b368-102b31a93000",
      organization: "35ff0a5b-97da-4681-9200-8115efc9cd59",
      status: "sent",
      issued_date: fifthDate,
      subject: "",
      invoice_number: "SpaceX-4409",
      paid_amount: 0.0,
      client_id: clientsListSpaceX[0].id,
      discounted_amount: 0.0,
      currency: "USD",
      client_name: clientsListSpaceX[0].name,
    },
    {
      id: "06f356f7-b826-47ad-b476-eddb59058b12",
      organization: "35ff0a5b-97da-4681-9200-8115efc9cd59",
      status: "draft",
      issued_date: fifthDate,
      subject: "",
      invoice_number: "SpaceX-1238",
      paid_amount: 0.0,
      client_id: clientsListSpaceX[1].id,
      discounted_amount: 0.0,
      currency: "USD",
      client_name: clientsListSpaceX[1].name,
    },
    {
      id: "d0e69d70-8421-4531-a23b-c76b43979fb6",
      organization: "35ff0a5b-97da-4681-9200-8115efc9cd59",
      status: "partial",
      issued_date: fifthDate,
      subject: "",
      invoice_number: "SpaceX-2715",
      paid_amount: 54985.0,
      client_id: clientsListSpaceX[1].id,
      discounted_amount: 1398852.19,
      currency: "USD",
      client_name: clientsListSpaceX[1].name,
    },
    {
      id: "23067e42-a8d9-44d7-a8c6-8f4da16ea344",
      organization: "35ff0a5b-97da-4681-9200-8115efc9cd59",
      status: "sent",
      issued_date: sixthDate,
      subject: "",
      invoice_number: "SpaceX-1549",
      paid_amount: 0.0,
      client_id: clientsListSpaceX[0].id,
      discounted_amount: 144.0,
      currency: "USD",
      client_name: clientsListSpaceX[0].name,
    },
    {
      id: "104acd73-4a38-43ad-9768-e6fd8a03d15d",
      organization: "35ff0a5b-97da-4681-9200-8115efc9cd59",
      status: "paid",
      issued_date: sixthDate,
      subject: "",
      invoice_number: "SpaceX-2053",
      paid_amount: 100.0,
      client_id: clientsListSpaceX[2].id,
      discounted_amount: 100.0,
      currency: "USD",
      client_name: clientsListSpaceX[2].name,
    },
    {
      id: "49342b30-16c8-42de-a3cd-ca9b83d3a5c2",
      organization: "35ff0a5b-97da-4681-9200-8115efc9cd59",
      status: "paid",
      issued_date: sixthDate,
      subject: "",
      invoice_number: "SpaceX-2258",
      paid_amount: 9.0,
      client_id: clientsListSpaceX[0].id,
      discounted_amount: 1.0,
      currency: "USD",
      client_name: clientsListSpaceX[0].name,
    },
    {
      id: "9ece115b-3203-414a-bd70-091db6248927",
      organization: "35ff0a5b-97da-4681-9200-8115efc9cd59",
      status: "draft",
      issued_date: seventhDate,
      subject: "",
      invoice_number: "SpaceX-3421",
      paid_amount: 0.0,
      client_id: clientsListSpaceX[2].id,
      discounted_amount: 8100.0,
      currency: "USD",
      client_name: clientsListSpaceX[2].name,
    },
    {
      id: "f7ec22c0-627b-4266-90fe-b26bcdd8a128",
      organization: "35ff0a5b-97da-4681-9200-8115efc9cd59",
      status: "sent",
      issued_date: seventhDate,
      subject: "",
      invoice_number: "SpaceX-2899",
      paid_amount: 0.0,
      client_id: clientsListSpaceX[1].id,
      discounted_amount: 508.04,
      currency: "USD",
      client_name: clientsListSpaceX[1].name,
    },
  ],
};

export const invoiceListOceanaInc = {
  count: 5,
  next: null,
  previous: null,
  results: [
    {
      id: "476c44e7-6887-418d-a2ad-2784bf7cf835",
      organization: "89090ff8-e131-4e9d-a4f7-5ddd9f4a1efe",
      status: "sent",
      issued_date: fifthDate,
      subject: "",
      invoice_number: "OceanaInc-4689",
      paid_amount: 0.0,
      client_id: clientsListOceana[0].id,
      discounted_amount: 0.0,
      currency: "USD",
      client_name: clientsListOceana[0].name,
    },
    {
      id: "a5f4e94a-5e43-44e5-b3cc-5f9e1ad2293f",
      organization: "89090ff8-e131-4e9d-a4f7-5ddd9f4a1efe",
      status: "draft",
      issued_date: fifthDate,
      subject: "",
      invoice_number: "OceanaInc-5295",
      paid_amount: 0.0,
      client_id: clientsListOceana[1].id,
      discounted_amount: 0.0,
      currency: "USD",
      client_name: clientsListOceana[1].name,
    },
    {
      id: "702cf998-a956-4213-a4d7-2e251eaad859",
      organization: "89090ff8-e131-4e9d-a4f7-5ddd9f4a1efe",
      status: "partial",
      issued_date: fifthDate,
      subject: "",
      invoice_number: "OceanaInc-4445",
      paid_amount: 54985.0,
      client_id: clientsListOceana[0].id,
      discounted_amount: 1398852.19,
      currency: "USD",
      client_name: clientsListOceana[0].name,
    },
    {
      id: "4999efac-e2b0-47c1-a413-59b6dfbe7833",
      organization: "89090ff8-e131-4e9d-a4f7-5ddd9f4a1efe",
      status: "paid",
      issued_date: sixthDate,
      subject: "",
      invoice_number: "OceanaInc-5192",
      paid_amount: 100.0,
      client_id: clientsListOceana[2].id,
      discounted_amount: 100.0,
      currency: "USD",
      client_name: clientsListOceana[2].name,
    },
    {
      id: "81f0f6b0-f330-4003-bf37-b27beb682d57",
      organization: "89090ff8-e131-4e9d-a4f7-5ddd9f4a1efe",
      status: "sent",
      issued_date: seventhDate,
      subject: "",
      invoice_number: "OceanaInc-5819",
      paid_amount: 0.0,
      client_id: clientsListOceana[1].id,
      discounted_amount: 106.0,
      currency: "USD",
      client_name: clientsListOceana[1].name,
    },
  ],
};

export const invoiceListData = {
  "s1dty88f-5yfd-4fg5-b6c2-eeae27f8347c": invoiceListSpaceX,
  "4bcfb309-e861-4c9d-bd9a-fdb8rree6b427": invoiceListOceanaInc,
};
