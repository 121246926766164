import { connect } from "react-redux";

// Component
import Admin from "./Admin";

// Store props to map with current state
const mapStateToProps = (state) => ({
  selectedOrganization: state.organization.selectedOrganization,
  // // orgDetails: state.currentSubscriptionPlan.orgDetails,
  profile: state.profile,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(Admin);
