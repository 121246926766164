import React from "react";
import {
  CommonText,
  ModalButtonSection,
  PreviewPopup,
  PreviewContainer,
} from "../../styledComponents/common";
import { WhiteButton, ColoredButton } from "../../styledComponents/buttons";
import ButtonTextLoader from "../Loaders/ButtonTextLoader";

const DeletePopup = ({ type, isOpen, toggle, isDeleteLoading, onDelete }) => {
  const onPopupClick = (e) => {
    e.stopPropagation();
  };

  return (
    <PreviewContainer onClick={toggle}>
      <PreviewPopup>
        <div
          style={{ padding: `35px 30px 15px` }}
          onClick={(e) => onPopupClick(e)}
        >
          <div>
            <CommonText fontSize="16px" name="true">
              Are you sure you want to delete {type}?
            </CommonText>
          </div>
          <ModalButtonSection>
            <WhiteButton type="cancel" onClick={toggle}>
              Cancel
            </WhiteButton>
            <ColoredButton type="delete" onClick={onDelete}>
              {isDeleteLoading ? (
                <ButtonTextLoader loadingText="Deleting" fontSize="14px" />
              ) : (
                "Delete"
              )}
            </ColoredButton>
          </ModalButtonSection>
        </div>
      </PreviewPopup>
    </PreviewContainer>
  );
};

export default DeletePopup;
