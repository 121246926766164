import moment from "moment";
let initialDateTime = moment().format("MM/DD/YYYY hh:mm A")

let payload = {
    RecieptDate:initialDateTime,
    EntryDate: initialDateTime,
    BillDate: initialDateTime,
    DeliveryDate: initialDateTime,
    DueDate: initialDateTime,
    BillPayDate: initialDateTime,
    OrderDate: initialDateTime,
    TaxiDate: initialDateTime,
    TaxiWTTime: initialDateTime,
    PickupTime: initialDateTime,
    DropTime: initialDateTime,
    BillRecieptId: 0,
    CurrencyId: 3,
    CategoryRecieptId: "",
    ExitDate: initialDateTime,
    FontId: 1,
    Business: "",
    Address: "",
    CityState: "",
    PaymentType: "",
    Trans: "",
    Mcc: "",
    BillRecieptTaxInfoDc: [],
    BillRecieptItemInfoDc: [],
    CategoryRecieptPDFDc: {
      BillRecieptSampleImage: "",
      RecieptType: "",
      IsRecieptLogo: false,
      ReceiptWidth: "300pt",
      ReceiptHight: "450pt",
    },
    UserId: "",
    FormDataImages: {},
    BillRecieptDynamicHtml: "",
    SubscriptionPlanId: 597937,
  };

  export default payload;