// ------------------------------------
// Constants
// ------------------------------------

//Login
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_REQUEST_SUCCESS = "LOGIN_REQUEST_SUCCESS";
export const LOGIN_REQUEST_FAILURE = "LOGIN_REQUEST_FAILURE";
export const LOGIN_REDIRECT_RESET = "LOGIN_REDIRECT_RESET";
export const LOGIN_PROFILE_CREATED_RESET = "LOGIN_PROFILE_CREATED_RESET";

//Register
export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_REQUEST_SUCCESS = "REGISTER_REQUEST_SUCCESS";
export const REGISTER_REQUEST_FAILURE = "REGISTER_REQUEST_FAILURE";
export const REGISTER_RESET = "REGISTER_RESET";

export const SIGN_UP_REQUEST = "SIGN_UP_REQUEST";
export const SIGN_UP_REQUEST_SUCCESS = "SIGN_UP_REQUEST_SUCCESS";
export const SIGN_UP_REQUEST_FAILURE = "SIGN_UP_REQUEST_FAILURE";
export const SIGN_UP_RESET = "SIGN_UP_RESET";

export const PADDLE_REGISTER = "PADDLE_REGISTER";

export const ADD_REGISTRATION = "ADD_REGISTRATION";
export const ADD_REGISTRATION_SUCCESS = "ADD_REGISTRATION_SUCCESS";
export const ADD_REGISTRATION_FAILURE = "ADD_REGISTRATION_FAILURE";

export const DONT_SHOW_ORGANIZATION_WIZARD_SUCCESS =
  "DONT_SHOW_ORGANIZATION_WIZARD_SUCCESS";
//Verify User
export const VERIFY_REQUEST = "VERIFY_REQUEST";
export const VERIFY_REQUEST_SUCCESS = "VERIFY_REQUEST_SUCCESS";
export const VERIFY_REQUEST_FAILURE = "VERIFY_REQUEST_FAILURE";
export const VERIFY_RESET = "VERIFY_RESET";

export const VERIFY_CODE = "VERIFY_CODE";
export const VERIFY_CODE_SUCCESS = "VERIFY_CODE_SUCCESS";
export const VERIFY_CODE_FAILURE = "VERIFY_CODE_FAILURE";

export const RESEND_VERIFICATION_CODE = "RESEND_VERIFICATION_CODE";
export const RESEND_VERIFICATION_CODE_SUCCESS =
  "RESEND_VERIFICATION_CODE_SUCCESS";
export const RESEND_VERIFICATION_CODE_FAILURE =
  "RESEND_VERIFICATION_CODE_FAILURE";

export const VERIFY_CODE_RESET = "VERIFY_CODE_RESET";

//Checking Token
export const CHECK_TOKEN = "CHECK_TOKEN";
export const CHECK_TOKEN_SUCCESS = "CHECK_TOKEN_SUCCESS";
export const CHECK_TOKEN_FAILURE = "CHECK_TOKEN_FAILURE";

//Onboarding
export const CHECK_ONBOARDING_APPLICABLE = "CHECK_ONBOARDING_APPLICABLE";
export const CHECK_ONBOARDING_APPLICABLE_SUCCESS =
  "CHECK_ONBOARDING_APPLICABLE_SUCCESS";
export const CHECK_ONBOARDING_APPLICABLE_FAILURE =
  "CHECK_ONBOARDING_APPLICABLE_FAILURE";

export const GET_JOIN_REQUESTS = "GET_JOIN_REQUESTS";
export const GET_JOIN_REQUESTS_SUCCESS = "GET_JOIN_REQUESTS_SUCCESS";
export const GET_JOIN_REQUESTS_FAILURE = "GET_JOIN_REQUESTS_FAILURE";

export const CLEAR_JOIN_REQUESTS = "CLEAR_JOIN_REQUESTS";

// export const REQUEST_TO_JOIN = "REQUEST_TO_JOIN";
// export const REQUEST_TO_JOIN_SUCCESS = "REQUEST_TO_JOIN_SUCCESS";
// export const REQUEST_TO_JOIN_FAILURE = "REQUEST_TO_JOIN_FAILURE";

export const REQUEST_TO_JOIN_ORGANIZATION = "REQUEST_TO_JOIN_ORGANIZATION";
export const REQUEST_TO_JOIN_ORGANIZATION_SUCCESS =
  "REQUEST_TO_JOIN_ORGANIZATION_SUCCESS";
export const REQUEST_TO_JOIN_ORGANIZATION_FAILURE =
  "REQUEST_TO_JOIN_ORGANIZATION_FAILURE";

export const ACCEPT_ORG_JOIN_REQUEST = "ACCEPT_ORG_JOIN_REQUEST";
export const ACCEPT_ORG_JOIN_REQUEST_SUCCESS =
  "ACCEPT_ORG_JOIN_REQUEST_SUCCESS";
export const ACCEPT_ORG_JOIN_REQUEST_FAILURE =
  "ACCEPT_ORG_JOIN_REQUEST_FAILURE";

export const SAVE_ONBOARDING_DATA = "SAVE_ONBOARDING_DATA";
export const SAVE_ONBOARDING_DATA_SUCCESS = "SAVE_ONBOARDING_DATA_SUCCESS";
export const SAVE_ONBOARDING_DATA_FAILURE = "SAVE_ONBOARDING_DATA_FAILURE";

//Dashboard
export const REQUEST_DASHBOARD_DATA = "REQUEST_DASHBOARD_DATA";
export const REQUEST_DASHBOARD_DATA_SUCCESS = "REQUEST_DASHBOARD_DATA_SUCCESS";
export const REQUEST_DASHBOARD_DATA_FAILURE = "REQUEST_DASHBOARD_DATA_FAILURE";

//admin panel
export const GET_TRIAL_INFO = "GET_TRIAL_INFO";
export const GET_TRIAL_INFO_SUCCESS = "GET_TRIAL_INFO_SUCCESS";
export const GET_TRIAL_INFO_FAILURE = "GET_TRIAL_INFO_FAILURE";

//Forgot Password
export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_REQUEST_SUCCESS =
  "FORGOT_PASSWORD_REQUEST_SUCCESS";
export const FORGOT_PASSWORD_REQUEST_FAILURE =
  "FORGOT_PASSWORD_REQUEST_FAILURE";

export const CLEAR_FORGOT_PASSWORD_LINK_SENT =
  "CLEAR_FORGOT_PASSWORD_LINK_SENT";

//Reset Password
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";
export const RESET_PASSWORD_STATE_RESET = "RESET_PASSWORD_STATE_RESET";

//Resend Verification mail
export const RESEND_VERIFICATION_MAIL = "RESEND_VERIFICATION_MAIL";
export const RESEND_VERIFICATION_MAIL_SUCCESS =
  "RESEND_VERIFICATION_MAIL_SUCCESS";
export const RESEND_VERIFICATION_MAIL_FAILURE =
  "RESEND_VERIFICATION_MAIL_FAILURE";

//Change Password
export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_REQUEST_SUCCESS =
  "CHANGE_PASSWORD_REQUEST_SUCCESS";
export const CHANGE_PASSWORD_REQUEST_FAILURE =
  "CHANGE_PASSWORD_REQUEST_FAILURE";

//Send invite
export const SEND_INVITE_REQUEST = "SEND_INVITE_REQUEST";
export const SEND_INVITE_REQUEST_SUCCESS = "SEND_INVITE_REQUEST_SUCCESS";
export const SEND_INVITE_REQUEST_FAILURE = "SEND_INVITE_REQUEST_FAILURE";

//Verify invite
export const VERIFY_INVITATION_REQUEST = "VERIFY_INVITATION_REQUEST";
export const VERIFY_INVITATION_REQUEST_SUCCESS =
  "VERIFY_INVITATION_REQUEST_SUCCESS";
export const VERIFY_INVITATION_REQUEST_FAILURE =
  "VERIFY_INVITATION_REQUEST_FAILURE";
export const INVITATION_RESET = "INVITATION_RESET";

//User Profile
export const REQUEST_USER_PROFILE = "REQUEST_USER_PROFILE";
export const REQUEST_USER_PROFILE_SUCCESS = "REQUEST_USER_PROFILE_SUCCESS";
export const REQUEST_USER_PROFILE_FAILURE = "REQUEST_USER_PROFILE_FAILURE";
export const CREATE_USER_PROFILE = "CREATE_USER_PROFILE";
export const CREATE_USER_PROFILE_SUCCESS = "CREATE_USER_PROFILE_SUCCESS";
export const CREATE_USER_PROFILE_FAILURE = "CREATE_USER_PROFILE_FAILURE";

export const DELETE_ORGANIZATION = "DELETE_ORGANIZATION";
export const DELETE_ORGANIZATION_SUCCESS = "DELETE_ORGANIZATION_SUCCESS";
export const DELETE_ORGANIZATION_FAILURE = "DELETE_ORGANIZATION_FAILURE";

export const DELETE_ACCOUNT = "DELETE_ACCOUNT";
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_FAILURE = "DELETE_ACCOUNT_FAILURE";

//User Emails
export const GET_USER_EMAILS = "GET_USER_EMAILS";
export const GET_USER_EMAILS_SUCCESS = "GET_USER_EMAILS_SUCCESS";
export const GET_USER_EMAILS_FAILURE = "GET_USER_EMAILS_FAILURE";

export const ADD_EMAIL = "ADD_EMAIL";
export const ADD_EMAIL_SUCCESS = "ADD_EMAIL_SUCCESS";
export const ADD_EMAIL_FAILURE = "ADD_EMAIL_FAILURE";

export const DELETE_EMAIL = "DELETE_EMAIL";
export const DELETE_EMAIL_SUCCESS = "DELETE_EMAIL_SUCCESS";
export const DELETE_EMAIL_FAILURE = "DELETE_EMAIL_FAILURE";

export const MAKE_EMAIL_DEFAULT = "MAKE_EMAIL_DEFAULT";
export const MAKE_EMAIL_DEFAULT_SUCCESS = "MAKE_EMAIL_DEFAULT_SUCCESS";
export const MAKE_EMAIL_DEFAULT_FAILURE = "MAKE_EMAIL_DEFAULT_FAILURE";

//Invoice
export const GET_INVOICE_LIST = "GET_INVOICE_LIST";
export const GET_INVOICE_LIST_SUCCESS = "GET_INVOICE_LIST_SUCCESS";
export const GET_INVOICE_LIST_FAILURE = "GET_INVOICE_LIST_FAILURE";

export const CREATE_INVOICE_REQUEST = "CREATE_INVOICE_REQUEST";
export const CREATE_INVOICE_REQUEST_SUCCESS = "CREATE_INVOICE_REQUEST_SUCCESS";
export const CREATE_INVOICE_REQUEST_FAILURE = "CREATE_INVOICE_FAILURE";

export const GET_INVOICE_DETAILS = "GET_INVOICE_DETAILS";
export const GET_INVOICE_DETAILS_SUCCESS = "GET_INVOICE_DETAILS_SUCCESS";
export const GET_INVOICE_DETAILS_FAILURE = "GET_INVOICE_DETAILS_FAILURE";

export const UPDATE_INVOICE_REQUEST = "UPDATE_INVOICE_REQUEST";
export const UPDATE_INVOICE_REQUEST_SUCCESS = "UPDATE_INVOICE_REQUEST_SUCCESS";
export const UPDATE_INVOICE_REQUEST_FAILURE = "UPDATE_INVOICE_REQUEST_FAILURE";

export const DELETE_INVOICE = "DELETE_INVOICE";
export const DELETE_INVOICE_SUCCESS = "DELETE_INVOICE_SUCCESS";
export const DELETE_INVOICE_FAILURE = "DELETE_INVOICE_FAILURE";

export const CREATE_INVOICE_ITEM = "CREATE_INVOICE_ITEM";
export const CREATE_INVOICE_ITEM_SUCCESS = "CREATE_INVOICE_ITEM_SUCCESS";
export const CREATE_INVOICE_ITEM_FAILURE = "CREATE_INVOICE_ITEM_FAILURE";

export const UPDATE_INVOICE_ITEM = "UPDATE_INVOICE_ITEM";
export const UPDATE_INVOICE_ITEM_SUCCESS = "UPDATE_INVOICE_ITEM_SUCCESS";
export const UPDATE_INVOICE_ITEM_FAILURE = "UPDATE_INVOICE_ITEM_FAILURE";

export const DELETE_INVOICE_ITEM = "DELETE_INVOICE_ITEM";
export const DELETE_INVOICE_ITEM_SUCCESS = "DELETE_INVOICE_ITEM_SUCCESS";
export const DELETE_INVOICE_ITEM_FAILURE = "DELETE_INVOICE_ITEM_FAILURE";

export const CREATE_INVOICE_TAX = "CREATE_INVOICE_TAX";
export const CREATE_INVOICE_TAX_SUCCESS = "CREATE_INVOICE_TAX_SUCCESS";
export const CREATE_INVOICE_TAX_FAILURE = "CREATE_INVOICE_TAX_FAILURE";

export const UPDATE_INVOICE_TAX = "UPDATE_INVOICE_TAX";
export const UPDATE_INVOICE_TAX_SUCCESS = "UPDATE_INVOICE_TAX_SUCCESS";
export const UPDATE_INVOICE_TAX_FAILURE = "UPDATE_INVOICE_TAX_FAILURE";

export const GET_INVOICE_TAX = "GET_INVOICE_TAX";
export const GET_INVOICE_TAX_SUCCESS = "GET_INVOICE_TAX_SUCCESS";
export const GET_INVOICE_TAX_FAILURE = "GET_INVOICE_TAX_FAILURE";

export const UPDATE_INVOICE_DISCOUNT = "UPDATE_INVOICE_DISCOUNT";
export const UPDATE_INVOICE_DISCOUNT_SUCCESS =
  "UPDATE_INVOICE_DISCOUNT_SUCCESS";
export const UPDATE_INVOICE_DISCOUNT_FAILURE =
  "UPDATE_INVOICE_DISCOUNT_FAILURE";

export const SEND_INVOICE_EMAIL = "SEND_INVOICE_EMAIL";
export const SEND_INVOICE_EMAIL_SUCCESS = "SEND_INVOICE_EMAIL_SUCCESS";
export const SEND_INVOICE_EMAIL_FAILURE = "SEND_INVOICE_EMAIL_FAILURE";

export const GET_INVOICE_NUMBER = "GET_INVOICE_NUMBER";
export const GET_INVOICE_NUMBER_SUCCESS = "GET_INVOICE_NUMBER_SUCCESS";
export const GET_INVOICE_NUMBER_FAILURE = "GET_INVOICE_NUMBER_FAILURE";

export const RECORD_PAYMENT_INVOICE = "RECORD_PAYMENT_INVOICE";
export const RECORD_PAYMENT_INVOICE_SUCCESS = "RECORD_PAYMENT_INVOICE_SUCCESS";
export const RECORD_PAYMENT_INVOICE_FAILURE = "RECORD_PAYMENT_INVOICE_FAILURE";

export const GET_RECORD_PAYMENT_INVOICE = "GET_RECORD_PAYMENT_INVOICE";
export const GET_RECORD_PAYMENT_INVOICE_SUCCESS =
  "GET_RECORD_PAYMENT_INVOICE_SUCCESS";
export const GET_RECORD_PAYMENT_INVOICE_FAILURE =
  "GET_RECORD_PAYMENT_INVOICE_FAILURE";

export const DELETE_RECORD_PAYMENT_INVOICE = "DELETE_RECORD_PAYMENT_INVOICE";
export const DELETE_RECORD_PAYMENT_INVOICE_SUCCESS =
  "DELETE_RECORD_PAYMENT_INVOICE_SUCCESS";
export const DELETE_RECORD_PAYMENT_INVOICE_FAILURE =
  "DELETE_RECORD_PAYMENT_INVOICE_FAILURE";

export const GET_INVOICE_HISTORY = "GET_INVOICE_HISTORY";
export const GET_INVOICE_HISTORY_SUCCESS = "GET_INVOICE_HISTORY_SUCCESS";
export const GET_INVOICE_HISTORY_FAILURE = "GET_INVOICE_HISTORY_FAILURE";

export const INVOICE_PUBLIC_VIEW = "INVOICE_PUBLIC_VIEW";
export const INVOICE_PUBLIC_VIEW_SUCCESS = "INVOICE_PUBLIC_VIEW_SUCCESS";
export const INVOICE_PUBLIC_VIEW_FAILURE = "INVOICE_PUBLIC_VIEW_FAILURE";

export const INVOICE_UPDATE_SEND_LOG = "INVOICE_UPDATE_SEND_LOG";
export const INVOICE_UPDATE_SEND_LOG_SUCCESS =
  "INVOICE_UPDATE_SEND_LOG_SUCCESS";
export const INVOICE_UPDATE_SEND_LOG_FAILURE =
  "INVOICE_UPDATE_SEND_LOG_FAILURE";

export const INVOICE_LINK_PROJECT = "INVOICE_LINK_PROJECT";
export const INVOICE_LINK_PROJECT_SUCCESS = "INVOICE_LINK_PROJECT_SUCCESS";
export const INVOICE_LINK_PROJECT_FAILURE = "INVOICE_LINK_PROJECT_FAILURE";

export const CLEAR_INVOICE_LINKED_PROJECT = "CLEAR_INVOICE_LINKED_PROJECT";
export const IS_MAIL_SEND_FROM_EDIT = "IS_MAIL_SEND_FROM_EDIT";

//Organiztion
export const REQUEST_ORGANIZATION_LIST = "REQUEST_ORGANIZATION_LIST";
export const REQUEST_ORGANIZATION_LIST_SUCCESS =
  "REQUEST_ORGANIZATION_LIST_SUCCESS";
export const REQUEST_ORGANIZATION_LIST_FAILURE =
  "REQUEST_ORGANIZATION_LIST_FAILURE";

export const CLEAR_ORG_CREATED = "CLEAR_ORG_CREATED";
//Create
export const CREATE_ORGANIZATION = "CREATE_ORGANIZATION";
export const CREATE_ORGANIZATION_SUCCESS = "CREATE_ORGANIZATION_SUCCESS";
export const CREATE_ORGANIZATION_FAILURE = "CREATE_ORGANIZATION_FAILURE";
//GET types
export const GET_ORGANIZATION_TYPES = "GET_ORGANIZATION_TYPES";
export const GET_ORGANIZATION_TYPES_SUCCESS = "GET_ORGANIZATION_TYPES_SUCCESS";
export const GET_ORGANIZATION_TYPES_FAILURE = "GET_ORGANIZATION_TYPES_FAILURE";
//GET plans
export const GET_ORGANIZATION_PLANS = "GET_ORGANIZATION_PLANS";
export const GET_ORGANIZATION_PLANS_SUCCESS = "GET_ORGANIZATION_PLANS_SUCCESS";
export const GET_ORGANIZATION_PLANS_FAILURE = "GET_ORGANIZATION_PLANS_FAILURE";
//Update
export const UPDATE_ORGANIZATION = "UPDATE_ORGANIZATION";
export const UPDATE_ORGANIZATION_SUCCESS = "UPDATE_ORGANIZATION_SUCCESS";
export const UPDATE_ORGANIZATION_FAILURE = "UPDATE_ORGANIZATION_FAILURE";
//Get Organization members
export const GET_ORGANIZATION_MEMBERS = "GET_ORGANIZATION_MEMBERS";
export const GET_ORGANIZATION_MEMBERS_SUCCESS =
  "GET_ORGANIZATION_MEMBERS_SUCCESS";
export const GET_ORGANIZATION_MEMBERS_FAILURE =
  "GET_ORGANIZATION_MEMBERS_FAILURE";

export const GET_MEMBERS_COUNT = "GET_MEMBERS_COUNT";
export const GET_MEMBERS_COUNT_SUCCESS = "GET_MEMBERS_COUNT_SUCCESS";
export const GET_MEMBERS_COUNT_FAILURE = "GET_MEMBERS_COUNT_FAILURE";

export const CLEAR_MEMBERS_COUNT = "CLEAR_MEMBERS_COUNT";

export const SELECT_ORGANIZATION = "SELECT_ORGANIZATION";

//Members
export const GET_MEMBERS = "GET_MEMBERS";
export const GET_MEMBERS_SUCCESS = "GET_MEMBERS_SUCCESS";
export const GET_MEMBERS_FAILURE = "GET_MEMBERS_FAILURE";

export const GET_INVITED_MEMBERS = "GET_INVITED_MEMBERS";
export const GET_INVITED_MEMBERS_SUCCESS = "GET_INVITED_MEMBERS_SUCCESS";
export const GET_INVITED_MEMBERS_FAILURE = "GET_INVITED_MEMBERS_FAILURE";

export const GET_MEMBER_DETAILS = "GET_MEMBER_DETAILS";
export const GET_MEMBER_DETAILS_SUCCESS = "GET_MEMBER_DETAILS_SUCCESS";
export const GET_MEMBER_DETAILS_FAILURE = "GET_MEMBER_DETAILS_FAILURE";

export const GET_MEMBER_WEEKLY_EARNING = "GET_MEMBER_WEEKLY_EARNING";
export const GET_MEMBER_WEEKLY_EARNING_SUCCESS =
  "GET_MEMBER_WEEKLY_EARNING_SUCESS";
export const GET_MEMBER_WEEKLY_EARNING_FAILURE =
  "GET_MEMBER_WEEKLY_EARNING_FAILURE";

export const GET_MEMBER_MONTHLY_EARNING = "GET_MEMBER_MONTHLY_EARNING";
export const GET_MEMBER_MONTHLY_EARNING_SUCCESS =
  "GET_MEMBER_MONTHLY_EARNING_SUCESS";
export const GET_MEMBER_MONTHLY_EARNING_FAILURE =
  "GET_MEMBER_WEEKLY_EARNING_FAILURE";

export const INVITE_MEMBER = "INVITE_MEMBER";
export const INVITE_MEMBER_SUCCESS = "INVITE_MEMBER_SUCCESS";
export const INVITE_MEMBER_FAILURE = "INVITE_MEMBER_FAILURE";

export const CLEAR_MEMBER_INVITED = "CLEAR_MEMBER_INVITED";

export const DELETE_MEMBER_INVITATION = "DELETE_MEMBER_INVITATION";
export const DELETE_MEMBER_INVITATION_SUCCESS =
  "DELETE_MEMBER_INVITATION_SUCCESS";
export const DELETE_MEMBER_INVITATION_FAILURE =
  "DELETE_MEMBER_INVITATION_FAILURE";

export const UPDATE_MEMBER = "UPDATE_MEMBER";
export const UPDATE_MEMBER_SUCCESS = "UPDATE_MEMBER_SUCCESS";
export const UPDATE_MEMBER_FAILURE = "UPDATE_MEMBER_FAILURE";

export const CLEAR_MEMBER_DETAILS_DATA = "CLEAR_MEMBER_DETAILS_DATA";

export const ARCHIVE_MEMBERS = "ARCHIVE_MEMBER";
export const ARCHIVE_MEMBERS_SUCCESS = "ARCHIVE_MEMBER_SUCCESS";
export const ARCHIVE_MEMBERS_FAILURE = "ARCHIVE_MEMBER_FAILURE";

export const REMOVE_MEMBER = "REMOVE_MEMBER";
export const REMOVE_MEMBER_SUCCESS = "REMOVE_MEMBER_SUCCESS";
export const REMOVE_MEMBER_FAILURE = "REMOVE_MEMBER_FAILURE";

export const SET_PAYMENT_CONFIGURATION = "SET_PAYMENT_CONFIGURATION";
export const SET_PAYMENT_CONFIGURATION_SUCCESS =
  "SET_PAYMENT_CONFIGURATION_SUCCESS";
export const SET_PAYMENT_CONFIGURATION_FAILURE =
  "SET_PAYMENT_CONFIGURATION_FAILURE";

export const UPDATE_PAYMENT_CONFIGURATION = "UPDATE_PAYMENT_CONFIGURATION";
export const UPDATE_PAYMENT_CONFIGURATION_SUCCESS =
  "UPDATE_PAYMENT_CONFIGURATION_SUCCESS";
export const UPDATE_PAYMENT_CONFIGURATION_FAILURE =
  "UPDATE_PAYMENT_CONFIGURATION_FAILURE";

// payment
export const GET_PAYABLE_DATA = "GET_PAYABLE_DATA";
export const GET_PAYABLE_DATA_SUCCESS = "GET_PAYABLE_DATA_SUCCESS";
export const GET_PAYABLE_DATA_FAILURE = "GET_PAYABLE_DATA_FAILURE";

export const PAY_INDIVIDUAL_DATA = "PAY_INDIVIDUAL_DATA";
export const PAY_INDIVIDUAL_DATA_SUCCESS = "PAY_INDIVIDUAL_DATA_SUCCESS";
export const PAY_INDIVIDUAL_DATA_FAILURE = "PAY_INDIVIDUAL_DATA_FAILURE";

export const BULK_PAYMENT = "BULK_PAYMENT";
export const BULK_PAYMENT_SUCCESS = "BULK_PAYMENT_SUCCESS";
export const BULK_PAYMENT_FAILURE = "BULK_PAYMENT_FAILURE";

export const ONE_TIME_PAYMENT = "ONE_TIME_PAYMENT";
export const ONE_TIME_PAYMENT_SUCCESS = "ONE_TIME_PAYMENT_SUCCESS";
export const ONE_TIME_PAYMENT_FAILURE = "ONE_TIME_PAYMENT_FAILURE";

//Settings
export const GET_WEEKLY_EMAIL_SETTINGS = "GET_WEEKLY_EMAIL_SETTINGS";
export const GET_WEEKLY_EMAIL_SETTINGS_SUCCESS =
  "GET_WEEKLY_EMAIL_SETTINGS_SUCCESS";
export const GET_WEEKLY_EMAIL_SETTINGS_FAILURE =
  "GET_WEEKLY_EMAIL_SETTINGS_FAILURE";

export const UPDATE_WEEKLY_EMAIL_SETTINGS = "UPDATE_WEEKLY_EMAIL_SETTINGS";
export const UPDATE_WEEKLY_EMAIL_SETTINGS_SUCCESS =
  "UPDATE_WEEKLY_EMAIL_SETTINGS_SUCCESS";
export const UPDATE_WEEKLY_EMAIL_SETTINGS_FAILURE =
  "UPDATE_WEEKLY_EMAIL_SETTINGS_FAILURE";

// Activity Settings
export const GET_SCREENSHOT_SETTINGS = "GET_SCREENSHOT_SETTINGS";
export const GET_SCREENSHOT_SETTINGS_SUCCESS =
  "GET_SCREENSHOT_SETTINGS_SUCCESS";
export const GET_SCREENSHOT_SETTINGS_FAILURE =
  "GET_SCREENSHOT_SETTINGS_FAILURE";

export const UPDATE_SCREENSHOT_SETTINGS = "UPDATE_SCREENSHOT_SETTINGS";
export const UPDATE_SCREENSHOT_SETTINGS_SUCCESS =
  "UPDATE_SCREENSHOT_SETTINGS_SUCCESS";
export const UPDATE_SCREENSHOT_SETTINGS_FAILURE =
  "UPDATE_SCREENSHOT_SETTINGS_FAILURE";

export const GET_MEMBER_SETTINGS = "GET_MEMBER_SETTINGS";
export const GET_MEMBER_SETTINGS_SUCCESS = "GET_MEMBER_SETTINGS_SUCCESS";
export const GET_MEMBER_SETTINGS_FAILURE = "GET_MEMBER_SETTINGS_FAILURE";

export const UPDATE_MEMBER_SETTINGS = "UPDATE_MEMBER_SETTINGS";
export const UPDATE_MEMBER_SETTINGS_SUCCESS = "UPDATE_MEMBER_SETTINGS_SUCCESS";
export const UPDATE_MEMBER_SETTINGS_FAILURE = "UPDATE_MEMBER_SETTINGS_FAILURE";

//Subscription Plan
export const GET_ORGANIZATION_DETAILS = "GET_ORGANIZATION_DETAILS";
export const GET_ORGANIZATION_DETAILS_SUCCESS =
  "GET_ORGANIZATION_DETAILS_SUCCESS";
export const GET_ORGANIZATION_DETAILS_FAILURE =
  "GET_ORGANIZATION_DETAILS_FAILURE";

export const GET_CURRENT_SUBSCRIPTION_PLAN = "GET_CURRENT_SUBSCRIPTION_PLAN";
export const GET_CURRENT_SUBSCRIPTION_PLAN_SUCCESS =
  "GET_CURRENT_SUBSCRIPTION_PLAN_SUCCESS";
export const GET_CURRENT_SUBSCRIPTION_PLAN_FAILURE =
  "GET_CURRENT_SUBSCRIPTION_PLAN_FAILURE";

export const SUBSCRIPTION_REQUEST = "SUBSCRIPTION_REQUEST";
export const SUBSCRIPTION_REQUEST_SUCCESS = "SUBSCRIPTION_REQUEST_SUCCESS";
export const SUBSCRIPTION_REQUEST_FAILURE = "SUBSCRIPTION_REQUEST_FAILURE";

export const CHECK_EXISTING_SUBSCRIPTION = "CHECK_EXISTING_SUBSCRIPTION";
export const CHECK_EXISTING_SUBSCRIPTION_SUCCESS =
  "CHECK_EXISTING_SUBSCRIPTION_SUCCESS";
export const CHECK_EXISTING_SUBSCRIPTION_FAILURE =
  "CHECK_EXISTING_SUBSCRIPTION_FAILURE";

export const CLEAR_EXISTING_SUBSCRIPTION = "CLEAR_EXISTING_SUBSCRIPTION";

export const CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION";
export const CANCEL_SUBSCRIPTION_SUCCESS = "CANCEL_SUBSCRIPTION_SUCCESS";
export const CANCEL_SUBSCRIPTION_FAILURE = "CANCEL_SUBSCRIPTION_FAILURE";

export const UPDATE_PAYMENT_METHOD = "UPDATE_PAYMENT_METHOD";
export const UPDATE_PAYMENT_METHOD_SUCCESS = "UPDATE_PAYMENT_METHOD_SUCCESS";
export const UPDATE_PAYMENT_METHOD_FAILURE = "UPDATE_PAYMENT_METHOD_FAILURE";

//check payment applicable
export const CHECK_PAYMENT_APPLICABLE = "CHECK_PAYMENT_APPLICABLE";
export const CHECK_PAYMENT_APPLICABLE_SUCCESS =
  "CHECK_PAYMENT_APPLICABLE_SUCCESS";
export const CHECK_PAYMENT_APPLICABLE_FAILURE =
  "CHECK_PAYMENT_APPLICABLE_FAILURE";

export const CLEAR_PAYMENT_APPLICABLE = "CLEAR_PAYMENT_APPLICABLE";

//check discount
export const CHECK_DISCOUNT = "CHECK_DISCOUNT";
export const CHECK_DISCOUNT_SUCCESS = "CHECK_DISCOUNT_SUCCESS";
export const CHECK_DISCOUNT_FAILURE = "CHECK_DISCOUNT_FAILURE";

// Subscription
export const SEARCH_SUBSCRIPTION = "SEARCH_SUBSCRIPTION";
export const SEARCH_SUBSCRIPTION_SUCCESS = "SEARCH_SUBSCRIPTION_SUCCESS";
export const SEARCH_SUBSCRIPTION_FAILURE = "SEARCH_SUBSCRIPTION_FAILURE";

export const UPDATE_SUBSCRIPTION_PLAN = "UPDATE_SUBSCRIPTION_PLAN";
export const UPDATE_SUBSCRIPTION_PLAN_SUCCESS =
  "UPDATE_SUBSCRIPTION_PLAN_SUCCESS";
export const UPDATE_SUBSCRIPTION_PLAN_FAILURE =
  "UPDATE_SUBSCRIPTION_PLAN_FAILURE";

export const UPGRADE_PLAN = "UPGRADE_PLAN";
export const UPGRADE_PLAN_SUCCESS = "UPGRADE_PLAN_SUCCESS";
export const UPGRADE_PLAN_FAILURE = "UPGRADE_PLAN_FAILURE";

export const CLEAR_CURRENT_SUBSCRIPTION = "CLEAR_CURRENT_SUBSCRIPTION";

export const CLEAR_SUBSCRIPTION_UPDATED = "CLEAR_SUBSCRIPTION_UPDATED";

export const APPLY_COUPON = "APPLY_COUPON";
export const APPLY_COUPON_SUCCESS = "APPLY_COUPON_SUCCESS";
export const APPLY_COUPON_FAILURE = "APPLY_COUPON_FAILURE";

export const CLEAR_DISCOUNT_INFO = "CLEAR_DISCOUNT_INFO";
export const CLEAR_DISCOUNT_ERROR_MESSAGE = "CLEAR_DISCOUNT_ERROR_MESSAGE";

// generate receipt page api call
export const GET_FONT = "GET_FONT";
export const GET_FONT_SUCCESS = "GET_FONT_SUCCESS";
export const GET_FONT_FAILURE = "GET_FONT_FAILURE";

export const GET_TAX = "GET_TAX";
export const GET_TAX_SUCCESS = "GET_TAX_SUCCESS";
export const GET_TAX_FAILURE = "GET_TAX_FAILURE";

export const GET_CATEGORY_RECEIPT_BY_ID = "GET_CATEGORY_RECEIPT_BY_ID";
export const GET_CATEGORY_RECEIPT_BY_ID_SUCCESS =
  "GET_CATEGORY_RECEIPT_BY_ID_SUCCESS";
export const GET_CATEGORY_RECEIPT_BY_ID_FAILURE =
  "GET_CATEGORY_RECEIPT_BY_ID_FAILURE";

export const ADD_UPDATE_BILL_RECEIPT = "ADD_UPDATE_BILL_RECEIPT";
export const ADD_UPDATE_BILL_RECEIPT_SUCCESS =
  "ADD_UPDATE_BILL_RECEIPT_SUCCESS";
export const ADD_UPDATE_BILL_RECEIPT_FAILURE =
  "ADD_UPDATE_BILL_RECEIPT_FAILURE";

export const GET_BILL_RECEIPT_BY_ID = "GET_BILL_RECEIPT_BY_ID";
export const GET_BILL_RECEIPT_BY_ID_SUCCESS = "GET_BILL_RECEIPT_BY_ID_SUCCESS";
export const GET_BILL_RECEIPT_BY_ID_FAILURE = "GET_BILL_RECEIPT_BY_ID_FAILURE";

export const CLEAR_GENERATED_STATE = "CLEAR_GENERATED_STATE";

//my receipt api call

export const GET_REGISTRATION_DROPDOWN = "GET_REGISTRATION_DROPDOWN";
export const GET_REGISTRATION_DROPDOWN_SUCCESS =
  "GET_REGISTRATION_DROPDOWN_SUCCESS";
export const GET_REGISTRATION_DROPDOWN_FAILURE =
  "GET_REGISTRATION_DROPDOWN_FAILURE";

export const SEARCH_CATEGORY = "SEARCH_CATEGORY";
export const SEARCH_CATEGORY_SUCCESS = "SEARCH_CATEGORY_SUCCESS";
export const SEARCH_CATEGORY_FAILURE = "SEARCH_CATEGORY_FAILURE";

export const SEARCH_BILL_RECEIPT = "SEARCH_BILL_RECEIPT";
export const SEARCH_BILL_RECEIPT_SUCCESS = "SEARCH_BILL_RECEIPT_SUCCESS";
export const SEARCH_BILL_RECEIPT_FAILURE = "SEARCH_BILL_RECEIPT_FAILURE";

export const DELETE_BILL_RECEIPT_BY_ID = "DELETE_BILL_RECEIPT_BY_ID";
export const DELETE_BILL_RECEIPT_BY_ID_SUCCESS =
  "DELETE_BILL_RECEIPT_BY_ID_SUCCESS";
export const DELETE_BILL_RECEIPT_BY_ID_FAILURE =
  "DELETE_BILL_RECEIPT_BY_ID_FAILURE";
