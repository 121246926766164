import React, { useState, useEffect, useRef, useCallback } from "react";
import queryString from "query-string";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import {
  validateEmail,
  password_validate,
  checkUrl,
  checkHtml,
} from "../../utils/helper";
import { createNotification } from "../../utils/notificationManager";
import { planList } from "../../utils/planList";
import {
  PageTitle,
  RedStar,
  InputWithLabelSection,
  InputLabel,
  InputField,
  CommonText,
  CommonGrid,
} from "../../styledComponents/common";
import {
  AuthPageContainer,
  AuthPageRightContainer,
  AuthCard,
  LoginTabContainer,
  LoginActiveTab,
  LoginInactiveTab,
  AuthForm,
  AuthInputContainer,
  ShowPasswordIcon,
  AuthSubmitButton,
  GoogleSignInButton,
  GoogleIcon,
  GoogleButtonText,
  SignUpNameContainer,
  TermsAndConditionsSection,
  AuthFormAlert,
  TermsAndConditionsLink,
  SignUpContainer,
  SignUpSection,
  InputSection,
  Card,
  CardTitle,
  PricingSection,
  CardPricingTitle,
  CardPricingText,
  CardFeatureTextSection,
  TickCircle,
  FeatureText,
  PaymentCard,
  PaymentCardRow,
} from "../../styledComponents/authLayout";

import PasswordStrengthChecker from "../../components/PasswordStrengthChecker/PasswordStrengthChecker";

import TextInput from "../../components/FloatingLabelInput/FloatingLabelInput";

import ButtonTextLoader from "../../components/Loaders/ButtonTextLoader";

import logoWeb from "../../assets/img/logo.svg";

import viewPassword from "../../assets/img/common/password_show.svg";
import hidePassword from "../../assets/img/common/password_hide.svg";
import tick from "../../assets/img/tick.svg";
import { PrimaryButton } from "../../styledComponents/buttons";
import securePayment from "../../assets/img/icons/secure-payment.svg";

import visa from "../../assets/img/common/visa.svg";
import masterCard from "../../assets/img/common/master-card.svg";
import americanExpress from "../../assets/img/common/american-express.svg";
import applePay from "../../assets/img/common/apple-pay.svg";
import paypal from "../../assets/img/common/paypal.svg";

const Paddle = window.Paddle;

const CardUpperContainer = styled.div`
  padding: 80px 7% 25px;
  @media screen and (max-width: 576px) {
    padding: 50px 5% 25px;
  }
`;

const TickCircleComp = () => (
  <TickCircle>
    <img
      style={{ verticalAlign: `initial` }}
      src={tick}
      alt="tick"
      width="100%"
    />
  </TickCircle>
);

const SignUp = (props) => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [invitedEmail, setInvitedEmail] = useState("");
  const [invitedRequireSignUp, setInvitedRequireSignUp] = useState(false);
  const [saveErrors, setSaveErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [passwordIsFocused, setPasswordIsFocused] = useState(false);
  const passwordFieldRef = useRef(null);
  const [plan, setPlan] = useState(null);
  const [isFormSubmitted, SetIsFormSubmitted] = useState(false);
  const methods = {
    setName,
    setPhone,
    setEmail,
    setPassword,
  };

  useEffect(() => {
    props.resetSignUp();
    let token = localStorage.getItem("user_id");
    if (token != null) {
      navigate("/user/dashboard");
    }
    if (
      window.location &&
      window.location.search &&
      window.location.search.length > 0
    ) {
      const queryParsed = queryString.parse(window.location.search);
      if (
        queryParsed.plan &&
        planList[queryParsed.plan] !== undefined &&
        Object.keys(planList[queryParsed.plan]).length > 0
      ) {
        setPlan(planList[queryParsed.plan]);
      } else {
        setPlan(planList.weekly);
      }
    } else {
      window.location.href = "https://receiptmakerly.com/pricing/";
    }
  }, []);

  useEffect(() => {
    if (
      props.redirect &&
      isFormSubmitted &&
      (props.getCredential === null ||
        (props.getCredential && props.getCredential.IsSubscription === false))
    ) {
      Paddle.Checkout.open({
        product: plan.SubscriptionPlanId,
        email: email,
        successCallback: (data) => onPaddleSuccessCallback(data),
        closeCallback: function(data) {
          console.log(data);
          alert(
            `Your purchase has been cancelled, we hope to see you again soon!`
          );
        },
      });
    } else if (props.getCredential && props.getCredential.IsSubscription) {
      createNotification("error", `${email} has already active account`, 3000);
    }
  }, [props.redirect, props.getCredential]);

  useEffect(() => {
    // Moving cursor to the end
    passwordFieldRef.current.selectionStart =
      passwordFieldRef.current.value.length;
    passwordFieldRef.current.selectionEnd =
      passwordFieldRef.current.value.length;
  }, [showPassword]);

  useEffect(() => {
    if (
      props.registeredInfo &&
      props.registeredInfo === "succeeded" &&
      isFormSubmitted
    ) {
      window.location.href = `https://app.receiptmakerly.com/auth/login?email=${email}`;
    }
  }, [props.registeredInfo]);

  const onTextChange = (e) => {
    const { name, value } = e.target;
    let saveErrors = {};
    if (name === "email") {
      if (!invitedRequireSignUp) {
        if (!validateEmail(value.trim())) {
          saveErrors["email"] = "Please enter a valid email address";
        }
      } else {
        return;
      }
    }
    methods[name](value);
    setSaveErrors(saveErrors);
  };
  const checkError = () => {
    let saveErrors = {};
    let formIsValid = true;
    if (!name.trim()) {
      formIsValid = false;
      saveErrors["name"] = "Name Required";
    } else if (name.length > 128) {
      formIsValid = false;
      saveErrors["name"] = "Name can not be more than 128 characters.";
    } else if (checkUrl(name)) {
      formIsValid = false;
      saveErrors["name"] = "Name can not be url.";
    } else if (checkHtml(name)) {
      formIsValid = false;
      saveErrors["name"] = "Invalid Name.";
    }
    if (!phone.trim()) {
      saveErrors["phone"] = "Phone Required";
    }
    if (!password) {
      formIsValid = false;
      saveErrors["password"] = "Password Required";
    } else if (password.length > 32) {
      formIsValid = false;
      saveErrors["password"] = "Password can not be more than 32 characters.";
    } else if (password_validate(password) === false) {
      formIsValid = false;
    }
    if (!email.trim()) {
      formIsValid = false;
      saveErrors["email"] = "Email Required";
    } else if (!invitedEmail) {
      if (!validateEmail(email.trim())) {
        formIsValid = false;
        saveErrors["email"] = "Please enter a valid email address";
      }
    }
    if (!plan || Object.keys(plan).length === 0) {
      formIsValid = false;
    }
    setSaveErrors(saveErrors);
    return formIsValid;
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    if (checkError()) {
      let payload = {
        UserId: 0,
        Currency: "USD",
        SubscriptionPlanId: plan.SubscriptionPlanId && plan.SubscriptionPlanId,
        SubscriptionPlanName:
          plan.SubscriptionPlanName && plan.SubscriptionPlanName,
        Name: name,
        MobileNo: phone,
        EmailId: email,
        Password: password,
      };
      SetIsFormSubmitted(true);
      props.signUp(payload);
    }
  };

  const onPaddleSuccessCallback = (data) => {
    const payload = {
      UserId: 0,
      Name: name,
      MobileNo: phone,
      EmailId: email,
      Password: password,
      IsActive: true,
      CreatedDate: data.checkout.created_at,
      IsSubscription: true,
      SubscriptionPlanId: data.product.id,
      UserSubscriptionId: data.subscription_id,
      SubscriptionPlanName: data.product.name,
      SubscriptionPlanType: data.checkout.recurring_prices.interval.type,
      SubscriptionPlanLength: data.checkout.recurring_prices.interval.length,
      ExpMonth: 0,
      ExpYear: 0,
      Currency: data.checkout.prices.customer.currency,
      PaymentAmount: data.checkout.prices.customer.total,
      Country: data.user.country,
      TokenId: data.checkout.id,
    };
    props.addRegistration(payload);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
    passwordFieldRef.current.focus();
  };
  return (
    <SignUpContainer>
      <CommonGrid columns="220px auto">
        <img src={logoWeb} alt="receipt makerly" width="100%" />
        <PrimaryButton
          style={{ marginLeft: `auto` }}
          onClick={() => navigate("/auth/login")}
        >
          Login
        </PrimaryButton>
      </CommonGrid>
      <PageTitle
        fontSize="24px"
        fontWeight="600"
        style={{ textAlign: `center`, marginTop: `20px` }}
      >
        Register & Subscribe to Proceed
      </PageTitle>
      <SignUpSection>
        <InputSection>
          <InputWithLabelSection>
            <InputLabel>
              Name<RedStar>*</RedStar>
            </InputLabel>
            <InputField
              type="text"
              id="name"
              name="setName"
              placeholder="name..."
              value={name}
              error={saveErrors && saveErrors.name}
              onChange={(e) => onTextChange(e)}
            />
          </InputWithLabelSection>

          <InputWithLabelSection>
            <InputLabel>
              Phone Number<RedStar>*</RedStar>
            </InputLabel>
            <InputField
              type="text"
              id="phone"
              name="setPhone"
              placeholder="phone number..."
              value={phone}
              error={saveErrors && saveErrors.phone}
              onChange={(e) => onTextChange(e)}
            />
          </InputWithLabelSection>

          <InputWithLabelSection>
            <InputLabel>
              Email<RedStar>*</RedStar>
            </InputLabel>
            <InputField
              type="email"
              id="email"
              name="setEmail"
              placeholder="email..."
              value={email}
              error={saveErrors && saveErrors.email}
              onChange={(e) => onTextChange(e)}
            />
          </InputWithLabelSection>

          <InputWithLabelSection>
            <InputLabel>
              Password<RedStar>*</RedStar>
            </InputLabel>
            <AuthInputContainer>
              <InputField
                ref={passwordFieldRef}
                type={showPassword ? "text" : "password"}
                id="password"
                name="setPassword"
                value={password}
                error={saveErrors && saveErrors.password}
                onChange={(e) => onTextChange(e)}
                onFocus={() => setPasswordIsFocused(true)}
                onBlur={() => setPasswordIsFocused(false)}
              />
              <ShowPasswordIcon
                src={showPassword ? hidePassword : viewPassword}
                alt=""
                active={passwordIsFocused}
                onClick={(e) => toggleShowPassword(e)}
              />
            </AuthInputContainer>
          </InputWithLabelSection>

          {((password && password.length > 0) || passwordIsFocused) && (
            <PasswordStrengthChecker password={password} />
          )}
          {saveErrors &&
            saveErrors !== {} &&
            (saveErrors.name || saveErrors.email || saveErrors.password) && (
              <AuthFormAlert style={{ marginTop: "15px" }}>
                {saveErrors.name || saveErrors.email || saveErrors.password}
              </AuthFormAlert>
            )}
        </InputSection>

        {plan && Object.keys(plan).length > 0 && (
          <Card hoverBorder={plan.color}>
            <CardTitle color={plan.color}>
              {plan.SubscriptionPlanName}
            </CardTitle>
            <PricingSection bgColor={plan.color}>
              <CardPricingTitle> {plan.price} </CardPricingTitle>
              <CardPricingText>Recurring Plan. Cancel Anytime</CardPricingText>
            </PricingSection>
            <CardFeatureTextSection>
              <TickCircleComp />
              <FeatureText>Custom Receipts</FeatureText>
              <TickCircleComp />
              <FeatureText>All Receipt Styles</FeatureText>
              <TickCircleComp />
              <FeatureText>Custom Fonts</FeatureText>
              <TickCircleComp />
              <FeatureText>No Watermark</FeatureText>
            </CardFeatureTextSection>
          </Card>
        )}
      </SignUpSection>

      {plan && Object.keys(plan).length > 0 && (
        <PaymentCard>
          <PageTitle>
            Total Due:{" "}
            {plan.price && plan.price.slice(0, plan.price.lastIndexOf("/ "))}{" "}
          </PageTitle>
          <CommonText textAlign="center">
            By clicking the button below, you agree to our Terms of Services and
            Privacy Policies.
          </CommonText>

          <AuthSubmitButton
            disabledBgColor="rgba(99, 91, 255, 0.4)"
            type="submit"
            disabled={!name || !email || !password_validate(password) || !phone}
            isDisabled={
              !name || !email || !password_validate(password) || !phone
            }
            marginTop={
              saveErrors &&
              saveErrors !== {} &&
              (saveErrors.name ||
                saveErrors.email ||
                saveErrors.password ||
                saveErrors.phone)
                ? "11px"
                : null
            }
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "initial",
              padding: "0 30px",
              margin: "20px 0",
            }}
            onClick={(e) => handleSignUp(e)}
          >
            {props.isLoading ? (
              <ButtonTextLoader loadingText="Submitting" />
            ) : (
              "Register & Subscribe"
            )}
          </AuthSubmitButton>

          <PaymentCardRow>
            <img src={securePayment} alt="secure payment" width="25px" />
            <CommonText>Secure Payments</CommonText>
          </PaymentCardRow>
          <PaymentCardRow>
            <img src={visa} alt="secure payment" width="100%" />
            <img src={masterCard} alt="secure payment" width="100%" />
            <img src={americanExpress} alt="secure payment" width="100%" />
            <img src={applePay} alt="secure payment" width="100%" />
            <img src={paypal} alt="secure payment" width="100%" />
          </PaymentCardRow>
        </PaymentCard>
      )}

      {/* <TermsAndConditionsSection>
        <p style={{ margin: "0" }}>
          By clicking the button above, you agree to our{" "}
          <TermsAndConditionsLink
            href="https://apploye.com/terms-of-service/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            terms of services{" "}
          </TermsAndConditionsLink>{" "}
          and{" "}
          <TermsAndConditionsLink
            href="https://apploye.com/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            privacy policies{" "}
          </TermsAndConditionsLink>
        </p>
      </TermsAndConditionsSection> */}
    </SignUpContainer>
  );
};

export default SignUp;
