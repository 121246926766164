import React from "react";
import {
  RoundLetterSection,
  FirstWordRound,
  TableText,
} from "../../styledComponents/common";

const FirstRoundLetter = ({ text, backColor, size, fontSize, gap }) => {
  const colorList = [
    { color: "#73C0FF" },
    { color: "#9A96F9" },
    { color: "#F9D059" },
    { color: "#F9828B" }
  ];
  let selectedIndex = 0;
  if (backColor) {
    if (backColor < 4) {
      selectedIndex = backColor;
    } else {
      selectedIndex = backColor % 4;
    }
  }
  return (
    <FirstWordRound
      backColor={colorList[selectedIndex].color}
      size={size}
      fontSize={fontSize}
    >
      {" "}
      {text.slice(0, 1).toUpperCase()}{" "}
    </FirstWordRound>
  );
};

export default FirstRoundLetter;
