import React, { useState, useEffect } from "react";
import {
  AuthPageLeftContainer,
  LogoImg,
  SloganText,
  AuthPageArtwork,
} from "../../styledComponents/authLayout";
import logoWeb from "../../assets/img/logo.svg";
import AuthArtwork from "../../assets/img/common/auth_artwork.svg";
import banner from "../../assets/img/banner.png";

const AuthPageLeftSection = () => {
  return (
    <AuthPageLeftContainer>
      <LogoImg src={logoWeb} alt="receipt makerly" />
      <AuthPageArtwork src={banner} alt="banner"  width="100%" />
    </AuthPageLeftContainer>
  );
};

export default AuthPageLeftSection;
