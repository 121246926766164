import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import AuthLayout from "./layouts/Auth/Auth.jsx";
import AdminLayout from "./layouts/Admin";
import { PrivateRoute } from "./utils/privateRoute.js";
import routes from "./routes";

function App() {
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/user" && prop.component) {
        const Element = prop.component;
        return (
          <Route path={prop.path} element={<Element />} key={key} />
        );
      } else {
        return null;
      }
    });
  };

  const getRoutesAuth = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/auth" && prop.component) {
        const Element = prop.component;
        return (
          <Route
            path={prop.layout + prop.path}
            element={<Element />}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <Routes>
      <Route path="/auth" element={<AuthLayout />}>
        {getRoutesAuth(routes)}
      </Route>
      <Route path="/user" element={<PrivateRoute />}>
        {getRoutes(routes)}
        </Route>
      <Route
        path="/"
        exact
        element={<Navigate to="/auth/login" replace />}
      />
    </Routes>
  );
}

export default App;
