import React from "react";
import styled from "styled-components";
import { css } from "@emotion/react";
import PulseLoader from "react-spinners/PulseLoader";

const customCss = css`
  margin: 3px;
  padding-top: 5px;
  text-align: center;
`;

const LoaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadingText = styled.div`
  font-size: ${({ fontSize }) => fontSize || `16px`};
  font-weight: ${({ fontWeight }) => fontWeight || `600`};
  color: ${({ color }) => color || "#fff"};
`;

const TextLoader = (props) => {
  return (
    <div className="sweet-loading">
      <PulseLoader
        size={props.size || 4}
        color={props.color || "#fff"}
        loading={props.loading}
        css={customCss}
      />
    </div>
  );
};

const ButtonTextLoader = (props) => {
  return (
    <LoaderContainer>
      <LoadingText
        fontSize={props.fontSize || "16px"}
        fontWeight={props.fontWeight || "600"}
        color={props.color || "#fff"}
      >
        {props.loadingText || "Loading"}
      </LoadingText>
      <TextLoader
        size={props.size}
        loading
        color={props.color || "#fff"}
      />
    </LoaderContainer>
  );
};

export default ButtonTextLoader;
