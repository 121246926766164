/* eslint-disable react/display-name */
import React from "react";
import Select from "react-select";
import { DropdownIndicator, IndicatorSeparator } from "./DropdownIndicator";

export default (props) => {
  return (
    <Select components={{ IndicatorSeparator, DropdownIndicator }} {...props} />
  );
};
