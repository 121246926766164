import React, { useState, useEffect, useRef } from "react";
import { Routes, Route, useLocation, Outlet } from "react-router-dom";

const Auth = () => {
    const { pathname } = useLocation();
    const wrapperRef = useRef(null);

    useEffect(() => {
        if (wrapperRef && wrapperRef.current) {
          document.documentElement.scrollTop = 0;
          document.scrollingElement.scrollTop = 0;
          wrapperRef.current.scrollTop = 0;
        }
      }, [pathname]);
    

  return (
    <div ref={wrapperRef}>
      <Outlet />
    </div>
  );
};

export default Auth;
