import {
  SEARCH_SUBSCRIPTION,
  SEARCH_SUBSCRIPTION_SUCCESS,
  SEARCH_SUBSCRIPTION_FAILURE,
  UPDATE_SUBSCRIPTION_PLAN,
  UPDATE_SUBSCRIPTION_PLAN_SUCCESS,
  UPDATE_SUBSCRIPTION_PLAN_FAILURE,
  CANCEL_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_FAILURE,
  UPDATE_PAYMENT_METHOD,
  UPDATE_PAYMENT_METHOD_SUCCESS,
  UPDATE_PAYMENT_METHOD_FAILURE,
  UPGRADE_PLAN,
  UPGRADE_PLAN_SUCCESS,
  UPGRADE_PLAN_FAILURE,
  CLEAR_CURRENT_SUBSCRIPTION,
  CLEAR_SUBSCRIPTION_UPDATED,
  LOGIN_PROFILE_CREATED_RESET,
} from "../../utils/constants";

import { createNotification } from "../../utils/notificationManager";

export const getOrganizationSubscriptionRequestHandler = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const getOrganizationSubscriptionRequestSuccessHandler = (
  state,
  action
) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200 && data.data.length > 0) {
    return {
      ...state,
      isLoading: false,
      currentSubscription: data.data[0],
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};
export const getOrganizationSubscriptionRequestFailureHandler = (
  state,
  action
) => {
  const {
    payload: { response },
  } = action;
  return {
    ...state,
    isLoading: false,
    currentSubscription: null,
  };
};

export const updateSubscriptionPlanRequestHandler = (state, action) => {
  return {
    ...state,
    updateSubscriptionIsLoading: true,
    subscriptionUpdateInfo: false,
  };
};
export const updateSubscriptionPlanRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200 && data.data) {
    createNotification("success", "Payment successfully completed & Account Subscription plan updated.", 3000);
    return {
      ...state,
      updateSubscriptionIsLoading: false,
      subscriptionUpdateInfo: data.data,
    };
  }
  if (data.status === 200 && !data.data) {
    createNotification("error", "Error occured in updating payment subscription", 3000);
    return {
      ...state,
      updateSubscriptionIsLoading: false,
      subscriptionUpdateInfo: data.data,
    };
  }
  return {
    ...state,
    updateSubscriptionIsLoading: false,
    subscriptionUpdateInfo: false,
  };
};

export const updateSubscriptionPlanRequestFailureHandler = (state, action) => {
  return {
    ...state,
    updateSubscriptionIsLoading: false,
    subscriptionUpdateInfo: false,
  };
};

//UPGRADE PLAN
export const upgradePlanRequestHandler = (state, action) => {
  return {
    ...state,
    upgradePlanIsLoading: true,
    upgradePlanInfo: null,
  };
};
export const upgradePlanRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    createNotification("success", "Payment successfully completed & Account Subscription plan upgraded.", 3000);
    return {
      ...state,
      upgradePlanIsLoading: false,
      upgradePlanInfo: data.data,
    };
  }
  return {
    ...state,
    upgradePlanIsLoading: false,
    upgradePlanInfo: null,
  };
};

export const upgradePlanRequestFailureHandler = (state, action) => {
  return {
    ...state,
    upgradePlanIsLoading: false,
    upgradePlanInfo: null,
  };
};



//Cancel subscription
export const cancelSubscriptionRequestHandler = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const cancelSubscriptionSuccessRequestHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    createNotification(
      "success",
      "Subscription plan cancelled successfully",
      2000
    );
    return {
      ...state,
      isCancelled: data.data,
      isLoading: false,
    };
  }
  return {
    ...state,
    isCancelled: false,
    isLoading: false,
  };
};
export const cancelSubscriptionFailureRequestHandler = (state, action) => {
  const {
    payload: { response },
  } = action;
  createNotification("error", "Error in Subscription cancel", 2000);
  return {
    ...state,
    isCancelled: false,
    isLoading: false,
  };
};

//update payment method
export const updatePaymentMethodRequestHandler = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const updatePaymentMethodSuccessRequestHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    // createNotification("success", "Payment method updated successfully.", 2000);
    return {
      ...state,
      changePaymentMethodInfo: data.data,
      isLoading: false,
    };
  }
  return {
    ...state,
    changePaymentMethodInfo: null,
    isLoading: false,
  };
};
export const updatePaymentMethodFailureRequestHandler = (state, action) => {
  // createNotification("error", "Error in Payment method change.", 2000);
  return {
    ...state,
    changePaymentMethodInfo: null,
    isLoading: false,
  };
};

export const clearCurrentSubscription = (state, action) => {
  return {
    ...state,
    currentSubscription: null,
  };
};

export const clearSubscriptionUpdated = (state, action) => {
  return {
    ...state,
    subscriptionUpdateInfo: false,
  };
};

export const handleLogoutRequest = (state, action) => {
  return {
    ...state,
    ...initialState,
  };
};

// Action Handlers
const ACTION_HANDLERS = {
  [SEARCH_SUBSCRIPTION]: getOrganizationSubscriptionRequestHandler,
  [SEARCH_SUBSCRIPTION_SUCCESS]: getOrganizationSubscriptionRequestSuccessHandler,
  [SEARCH_SUBSCRIPTION_FAILURE]: getOrganizationSubscriptionRequestFailureHandler,

  [UPDATE_SUBSCRIPTION_PLAN]: updateSubscriptionPlanRequestHandler,
  [UPDATE_SUBSCRIPTION_PLAN_SUCCESS]: updateSubscriptionPlanRequestSuccessHandler,
  [UPDATE_SUBSCRIPTION_PLAN_FAILURE]: updateSubscriptionPlanRequestFailureHandler,

  [UPGRADE_PLAN]: upgradePlanRequestHandler,
  [UPGRADE_PLAN_SUCCESS]: upgradePlanRequestSuccessHandler,
  [UPGRADE_PLAN_FAILURE]: upgradePlanRequestFailureHandler,

  [CANCEL_SUBSCRIPTION]: cancelSubscriptionRequestHandler,
  [CANCEL_SUBSCRIPTION_SUCCESS]: cancelSubscriptionSuccessRequestHandler,
  [CANCEL_SUBSCRIPTION_FAILURE]: cancelSubscriptionFailureRequestHandler,

  [UPDATE_PAYMENT_METHOD]: updatePaymentMethodRequestHandler,
  [UPDATE_PAYMENT_METHOD_SUCCESS]: updatePaymentMethodSuccessRequestHandler,
  [UPDATE_PAYMENT_METHOD_FAILURE]: updatePaymentMethodFailureRequestHandler,

  [CLEAR_CURRENT_SUBSCRIPTION]: clearCurrentSubscription,

  [CLEAR_SUBSCRIPTION_UPDATED]: clearSubscriptionUpdated,

  [LOGIN_PROFILE_CREATED_RESET]: handleLogoutRequest,
};

// default initial state
const initialState = {
  isLoading: false,
  updateSubscriptionIsLoading: false,
  upgradePlanIsLoading: false,
  redirect: false,
  currentSubscription: null,
  subscriptionUpdateInfo: false,
  isCancelled: false,
  changePaymentMethodInfo: null,
  upgradePlanInfo: null,
};

export default function subscriptionReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
