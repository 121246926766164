import { colors } from "./colors";
import { fontWeightConfig } from "../common";

export const sidebarConfiguration = {
  sidebarWidth: "215px",
  sidebarMiniWidth: "80px",
  sidebarBorderColor: "rgba(133, 145, 158, 0.2)",
  collapseButtonShadow: "0px 6px 6px #698ebe29",
  textColor: "rgba(61, 77, 105, 0.8)",
  fontSize: "13px",
  hoverColor: colors.textSecondary,
  activeColor: colors.primary,
  singleLink: {
    activeBgColor: colors.primary,
    activeTextColor: colors.white,
    activeBorderRadius: "6px",
    activeHeight: "34px",
  },
  linkGroup: {
    borderColor: "rgba(133, 145, 158, 0.1)",
    fontWeight: fontWeightConfig.medium,
  },
  collapseLink: {
    width: "15px",
    height: "15px",
    fontSize: "10px",
    fontWeight: fontWeightConfig.bold,
    textColor: colors.white,
    bgColor: "rgba(61, 77, 105, 0.3)",
    hoverBgColor: "rgba(61, 77, 105, 0.5)",
    activeBgColor: colors.primary,
  },
  imageFilter: {
    hover: `brightness(0) saturate(100%) invert(27%) sepia(11%)
    saturate(1622%) hue-rotate(180deg) brightness(97%) contrast(89%)`,
    active: `brightness(0) saturate(100%) invert(100%) sepia(100%)
    saturate(0%) hue-rotate(89deg) brightness(104%) contrast(101%)`,
    seeMoreActive: `brightness(0) saturate(100%) invert(66%) sepia(26%) 
    saturate(6964%) hue-rotate(132deg) brightness(102%) contrast(75%)`,
  },
  profileSection: {
    height: "65px",
    nameFontSize: "14px",
    nameTextColor: colors.textSecondary,
  },
};
