//Effects
import { all, put, takeLatest } from "redux-saga/effects";

//Service
import { defaultApi } from "../../utils/axiosApi";

//Constants
import {
  SIGN_UP_REQUEST,
  SIGN_UP_REQUEST_SUCCESS,
  SIGN_UP_REQUEST_FAILURE,
  ADD_REGISTRATION,
  ADD_REGISTRATION_SUCCESS,
  ADD_REGISTRATION_FAILURE,
} from "../../utils/constants";;

export function* signUpRequest({ payload }) {
  try {
    let url = "registration/GetRegistrationByCredential";
    let method = "POST";
    const response = yield defaultApi(url, method, payload);
    yield put({
      type: SIGN_UP_REQUEST_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: SIGN_UP_REQUEST_FAILURE,
      payload: err,
    });
  }
}

export function* addRegistrationRequest({ payload }) {
  try {
    const { ...details } = payload;
    const url = `registration/AddRegistration`;
    const method = "POST";
    const response = yield defaultApi(url, method, details);

    yield put({
      type: ADD_REGISTRATION_SUCCESS,
      payload: { data: response, notify: "POST" },
    });
  } catch (err) {
    yield put({
      type: ADD_REGISTRATION_FAILURE,
      payload: err,
    });
  }
}


function* signUpWatcher() {
  yield all([
    takeLatest(SIGN_UP_REQUEST, signUpRequest),
    takeLatest(ADD_REGISTRATION, addRegistrationRequest),
  ]);
}

export default signUpWatcher;
