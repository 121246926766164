import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

export const AuthPageContainer = styled.div`
  width: 100%;
  max-width: 100%;
  min-height: 100vh;
  background: #f6f9fb;
  display: grid;
  grid-template-columns: 45% 30%;
  justify-content: space-evenly;
  align-content: center;
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 35px;
    align-content: space-around;
  }
`;
export const AuthPageLeftContainer = styled.div`
  display: grid;
  grid-row-gap: 4vh;
  padding-left: 10%;
  @media screen and (max-width: 768px) {
    grid-row-gap: 30px;
    padding: 0 5%;
  }
`;
export const LogoImg = styled.img`
  width: 210px;
  @media screen and (max-width: 768px) {
    justify-self: center;
  }
`;
export const AuthPageArtwork = styled.img`
  margin-top: auto;
  @media screen and (max-width: 768px) {
    width: 80%;
    justify-self: center;
  }
`;
export const SloganText = styled.div`
  color: ${({ theme }) => theme.colors.main.textMain};
  font-size: 32px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  @media screen and (max-width: 768px) {
    text-align: center;
  }
  @media screen and (max-width: 576px) {
    font-size: 24px;
  }
`;

export const AuthPageRightContainer = styled.div`
  display: grid;
  grid-template-columns: 100%;
`;
export const AuthCard = styled.div`
  max-width: 430px;
  min-height: 520px;
  align-self: center;
  background: ${({ theme }) => theme.colors.main.white};
  border-radius: 20px;
  box-shadow: 0px 20px 40px #94a6ce1a;
  padding: 75px 7% 25px;
  animation: ${(props) => (props.from ? `grow 1s 1` : `none`)};

  @keyframes grow {
    0% {
      min-height: 65vh;
    }
    100% {
      min-height: 80vh;
    }
  }
  @media screen and (max-width: 1480px) {
    min-height: 490px;
  }
  @media screen and (max-width: 768px) {
    max-width: 80%;
    min-height: auto;
    width: 80%;
    justify-self: center;
  }
  @media screen and (max-width: 576px) {
    max-width: 90%;
    width: 90%;
    padding: 50px 5% 25px;
  }
`;

export const AuthCardTitle = styled.div`
  font-size: 24px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.colors.main.textMain};
  padding: 0 15px;
`;
export const AuthCardSubtitle = styled.div`
  margin-top: 10px;
  color: ${({ theme }) => theme.colors.main.labelText};
  padding: 0 15px;
`;

export const LoginTabContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 210px;
  height: 45px;
  padding: 4px;
  background: #ddf1ee;
  border-radius: 25px;
`;
export const LoginActiveTab = styled.div`
  width: 50%;
  text-align: center;
  border-radius: 25px;
  background: ${({ theme }) => theme.colors.main.primary};
  font-size: 15px;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.colors.main.white};
  padding: 7px 20px;
  border: none;
  cursor: pointer;
`;
export const LoginInactiveTab = styled(Link)`
  width: 50%;
  font-size: 15px;
  color: ${({ theme }) => theme.colors.main.textSecondary};
  padding: 7px 20px;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.main.textMain};
    text-decoration: none;
  }
`;

export const AuthForm = styled.form`
  width: 100%;
  margin-top: ${({ page }) => (page === "signUp" ? "4rem" : "3.5rem")};
  padding: 0px 15px;
  @media screen and (max-width: 1400px) {
    margin-top: ${({ page }) => (page === "signUp" ? "2rem" : "3rem")};
  }
`;
export const AuthFormAlert = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.colors.main.error};
  font-size: 13px;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  text-align: left;
`;

export const AuthInputContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const ShowPasswordIcon = styled.img`
  width: ${(props) => (props.active ? "32px" : "25px")};
  position: absolute;
  cursor: pointer;
  right: 10px;
  bottom: ${(props) => (props.active ? "4px" : "6px")};
  transition: width 0.3s ease-in-out;
`;

export const ForgotPasswordDiv = styled.div`
  display: flex;
  justify-content: ${(props) => (props.alert ? "space-between" : "flex-end")};
  margin-top: ${({ marginTop }) => marginTop || "10px"};
`;
export const ForgotPasswordText = styled(Link)`
  font-size: 14px;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.colors.main.labelText};
  text-decoration: none;
  &:hover {
    color: ${({ theme }) => theme.colors.main.textSecondary};
    text-decoration: none;
  }
`;

export const AuthSubmitButton = styled.button`
  background: ${({ theme }) => theme.button.primary.bgColor};
  height: 50px;
  width: 100%;
  border: none;
  color: ${({ theme }) => theme.button.primary.textColor};
  border-radius: ${({ theme }) => theme.button.borderRadius};
  font-size: 16px;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  margin-top: ${({ marginTop }) => marginTop || "45px"};
  &:disabled {
    background-color: ${({ disabledBgColor, theme }) =>
      disabledBgColor || theme.button.primary.disabledBgColor};
    cursor: not-allowed;
  }
  &:focus {
    outline: none;
  }
  ${({ isDisabled }) =>
    !isDisabled &&
    css`
      &:hover {
        background-color: ${({ theme }) => theme.button.primary.hoverBgColor};
      }
    `}
`;

export const GoogleSignInButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 56px;
  width: 100%;
  border: 1px solid #e4e7eb;
  background: #fff;
  margin-top: 25px;
  border-radius: 6px;
`;

export const GoogleIcon = styled.img`
  height: 21px;
  width: 21px;
`;

export const GoogleButtonText = styled.span`
  font-size: 14px;
  margin-left: 10px;
  font-weight: 600;
  color: rgba(47, 57, 78, 0.6);
`;

export const SignUpNameContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const TermsAndConditionsSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 49px;
  width: 100%;
  background: #f7fafb;
  bottom: 0;
  border-radius: 10px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.main.labelText};
  text-align: center;
  padding: 12px 15px;
`;
export const TermsAndConditionsLink = styled.a`
  color: ${({ theme }) => theme.colors.main.blue};
  cursor: pointer;
  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.hover.blue};
  }
  &:visited {
    color: ${({ theme }) => theme.colors.hover.blue};
  }
`;
export const SignUpContainer = styled.div`
  display: grid;
  justify-content: center;
  align-content: center;
  gap: 25px;
  min-height: 100vh;
  padding: 40px 30px;
`;
export const SignUpSection = styled.div`
  display: grid;
  grid-template-columns: 260px 300px;
  justify-content: center;
  gap: 6em;
  align-items: start;
`;
export const InputSection = styled.div`
  display: grid;
  gap: 20px;
  align-content: start;
`;
export const Card = styled.div`
  display: grid;
  align-content: space-between;
  gap: 2em;
  padding: 30px;
  border: 1px solid #ffffff;
  border: ${({ yearly }) => yearly && `1px solid #635BFF`};
  border-radius: 10px;
  box-shadow: 0 20px 40px #94a6ce29;
  background-color: #fff;
  &:hover {
    border-color: ${({ hoverBorder }) => hoverBorder && hoverBorder};
  }
`;
export const CardTitle = styled.h4`
  color: ${({ color }) => color || `#635BFF`};
  font-size: 600;
  text-align: center;
  margin-bottom: 0%;
`;
export const PricingSection = styled.div`
  padding: 20px;
  background-color: ${({ bgColor }) => bgColor || `#635BFF`};
  border-radius: 10px;
  margin: 0 -15px;
  text-align: center;
`;
export const CardPricingTitle = styled.p`
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 10px;
  color: #ffffff;
`;
export const CardPricingText = styled.p`
  color: #fff;
  font-size: 11px;
  font-weight: 500;
`;
export const CardFeatureTextSection = styled.div`
  display: grid;
  grid-template-columns: 26px 1fr;
  gap: 20px 11px;
  align-items: center;
`;
export const TickCircle = styled.div`
  display: flex;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #b9c3e6;
  padding: 4px;
`;
export const FeatureText = styled.p`
  font-size: 14px;
  margin: 0;
  @media (max-width: 575px) {
    font-size: 13px;
  }
`;

export const PaymentCard = styled.div`
  display: grid;
  grid-template-columns: 400px;
  justify-content: center;
  justify-items: center;
  gap: 20px;
  padding: 40px 30px;
  border: 1px solid #635bff;
  border-radius: 10px;
`;
export const PaymentCardRow = styled.div`
  display: flex;
  gap: 10px;
`;
