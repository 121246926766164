import {
  REQUEST_USER_PROFILE,
  REQUEST_USER_PROFILE_SUCCESS,
  REQUEST_USER_PROFILE_FAILURE,
  DELETE_ACCOUNT,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_FAILURE,
  GET_USER_EMAILS,
  GET_USER_EMAILS_SUCCESS,
  GET_USER_EMAILS_FAILURE,
  ADD_EMAIL,
  ADD_EMAIL_SUCCESS,
  ADD_EMAIL_FAILURE,
  DELETE_EMAIL,
  DELETE_EMAIL_SUCCESS,
  DELETE_EMAIL_FAILURE,
  MAKE_EMAIL_DEFAULT,
  MAKE_EMAIL_DEFAULT_SUCCESS,
  MAKE_EMAIL_DEFAULT_FAILURE,
  LOGIN_PROFILE_CREATED_RESET,
} from "../../utils/constants";
import { createNotification } from "../../utils/notificationManager";

import { ownerProfileData } from "../../datasets/members";

// ------------------------------------
// Action handler methods
// ------------------------------------
export const profileRequestHandler = (state, action) => {
  if (action.payload.method && action.payload.method === "GET") {
    return {
      ...state,
      isLoading: true,
      profileData: ownerProfileData,
    };
  }
  return {
    ...state,
    updateIsLoading: true,
  };
};
export const profileRequestSuccessHandler = (state, action) => {
  const {
    payload: { data, notify, timezoneOffset },
  } = action;
  // if (data.status === 200) {
  //   localStorage.setItem(
  //     "user_fullName",
  //     `${data.data.first_name}${
  //       data.data.last_name ? ` ${data.data.last_name}` : ``
  //     }`
  //   );
  //   if (notify === true) {
  //     createNotification("success", "Profile update success", 3000);
  //     if (data.data && data.data.timezone && timezoneOffset) {
  //       localStorage.setItem(
  //         "user_timezone",
  //         JSON.stringify({
  //           tz: data.data.timezone,
  //           offset: timezoneOffset,
  //         })
  //       );
  //     }
  //     return {
  //       ...state,
  //       isLoading: false,
  //       updateIsLoading: false,
  //       redirect: state.redirect + 1,
  //       profileData: data.data,
  //     };
  //   }
  //   return {
  //     ...state,
  //     isLoading: false,
  //     updateIsLoading: false,
  //     profileData: data.data,
  //   };
  // }
  // return {
  //   ...state,
  //   redirect: state.redirect + 1,
  //   isLoading: false,
  //   updateIsLoading: false,
  // };
  return {
    ...state,
    isLoading: false,
    updateIsLoading: false,
  };
};
export const profileRequestFailureHandler = (state, action) => {
  return {
    ...state,
    isLoading: false,
    updateIsLoading: false,
  };
};

export const handleDeleteAccount = (state, action) => {
  return {
    ...state,
    deleteAccountLoading: true,
  };
};
export const handleDeleteAccountSuccess = (state, action) => {
  const {
    payload: { data, notify },
  } = action;
  if (data.status === 200) {
    if (notify === true) {
      createNotification("success", data.data.success, 3000);
      return {
        ...state,
        deleteAccountLoading: false,
        redirect: state.redirect + 1,
      };
    }
    return {
      ...state,
      deleteAccountLoading: false,
    };
  }
  return {
    ...state,
    redirect: state.redirect + 1,
    deleteAccountLoading: false,
  };
};
export const handleDeleteAccountFailure = (state, action) => {
  if (action.payload.response) {
    if (action.payload.response.data && action.payload.response.data.failure) {
      createNotification("error", action.payload.response.data.failure, 3000);
    } else {
      createNotification("error", "Something went wrong!", 3000);
    }
  } else {
    createNotification("error", "Please check your internet connection", 3000);
  }
  return {
    ...state,
    deleteAccountLoading: false,
  };
};

export const handleGetUserEmails = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const handleGetUserEmailsSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      isLoading: false,
      userEmails: data.data,
    };
  }
  return {
    ...state,
    isLoading: false,
    userEmails: null,
  };
};
export const handleGetUserEmailsFailure = (state, action) => {
  return {
    ...state,
    isLoading: false,
    userEmails: null,
  };
};

export const handleAddEmail = (state, action) => {
  return {
    ...state,
    addEmailLoading: true,
    requestUserEmails: false,
  };
};
export const handleAddEmailSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 201) {
    return {
      ...state,
      addEmailLoading: false,
      requestUserEmails: true,
    };
  }
  return {
    ...state,
    addEmailLoading: false,
    userEmails: null,
    requestUserEmails: true,
  };
};
export const handleAddEmailFailure = (state, action) => {
  createNotification(
    "error",
    "There was an error while adding your email. Please try again",
    3000
  );
  return {
    ...state,
    addEmailLoading: false,
    requestUserEmails: false,
  };
};

export const handleDeleteEmail = (state, action) => {
  return {
    ...state,
    deleteEmailLoading: true,
    requestUserEmails: false,
  };
};
export const handleDeleteEmailSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 204) {
    return {
      ...state,
      deleteEmailLoading: false,
      requestUserEmails: true,
    };
  }
  return {
    ...state,
    deleteEmailLoading: false,
    userEmails: null,
    requestUserEmails: true,
  };
};
export const handleDeleteEmailFailure = (state, action) => {
  createNotification(
    "error",
    "There was an error deleting your email. Please try again",
    3000
  );
  return {
    ...state,
    deleteEmailLoading: false,
    requestUserEmails: false,
  };
};

export const handleMakeEmailDefault = (state, action) => {
  return {
    ...state,
    requestUserEmails: false,
  };
};
export const handleMakeEmailDefaultSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      requestUserEmails: true,
    };
  }
  return {
    ...state,
    isLoading: false,
    userEmails: null,
    requestUserEmails: true,
  };
};
export const handleMakeEmailDefaultFailure = (state, action) => {
  createNotification("error", "There was an error !! Please try again", 3000);
  return {
    ...state,
    requestUserEmails: false,
  };
};

export const handleLogoutRequest = (state, action) => {
  return {
    ...state,
    ...initialState,
  };
};

// Action Handlers
const ACTION_HANDLERS = {
  [REQUEST_USER_PROFILE]: profileRequestHandler,
  [REQUEST_USER_PROFILE_SUCCESS]: profileRequestSuccessHandler,
  [REQUEST_USER_PROFILE_FAILURE]: profileRequestFailureHandler,
  [DELETE_ACCOUNT]: handleDeleteAccount,
  [DELETE_ACCOUNT_SUCCESS]: handleDeleteAccountSuccess,
  [DELETE_ACCOUNT_FAILURE]: handleDeleteAccountFailure,
  [GET_USER_EMAILS]: handleGetUserEmails,
  [GET_USER_EMAILS_SUCCESS]: handleGetUserEmailsSuccess,
  [GET_USER_EMAILS_FAILURE]: handleGetUserEmailsFailure,
  [ADD_EMAIL]: handleAddEmail,
  [ADD_EMAIL_SUCCESS]: handleAddEmailSuccess,
  [ADD_EMAIL_FAILURE]: handleAddEmailFailure,
  [DELETE_EMAIL]: handleDeleteEmail,
  [DELETE_EMAIL_SUCCESS]: handleDeleteEmailSuccess,
  [DELETE_EMAIL_FAILURE]: handleDeleteEmailFailure,
  [MAKE_EMAIL_DEFAULT]: handleMakeEmailDefault,
  [MAKE_EMAIL_DEFAULT_SUCCESS]: handleMakeEmailDefaultSuccess,
  [MAKE_EMAIL_DEFAULT_FAILURE]: handleMakeEmailDefaultFailure,
  [LOGIN_PROFILE_CREATED_RESET]: handleLogoutRequest,
};

// default initial state
const initialState = {
  isLoading: false,
  updateIsLoading: false,
  deleteAccountLoading: false,
  addEmailLoading: false,
  failure: false,
  userEmails: null,
  requestUserEmails: false,
  redirect: 0,
  profileData: {},
};

export default function verifyReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
