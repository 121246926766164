import {
  GET_WEEKLY_EMAIL_SETTINGS,
  UPDATE_WEEKLY_EMAIL_SETTINGS,
} from "../../utils/constants";

// ------------------------------------
// Actions
// ------------------------------------
export function getWeeklyEmailSettings(value) {
  return {
    type: GET_WEEKLY_EMAIL_SETTINGS,
    payload: value,
  };
}

export function updateWeeklyEmailSettings(value) {
  return {
    type: UPDATE_WEEKLY_EMAIL_SETTINGS,
    payload: value,
  };
}
