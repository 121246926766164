import styled from "styled-components";

export const InputSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;

export const AssigneesHeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 37% 20%;
  justify-content: start;
  align-content: center;
  grid-gap: 25px;
  max-width: 1200px;
  margin-top: 30px;
  @media (max-width: 450px) {
    grid-template-columns: 33% 20%;
    grid-column-gap: 15px;
  }
  @media (max-width: 330px) {
    grid-column-gap: 10px;
  }
`;

export const AssigneesInfoContainer = styled.div`
  display: grid;
  grid-template-columns: 37% 20% 5%;
  justify-content: start;
  align-content: center;
  grid-gap: 25px;
  max-width: 1200px;
  padding: 15px 0;
  @media (max-width: 450px) {
    grid-template-columns: 33% 20% auto;
    grid-gap: 25px 15px;
  }
  @media (max-width: 330px) {
    grid-gap: 25px 10px;
  }
`;

//Team Details
export const TeamMemberDivContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  grid-gap: 12px;
`;
export const TeamMemberDiv = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: ${({ columns }) => columns || `40px auto`};
  grid-column-gap: 12px;
  padding: ${({ padding }) => padding || "10px 15px"};
  border: 1px solid ${({ theme }) => theme.colors.border.secondaryLight};
  border-radius: ${({ theme }) => theme.borderRadii.button};
  min-height: 82px;
  align-items: center;
`;
export const TeamMemberAvatar = styled.img`
  height: ${({ size }) => size || "40px"};
  width: ${({ size }) => size || "40px"};
  border-radius: 50%;
`;
export const NameRoleSection = styled.div`
  display: grid;
  grid-row-gap: 8px;
  justify-content: flex-start;
`;
export const TeamMemberRole = styled.div`
  background-color: ${({ bgColor }) => bgColor || `#ECF0FC`};
  padding: 4px 10px;
  border-radius: 14px;
  color: ${({ color }) => color || `#9A96F9`};
  font-size: 12px;
  white-space: nowrap;
  text-align: center;
`;

export const TeamProjectsDivContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 12px;
`;

export const TeamProjectDiv = styled.div`
  background: ${({ theme }) => theme.colors.main.whiteBg};
  border: 1px solid ${({ theme }) => theme.colors.border.secondaryLight};
  border-radius: ${({ theme }) => theme.borderRadii.button};
  min-height: 45px;
  padding: 8px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${({ theme }) => theme.font.pageText.size};
  color: ${({ theme }) => theme.colors.main.textSecondary};
`;

// ----- TEAM TABLE -----
export const TeamTableHeadContainer = styled.div`
  display: grid;
  grid-template-columns: 22% 10% 15% 22% 13%;
  grid-column-gap: 20px;
  justify-content: space-between;
  padding: 0 30px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.secondary};
  @media (max-width: 1250px) {
    grid-template-columns: 22% 10% 15% 1fr 13%;
    grid-column-gap: 10px;
  }
  @media (max-width: 1024px) {
    grid-template-columns: 200px 100px 120px 200px 120px;
  }
`;
export const TeamTableContainer = styled.div`
  display: grid;
  grid-template-columns: 22% 10% 15% 22% 13%;
  grid-column-gap: 20px;
  justify-content: space-between;
  padding: 0 30px;
  :nth-of-type(even) {
    background-color: #f8f9fc;
  }
  @media (max-width: 1250px) {
    grid-template-columns: 22% 10% 15% 1fr 13%;
    grid-column-gap: 10px;
  }
  @media (max-width: 1024px) {
    grid-template-columns: 200px 100px 120px 200px 120px;
  }
`;
export const TableItem = styled.div`
  display: grid;
  padding: ${({ buttonPadding }) => (buttonPadding ? `10px 0` : `15px 0`)};
  align-content: center;
`;

export const AssignedProjectBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  border: 1px solid
    ${({ borderColor, theme }) =>
      borderColor || theme.colors.border.secondaryLight};
  border-radius: ${({ theme }) => theme.borderRadii.button};
  color: ${({ color, theme }) => color || theme.font.pageText.colorSecondary};
  font-size: ${({ fontSize, theme }) => fontSize || "13px"};
  font-weight: ${({ fontWeight, theme }) =>
    fontWeight || theme.font.pageText.weight};
  white-space: nowrap;
  width: 130px;
`;

export const ContainerUpperPart = styled.div`
  display: grid;
  grid-template-columns: 1fr 180px;
  justify-content: space-between;
  align-content: center;
  grid-gap: 15px;
`;

export const ContainerUpperTextSection = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: start;
  align-content: center;
  grid-column-gap: 40px;
`;

export const DropdownImage = styled.div`
  cursor: pointer;
`;

export const TeamEditSection = styled.div`
  display: grid;
  grid-template-columns: auto 35px;
  justify-content: start;
  align-items: center;
  grid-column-gap: 20px;
  min-height: 52px;
`;

// ----- ADD TEAM -----
export const InputTickSection = styled.div`
  display: grid;
  grid-template-columns: ${({ columns }) => columns || `auto 35px`};
  grid-column-gap: 15px;
  justify-content: start;
  align-items: center;
  margin: ${({ margin }) => margin || "0"};
`;
export const TickCircle = styled.div`
  position: relative;
  display: grid;
  justify-content: center;
  align-content: center;
  border-radius: ${({ theme }) => theme.borderRadii.circle};
  background-color: ${({ theme }) => theme.colors.main.white};
  width: 36px;
  height: 36px;
  border: 1px solid ${({ theme }) => theme.colors.border.secondaryLight};
  cursor: pointer;

  &:hover {
    border-color: ${({ theme }) => theme.colors.border.primary};
  }
`;
