import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  gap: ${({ gap }) => gap || `3em`};
  min-height: ${({ minHeight }) => minHeight && minHeight};
  align-content: ${({ alignContent }) => alignContent && alignContent};
  padding: 30px 10%;
  background-color: #f6f9fb;
  @media (max-width: 1440px) {
    padding: 30px 5%;
  }
  @media (max-width: 1440px) {
    padding: 30px 5%;
  }
  @media (max-width: 1024px) {
    padding: 30px;
    gap: 30px;
  }
  @media (max-width: 575px) {
    grid-template-columns: 1fr;
  }
`;
export const Form = styled.div`
  position: relative;
  background-color: #ffffff;
  padding: 40px 30px;
  border-radius: 10px;
  display: grid;
  gap: 15px;
  align-content: start;
`;
export const ImageContainer = styled(Form)`
  grid-template-columns: minmax(auto, 360px);
  justify-content: center;
  @media (max-width: 575px) {
    margin-top: 20px;
  }
`;

export const ColoredTab = styled.div`
  position: absolute;
  top: -25px;
  left: 4px;
  border-radius: 6px 6px 0 0;
  background-color: ${({ form }) => (form ? `#6AB7E8` : `#49CBB7`)};
  padding: 5px 12px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 500px;
`;
export const GenerateButtonSection = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  margin: 0 -30px;
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  padding: 15px 30px;
  border-top: 1px solid #e4e7eb;
  background-color: #ffffff;
`;
export const ImageSection = styled.div`
  position: sticky;
  top: 80px;
  left: 0;
`;
export const TaxInputSection = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  gap: 15px;
  justify-content: start;
`;
export const TaxRowTable = styled.div`
  display: grid;
  grid-template-columns: 50px minmax(160px, 250px) 200px 75px;
  gap: 15px;
  border-bottom: 1px solid #e4e7eb;
  padding: 10px;
`;
export const ItemRowContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 10px;
  align-items: center;
`;
export const ItemInputContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 10px;
  justify-items: start;
`;
export const DividedSection = styled.div`
  display: grid;
  grid-row-gap: ${({ gap }) => gap || "6px"};
  margin: 0 -30px;
  padding: 15px 30px;
  border-top: 1px solid #e4e7eb;
`;
export const ItemRowTable = styled.div`
  display: grid;
  grid-template-columns: 60px 60px minmax(120px, 1fr) minmax(95px, 100px) 80px 95px 85px;
  border: 1px solid #e4e7eb;
  border-top: ${({ heading }) => !heading && `none`};
`;
export const ItemRowCell = styled.p`
  margin: 0;
  color: ${({ color, theme }) => color || theme.font.inputLabel.color};
  font-size: ${({ fontSize, theme }) => fontSize || theme.font.inputLabel.size};
  font-weight: ${({ fontWeight, theme }) =>
    fontWeight || theme.font.inputLabel.weight};
  padding: 10px;
  border-right: 1px solid #e4e7eb;
  line-height: 3;
`;
export const FileUpdloadLogo = styled.label`
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
`;
export const RemoveImageIcon = styled.div`
  position: absolute;
  right: -15px;
  top: -15px;
  display: flex;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  cursor: pointer;
  background-color: #FFF;
  border: ${({ theme }) => `2px solid ${theme.colors.main.error}`};
`;
export const ImgPreviewSection = styled.div`
  position: relative;
  margin-right: auto;
`;
export const ImageAlert = styled.span`
  font-size: ${({ fontSize }) => fontSize || "13px"};
  margin: ${({ margin }) => margin || "5px 0 0 5px"};
  font-weight: 600;
`;
