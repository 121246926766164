import spaceXLogo from "../assets/img/dataImages/spacex-logo.svg";
import oceanaLogo from "../assets/img/dataImages/oceana-logo.svg";

export const organizationsListData = [
  {
    id: "s1dty88f-5yfd-4fg5-b6c2-eeae27f8347c",
    name: "SpaceX",
    description: null,
    color: "#708CEB",
    logo: spaceXLogo,
    organization_type: {
      id: "002abc09-65d0-49f2-bcba-8ref107ba641",
      name: "IT & Software",
    },
    address: "37 Hawthorne, California.",
    timezone: "US/Pacific",
    timezoneoffset: "-0500",
    is_active: true,
    role: "owner",
    plan: {
      id: "1f73f75d-bb42-4fd9-7857-8rte69c95375",
      name: "Elite",
      amount: 7.0,
      trial_period_days: 10,
      interval: "monthly",
      screenshot_track: true,
      screenshot_blur: false,
      screenshot_frequency: 1,
      application_track: true,
      url_track: true,
      keystroke_track: true,
      mouse_track: true,
      location_track: true,
      time_off_holidays: true,
      trial_in_days: 10,
    },
    discount: null,
    subscription: {
      id: "e9e74280-2dfa-459c-bf89-c8d32etec529",
      plan: {
        id: "1f73f75d-bb42-4fd9-8b57-8duu869c95375",
        name: "Elite",
        amount: 7.0,
        trial_period_days: 10,
        interval: "monthly",
        screenshot_track: true,
        screenshot_blur: false,
        screenshot_frequency: 1,
        application_track: true,
        url_track: true,
        keystroke_track: true,
        mouse_track: true,
        location_track: true,
        time_off_holidays: true,
        trial_in_days: 10,
        grandfathered: false,
      },
      status: "active",
      trial_till: null,
      prepurchased_count: 0,
      update_url: null,
      cancel_url: null,
    },
    code: "kCJYPp",
    configuration: {
      id: "715139ae-5f15-4fb5-ad45-b436dcd602e7",
      is_active: true,
      created_at: "2019-03-17T09:18:23.856693",
      modified_at: "2022-02-06T05:54:55.599840",
      screenshot_track: true,
      screenshot_blur: false,
      screenshot_frequency: 2,
      application_track: true,
      url_track: true,
      keystroke_track: true,
      mouse_track: true,
      location_track: true,
      time_off_holidays: false,
      weekend_days: null,
      user_delete_screenshot: true,
      receive_weekly_email: false,
      currency: "USD",
      created_by: null,
      modified_by: null,
    },
  },
  {
    id: "4bcfb309-e861-4c9d-bd9a-fdb8rree6b427",
    name: "Oceana Inc.",
    description: null,
    color: "#76C794",
    logo: oceanaLogo,
    organization_type: {
      id: "002abc09-65d0-49f2-bcba-8eaf107ba641",
      name: "Agency",
    },
    address: "Washington, DC 236, USA",
    timezone: "America/New_York",
    timezoneoffset: "-0400",
    is_active: true,
    role: "owner",
    plan: {
      id: "09680ffc-77db-469b-b452-7197442e8d72",
      name: "Standard",
      amount: 5.0,
      trial_period_days: 10,
      interval: "monthly",
      screenshot_track: false,
      screenshot_blur: false,
      screenshot_frequency: 1,
      application_track: false,
      url_track: false,
      keystroke_track: false,
      mouse_track: false,
      location_track: false,
      time_off_holidays: true,
      trial_in_days: 10,
    },
    discount: null,
    subscription: {
      id: "f1b74e5c-114a-4b6b-badd-a6f4b2e5red0",
      plan: {
        id: "09680ffc-77db-469b-b452-7197442e8d72",
        name: "Standard",
        amount: 5.0,
        trial_period_days: 10,
        interval: "monthly",
        screenshot_track: false,
        screenshot_blur: false,
        screenshot_frequency: 1,
        application_track: false,
        url_track: false,
        keystroke_track: false,
        mouse_track: false,
        location_track: false,
        time_off_holidays: true,
        trial_in_days: 10,
        grandfathered: false,
      },
      status: "active",
      trial_till: null,
      prepurchased_count: 0,
      update_url: null,
      cancel_url: null,
    },
    code: "b4adFR",
    configuration: {
      id: "524c778f-46ba-4fd6-97d2-cab16ea53010",
      is_active: true,
      created_at: "2019-10-13T09:53:54.011960",
      modified_at: "2019-10-13T09:53:54.011977",
      screenshot_track: false,
      screenshot_blur: false,
      screenshot_frequency: 1,
      application_track: false,
      url_track: false,
      keystroke_track: false,
      mouse_track: false,
      location_track: false,
      time_off_holidays: true,
      weekend_days: null,
      user_delete_screenshot: true,
      receive_weekly_email: true,
      currency: "USD",
      created_by: null,
      modified_by: null,
    },
  },
];

export const selectedOrganizationData = organizationsListData[0];
