import { createStore, applyMiddleware, compose } from "redux";

import createSagaMiddleware from "redux-saga";
import rootReducer from "./reducers";
import sagas from "./sagas";

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
  rootReducer(),
  compose(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(sagas);
