import { createGlobalStyle, css } from "styled-components";

export default createGlobalStyle`
  ${({ theme }) => css`
    * {
      box-sizing: border-box;
      font-family: ${theme.defaultFontFamily};
      /* color: ${theme.colors.main.textMain}; */
      font-weight: ${theme.fontWeights.regular};
      letter-spacing: 0;
    }
    body {
      color: ${theme.colors.main.textMain};
    }
  `}
`;
