// Effects
import { all, call, put, takeLatest } from "redux-saga/effects";
// Service
import { defaultApi, headerPayloadApi } from "../../utils/axiosApi";
// Constants
import {
  GET_FONT,
  GET_FONT_SUCCESS,
  GET_FONT_FAILURE,
  GET_TAX,
  GET_TAX_SUCCESS,
  GET_TAX_FAILURE,
  GET_CATEGORY_RECEIPT_BY_ID,
  GET_CATEGORY_RECEIPT_BY_ID_SUCCESS,
  GET_CATEGORY_RECEIPT_BY_ID_FAILURE,
  ADD_UPDATE_BILL_RECEIPT,
  ADD_UPDATE_BILL_RECEIPT_SUCCESS,
  ADD_UPDATE_BILL_RECEIPT_FAILURE,
  GET_BILL_RECEIPT_BY_ID,
  GET_BILL_RECEIPT_BY_ID_SUCCESS,
  GET_BILL_RECEIPT_BY_ID_FAILURE,
} from "../../utils/constants";

//get fonts list
export function* getFontList() {
  try {
    let url = `master/GetFontDropdown`;
    let method = "GET";
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_FONT_SUCCESS,
      payload: { data: response, notify: "GET" },
    });
  } catch (err) {
    yield put({
      type: GET_FONT_FAILURE,
      payload: err,
    });
  }
}

//get tax list
export function* getTaxList({ payload }) {
  const { is_active } = payload;
  try {
    let url = `master/GetTax?IsActive=${is_active}`;
    let method = "GET";
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_TAX_SUCCESS,
      payload: { data: response, notify: "GET" },
    });
  } catch (err) {
    yield put({
      type: GET_TAX_FAILURE,
      payload: err,
    });
  }
}

//get template info by id
export function* getCategoryReceiptById({ payload }) {
  const { category_reciept_id } = payload;
  try {
    let url = `category/GetCategoryRecieptById?CategoryRecieptId=${category_reciept_id}`;
    let method = "GET";
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_CATEGORY_RECEIPT_BY_ID_SUCCESS,
      payload: { data: response, notify: "GET" },
    });
  } catch (err) {
    yield put({
      type: GET_CATEGORY_RECEIPT_BY_ID_FAILURE,
      payload: err,
    });
  }
}

export function* updateBillReceipt({ payload }) {
  try {
    const { ...details } = payload;
    const url = `billreciept/AddUpdateBillReciept`;
    const method = "POST";
    const response = yield headerPayloadApi(url, method, details);

    yield put({
      type: ADD_UPDATE_BILL_RECEIPT_SUCCESS,
      payload: { data: response, notify: "POST" },
    });
  } catch (err) {
    yield put({
      type: ADD_UPDATE_BILL_RECEIPT_FAILURE,
      payload: err,
    });
  }
}

//get bill receipt info by id
export function* getBillReceiptById({ payload }) {
  const { bill_reciept_id } = payload;
  try {
    let url = `billreciept/GetBillRecieptById?BillRecieptId=${bill_reciept_id}`;
    let method = "GET";
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_BILL_RECEIPT_BY_ID_SUCCESS,
      payload: { data: response, notify: "GET" },
    });
  } catch (err) {
    yield put({
      type: GET_BILL_RECEIPT_BY_ID_FAILURE,
      payload: err,
    });
  }
}



export default function* root() {
  yield all([
    takeLatest(GET_FONT, getFontList),
    takeLatest(GET_TAX, getTaxList),
    takeLatest(GET_CATEGORY_RECEIPT_BY_ID, getCategoryReceiptById),
    takeLatest(ADD_UPDATE_BILL_RECEIPT, updateBillReceipt),
    takeLatest(GET_BILL_RECEIPT_BY_ID, getBillReceiptById),
  ]);
}
