export const planList = {
    weekly: {
      SubscriptionPlanName: "Weekly",
      price: "$4.90/ week",
      SubscriptionPlanId: "597937",
      color: "#729EB2",
    },
    monthly: {
      SubscriptionPlanName: "Monthly",
      price: "$8.90/ month",
      SubscriptionPlanId: "597941",
      color: "#0DC3E8",
    },
    yearly: {
      SubscriptionPlanName: "Yearly",
      price: "$47.00/ year",
      SubscriptionPlanId: "598805",
      color: "#635BFF",
    },
  };