import { connect } from "react-redux";

// Component
import CurrentPlan from "./CurrentPlan";

// Actions
import {
  searchSubscription,
  cancelSubscription,
  updatePaymentMethod,
  updateSubscriptionPlan,
} from "../subscriptionActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  userId: localStorage.getItem("user_id"),
  isLoading: state.subscription.isLoading,
  currentPlan: state.subscription.currentSubscription,
  isCancelled: state.subscription.isCancelled,
  changePaymentMethodInfo: state.subscription.changePaymentMethodInfo,
  updateSubscriptionIsLoading: state.subscription.updateSubscriptionIsLoading,
  subscriptionUpdateInfo: state.subscription.subscriptionUpdateInfo,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  searchSubscription: (payload) => dispatch(searchSubscription(payload)),
  cancelSubscription: (payload) => dispatch(cancelSubscription(payload)),
  updatePaymentMethod: (payload) => dispatch(updatePaymentMethod(payload)),
  updateSubscriptionPlan: (payload) => dispatch(updateSubscriptionPlan(payload)),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(CurrentPlan);
