// Effects
import { all, delay, put, select, takeLatest } from "redux-saga/effects";
import { defaultApi, headerPayloadApi } from "../../utils/axiosApi";
// Constants
import {
  GET_REGISTRATION_DROPDOWN,
  GET_REGISTRATION_DROPDOWN_SUCCESS,
  GET_REGISTRATION_DROPDOWN_FAILURE,
  SEARCH_CATEGORY,
  SEARCH_CATEGORY_SUCCESS,
  SEARCH_CATEGORY_FAILURE,
  SEARCH_BILL_RECEIPT,
  SEARCH_BILL_RECEIPT_SUCCESS,
  SEARCH_BILL_RECEIPT_FAILURE,
  DELETE_BILL_RECEIPT_BY_ID,
  DELETE_BILL_RECEIPT_BY_ID_SUCCESS,
  DELETE_BILL_RECEIPT_BY_ID_FAILURE,
} from "../../utils/constants";

export function* getRegistrationDropdown({ payload }) {
  const { role } = payload;
  try {
    let url = `registration/GetRegistrationDropdown?Role=${role}`;
    let method = "GET";
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_REGISTRATION_DROPDOWN_SUCCESS,
      payload: { data: response, notify: "GET" },
    });
  } catch (err) {
    yield put({
      type: GET_REGISTRATION_DROPDOWN_FAILURE,
      payload: err,
    });
  }
}

export function* searchCategory({ payload }) {
  const { ...details } = payload;
  try {
    let url = `category/SearchCategory`;
    let method = "POST";
    const response = yield defaultApi(url, method, details);
    yield put({
      type: SEARCH_CATEGORY_SUCCESS,
      payload: { data: response, notify: "POST" },
    });
  } catch (err) {
    yield put({
      type: SEARCH_CATEGORY_FAILURE,
      payload: err,
    });
  }
}

export function* searchBillReceipt({ payload }) {
  const { ...details } = payload;
  try {
    let url = `billreciept/SearchBillReciept`;
    let method = "POST";
    const response = yield defaultApi(url, method, details);
    yield put({
      type: SEARCH_BILL_RECEIPT_SUCCESS,
      payload: { data: response, notify: "POST" },
    });
  } catch (err) {
    yield put({
      type: SEARCH_BILL_RECEIPT_FAILURE,
      payload: err,
    });
  }
}

export function* deleteBillReceiptById({ payload }) {
  const { bill_reciept_id } = payload;
  try {
    let url = `billreciept/DeleteBillRecieptById?BillRecieptId=${bill_reciept_id}`;
    let method = "GET";
    const response = yield defaultApi(url, method);
    yield put({
      type: DELETE_BILL_RECEIPT_BY_ID_SUCCESS,
      payload: { data: response, notify: "GET" },
    });
  } catch (err) {
    yield put({
      type: DELETE_BILL_RECEIPT_BY_ID_FAILURE,
      payload: err,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(GET_REGISTRATION_DROPDOWN, getRegistrationDropdown),
    takeLatest(SEARCH_CATEGORY, searchCategory),
    takeLatest(SEARCH_BILL_RECEIPT, searchBillReceipt),
    takeLatest(DELETE_BILL_RECEIPT_BY_ID, deleteBillReceiptById),
  ]);
}
