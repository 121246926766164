import {
  GET_INVOICE_LIST,
  CREATE_INVOICE_REQUEST,
  GET_INVOICE_DETAILS,
  UPDATE_INVOICE_REQUEST,
  DELETE_INVOICE,
  CREATE_INVOICE_ITEM,
  UPDATE_INVOICE_ITEM,
  DELETE_INVOICE_ITEM,
  CREATE_INVOICE_TAX,
  UPDATE_INVOICE_TAX,
  GET_INVOICE_TAX,
  UPDATE_INVOICE_DISCOUNT,
  SEND_INVOICE_EMAIL,
  GET_INVOICE_NUMBER,
  RECORD_PAYMENT_INVOICE,
  GET_RECORD_PAYMENT_INVOICE,
  DELETE_RECORD_PAYMENT_INVOICE,
  GET_INVOICE_HISTORY,
  INVOICE_PUBLIC_VIEW,
  INVOICE_UPDATE_SEND_LOG,
  INVOICE_LINK_PROJECT,
  CLEAR_INVOICE_LINKED_PROJECT,
  IS_MAIL_SEND_FROM_EDIT,
} from "../../utils/constants";

// ------------------------------------
// Actions
// ------------------------------------

export function getInvoiceList(value) {
  return {
    type: GET_INVOICE_LIST,
    payload: value,
  };
}

export function createInvoice(value) {
  return {
    type: CREATE_INVOICE_REQUEST,
    payload: value,
  };
}

export function getInvoiceDetails(value) {
  return {
    type: GET_INVOICE_DETAILS,
    payload: value,
  };
}

export function updateInvoice(value) {
  return {
    type: UPDATE_INVOICE_REQUEST,
    payload: value,
  };
}

export function deleteInvoice(value) {
  return {
    type: DELETE_INVOICE,
    payload: value,
  };
}

export function createInvoiceItem(value) {
  return {
    type: CREATE_INVOICE_ITEM,
    payload: value,
  };
}

export function updateInvoiceItem(value) {
  return {
    type: UPDATE_INVOICE_ITEM,
    payload: value,
  };
}

export function deleteInvoiceItem(value) {
  return {
    type: DELETE_INVOICE_ITEM,
    payload: value,
  };
}

export function createInvoiceTax(value) {
  return {
    type: CREATE_INVOICE_TAX,
    payload: value,
  };
}

export function updateInvoiceTax(value) {
  return {
    type: UPDATE_INVOICE_TAX,
    payload: value,
  };
}

export function getInvoiceTax(value) {
  return {
    type: GET_INVOICE_TAX,
    payload: value,
  };
}

export function updateInvoiceDiscount(value) {
  return {
    type: UPDATE_INVOICE_DISCOUNT,
    payload: value,
  };
}

export function sendInvoiceEmail(value) {
  return {
    type: SEND_INVOICE_EMAIL,
    payload: value,
  };
}

export function getInvoiceNumber(value) {
  return {
    type: GET_INVOICE_NUMBER,
    payload: value,
  };
}

export function recordPaymentInvoice(value) {
  return {
    type: RECORD_PAYMENT_INVOICE,
    payload: value,
  };
}

export function getRecordPaymentInvoice(value) {
  return {
    type: GET_RECORD_PAYMENT_INVOICE,
    payload: value,
  };
}

export function deleteRecordPaymentInvoice(value) {
  return {
    type: DELETE_RECORD_PAYMENT_INVOICE,
    payload: value,
  };
}

export function getInvoiceHistory(value) {
  return {
    type: GET_INVOICE_HISTORY,
    payload: value,
  };
}

export function invoicePublicView(value) {
  return {
    type: INVOICE_PUBLIC_VIEW,
    payload: value,
  };
}

export function createInvoiceUpdateSendLog(value) {
  return {
    type: INVOICE_UPDATE_SEND_LOG,
    payload: value,
  };
}

export function invoiceLinkProject(value) {
  return {
    type: INVOICE_LINK_PROJECT,
    payload: value,
  };
}

export function clearInvoiceLinkedProject() {
  return {
    type: CLEAR_INVOICE_LINKED_PROJECT,
  };
}

export function isMailSendFromEdit(value) {
  return {
    type: IS_MAIL_SEND_FROM_EDIT,
    payload: value,
  };
}