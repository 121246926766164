import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Admin from "../layouts/Admin";

export const PrivateRoute = () => {
  return localStorage.getItem("user_id") ? (
    <Admin />
  ) : (
    <Navigate to="/auth/login" />
  );
};
