import React, { useState, useEffect, useRef } from "react";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../../utils/api";

import {
  AuthPageContainer,
  AuthPageRightContainer,
  AuthCard,
  LoginTabContainer,
  LoginActiveTab,
  LoginInactiveTab,
  AuthForm,
  AuthFormAlert,
  AuthInputContainer,
  ShowPasswordIcon,
  ForgotPasswordDiv,
  ForgotPasswordText,
  AuthSubmitButton,
  GoogleSignInButton,
  GoogleIcon,
  GoogleButtonText,
} from "../../styledComponents/authLayout";

import { LoginLoaderContainer } from "../../styledComponents/login";

import TextInput from "../../components/FloatingLabelInput/FloatingLabelInput";

import AuthPageLeftSection from "../../components/AuthLayout/AuthPageLeftSection";

import ButtonTextLoader from "../../components/Loaders/ButtonTextLoader";
import LoginLoader from "./LoginLoader";

import GoogleLogo from "../../assets/img/icons/google_logo.svg";
import viewPassword from "../../assets/img/common/password_show.svg";
import hidePassword from "../../assets/img/common/password_hide.svg";
import { PageTitle } from "../../styledComponents/common";

const Login = ({
  loginRequest,
  auth,
  userId,
  redirect,
  isLoading,
  errorMessage,
  loginRedirectReset,
  currentPlan,
  searchSubscription,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [saveErrors, setSaveErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [passwordIsFocused, setPasswordIsFocused] = useState(false);
  const [redirectLocation, setRedirectLocation] = useState(null);
  const [fromLocation, setFromLocation] = useState(null);
  const [isLoginLoaderOpen, setIsLoginLoaderOpen] = useState(false);
  const passwordFieldRef = useRef(null);
  const methods = {
    setEmail,
    setPassword,
  };
  useEffect(() => {
    let token = localStorage.getItem("user_id");
    if (token != null) {
      setIsLoginLoaderOpen(true);
      navigate("/user/dashboard");
      setIsLoginLoaderOpen(false);
    }
    if (location.state && location.state.from) {
      setFromLocation(location.state.from);
    }
    return () => {
      setFromLocation(null);
    };
  }, []);

  useEffect(() => {
    // Moving cursor to the end
    passwordFieldRef.current.selectionStart =
      passwordFieldRef.current.value.length;
    passwordFieldRef.current.selectionEnd =
      passwordFieldRef.current.value.length;
  }, [showPassword]);

  useEffect(() => {
    const saveErrors = {};
    if (errorMessage) {
      saveErrors["password"] = errorMessage;
    }
    setSaveErrors(saveErrors);
    if (userId && auth && Object.keys(auth).length > 0) {
      localStorage.setItem("user_id", userId);
      localStorage.setItem("subscription_plan_id", auth.SubscriptionPlanId);
      localStorage.setItem("user_name", auth.Name);
      if (auth.Role) {
        // eslint-disable-next-line
        localStorage.setItem("role", auth.role);
      }
      if (
        auth.IsSubscription &&
        (auth.Status === "succeeded" || auth.Status === "active")
      ) {
        localStorage.setItem("plan_status", "active");
        navigate("/user/dashboard");
        loginRedirectReset();
      } else {
        localStorage.setItem("plan_status", "inactive");
        navigate("/user/current-plan");
        loginRedirectReset();
      }
    }
  }, [auth]);

  const onTextChange = (e) => {
    const { name, value } = e.target;
    setSaveErrors({});
    methods[name](value);
  };

  const checkError = () => {
    let errors = { ...saveErrors };
    let formIsValid = true;
    if (errors.password) {
      formIsValid = false;
    }
    if (!email) {
      formIsValid = false;
      errors["email"] = "Email Required";
    }
    if (!password) {
      formIsValid = false;
      errors["password"] = "Password Required";
    }
    setSaveErrors(errors);
    return formIsValid;
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (checkError()) {
      let payload = {
        email: email,
        password,
      };
      loginRequest(payload);
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
    passwordFieldRef.current.focus();
  };

  if (isLoginLoaderOpen) {
    return (
      <LoginLoaderContainer>
        <LoginLoader />
      </LoginLoaderContainer>
    );
  } else {
    return (
      <AuthPageContainer>
        <AuthPageLeftSection />
        <AuthPageRightContainer>
          <AuthCard from={fromLocation}>
            <PageTitle
              fontSize="24px"
              fontWeight="600"
              style={{ marginLeft: `15px` }}
            >
              Sign In
            </PageTitle>
            <AuthForm onSubmit={(e) => handleLogin(e)}>
              <AuthInputContainer>
                <TextInput
                  id="email"
                  name="setEmail"
                  label="Email"
                  type="text"
                  onChange={(e) => onTextChange(e)}
                  value={email}
                  autoFocus
                />
              </AuthInputContainer>
              <AuthInputContainer>
                <TextInput
                  refs={passwordFieldRef}
                  id="password"
                  name="setPassword"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  onChange={(e) => onTextChange(e)}
                  onFocus={() => setPasswordIsFocused(true)}
                  onBlur={() => setPasswordIsFocused(false)}
                  value={password}
                />
                <ShowPasswordIcon
                  src={showPassword ? hidePassword : viewPassword}
                  alt=""
                  active={passwordIsFocused}
                  onClick={(e) => toggleShowPassword(e)}
                />
              </AuthInputContainer>
              <ForgotPasswordDiv
                alert={
                  saveErrors &&
                  saveErrors !== {} &&
                  (saveErrors.email || saveErrors.password)
                }
              >
                {saveErrors &&
                  saveErrors !== {} &&
                  (saveErrors.email || saveErrors.password) && (
                    <AuthFormAlert>
                      {saveErrors.email
                        ? saveErrors.email
                        : saveErrors.password}
                    </AuthFormAlert>
                  )}
                <ForgotPasswordText to="/auth/forgot-password">
                  Forgot Password?
                </ForgotPasswordText>
              </ForgotPasswordDiv>
              <AuthSubmitButton
                type="submit"
                disabled={!email || !password}
                isDisabled={!email || !password}
              >
                {isLoading ? (
                  <ButtonTextLoader loadingText="Logging You In" />
                ) : (
                  "Let Me In"
                )}
              </AuthSubmitButton>
            </AuthForm>
          </AuthCard>
        </AuthPageRightContainer>
      </AuthPageContainer>
    );
  }
};

export default Login;
