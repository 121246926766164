import React, { useState } from "react";
import styled from "styled-components";
import "../../assets/scss/custom.css";

import Select from "../../components/ReactSelectDropdown";
import DropdownStyle from "./DropdownStyle";

import { TableItem } from "../../styledComponents/teams";
import {
  TableText,
  RoundLetterSection,
  FirstWordRound,
  RoundMiniImage,
} from "../../styledComponents/common";

const activeActionOptions = [
  {
    value: "edit",
    label: "Edit",
  },
  { value: "archive", label: "Archive" },
  { value: "delete", label: "Delete" },
];
const archivedActionOptions = [
  {
    value: "edit",
    label: "Edit",
  },
  { value: "unarchive", label: "Unarchive" },
  { value: "delete", label: "Delete" },
];

const TableHeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 2fr 110px 160px 110px 140px;
  grid-column-gap: 4px;
  justify-content: space-between;
  padding: 0 30px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.secondary};
  @media (max-width: 1250px) {
    grid-template-columns: 220px 220px 110px 160px 110px 140px;
    grid-column-gap: 6px;
  }
`;
const TableBodyContainer = styled(TableHeaderContainer)`
  border-bottom: none;
  :nth-of-type(even) {
    background-color: #f8f9fc;
  }
`;

export const RoundLetter = ({ text, backColor, logo, size, fontSize, gap }) => {
  return (
    <RoundLetterSection gap={gap}>
      <RoundMiniImage src={logo} alt="" size={size} />
      {/* <FirstWordRound backColor={backColor} size={size} fontSize={fontSize}>
        {text.slice(0, 1).toUpperCase()}
      </FirstWordRound> */}
      <TableText name>{text}</TableText>
    </RoundLetterSection>
  );
};

const OrganizationsTable = (props) => {
  const [archiveItem, setArchiveItem] = useState(null);
  const [deleteItem, setDeleteItem] = useState(null);

  const { toggleArchive, deleteOrganization, organizationsList, edit } = props;

  const handleToggleArchive = (org = null) => {
    setArchiveItem(org);
  };

  const handleArchiveConfirm = () => {
    toggleArchive(archiveItem);
    setArchiveItem(null);
  };

  const toggleDeleteItem = (org = null) => {
    setDeleteItem(org);
  };

  const handleDeleteItemConfirm = (password, agreed) => {
    if (deleteItem && deleteItem.id) {
      deleteOrganization({
        id: deleteItem.id,
        password: password,
        agreed: agreed,
      });
      setDeleteItem(null);
    }
  };

  const onActionChange = (action, org) => {
    switch (action) {
      case "edit":
        edit(org.id);
        break;
      case "archive":
      case "unarchive":
        handleToggleArchive(org);
        break;
      case "delete":
        toggleDeleteItem(org);
        break;
      default:
      // do nothing
    }
  };

  return (
    <>
      <>
        <TableHeaderContainer>
          <TableItem>
            <TableText>Name</TableText>
          </TableItem>
          <TableItem>
            <TableText>Address</TableText>
          </TableItem>
          {/* <TableItem>
            <TableText>Type</TableText>
          </TableItem> */}
          <TableItem>
            <TableText>Code</TableText>
          </TableItem>
          <TableItem>
            <TableText>Timezone</TableText>
          </TableItem>
          {/* <TableItem>
            <TableText>Plan</TableText>
          </TableItem> */}
          <TableItem>
            <TableText>Currency</TableText>
          </TableItem>
          <TableItem>
            <TableText>Action</TableText>
          </TableItem>
        </TableHeaderContainer>
        {organizationsList &&
          organizationsList.map((org, i) => {
            return (
              <TableBodyContainer key={i}>
                <TableItem>
                  <RoundLetter
                    text={org.name}
                    logo={org.logo}
                    backColor={org.is_active ? org.color : "#9eafc2"}
                  />
                  {/* <TableText name>{org.name}</TableText> */}
                </TableItem>
                <TableItem>
                  <TableText name>{org.address || "-"}</TableText>
                </TableItem>
                {/* <TableItem>
                  <TableText name>
                    {(org.organization_type && org.organization_type.name) ||
                      "-"}
                  </TableText>
                </TableItem> */}
                <TableItem>
                  <TableText name>{org.timezone}</TableText>
                </TableItem>
                <TableItem>
                  <TableText name>
                    {org.configuration && org.configuration.currency}
                  </TableText>
                </TableItem>
                {/* <TableItem className="org-plan">
                    <span
                      className="report-date-span"
                      style={{
                        background:
                          "linear-gradient(to right, #33ccae 0%, #00a99d 100%)",
                        color: "#fff",
                        fontWeight: "700",
                        fontSize: "13px",
                        minWidth: "240px",
                        height: "35px",
                      }}
                    >
                      {org.plan ? org.plan.name : "No plan"}
                    </span>
                  </TableItem> */}
                <TableItem>
                  <Select
                    isSearchable={false}
                    isDisabled={org.role !== "owner"}
                    value={null}
                    options={
                      org.is_active
                        ? activeActionOptions
                        : archivedActionOptions
                    }
                    onChange={(e) =>
                      org.role === "owner" && onActionChange(e.value, org)
                    }
                    placeholder="Actions"
                    styles={DropdownStyle(null)}
                  />
                </TableItem>
              </TableBodyContainer>
            );
          })}
      </>
      {/* {archiveItem && (
        <SweetAlert
          title="Are you sure"
          showCancel
          onConfirm={handleArchiveConfirm}
          onCancel={() => handleToggleArchive()}
          confirmBtnText={archiveItem.is_active ? "Archive" : "Unarchive"}
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="default"
        >
          {archiveItem.is_active
            ? "Do you really want to archive your organization?"
            : "Do you really want to make it active?"}
        </SweetAlert>
      )} */}
    </>
  );
};

export default OrganizationsTable;
