//Effects
import { put, takeLatest } from "redux-saga/effects"; //axios
// import axios from 'axios';
//Service
import { defaultApi } from "../../utils/axiosApi";

//Constants
import {
  LOGIN_REQUEST,
  LOGIN_REQUEST_SUCCESS,
  LOGIN_REQUEST_FAILURE,
} from "../../utils/constants";

//Handle login request
export function* loginRequest({ payload }) {
  const { email, password } = payload;
  try {
    let url = "registration/GetRegistrationByCredential";
    let details = {
      EmailId: email,
      Password: password,
    };
    const response = yield defaultApi(url, "POST", details);
    yield put({
      type: LOGIN_REQUEST_SUCCESS,
      payload: { data: response, notify: "POST" },
    });
  } catch (err) {
    yield put({
      type: LOGIN_REQUEST_FAILURE,
      payload: err,
    });
  }
}

// Watches for the LOGIN_REQUEST action type
// WITH the action we dispatched
export default function* root() {
  yield takeLatest(LOGIN_REQUEST, loginRequest);
}
