import React from "react";
import styled from "styled-components";
import { css } from "@emotion/react";
import PulseLoader from "react-spinners/PulseLoader";

const customCss = css`
  margin: 5px auto;
  text-align: center;
`;

const LoaderContainer = styled.div`
  display: ${({ loading }) => (!loading ? "none" : "flex")};
`;

const DropdownLoader = (props) => {
  return (
    <LoaderContainer loading={props.loading} className="sweet-loading">
      <PulseLoader
        size={props.size || 10}
        color={props.color || "#c2cce1"}
        loading={props.loading}
        css={customCss}
      />
    </LoaderContainer>
  );
};

export default DropdownLoader;
