//Effects
import { all, put, takeLatest } from "redux-saga/effects";

//Service
import { defaultApi } from "../../utils/axiosApi";

//Constants
import {
  SEARCH_SUBSCRIPTION,
  SEARCH_SUBSCRIPTION_SUCCESS,
  SEARCH_SUBSCRIPTION_FAILURE,
  UPDATE_SUBSCRIPTION_PLAN,
  UPDATE_SUBSCRIPTION_PLAN_SUCCESS,
  UPDATE_SUBSCRIPTION_PLAN_FAILURE,
  CANCEL_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_FAILURE,
  APPLY_COUPON,
  APPLY_COUPON_SUCCESS,
  APPLY_COUPON_FAILURE,
  UPDATE_PAYMENT_METHOD,
  UPDATE_PAYMENT_METHOD_SUCCESS,
  UPDATE_PAYMENT_METHOD_FAILURE,
  UPGRADE_PLAN,
  UPGRADE_PLAN_SUCCESS,
  UPGRADE_PLAN_FAILURE,
} from "../../utils/constants";

export function* searchSubscription({ payload }) {
  try {
    const { ...details } = payload;
    let url = `subscriptionplanpay/SearchSubscriptionPlanPay`;
    const method = "POST";
    const response = yield defaultApi(url, method, details);
    yield put({
      type: SEARCH_SUBSCRIPTION_SUCCESS,
      payload: { data: response, notify: "POST" },
    });
  } catch (err) {
    yield put({
      type: SEARCH_SUBSCRIPTION_FAILURE,
      payload: err,
    });
  }
}

export function* updateSubscriptionPlan({ payload }) {
  try {
    const { ...details } = payload;
    let url = `SubscriptionPlanPay/UpdateUserSubscriptionPlan`;
    const method = "POST";
    const response = yield defaultApi(url, method, details);
    yield put({
      type: UPDATE_SUBSCRIPTION_PLAN_SUCCESS,
      payload: { data: response, notify: "POST" },
    });
  } catch (err) {
    yield put({
      type: UPDATE_SUBSCRIPTION_PLAN_FAILURE,
      payload: err,
    });
  }
}

export function* upgradePlanMethod({ payload }) {
  try {
    const { ...details } = payload;
    let url = `SubscriptionPlanPay/AddUpdateSubscriptionPlanPay`;
    const method = "POST";
    const response = yield defaultApi(url, method, details);
    yield put({
      type: UPGRADE_PLAN_SUCCESS,
      payload: { data: response, notify: "POST" },
    });
  } catch (err) {
    yield put({
      type: UPGRADE_PLAN_FAILURE,
      payload: err,
    });
  }
}

export function* applyCoupon({ payload }) {
  try {
    const { selected_plan_id, selected_plan_interval, ...details } = payload;
    let url = "/billing/discounts/";
    const response = yield defaultApi(url, "POST", details);
    yield put({
      type: APPLY_COUPON_SUCCESS,
      payload: {
        data: response,
        selected_plan_id,
        selected_plan_interval,
        organization_id: payload.organization_id,
      },
    });
  } catch (err) {
    yield put({
      type: APPLY_COUPON_FAILURE,
      payload: err,
    });
  }
}

export function* cancelSubscription({ payload }) {
  try {
    const { ...details } = payload;
    let url = `SubscriptionPlanPay/CancelUserSubscription`;
    const method = "POST";
    const response = yield defaultApi(url, method, details);
    yield put({
      type: CANCEL_SUBSCRIPTION_SUCCESS,
      payload: { data: response, notify: "POST" },
    });
  } catch (err) {
    yield put({
      type: CANCEL_SUBSCRIPTION_FAILURE,
      payload: err,
    });
  }
}

export function* updatePaymentMethod({ payload }) {
  try {
    const { ...details } = payload;
    let url = `SubscriptionPlanPay/UpdatePaddlePaymentMethod`;
    const method = "POST";
    const response = yield defaultApi(url, method, details);
    yield put({
      type: UPDATE_PAYMENT_METHOD_SUCCESS,
      payload: { data: response, notify: "POST" },
    });
  } catch (err) {
    yield put({
      type: UPDATE_PAYMENT_METHOD_FAILURE,
      payload: err,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(SEARCH_SUBSCRIPTION, searchSubscription),
    takeLatest(UPDATE_SUBSCRIPTION_PLAN, updateSubscriptionPlan),
    takeLatest(APPLY_COUPON, applyCoupon),
    takeLatest(CANCEL_SUBSCRIPTION, cancelSubscription),
    takeLatest(UPDATE_PAYMENT_METHOD, updatePaymentMethod),
    takeLatest(UPGRADE_PLAN, upgradePlanMethod),
  ]);
}
