import React, { useState, useEffect, useRef } from "react";
import { Navigate, Routes, Route, useLocation, Outlet } from "react-router-dom";

import Navbar from "../../pages/Navbar";
import routes from "../../routes";
import CurrentPlan from "../../pages/Subscription/CurrentPlan";

const Admin = (props) => {
  const { pathname } = useLocation();
  const wrapperRef = useRef(null);

  // useEffect(() => {
  //   if (localStorage.getItem("is_owner")) {
  //     window.$crisp = [];
  //     window.CRISP_WEBSITE_ID = "256df938-dacc-4ac6-bc76-8d8e8493d561";
  //     (function () {
  //       var d = document;
  //       var s = d.createElement("script");
  //       s.src = "https://client.crisp.chat/l.js";
  //       s.async = 1;
  //       d.getElementsByTagName("head")[0].appendChild(s);
  //     })();
  //     if (localStorage.getItem("user_fullName")) {
  //       window.$crisp.push([
  //         "set",
  //         "user:nickname",
  //         localStorage.getItem("user_fullName"),
  //       ]);
  //     }
  //     if (localStorage.getItem("email")) {
  //       window.$crisp.push([
  //         "set",
  //         "user:email",
  //         localStorage.getItem("email"),
  //       ]);
  //     }
  //   }
  // }, []);

  useEffect(() => {
    if (wrapperRef && wrapperRef.current) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      wrapperRef.current.scrollTop = 0;
    }
  }, [pathname]);

  return (
    <div ref={wrapperRef}>
      <div>
        {localStorage.getItem("plan_status") === "active" ? (
          <>
            <Navbar {...props} />
            <Outlet />
          </>
        ) : (
          <>
          <Navbar {...props} />
            <CurrentPlan />
          </>
        )}
      </div>
    </div>
  );
};

export default Admin;
