import React from "react";
import { planList } from "../../../utils/planList";
import {
  CommonText,
  ModalButtonSection,
  PreviewPopup,
  PreviewContainer,
  PageTitle,
} from "../../../styledComponents/common";
import { WhiteButton, ColoredButton } from "../../../styledComponents/buttons";
import ButtonTextLoader from "../../../components/Loaders/ButtonTextLoader";
import {
  CancelCircle,
  CardContainer,
  Card,
  CardTitle,
  PricingSection,
  CardPricingTitle,
  CardPricingText,
  CardFeatureTextSection,
  TickCircle,
  FeatureText,
  CurrentPlanTab,
} from "../subscriptionStyles";
import cross from "../../../assets/img/icons/cross_black.svg";
import tick from "../../../assets/img/icons/tick_2.svg";

const TickCircleComp = () => (
  <TickCircle>
    <img src={tick} alt="tick" width="100%" />
  </TickCircle>
);

const PricingPlanModal = ({ isOpen, plan, toggle, updatePlan, isLoading }) => {
  return (
    <PreviewContainer>
      <PreviewPopup
        maxWidth="930px"
        padding="30px"
        minHeight="400px"
        alignItems="center"
        justifyItems="center"
      >
        <PageTitle>Select plan to proceed</PageTitle>
        <CommonText>
          Try Risk Free with No Questions Asked Money Back Guarantee.
        </CommonText>

        <CardContainer>
          <div style={{ position: `relative` }}>
            <Card hoverBorder="#729EB2" plan={plan === "Weekly"}>
              <CardTitle color="#729EB2">Weekly</CardTitle>
              <PricingSection bgColor="#729EB2">
                <CardPricingTitle>$ 4.90</CardPricingTitle>
                <CardPricingText>
                  Recurring Plan. Cancel Anytime
                </CardPricingText>
              </PricingSection>
              <CardFeatureTextSection>
                <TickCircleComp />
                <FeatureText>Custom Receipts</FeatureText>
                <TickCircleComp />
                <FeatureText>All Receipt Styles</FeatureText>
                <TickCircleComp />
                <FeatureText>Custom Fonts</FeatureText>
                <TickCircleComp />
                <FeatureText>No Watermark</FeatureText>
              </CardFeatureTextSection>
              <ColoredButton
                bgColor="#729EB2"
                radius="6px"
                boxShadow="none"
                hoverBg="#5F8B9F"
                onClick={() => updatePlan(planList.weekly)}
              >
                Choose Plan
              </ColoredButton>
            </Card>
            {plan === "Weekly" && (<CurrentPlanTab bgColor="#729EB2">Current Plan</CurrentPlanTab>)}
          </div>

          <div style={{ position: `relative` }}>
            <Card hoverBorder="#0DC3E8" plan={plan === "Monthly"}>
              <CardTitle color="#0DC3E8">Monthly</CardTitle>
              <PricingSection bgColor="#0DC3E8">
                <CardPricingTitle>$ 8.90</CardPricingTitle>
                <CardPricingText>
                  Recurring Plan. Cancel Anytime
                </CardPricingText>
              </PricingSection>
              <CardFeatureTextSection>
                <TickCircleComp />
                <FeatureText>Custom Receipts</FeatureText>
                <TickCircleComp />
                <FeatureText>All Receipt Styles</FeatureText>
                <TickCircleComp />
                <FeatureText>Custom Fonts</FeatureText>
                <TickCircleComp />
                <FeatureText>No Watermark</FeatureText>
              </CardFeatureTextSection>
              <ColoredButton
                bgColor="#0DC3E8"
                radius="6px"
                boxShadow="none"
                hoverBg="#00CAF4"
                onClick={() => updatePlan(planList.monthly)}
              >
                Choose Plan
              </ColoredButton>
            </Card>
            {plan === "Monthly" && (<CurrentPlanTab bgColor="#0DC3E8">Current Plan</CurrentPlanTab>)}
          </div>

          <div style={{ position: `relative` }}>
            <Card hoverBorder="#635BFF" plan={plan === "Yearly"}>
              <CardTitle>Yearly</CardTitle>
              <PricingSection>
                <CardPricingTitle>$ 47.00</CardPricingTitle>
                <CardPricingText>
                  Recurring Plan. Cancel Anytime
                </CardPricingText>
              </PricingSection>
              <CardFeatureTextSection>
                <TickCircleComp />
                <FeatureText>Custom Receipts</FeatureText>
                <TickCircleComp />
                <FeatureText>All Receipt Styles</FeatureText>
                <TickCircleComp />
                <FeatureText>Custom Fonts</FeatureText>
                <TickCircleComp />
                <FeatureText>No Watermark</FeatureText>
              </CardFeatureTextSection>
              <ColoredButton
                bgColor="#635BFF"
                radius="6px"
                boxShadow="none"
                onClick={() => updatePlan(planList.yearly)}
              >
                Choose Plan
              </ColoredButton>
            </Card>
            {plan === "Yearly" && (<CurrentPlanTab>Current Plan</CurrentPlanTab>)}
          </div>
        </CardContainer>

        <CancelCircle onClick={toggle}>
          <img src={cross} alt="cancel popup" width="100%" />
        </CancelCircle>
      </PreviewPopup>
    </PreviewContainer>
  );
};

export default PricingPlanModal;
