import {
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_REQUEST_SUCCESS,
  FORGOT_PASSWORD_REQUEST_FAILURE,
  CLEAR_FORGOT_PASSWORD_LINK_SENT,
} from "../../utils/constants";
import { createNotification } from "../../utils/notificationManager";

// ------------------------------------
// Action handler methods
// ------------------------------------
export const handleForgotPasswordRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    linkSent: null,
    emailNotExist: false,
    errorMessage: null,
  };
};

export const handleForgotPasswordRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200 && data.data > 1) {
    return {
      ...state,
      isLoading: false,
      redirect: true,
      linkSent: data.data,
      emailNotExist: false,
    };
  }
  if (data.status === 200 && data.data === 0) {
    return {
      ...state,
      isLoading: false,
      linkSent: null,
      emailNotExist: true,
    };
  }
  return {
    ...state,
    isLoading: false,
    emailNotExist: false,
  };
};

export const handleForgotPasswordRequestFailure = (state, action) => {
  if (action.payload.response === undefined) {
    createNotification("error", "No internet connection found", 3000);
    return {
      ...state,
      isLoading: false,
    };
  }
  const {
    payload: {
      response: { data, status },
    },
  } = action;
  if (status === 400) {
    return {
      ...state,
      isLoading: false,
      errorMessage: data.failure,
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};

export const handleClearForgotPasswordLinkSent = (state, action) => {
  return {
    redirect: false,
    isLoading: false,
    linkSent: false,
    emailNotExist: false,
    errorMessage: null,
  };
};

// Action Handlers
const ACTION_HANDLERS = {
  [FORGOT_PASSWORD_REQUEST]: handleForgotPasswordRequest,
  [FORGOT_PASSWORD_REQUEST_SUCCESS]: handleForgotPasswordRequestSuccess,
  [FORGOT_PASSWORD_REQUEST_FAILURE]: handleForgotPasswordRequestFailure,

  [CLEAR_FORGOT_PASSWORD_LINK_SENT]: handleClearForgotPasswordLinkSent,
};

// default initial state
const initialState = {
  redirect: false,
  isLoading: false,
  linkSent: null,
  emailNotExist: false,
  errorMessage: null,
};

export default function forgotPasswordReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
