import React, { useState } from "react";
import {
  AssigneesManageImgTextGrid,
  AssigneesManageRoundImage,
} from "../../styledComponents/createProject";
import {
  RoundMiniImage,
  FirstWordRound,
  TableText,
  MailSendSection,
  ResendInvite,
} from "../../styledComponents/common";

export const CommImageNameComp = (props) => (
  <AssigneesManageImgTextGrid>
    <AssigneesManageRoundImage imageSize={props.size}>
      <RoundMiniImage src={props.imgSource} alt="" imageSize={props.size} />
    </AssigneesManageRoundImage>
    <TableText name>{props.name}</TableText>
  </AssigneesManageImgTextGrid>
);

export const CommImageInviteComp = (props) => (
  <AssigneesManageImgTextGrid>
    <AssigneesManageRoundImage imageSize={props.size}>
      <RoundMiniImage src={props.imgSource} alt="" imageSize={props.size} />
    </AssigneesManageRoundImage>
    <MailSendSection>
      <TableText name>{props.member.email}</TableText>
      <ResendInvite onClick={() => props.resendInvite(props.member)}>
        Resend Invite
      </ResendInvite>
    </MailSendSection>
  </AssigneesManageImgTextGrid>
);

export const CommFirstRoundLetterComp = ({
  text,
  backColor,
  size,
  fontSize,
}) => {
  const colorList = [
    { color: "#9A96F9" },
    { color: "#73C0FF" },
    { color: "#F9828B" },
    { color: "#F9D059" },
  ];
  let selectedIndex = 0;
  if (backColor < 4) {
    selectedIndex = backColor;
  } else {
    selectedIndex = backColor % 4;
  }

  return (
    <FirstWordRound
      backColor={colorList[selectedIndex].color}
      size={size}
      fontSize={fontSize}
    >
      {" "}
      {text.slice(0, 1).toUpperCase()}{" "}
    </FirstWordRound>
  );
};
