import {
  GET_FONT,
  GET_FONT_SUCCESS,
  GET_FONT_FAILURE,
  GET_TAX,
  GET_TAX_SUCCESS,
  GET_TAX_FAILURE,
  GET_CATEGORY_RECEIPT_BY_ID,
  GET_CATEGORY_RECEIPT_BY_ID_SUCCESS,
  GET_CATEGORY_RECEIPT_BY_ID_FAILURE,
  ADD_UPDATE_BILL_RECEIPT,
  ADD_UPDATE_BILL_RECEIPT_SUCCESS,
  ADD_UPDATE_BILL_RECEIPT_FAILURE,
  GET_BILL_RECEIPT_BY_ID,
  GET_BILL_RECEIPT_BY_ID_SUCCESS,
  GET_BILL_RECEIPT_BY_ID_FAILURE,
  CLEAR_GENERATED_STATE,
} from "../../utils/constants";

// ------------------------------------
// Action handler methods
// ------------------------------------

export const fontListRequestHandler = (state, action) => {
  return {
    ...state,
    fontListLoading: true,
  };
};
export const fontListRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;

  return {
    ...state,
    fontListLoading: false,
    fontList: data.data,
  };
};
export const fontListRequestFailureHandler = (state, action) => {
  return {
    ...state,
    fontListLoading: false,
    fontList: [],
  };
};

export const taxListRequestHandler = (state, action) => {
  return {
    ...state,
    taxListLoading: true,
  };
};
export const taxListRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;

  return {
    ...state,
    taxListLoading: false,
    taxList: data.data,
  };
};
export const taxListRequestFailureHandler = (state, action) => {
  return {
    ...state,
    taxListLoading: false,
    taxList: [],
  };
};

export const categoryReceiptByIdRequestHandler = (state, action) => {
  return {
    ...state,
    isReceiptLoading: true,
  };
};
export const categoryReceiptByIdRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    isReceiptLoading: false,
    receiptByIdInfo: data.data,
  };
};
export const categoryReceiptByIdRequestFailureHandler = (state, action) => {
  return {
    ...state,
    isReceiptLoading: false,
    receiptByIdInfo: null,
  };
};

//CREATE RECEIPT
export const updateBillReceiptRequestHandler = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const updateBillReceiptRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 200) {
    // createNotification("success", "campaign created successfully", 2000);
    return {
      ...state,
      isLoading: false,
      receiptID: data.data,
    };
  }
  return {
    ...state,
    isLoading: false,
    receiptID: null,
  };
};

export const updateBillReceiptRequestFailureHandler = (state, action) => {
  if (action.payload.data === undefined) {
    // createNotification("error", "Something went wrong", 2000);
    return {
      ...state,
      isLoading: false,
      receiptID: null,
    };
  }
  const {
    payload: { data },
  } = action;
  if (data.status === 400) {
    // createNotification("error", "Failed!! please try again", 2000);
  }
  return {
    ...state,
    isLoading: false,
    receiptID: null,
  };
};

export const billReceiptByIdRequestHandler = (state, action) => {
  return {
    ...state,
    isReceiptLoading: true,
  };
};
export const billReceiptByIdRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    isReceiptLoading: false,
    generatedReceiptInfo: data.data,
  };
};
export const billReceiptByIdRequestFailureHandler = (state, action) => {
  return {
    ...state,
    isReceiptLoading: false,
    generatedReceiptInfo: null,
  };
};

export const clearGeneratedStateHandler = (state, action) => {
  return {
    ...state,
    receiptByIdInfo: null,
    receiptID: null,
    generatedReceiptInfo: null,
  };
};

// Action Handlers
const ACTION_HANDLERS = {
  [GET_FONT]: fontListRequestHandler,
  [GET_FONT_SUCCESS]: fontListRequestSuccessHandler,
  [GET_FONT_FAILURE]: fontListRequestFailureHandler,

  [GET_TAX]: taxListRequestHandler,
  [GET_TAX_SUCCESS]: taxListRequestSuccessHandler,
  [GET_TAX_FAILURE]: taxListRequestFailureHandler,

  [GET_CATEGORY_RECEIPT_BY_ID]: categoryReceiptByIdRequestHandler,
  [GET_CATEGORY_RECEIPT_BY_ID_SUCCESS]: categoryReceiptByIdRequestSuccessHandler,
  [GET_CATEGORY_RECEIPT_BY_ID_FAILURE]: categoryReceiptByIdRequestFailureHandler,

  [ADD_UPDATE_BILL_RECEIPT]: updateBillReceiptRequestHandler,
  [ADD_UPDATE_BILL_RECEIPT_SUCCESS]: updateBillReceiptRequestSuccessHandler,
  [ADD_UPDATE_BILL_RECEIPT_FAILURE]: updateBillReceiptRequestFailureHandler,

  [GET_BILL_RECEIPT_BY_ID]: billReceiptByIdRequestHandler,
  [GET_BILL_RECEIPT_BY_ID_SUCCESS]: billReceiptByIdRequestSuccessHandler,
  [GET_BILL_RECEIPT_BY_ID_FAILURE]: billReceiptByIdRequestFailureHandler,

  [CLEAR_GENERATED_STATE]: clearGeneratedStateHandler,
};

// default initial state
const initialState = {
  isLoading: false,
  isReceiptLoading: false,
  fontListLoading: false,
  taxListLoading: false,
  fontList: [],
  taxList: [],
  receiptByIdInfo: null,
  receiptID: null,
  generatedReceiptInfo: null,
};

export default function generateReceiptReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
