//Effects
import { all, call, put, takeLatest, select, delay } from "redux-saga/effects";

//Constants
import {
  REQUEST_ORGANIZATION_LIST,
  REQUEST_ORGANIZATION_LIST_SUCCESS,
  REQUEST_ORGANIZATION_LIST_FAILURE,
  CREATE_ORGANIZATION,
  CREATE_ORGANIZATION_SUCCESS,
  CREATE_ORGANIZATION_FAILURE,
  UPDATE_ORGANIZATION,
  UPDATE_ORGANIZATION_SUCCESS,
  UPDATE_ORGANIZATION_FAILURE,
  GET_ORGANIZATION_TYPES,
  GET_ORGANIZATION_TYPES_SUCCESS,
  GET_ORGANIZATION_TYPES_FAILURE,
  REQUEST_DASHBOARD_DATA,
  GET_PROJECT_SHORT_LIST,
  SELECT_ORGANIZATION,
  GET_ORGANIZATION_MEMBERS,
  GET_ORGANIZATION_MEMBERS_SUCCESS,
  GET_ORGANIZATION_MEMBERS_FAILURE,
  GET_ORGANIZATION_PLANS,
  GET_ORGANIZATION_PLANS_SUCCESS,
  GET_ORGANIZATION_PLANS_FAILURE,
  DELETE_ORGANIZATION,
  DELETE_ORGANIZATION_SUCCESS,
  DELETE_ORGANIZATION_FAILURE,
} from "../../utils/constants";

import { organizationsListData } from "../../datasets/organizations";

const colorList = ["#708CEB", "#76C794", "#73C0FC", "#F9828B"];

//Handle login request
export function* handleOrganizationListRequest(payload) {
  const store = yield select();
  try {
    yield delay(1000);
    yield put({
      type: REQUEST_ORGANIZATION_LIST_SUCCESS,
      payload: { data: organizationsListData },
    });

    let selected;
    const selectedIndex = organizationsListData.findIndex(
      (item) => item.is_active === true
    );
    if (selectedIndex === -1) {
      selected = organizationsListData[0];
      selected.color = colorList[0];
    } else {
      selected = organizationsListData[selectedIndex];
      selected.color = colorList[selectedIndex % 4];
    }
    let y = localStorage.getItem("selected_organization_id");
    if (selected && selected.id !== y) {
      let organization;
      // let organization = store.organization.list.filter((i) => {
      //   return i.id === y;
      // });
      store.organization.list.forEach((org, index) => {
        if (org.id === y) {
          organization = { ...org };
          organization.color = colorList[index % 4];
        }
      });
      selected = organization;
    }
    yield put({ type: SELECT_ORGANIZATION, payload: selected });
  } catch (err) {
    yield put({
      type: REQUEST_ORGANIZATION_LIST_FAILURE,
      payload: err,
    });
  }
}

// export function* handleSelectOrganization({ payload }) {
//   try {
//     yield put({ type: REQUEST_DASHBOARD_DATA, payload: payload.id });
//     yield put({ type: GET_PROJECT_SHORT_LIST, payload: payload });
//   } catch (err) {
//     yield put({
//       type: "",
//       payload: err,
//     });
//   }
// }

// export function* handleDeleteOrganization({ payload }) {
//   try {
//     const store = yield select();
//     let url = `organization_delete/${payload.id}/`;
//     let method = "POST";
//     const body = {
//       password: payload.password,
//       agreed: payload.agreed,
//     };
//     yield defaultApi(url, method, body);
//     let y = localStorage.getItem("selected_organization");
//     let organization = store.organization.list.filter((i) => {
//       return i.id === payload.id;
//     });
//     yield put({
//       type: DELETE_ORGANIZATION_SUCCESS,
//       payload: { data: payload.id },
//     });
//     if (organization[0].name === y) {
//       localStorage.removeItem("selected_organization");
//       localStorage.removeItem("selected_organization_id");
//       let activeOrganization = store.organization.list.filter((i) => {
//         return i.id !== payload.id && i.is_active;
//       });
//       if (activeOrganization && activeOrganization[0]) {
//         localStorage.setItem(
//           "selected_organization",
//           activeOrganization[0].name
//         );
//         localStorage.setItem(
//           "selected_organization_id",
//           activeOrganization[0].id
//         );
//         yield put({
//           type: SELECT_ORGANIZATION,
//           payload: activeOrganization[0],
//         });
//       } else {
//         yield put({
//           type: SELECT_ORGANIZATION,
//           payload: null,
//         });
//       }
//     }
//   } catch (err) {
//     yield put({
//       type: DELETE_ORGANIZATION_FAILURE,
//       payload: err,
//     });
//   }
// }

// export function* handleCreateOrganization({ payload }) {
//   try {
//     let url = `organization/`;
//     let method = "POST";
//     const response = yield defaultApi(url, method, payload);
//     yield put({ type: SELECT_ORGANIZATION, payload: response.data });
//     yield put({
//       type: CREATE_ORGANIZATION_SUCCESS,
//       payload: { data: response.data, notify: "POST" },
//     });
//   } catch (err) {
//     yield put({
//       type: CREATE_ORGANIZATION_FAILURE,
//       payload: err,
//     });
//   }
// }

// export function* handleGetOrganizationTypes() {
//   try {
//     let url = `organization_types/`;
//     let method = "GET";
//     const response = yield defaultApi(url, method);
//     yield put({
//       type: GET_ORGANIZATION_TYPES_SUCCESS,
//       payload: { data: response.data, notify: "GET" },
//     });
//   } catch (err) {
//     yield put({
//       type: GET_ORGANIZATION_TYPES_FAILURE,
//       payload: err,
//     });
//   }
// }

// export function* handleGetOrganizationPlans() {
//   try {
//     let url = `organization_packages/`;
//     let method = "GET";
//     const response = yield defaultApi(url, method);
//     yield put({
//       type: GET_ORGANIZATION_PLANS_SUCCESS,
//       payload: { data: response, notify: "GET" },
//     });
//   } catch (err) {
//     yield put({
//       type: GET_ORGANIZATION_PLANS_FAILURE,
//       payload: err,
//     });
//   }
// }

// export function* handleUpdateOrganization({ payload }) {
//   try {
//     const store = yield select();
//     const { id, ...data } = payload;
//     let url = `organization/${id}/`;
//     let method = "PATCH";
//     const response = yield defaultApi(url, method, data);
//     let y = localStorage.getItem("selected_organization");
//     let organization = store.organization.list.filter((i) => {
//       return i.id === payload.id;
//     });
//     if (organization[0].name === y) {
//       localStorage.removeItem("selected_organization");
//       localStorage.removeItem("selected_organization_id");
//       localStorage.removeItem("selected_organization_role");
//     }
//     yield put({
//       type: UPDATE_ORGANIZATION_SUCCESS,
//       payload: { data: response.data, notify: method },
//     });
//     yield call(handleOrganizationListRequest);
//   } catch (err) {
//     yield put({
//       type: UPDATE_ORGANIZATION_FAILURE,
//       payload: err,
//     });
//   }
// }

// export function* handleOrganizationMembersRequest({ payload }) {
//   try {
//     let url = `organization_members/${payload.id}/`;
//     let method = "GET";
//     const response = yield defaultApi(url, method);

//     yield put({
//       type: GET_ORGANIZATION_MEMBERS_SUCCESS,
//       payload: { data: response, notify: "GET" },
//     });
//   } catch (err) {
//     yield put({
//       type: GET_ORGANIZATION_MEMBERS_FAILURE,
//       payload: err,
//     });
//   }
// }

export default function* root() {
  yield all([
    takeLatest(REQUEST_ORGANIZATION_LIST, handleOrganizationListRequest),
    // takeLatest(SELECT_ORGANIZATION, handleSelectOrganization),
    // takeLatest(CREATE_ORGANIZATION, handleCreateOrganization),
    // takeLatest(GET_ORGANIZATION_TYPES, handleGetOrganizationTypes),
    // takeLatest(GET_ORGANIZATION_PLANS, handleGetOrganizationPlans),
    // takeLatest(UPDATE_ORGANIZATION, handleUpdateOrganization),
    // takeLatest(GET_ORGANIZATION_MEMBERS, handleOrganizationMembersRequest),
    // takeLatest(DELETE_ORGANIZATION, handleDeleteOrganization),
  ]);
}
