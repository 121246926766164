import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  NavbarContainer,
  LogoLinkContainer,
  LinkSection,
  LinkItem,
  LinkText,
  LinkUnderline,
  ProfileTab,
  RoundNameSection,
  DropDownHolder,
  DropDownSection,
  RotatingIcon,
} from "../../styledComponents/navbar";
import { CommonText } from "../../styledComponents/common";
import FirstRoundLetter from "../../components/ColorLetterRound/FirstRoundLetter";
import logoWeb from "../../assets/img/logo.svg";
import dropDownArrow from "../../assets/img/dropdown-arrow.svg";

const AdminNavbar = (props) => {
  const [profileOpen, setProfileOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const openDropDown = () => {
    setProfileOpen(!profileOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem("user_id");
    localStorage.removeItem("subscription_plan_id");
    localStorage.removeItem("role");
    localStorage.removeItem("plan_status");
    localStorage.removeItem("user_name");
    navigate("/auth/login");
    props.loginProfileCreatedReset();
  };

  return (
    <NavbarContainer>
      <LogoLinkContainer>
        <img src={logoWeb} alt="receipt makerly" width="100%" />
        <LinkSection>
          <LinkItem
            isSelected={location.pathname === "/user/dashboard"}
            onClick={() => navigate("/user/dashboard")}
          >
            All Receipts
          </LinkItem>
          <LinkItem
            isSelected={location.pathname === "/user/my-receipts"}
            onClick={() => navigate("/user/my-receipts")}
          >
            My Receipts
          </LinkItem>
          <LinkItem
            isSelected={location.pathname === "/user/current-plan"}
            onClick={() => navigate("/user/current-plan")}
          >
            Subscription Plan
          </LinkItem>
        </LinkSection>
      </LogoLinkContainer>
      <ProfileTab onClick={() => openDropDown()}>
        <RoundNameSection>
          {localStorage.getItem("user_name") && (
            <>
              <FirstRoundLetter
                text={localStorage.getItem("user_name")}
                size="30px"
                fontSize="13px"
              />
              <div> {localStorage.getItem("user_name")} </div>
            </>
          )}
        </RoundNameSection>
        <RotatingIcon
          active={profileOpen}
          src={dropDownArrow}
          alt="dropdown arrow icon"
        />
        <DropDownHolder active={profileOpen}>
          <DropDownSection
            active={profileOpen}
            onClick={(e) => e.stopPropagation()}
          >
            {/* <CommonText>Account Settings</CommonText> */}
            <CommonText onClick={() => handleLogout()}>Logout</CommonText>
          </DropDownSection>
        </DropDownHolder>
      </ProfileTab>
    </NavbarContainer>
  );
};

export default AdminNavbar;
